var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "ConnectivityStatusComponent d-flex align-items-center",
        _vm.statusColor,
      ],
    },
    [
      _vm.isNonConnected
        ? _c(
            "div",
            { staticClass: "d-flex emobg-color-ink" },
            [
              _vm.showIcon
                ? _c("ui-icon", {
                    attrs: {
                      icon: _vm.ICONS.alertAloneFull,
                      size: _vm.iconSize,
                    },
                  })
                : _vm._e(),
              _vm.showText
                ? _c(
                    "span",
                    {
                      staticClass: "mr-1 emobg-body-1",
                      class: [
                        `${_vm.iconSize === _vm.SIZES.large ? "ml-2" : "ml-1"}`,
                      ],
                    },
                    [_vm._v(" Non-connected ")]
                  )
                : _vm._e(),
            ],
            1
          )
        : [
            _vm.showIcon && _vm.isStatusCorrect
              ? _c("ui-icon", {
                  attrs: { icon: _vm.ICONS.connectivity, size: _vm.iconSize },
                })
              : _vm._e(),
            _vm.showText
              ? _c(
                  "span",
                  {
                    staticClass: "mr-1",
                    class: [
                      `${_vm.iconSize === _vm.SIZES.large ? "ml-2" : "ml-1"}`,
                    ],
                  },
                  [_vm._v(" " + _vm._s(_vm.statusText) + " ")]
                )
              : _vm._e(),
            _vm.showTooltipChecked
              ? _c("ui-tooltip", { attrs: { tooltip: _vm.textOfTooltip } }, [
                  _c(
                    "div",
                    { staticClass: "d-flex align-items-center" },
                    [
                      _c("ui-icon", {
                        staticClass:
                          "emobg-color-ink-light emobg-color-ink-hover",
                        attrs: {
                          icon: _vm.ICONS.infoFull,
                          size: _vm.SIZES.small,
                          "data-test-id": "icon",
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }