import each from 'lodash/each';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import includes from 'lodash/includes';
import intersection from 'lodash/intersection';
import keys from 'lodash/keys';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import moment from 'moment';
import { computed, ref } from 'vue';

import {
  AVAILABLE_ZONE_TYPES,
  DATA_TO_GOOGLE_ZONE,
  DEFAULT_MAP_OPTIONS,
  DEFAULT_ZONE_OPTIONS,
  ZONE_TYPES,
  ZONE_TYPE_TO_GCLASS,
} from './GoogleMap.const';

/**
 * Creates a google map instance and tools to manage it.
 * Zone listeners provide same arguments as google but the zone is injected as first argument.
 * https://developers.google.com/maps/documentation/javascript/shapes?hl=en#editable_events
 * ZoneCreatedListener provide same arguments as google OverlayCompleteEvent:
 * https://developers.google.com/maps/documentation/javascript/reference/drawing?hl=en#OverlayCompleteEvent.
 * {
 *   googleInstance,
 *   zoneListener: {
 *     polygon: {
 *       [polygon_event]: handler(zone, polygon_arg_1, polygon_arg_2),
 *     },
 *   },
 *   onZoneCreated: handler,
 *   onZoneClick: handler,
 *   onDrawingModeChange: handler,
 * }
 * @param initialData
 * @returns {{createOrUpdateMarkers: createOrUpdateMarkers, fitMap: fitMap, createOrUpdateZones: createOrUpdateZones, deleteZones: deleteZones, createOrUpdateMarker: createOrUpdateMarker, initDrawingManager: initDrawingManager, initMap: initMap, removeDrawingManager: removeDrawingManager, createOrUpdateZone: createOrUpdateZone, drawingModes: ComputedRef<*>, deleteMarkers: deleteMarkers}}
 */
export const useGoogleMaps = initialData => {
  const {
    googleInstance,
    zoneListeners = {},
    onMapLoaded,
    onZoneCreated,
    onZoneClick,
    onDrawingModeChange,
  } = initialData;

  if (!googleInstance) {
    throw Error('Google instance not provided');
  }

  let map;
  let drawingManager;
  const drawingModes = ref(AVAILABLE_ZONE_TYPES);
  const markers = ref([]);
  const zones = ref([]);

  /**
   * Created the google map.
   * @param elementToMount
   * @param mapOptions
   */
  const initMap = (elementToMount, mapOptions) => {
    map = new googleInstance.maps.Map(elementToMount, {
      ...DEFAULT_MAP_OPTIONS,
      ...mapOptions,
    });

    if (onMapLoaded) {
      googleInstance.maps.event.addListenerOnce(map, 'idle', onMapLoaded);
    }
  };

  /**
   * Initialize the drawing manager. If this method is called and drawing manager already exist it will override the options only.
   * @param drawingManagerOptions
   */
  const initDrawingManager = drawingManagerOptions => {
    if (drawingManager) {
      drawingManager.setOptions({
        ...drawingManagerOptions,
        drawingControlOptions: {
          drawingModes: drawingModes.value,
        },
      });
      return;
    }

    const drawingModesFromOptions = get(drawingManagerOptions, 'drawingControlOptions.drawingModes', drawingModes.value);
    drawingModes.value = intersection(drawingModesFromOptions, AVAILABLE_ZONE_TYPES);

    drawingManager = new googleInstance.maps.drawing.DrawingManager({
      polygonOptions: DEFAULT_ZONE_OPTIONS,
      circleOptions: DEFAULT_ZONE_OPTIONS,
      rectangleOptions: DEFAULT_ZONE_OPTIONS,
      ...drawingManagerOptions,
      drawingControl: true,
      drawingControlOptions: {
        drawingModes: drawingModes.value,
      },
      drawingMode: null,
      map,
    });

    if (onZoneCreated) {
      drawingManager.addListener('overlaycomplete', event => {
        onZoneCreated(event);
        event.overlay.setMap(null);
      });
    }

    if (onDrawingModeChange) {
      drawingManager.addListener('drawingmode_changed', event => {
        onDrawingModeChange(event);
      });
    }
  };

  /**
   * Remove drawing manager instance and set zone editable to false.
   */
  const removeDrawingManager = () => {
    if (drawingManager) {
      drawingManager.setMap(null);
      drawingManager = undefined;
    }
  };

  /**
   * Specify the drawing mode. Only works if drawing manager is initialized.
   * @param drawMode
   */
  const setDrawingMode = drawMode => {
    if (!drawingManager) {
      return;
    }

    drawingManager.setDrawingMode(drawMode);
  };

  /**
   * Specify the zone appearance.
   * {
   *   fillColor,
   *   fillOpacity,
   *   strokeColor,
   *   strokeOpacity,
   *   strokeWidth,
   * }
   * @param commonAppearanceOptions = DEFAULT_ZONE_OPTIONS
   */
  const setZoneAppearanceOptions = (commonAppearanceOptions = DEFAULT_ZONE_OPTIONS) => {
    const commonKeys = keys(DEFAULT_ZONE_OPTIONS);
    const commonExistingOptions = pick(commonAppearanceOptions, commonKeys);
    each(zones.value, zone => zone.setOptions(commonExistingOptions));
  };

  /**
   * Set editable property for all zones.
   * @param isEditable = true
   */
  const setZonesEditable = (isEditable = true) => {
    each(zones.value, zone => zone.isEditable && zone.setEditable(isEditable));
  };

  /**
   * Create or update an existing marker.
   * Marker options are the ones of google. Extra "id" prop to identify the marker.
   * {
   *   id: 'test-marker',
   *   position: { lat, lng },
   *   ...google_marker_options
   * }
   * @param markerOptions
   */
  const createOrUpdateMarker = markerOptions => {
    const existingMarker = find(markers.value, { id: get(markerOptions, 'id') });

    if (existingMarker) {
      existingMarker.setOptions(markerOptions);
    } else {
      const newMarker = new googleInstance.maps.Marker({
        id: `marker_${moment().format('X')}`,
        ...markerOptions,
        map,
      });

      markers.value.push(newMarker);
    }
  };

  /**
   * Same as "createOrUpdateMarker" method but you can provide an array of markerOptions.
   * [{
   *   id: 'test-marker',
   *   position: { lat, lng },
   * }]
   * @param markerOptionsArray
   */
  const createOrUpdateMarkers = markerOptionsArray => {
    each(markerOptionsArray, markerOption => {
      createOrUpdateMarker(markerOption);
    });
  };

  /**
   * Get an object with specific properties to create a zone according to the type.
   * If type polygon a custom `getBounds` method is injected so fitting the map will be easier.
   * @param zoneOptions
   * @returns {{path, getBounds: (function(): googleInstance.maps.LatLngBounds)}|{bounds: googleInstance.maps.LatLngBounds}|*}
   */
  const getSpecificPropertiesByZone = zoneOptions => {
    let zoneProps = DATA_TO_GOOGLE_ZONE(zoneOptions);

    if (zoneOptions.type === ZONE_TYPES.polygon) {
      zoneProps = {
        ...zoneProps,
        getBounds: () => {
          const bounds = new googleInstance.maps.LatLngBounds();
          each(zoneOptions.data, path => bounds.extend(path));
          return bounds;
        },
      };
    }

    return zoneProps;
  };

  /**
   * Register specific zone listeners for each type of zone.
   * @param zone
   */
  const registerZoneListeners = zone => {
    const listeners = get(zoneListeners, zone.type);
    const objectToListen = zone.type === ZONE_TYPES.polygon ? zone.getPath() : zone;

    each(listeners, (handler, key) => {
      objectToListen.addListener(key, (...args) => handler(zone, ...args));
    });

    if (onZoneClick) {
      zone.addListener('click', () => onZoneClick(zone));
    }
  };

  /**
   * Removes a zone proving the ID
   * @param id
   */
  const deleteZone = id => {
    const foundZoneIndex = findIndex(zones.value, { id });

    if (foundZoneIndex > -1) {
      const zone = zones.value[foundZoneIndex];
      zone.setMap(null);
      zones.value.splice(foundZoneIndex, 1);
    }
  };

  /**
   * Removes zones providing an array of IDs
   * @param ids
   */
  const deleteZones = ids => {
    each(ids, id => {
      deleteZone(id);
    });
  };

  /**
   * Create or update a zone according to the ID.
   * {
   *   id: 'Zone_1',
   *   type: 'polygon',
   *   data: [{...path1}, {...path2}]
   *   isEditable: false,
   *   ...google_overlay_options,
   * }
   * @param zoneOptions
   */
  const createOrUpdateZone = zoneOptions => {
    const type = get(zoneOptions, 'type');
    const existingZone = find(zones.value, { id: get(zoneOptions, 'id') });

    if (existingZone) {
      // If the zone already exist but the type changes we need to create the zone again.
      if (existingZone.type !== type) {
        deleteZone(existingZone.id);
        createOrUpdateZone(zoneOptions);
      }
      existingZone.setOptions({
        ...omit(zoneOptions, ['data']),
        ...getSpecificPropertiesByZone(zoneOptions),
      });
    } else {
      if (!includes(AVAILABLE_ZONE_TYPES, type)) {
        throw Error(`Zone of type [${type}] not available.`);
      }

      const newZone = new googleInstance.maps[ZONE_TYPE_TO_GCLASS[type]]({
        id: `zone_${moment().format('X')}`,
        isEditable: true,
        ...DEFAULT_ZONE_OPTIONS,
        ...omit(zoneOptions, ['data']),
        ...getSpecificPropertiesByZone(zoneOptions),
        map,
      });

      registerZoneListeners(newZone);

      zones.value.push(newZone);
    }
  };

  /**
   * Same as createOrUpdateZone but executed in batch.
   * @param zoneOptionArray
   */
  const createOrUpdateZones = zoneOptionArray => {
    each(zoneOptionArray, zoneOption => {
      createOrUpdateZone(zoneOption);
    });
  };

  /**
   * Removes a marker providing the ID.
   * @param id
   */
  const deleteMarker = id => {
    const foundMarkerIndex = findIndex(markers.value, { id });

    if (foundMarkerIndex > -1) {
      const marker = markers.value[foundMarkerIndex];
      marker.setMap(null);
      markers.value.splice(foundMarkerIndex, 1);
    }
  };

  /**
   * Removes markers providing an array of IDs
   * @param ids
   */
  const deleteMarkers = ids => {
    each(ids, id => {
      deleteMarker(id);
    });
  };

  /**
   * Fit the map to the actual markers and zones.
   */
  const fitMap = () => {
    const bounds = new googleInstance.maps.LatLngBounds();

    each(markers.value, marker => {
      bounds.extend(marker.position);
    });

    each(zones.value, zone => {
      bounds.union(zone.getBounds());
    });

    if (!bounds.isEmpty()) {
      map.fitBounds(bounds);
    }
  };

  return {
    createOrUpdateMarker,
    createOrUpdateMarkers,
    createOrUpdateZone,
    createOrUpdateZones,
    deleteMarkers,
    deleteZones,
    drawingModes: computed(() => drawingModes.value),
    fitMap,
    initDrawingManager,
    initMap,
    removeDrawingManager,
    setDrawingMode,
    setZoneAppearanceOptions,
    setZonesEditable,
  };
};
