<script>
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import set from 'lodash/set';
import Cookies from 'js-cookie';
import { mapState } from 'vuex';
import { MuiCollapse } from '@emobg/motion-ui/v1';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';

export default {
  name: 'OverviewCollapse',
  components: {
    MuiCollapse,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isOpen: true,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      user: state => state.user.data,
    }),
  },
  created() {
    this.COLLAPSE_REF_NAME = 'collapse';
    if (this.id) {
      this.cookieName = `msbo-overviews-states-${this.user.id}`;
      const overviewCookie = Cookies.get(this.cookieName);
      this.overviewCookie = overviewCookie ? JSON.parse(overviewCookie) : {};

      if (isEmpty(this.overviewCookie)) {
        this.saveCookies();
        return;
      }

      const overviewIdCookie = get(this.overviewCookie, this.id);
      if (isNil(overviewIdCookie)) {
        this.saveCookies();
      } else {
        this.isOpen = overviewIdCookie;
      }
    }
  },
  mounted() {
    this.observer = new MutationObserver(() => {
      this.$refs[this.COLLAPSE_REF_NAME].updateHeight();
    });
    this.observer.observe(this.$refs[this.COLLAPSE_REF_NAME].$el, { subtree: true, childList: true });
  },
  methods: {
    toggleCollapse() {
      this.isOpen = !this.isOpen;
      this.saveCookies();
    },
    saveCookies() {
      if (this.id) {
        set(this.overviewCookie, this.id, this.isOpen);
        Cookies.set(this.cookieName, JSON.stringify(this.overviewCookie), { expires: 7 });
      }
    },
  },
};
</script>

<template>
  <div class="OverviewCollapse w-100">
    <div class="OverviewCollapse__header d-flex flex-wrap align-items-center py-3">
      <div class="OverviewCollapse__header-info flex-grow-1">
        <slot name="header" />
      </div>
      <div class="Overview__actions">
        <ui-button
          :face="FACES.outline"
          :color="GRAYSCALE.inkLight"
          @click="toggleCollapse"
        >
          <div class="d-flex align-items-center">
            {{ isOpen ? 'Hide' : 'Show' }}
            <ui-icon
              :icon="isOpen ? ICONS.arrowUp : ICONS.arrowDown"
              class="ml-1"
            />
          </div>
        </ui-button>
        <slot name="actions" />
      </div>
    </div>
    <hr class="emobg-border-top-1 emobg-border-color-ground-light">
    <MuiCollapse
      :ref="COLLAPSE_REF_NAME"
      :open="isOpen"
      class="OverviewCollapse__content"
    >
      <slot />
    </MuiCollapse>
  </div>
</template>
