import get from 'lodash/get';
import replace from 'lodash/replace';
import { axiosClientConfig } from '@emobg/web-api-client';
import { HTTP_STATUS_CODES, LOG_TYPE, logger } from '@emobg/web-utils';
import { authService } from '@emobg/access-utils';

import { SHARED_URLS } from '@/router/RouterMap';

import { getUpdatedHeaders } from './getUpdatedHeaders';
import { retryRequest } from './retryRequest';

export default class Api {
  constructor(ENV) {
    const host = get(ENV, 'axios.baseURL');
    const token = `Bearer ${get(authService, 'instance.accessToken', '')}`;
    if (!token) {
      logger.message('No authData in authService instance', LOG_TYPE.error);
    }

    const requestInterceptors = [request => {
      // eslint-disable-next-line no-param-reassign
      request.headers = getUpdatedHeaders({ request, ENV });

      return request;
    }];
    const responseInterceptors = [
      null,
      async state => {
        const isAuthenticationError = state.response.status === HTTP_STATUS_CODES.unauthorized;
        const isBackofficeApi = state.config.baseURL === host;
        const baseURL = get(state, 'response.config.baseURL', '');
        const responseURL = get(state, 'response.request.responseURL', '');
        const isMFAPath = replace(responseURL, baseURL, '') === SHARED_URLS.mfa && get(window, 'location.pathname', '') !== SHARED_URLS.mfa;

        if (isAuthenticationError && isBackofficeApi) {
          return retryRequest({ ENV, state });
        }

        if (isMFAPath) {
          window.location.href = SHARED_URLS.mfa;
        }

        throw state.response;
      }];

    axiosClientConfig.init({
      host,
      token,
      requestInterceptors,
      responseInterceptors,
    });
  }
}
