<script>
import { getImageName } from '@emobg/web-utils';
import { downloadFile, isPdf } from '@/utils';

export default {
  name: 'DocumentPreview',
  props: {
    image: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: '',
    },
    isDownloadHidden: {
      type: Boolean,
      default: false,
    },
    isPointerDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    imageStyle() {
      return {
        'background-image': `url('${this.image}')`,
        'background-size': 'cover',
        'background-position': 'center',
        height: '212px',
      };
    },
    isPdf() {
      return isPdf(this.image);
    },
  },
  methods: {
    downloadFile,
    getImageName,
  },
};
</script>
<template>
  <div class="DocumentPreview">
    <div
      class="p-2 emobg-background-color-ground-lighter emobg-border-color-ground emobg-border-1 emobg-border-radius-small"
      @click="$emit('click')"
    >
      <div
        :class="['d-flex flex-column emobg-background-color-white justify-content-center align-items-center', { 'cursor-pointer': !isPointerDisabled }]"
        :style="imageStyle"
      >
        <template v-if="isPdf">
          Preview not available
          <ui-button
            :face="FACES.text"
            :color="COLORS.primary"
            class="mt-1"
            @clickbutton.stop="() => { downloadFile({ url: image, name: name || getImageName(image) }) }"
          >
            Open
            <ui-icon
              :icon="ICONS.externalUrl"
              :size="ICONS_SIZES.xSmall"
              class="ml-1"
            />
          </ui-button>
        </template>
      </div>
    </div>
    <div
      v-if="!isDownloadHidden"
      class="d-flex justify-content-end mt-1"
    >
      <ui-icon
        :icon="ICONS.download"
        :size="ICONS_SIZES.xSmall"
        :color="GRAYSCALE.inkLight"
        class="cursor-pointer p-1"
        @click="() => { downloadFile({ url: image, name: name || getImageName(image) }) }"
      />
    </div>
  </div>
</template>
