import { BI_PERMISSIONS } from '../const/permissions';
import businessIntelligence from './BusinessIntelligenceRouterMap';

const BusinessIntelligenceView = () => import(/* webpackChunkName: "business-intelligence" */'../BusinessIntelligenceView.vue');

const ReportsView = () => import(/* webpackChunkName: "reports" */'../Reports/ReportsView.vue');
const ReportDetailView = () => import(/* webpackChunkName: "reports" */'../ReportDetail/ReportDetailView.vue');
const ReportsListView = () => import(/* webpackChunkName: "reports" */'../ReportsList/ReportsListView.vue');

export default [
  {
    path: 'business-intelligence',
    name: businessIntelligence.index,
    component: BusinessIntelligenceView,
    redirect: { name: businessIntelligence.reports.index },
    meta: {
      label: 'Analytics',
    },
    children: [
      {
        path: 'reports',
        component: ReportsView,
        children: [
          {
            name: businessIntelligence.reports.index,
            path: '/',
            component: ReportsListView,
            meta: {
              permissions: [BI_PERMISSIONS.viewAnalyticsReports],
              label: 'Report',
            },
          },
          {
            path: ':reportUuid',
            name: businessIntelligence.reports.detail,
            component: ReportDetailView,
            meta: {
              label: 'Report detail',
              permissions: [BI_PERMISSIONS.viewAnalyticsReports],
            },
          },
        ],
      },
    ],
  },
];
