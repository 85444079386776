<script>
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import camelCase from 'lodash/camelCase';
import { FALLBACK_MESSAGE, sentenceCase } from '@emobg/web-utils';
import {
  CONNECTIVITY_COLORS,
  CONNECTIVITY_STATUS,
  CONNECTIVITY_TOOLTIPS,
} from './constants/connectivity.const';

export default {
  name: 'ConnectivityStatusComponent',
  props: {
    status: {
      type: String,
      default: null,
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
    showText: {
      type: Boolean,
      default: true,
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    iconSize: {
      type: String,
      default: 'medium',
    },
    isNonConnected: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isStatusCorrect() {
      return !isEmpty(CONNECTIVITY_STATUS[this.statusNormalized]);
    },
    statusNormalized() {
      return camelCase(this.status);
    },
    textOfTooltip() {
      return get(CONNECTIVITY_TOOLTIPS, this.statusNormalized);
    },
    showTooltipChecked() {
      return this.showTooltip && this.textOfTooltip;
    },
    statusColor() {
      return get(CONNECTIVITY_COLORS, this.statusNormalized, '');
    },
    statusText() {
      return this.status && this.isStatusCorrect
        ? sentenceCase(this.status)
        : FALLBACK_MESSAGE.dash;
    },
  },
};
</script>

<template>
  <div :class="['ConnectivityStatusComponent d-flex align-items-center', statusColor]">
    <div
      v-if="isNonConnected"
      class="d-flex emobg-color-ink"
    >
      <ui-icon
        v-if="showIcon"
        :icon="ICONS.alertAloneFull"
        :size="iconSize"
      />
      <span
        v-if="showText"
        class="mr-1 emobg-body-1"
        :class="[`${iconSize === SIZES.large ? 'ml-2' : 'ml-1'}`]"
      >
        Non-connected
      </span>
    </div>
    <template v-else>
      <ui-icon
        v-if="showIcon && isStatusCorrect"
        :icon="ICONS.connectivity"
        :size="iconSize"
      />
      <span
        v-if="showText"
        class="mr-1"
        :class="[`${iconSize === SIZES.large ? 'ml-2' : 'ml-1'}`]"
      >
        {{ statusText }}
      </span>
      <ui-tooltip
        v-if="showTooltipChecked"
        :tooltip="textOfTooltip"
      >
        <div class="d-flex align-items-center">
          <ui-icon
            :icon="ICONS.infoFull"
            :size="SIZES.small"
            class="emobg-color-ink-light emobg-color-ink-hover"
            data-test-id="icon"
          />
        </div>
      </ui-tooltip>
    </template>
  </div>
</template>
