var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.firstInlineNotification
    ? _c(
        "ui-alert",
        {
          key: _vm.firstInlineNotification.id,
          staticClass:
            "InlineNotificationComponent w-100 d-inline-block animated fadeIn",
          attrs: { color: _vm.firstInlineNotification.type },
        },
        [
          _c(
            "div",
            {
              staticClass: "d-flex justify-content-between align-items-center",
            },
            [
              _c(
                "div",
                { staticClass: "d-flex align-items-center" },
                [
                  _c("ui-icon", {
                    staticClass: "mr-2",
                    attrs: {
                      color: _vm.firstInlineNotification.type,
                      icon: _vm.get(
                        _vm.NOTIFICATION_ICONS,
                        _vm.firstInlineNotification.type
                      ),
                      size: _vm.ICONS_SIZES.large,
                    },
                  }),
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(_vm.firstInlineNotification.message),
                    },
                  }),
                ],
                1
              ),
              _vm.firstInlineNotification.textAction
                ? _c(
                    "div",
                    {
                      staticClass: "emobg-link-primary emobg-body-2 ml-2",
                      on: { click: _vm.removeNotification },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.firstInlineNotification.textAction) +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }