var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._b({ staticClass: "CommentSkeleton py-4" }, "div", _vm.$attrs, false),
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex justify-content-between align-items-center pb-2 mb-1",
        },
        [
          _c(
            "div",
            { staticClass: "d-flex flex-column flex-fill" },
            [
              _c("ui-skeleton", {
                staticStyle: { "max-width": "200px" },
                attrs: { height: "30" },
              }),
              _c("ui-skeleton", {
                staticStyle: { "max-width": "150px" },
                attrs: { height: "30" },
              }),
            ],
            1
          ),
          _c("ui-skeleton", { attrs: { height: "50" } }),
        ],
        1
      ),
      _c("ui-skeleton", { attrs: { height: "50" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }