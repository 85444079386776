var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "DragFileComponent d-flex flex-column" },
    [
      _c(
        "MuiFileDrop",
        {
          ref: "fileDrop",
          class: [
            "cursor-pointer",
            {
              "DragFileComponent__uploader__box p-2 border-radius d-flex justify-content-center ":
                !_vm.hidden,
            },
          ],
          style: _vm.draggableHeight && { height: `${_vm.draggableHeight}px` },
          attrs: {
            accept: _vm.acceptedFormats,
            "max-items": _vm.multiple ? null : 1,
            "clear-items": !_vm.multiple,
            "data-test-id": "file_drop",
          },
          on: { change: _vm.onFileChange },
          model: {
            value: _vm.fileDrop,
            callback: function ($$v) {
              _vm.fileDrop = $$v
            },
            expression: "fileDrop",
          },
        },
        [
          !_vm.hidden
            ? [
                _vm.hasPreview
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-column align-items-center justify-content-center emobg-background-color-white h-100",
                      },
                      [_c("p", [_vm._v(" Preview not available ")])]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-column align-items-center justify-content-center p-4 emobg-color-ink-light full-height",
                    style: _vm.imageForPreview
                      ? {
                          "background-image": `url('${_vm.imageForPreview}')`,
                          "background-size": "cover",
                          "background-position": "center",
                        }
                      : {},
                    attrs: { "data-test-id": "preview_image" },
                  },
                  [
                    _c("i", {
                      staticClass:
                        "mx-auto icons-upload icons-large emobg-font-weight-semibold",
                      class: { invisible: _vm.imageForPreview },
                    }),
                    _c(
                      "p",
                      {
                        staticClass: "mx-auto mt-3",
                        class: { invisible: _vm.imageForPreview },
                      },
                      [
                        _vm._v(" Drop your file here or "),
                        _c(
                          "span",
                          {
                            staticClass:
                              "emobg-color-primary emobg-font-weight-semibold",
                          },
                          [_vm._v("Browse")]
                        ),
                        _vm._t("extraInformation"),
                      ],
                      2
                    ),
                  ]
                ),
              ]
            : _vm._t("default"),
        ],
        2
      ),
      !_vm.hidden
        ? _c("FileListComponent", {
            attrs: {
              files: _vm.normalizedFilesForList,
              "on-delete-item": _vm.removeFile,
              deleteable: "",
              "data-test-id": "new-files_list",
            },
          })
        : _vm._e(),
      !_vm.hidden
        ? _c("FileListComponent", {
            attrs: {
              files: _vm.existingFiles,
              "on-delete-item": _vm.removeExistingFile,
              deleteable: "",
              "data-test-id": "existing-files_list",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }