<script>
import moment from 'moment-timezone';
import { ClickOutside } from '@emobg/vue-base';

export default {
  directives: {
    ClickOutside,
  },
  model: {
    prop: 'date',
    event: 'change',
  },
  props: {
    hideIcon: {
      type: Boolean,
      default: false,
    },
    format: {
      type: String,
      default: 'MMM YYYY',
    },
    disableTyping: {
      type: Boolean,
      default: false,
    },
    date: {
      type: Object,
      default: () => moment().startOf('month'),
    },
  },
  data() {
    return {
      isMonthPickerVisible: false,
      selectedDate: moment().startOf('month'),
    };
  },
  methods: {
    setMonthPickerVisibility(isVisible) {
      this.isMonthPickerVisible = isVisible;
    },
    onChangeDate(newDate) {
      this.selectedDate = moment(newDate).startOf('month');
      this.$emit('change', moment(newDate));
      this.setMonthPickerVisibility(false);
    },
  },
};
</script>

<template>
  <div
    v-click-outside="() => setMonthPickerVisibility(false)"
    class="MonthSelectorComponent mt-n2"
    data-test-id="month_selector"
  >
    <ui-text-input
      :value="date ? date.format(format) : null"
      :icon-left="hideIcon ? null : ICONS.calendar"
      :placeholder="format"
      :disabled="disableTyping"
      name="date"
      reverse
      data-test-id="toggle_window-input"
      @click="setMonthPickerVisibility(true)"
    />
    <div
      v-show="isMonthPickerVisible"
      class="VehicleAllocationView__calendarWrapper"
      data-test-id="vehicle_allocation_calendar_wrapper"
    >
      <ui-date-year-month-picker
        :selected-month.prop="selectedDate.month()"
        :selected-year.prop="selectedDate.year()"
        @selectyearmonth="({ detail }) => onChangeDate(detail)"
      />
    </div>
  </div>
</template>
