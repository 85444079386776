<script>
import debounce from 'lodash/debounce';
import { DELAY } from '@emobg/web-utils';

export default {
  name: 'TableSearchBoxComponent',
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
  },
  created() {
    this.debouncedSearchData = debounce(search => {
      this.$emit('update:modelValue', search);
    }, DELAY.short);
  },
  methods: {
    searchData(search) {
      this.debouncedSearchData(search);
    },
  },
};
</script>
<template>
  <div class="TableSearchBoxComponent w-50 mx-2">
    <ui-text-input
      :value="modelValue"
      :icon-left="ICONS.search"
      reverse
      name="search"
      placeholder="Search in table"
      @changevalue="event => searchData(event.detail)"
    />
  </div>
</template>
