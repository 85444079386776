<script>
import filter from 'lodash/filter';
import get from 'lodash/get';
import invoke from 'lodash/invoke';
import map from 'lodash/map';
import replace from 'lodash/replace';
import { mapMutations } from 'vuex';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { NOTIFICATION_ICONS } from '@/constants/notifications';

export default {
  name: 'NotificationList',
  computed: {
    floatingNotifications() {
      const pathToNotifications = `${replace(DOMAINS_MODEL.app.messages.notifications.index, /\//g, '.')}.notifications`;
      const notifications = get(this.$store.state, pathToNotifications, []);
      return map(filter(notifications, 'isFloating'), notification => {
        const isAutomaticClosed = !notification.textAction || notification.delay;
        if (isAutomaticClosed) {
          setTimeout(() => {
            this.remove(notification.id);
          }, notification.delay || 10000);
        }
        return {
          ...notification,
          isAutomaticClosed,
        };
      });
    },
  },
  created() {
    this.NOTIFICATION_ICONS = NOTIFICATION_ICONS;
  },
  methods: {
    ...mapMutations(DOMAINS_MODEL.app.messages.notifications.index, ['remove']),
    get,
    removeNotification(notification) {
      invoke(notification, 'action');
      this.remove(notification.id);
    },
  },
};
</script>

<template>
  <div
    class="NotificationList position-fixed z-index-1032 d-inline-flex flex-column align-items-end w-100"
    data-test-id="notification-list"
  >
    <ui-notification
      v-for="notification in floatingNotifications"
      :key="notification.id"
      :type="notification.type"
      :icon="get(NOTIFICATION_ICONS, notification.type)"
      :dismissible="false"
      class="position-relative mb-4"
      data-test-id="notification"
      @dismissNotification="removeNotification(notification)"
    >
      <span
        data-test-id="message"
        v-html="notification.message"
      />
      <div
        v-if="notification.textAction"
        slot="customAction"
      >
        <div
          class="emobg-link-primary emobg-body-2 ml-2 NotificationList--removeNotification"
          @click="removeNotification(notification)"
        >
          {{ notification.textAction }}
        </div>
      </div>
    </ui-notification>
  </div>
</template>
