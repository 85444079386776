var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ui-button",
    _vm._b(
      {
        staticClass: "RemoveButton",
        attrs: { color: _vm.COLORS.danger, narrow: "" },
        on: {
          clickbutton: function ($event) {
            return _vm.$emit("click")
          },
        },
      },
      "ui-button",
      _vm.$attrs,
      false
    ),
    [_vm._v(" Remove ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }