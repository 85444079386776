import dashboard from './DashboardRouterMap';
const DashboardView = () => import(/* webpackChunkName: "shared" */'@domains/Dashboard/DashboardView');

export default [
  {
    path: '/dashboard',
    component: DashboardView,
    name: dashboard.index,
  },
];
