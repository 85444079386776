<script>
import EnvLabelComponent from './components/EnvLabelComponent';

export default {
  /* eslint-disable vue/match-component-file-name */
  name: 'DefaultView',
  components: {
    EnvLabelComponent,
  },
};
</script>

<template>
  <div
    id="app"
    class="DefaultView d-flex flex-column full-min-height"
  >
    <RouterView />
    <EnvLabelComponent />
  </div>
</template>

<style lang="scss">
  @import "./css/template_default.scss";
</style>
