import get from 'lodash/get';
import { mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';

export default {
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      permissions: state => get(state, 'user.data.permissions'),
    }),
  },
};
