<script>

const envNames = {
  development: 'development',
  staging: 'staging',
  local: 'local',
};

export default {
  name: 'EnvLabelComponent',
  data() {
    return {
      envName: envNames[process.env.NODE_ENV] || null,
    };
  },
};
</script>

<template>
  <span
    v-if="envName"
    :class="[
      'EnvLabelComponent',
      { [`EnvLabelComponent--${envName}`]: envName }
    ]"
  >
    {{ envName }}
  </span>
</template>

<style lang="scss" scoped>
.EnvLabelComponent {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99999;
  padding: 10px;
  margin: 10px;
  color: "white";
  opacity: 1;
  animation-name: blink;
  animation-duration: 2s;
  animation-iteration-count: infinite;

  &--dev,
  &--development {
    background-color: #6ec190;
  }

  &--staging {
    background-color: #ffc845;
  }

  &--local {
    background-color: #278b96;
  }

  @keyframes blink {
    from { opacity: 0.2; }
    to { opacity: 1; }
  }
}
</style>
