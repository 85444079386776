<script>
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import includes from 'lodash/includes';
import inRange from 'lodash/inRange';
import { DELAY, getImageName, isImage } from '@emobg/web-utils';
import { downloadFile } from '@/utils';
import { ANIMATIONS, INVERTED_DIMENSION_ANGLES } from './const/GalleryComponents.const';

export default {
  name: 'GalleryComponent',
  props: {
    /**
     * [
     *  {
     *    label: 'label of image',
     *    src: 'source of image',
     *  }
     * ]
     */
    images: {
      type: Array,
      default: () => [],
    },
    startIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      indexToShow: null,
      rotation: 0,
      wrapperDimensions: {
        width: null,
        height: null,
      },
      animation: ANIMATIONS.next,
      hasPreview: true,
    };
  },
  computed: {
    showingImage() {
      return get(this.images, `[${this.indexToShow}]`, {});
    },
    isNextDisabled() {
      return !this.images.length || this.indexToShow === this.images.length - 1;
    },
    isPreviousDisabled() {
      return this.indexToShow === 0;
    },
    isInvertedDimensions() {
      return includes(INVERTED_DIMENSION_ANGLES, this.rotation);
    },
    imageDimensions() {
      if (this.isInvertedDimensions) {
        return {
          width: this.wrapperDimensions.height,
          height: this.wrapperDimensions.width,
        };
      }

      return {
        width: this.wrapperDimensions.width,
        height: this.wrapperDimensions.height,
      };
    },
  },
  watch: {
    async indexToShow() {
      this.rotation = 0;
      this.hasPreview = await isImage(this.showingImage.src);
    },
  },
  created() {
    this.indexToShow = inRange(this.startIndex, 0, this.images.length) ? this.startIndex : 0;
    this.debouncedReSetWrapperDimensions = debounce(this.setWrapperDimensions, DELAY.tiny);
  },
  async mounted() {
    this.setWrapperDimensions();
    this.hasPreview = await isImage(this.showingImage.src);
    window.addEventListener('resize', this.debouncedReSetWrapperDimensions);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.debouncedReSetWrapperDimensions);
  },
  methods: {
    downloadFile,
    getImageName,
    next() {
      if (this.indexToShow === this.images.length - 1) {
        return;
      }
      this.animation = ANIMATIONS.next;
      this.indexToShow += 1;
    },
    previous() {
      if (!this.indexToShow) {
        return;
      }
      this.animation = ANIMATIONS.previous;
      this.indexToShow -= 1;
    },
    rotate() {
      this.rotation = !this.rotation ? 270 : this.rotation - 90;
    },
    setWrapperDimensions() {
      const { clientWidth, clientHeight } = this.getImageWrapperDimensions();
      this.wrapperDimensions = {
        width: clientWidth - 16,
        height: clientHeight - 16,
      };
    },
    getImageWrapperDimensions() {
      const { clientWidth, clientHeight } = this.$refs.imageWrapper;
      return {
        clientWidth,
        clientHeight,
      };
    },
  },
};
</script>

<template>
  <div
    class="GalleryComponent d-flex flex-column"
    data-test-id="gallery"
  >
    <label
      v-if="showingImage.label"
      class="d-block emobg-label-1 mb-1"
    >
      {{ showingImage.label }}
    </label>
    <div
      ref="imageWrapper"
      class="GalleryComponent__image-wrapper flex-fill overflow-hidden position-relative
        emobg-border-1 emobg-border-color-ground emobg-border-radius-small emobg-background-color-ground-lighter"
    >
      <Transition :name="`GalleryComponent--${animation}`">
        <div
          v-if="showingImage.src"
          :key="indexToShow"
          class="GalleryComponent__inner-image-wrapper position-absolute d-flex flex-column align-items-center justify-content-center p-2 w-100"
        >
          <div
            v-if="hasPreview"
            :style="{
              backgroundImage: `url('${showingImage.src}')`,
              transform: `rotate(${rotation}deg)`,
              width: imageDimensions.width && `${imageDimensions.width}px`,
              height: imageDimensions.height && `${imageDimensions.height}px`,
            }"
            :data-test-id="`image-${indexToShow}`"
            class="GalleryComponent__image position-absolute"
          />
          <div
            v-else
            class="d-flex flex-column align-items-center justify-content-center emobg-background-color-white h-100 w-75"
          >
            <p class="mb-3">
              Preview not available
            </p>
            <div
              class="emobg-link-primary emobg-body-2"
              @click="downloadFile({ url: showingImage.src, name: showingImage.label || getImageName(showingImage.src) })"
            >
              Open
              <ui-icon
                :icon="ICONS.externalUrl"
                :size="ICONS_SIZES.xSmall"
                class="ml-1"
              />
            </div>
          </div>
        </div>
      </Transition>
    </div>
    <div class="GalleryComponent__actions d-flex align-items-center justify-content-center mt-2">
      <div class="flex-grow-1 d-flex align-items-center justify-content-center mr-n5">
        <div class="emobg-body-2 emobg-color-ink-light mr-2">
          {{ images.length ? indexToShow + 1 : 0 }}
          of
          {{ images.length }}
        </div>
        <ui-button
          :face="FACES.outline"
          :color="GRAYSCALE.inkLight"
          square
          :disabled="isPreviousDisabled"
          class="mr-2"
          data-test-id="previous-button"
          @clickbutton="previous"
        >
          <ui-icon
            :icon="ICONS.bold.left"
            :size="ICONS_SIZES.medium"
            class="d-flex flex-column align-center"
          />
        </ui-button>
        <ui-button
          :face="FACES.outline"
          :color="GRAYSCALE.inkLight"
          :disabled="isNextDisabled"
          square
          data-test-id="next-button"
          @clickbutton="next"
        >
          <ui-icon
            :icon="ICONS.bold.right"
            :size="ICONS_SIZES.medium"
            class="d-flex flex-column align-center"
          />
        </ui-button>
      </div>
      <div class="flex-grow-0">
        <ui-tooltip
          :placement="PLACEMENTS.left"
          tooltip="Rotation is preview only"
        >
          <ui-button
            :face="FACES.outline"
            :color="GRAYSCALE.inkLight"
            square
            :disabled="!images.length || !hasPreview"
            data-test-id="rotate-button"
            @clickbutton="rotate"
          >
            <ui-icon
              :icon="ICONS.rotate"
              :size="ICONS_SIZES.medium"
              class="d-flex flex-column align-center"
            />
          </ui-button>
        </ui-tooltip>
      </div>
    </div>
  </div>
</template>
