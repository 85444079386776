<script>
export default {
  name: 'SaveButton',
};
</script>
<template>
  <ui-button
    class="SaveButton"
    v-bind="$attrs"
    narrow
    @clickbutton="$emit('click')"
  >
    <slot>
      Save
    </slot>
  </ui-button>
</template>
