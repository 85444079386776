import { createCustomStore, withScopes } from '@emobg/web-utils';
import { backOffice } from '@emobg/web-api-client';
import { clearErrors } from '@/utils';
import { setError as globalSetError } from '@/store/setError';

export const scopes = {
  newLocation: 'newLocation',
  detail: 'detail',
  description: 'description',
  addMedia: 'addMedia',
  deleteMedia: 'deleteMedia',
  deleteLocation: 'deleteLocation',
  openDoors: 'openDoors',
};

const locations = createCustomStore(({ runAsyncFlow }) => withScopes(scopes, ({
  state: {
    [scopes.detail]: {
      data: null,
    },
  },
  mutations: {
    setError(state, payload) {
      const { scope, value } = payload;

      if (![
        scopes.deleteMedia,
        scopes.deleteLocation,
        scopes.openDoors,
      ].includes(scope)) {
        state[scope].error = value;
      } else {
        globalSetError.bind(this)(state, payload);
      }
    },
    clearErrors,
  },
  actions: {
    async getLocation({ commit }, locationUuid) {
      await runAsyncFlow(commit, {
        request: backOffice.locations.getLocation,
        params: [locationUuid],
        scope: scopes.detail,
      });
    },
    async postLocation({ commit }, locationData) {
      await runAsyncFlow(commit, {
        request: backOffice.locations.postLocation,
        params: [locationData],
        scope: scopes.newLocation,
      });
    },
    async putLocationDescriptions({ commit }, { locationId, data }) {
      await runAsyncFlow(commit, {
        request: backOffice.locations.putLocationDescriptions,
        params: [locationId, data],
        scope: scopes.description,
      });
    },
    async postLocationPicture({ commit }, { locationId, data }) {
      await runAsyncFlow(commit, {
        request: backOffice.locations.postLocationPicture,
        params: [locationId, data],
        scope: scopes.addMedia,
      });
    },
    async deleteLocationPicture({ commit }, { locationId, mediaId }) {
      await runAsyncFlow(commit, {
        request: backOffice.locations.deleteLocationPicture,
        params: [locationId, mediaId],
        scope: scopes.deleteMedia,
      });
    },
    async deleteLocation({ commit }, locationId) {
      await runAsyncFlow(commit, {
        request: backOffice.locations.deleteLocation,
        params: [locationId],
        scope: scopes.deleteLocation,
      });
    },
    async postOpenLocationDoors({ commit }, locationId) {
      await runAsyncFlow(commit, {
        request: backOffice.locations.postOpenLocationDoors,
        params: [locationId],
        scope: scopes.openDoors,
      });
    },
  },
})));

export default locations;
