<script>
export default {
  name: 'DeleteButton',
};
</script>
<template>
  <ui-button
    :color="COLORS.danger"
    class="DeleteButton"
    v-bind="$attrs"
    narrow
    @clickbutton="$emit('click')"
  >
    <slot>
      Yes, delete
    </slot>
  </ui-button>
</template>
