import { getValue } from '@emobg/web-utils';
import pick from 'lodash/pick';
import keys from 'lodash/keys';
import pickBy from 'lodash/pickBy';
import intersection from 'lodash/intersection';
import identity from 'lodash/identity';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import findLast from 'lodash/findLast';
import Cookie from 'js-cookie';

import { MENU_OPERATORS } from '@/constants/menuOperators';
import { MSBO_COOKIE_NAMES } from '@/constants/cookies.const';

import RouterMap, { SHARED_URLS } from '../RouterMap';
import createStore from '../../store';

/**
 * Router guard to restrict route access by operator
 * Add param in the route, 4 possible options in array: { meta: operators: ['is_carsharing', 'is_carrental']}
 * If an operator is set on a route, and the operatorData has it false, it will redirects to /403
 * If not, goes correctly to the route.
 */
export const operatorGuard = async (to, _from, next) => {
  // Exclude this guard for this routes
  if (to.name === RouterMap.verificationCode) {
    next();
    return;
  }

  try {
    const store = createStore();
    const cookieActiveOperator = Cookie.get(MSBO_COOKIE_NAMES.operator);
    let operatorData;
    if (cookieActiveOperator) {
      operatorData = JSON.parse(cookieActiveOperator);
    } else {
      const requests = [];

      const operators = getValue(store, 'state.App.UserAccount.operators.data', []);
      if (isEmpty(operators)) {
        requests.push(store.dispatch('App/UserAccount/getOperators'));
      }

      const user = getValue(store, 'state.App.UserAccount.user.data', {});
      if (isEmpty(user)) {
        requests.push(store.dispatch('App/UserAccount/getUserAccount'));
      }

      await Promise.all(requests);
      await store.dispatch('App/UserAccount/setActiveOperator', first(getValue(store, 'state.App.UserAccount.operators.data', null)));
      operatorData = getValue(store, 'state.App.UserAccount.operators.active', null);
    }

    // The meta.operators that will be checked is the first starting from child to parent, so child routes have more priority than parents.
    const metaOperators = getValue(findLast(to.matched, 'meta.operators'), 'meta.operators', []);
    const operatorTypes = pick(operatorData, MENU_OPERATORS.isCarrental, MENU_OPERATORS.isCarsharing);
    const operatorTypeKeys = keys(pickBy(operatorTypes, identity));

    const canAccessRoute = !metaOperators.length
      || !isEmpty(intersection(metaOperators, operatorTypeKeys))
      || to.path === SHARED_URLS.forbidden;
    if (canAccessRoute) {
      next();
    } else {
      next(SHARED_URLS.forbidden);
    }
  } catch (error) {
    next();
  }
};
