const settings = Object.freeze({
  index: 'settings',
  autoInterventions: {
    form: 'settings.autoInterventions.form',
  },
  bookingRules: {
    index: 'settings.bookingRules.list',
  },
  fuelPriceLog: {
    index: 'settings.fuelPrice',
    list: 'settings.fuelPrice.list',
  },
});

export default settings;
