<script>
export default {
  name: 'GenericModalBodyComponent',
};
</script>

<template>
  <div class="GenericModalBodyComponent h-100">
    <slot />
  </div>
</template>
