<script>
import get from 'lodash/get';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import toNumber from 'lodash/toNumber';
import {
  AsYouType,
  getCountries,
  getCountryCallingCode,
  parsePhoneNumber,
} from 'libphonenumber-js';
import { MuiInputText, MuiSelect } from '@emobg/motion-ui/v1';

export default {
  name: 'PhoneInputComponent',
  components: {
    MuiInputText,
    MuiSelect,
  },
  model: {
    prop: 'model',
    event: 'input',
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    model: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      phoneNumber: null,
      country: 'US',
      phone: '',
      countries: [],
      isActive: false,
    };
  },
  watch: {
    country() {
      if (this.country) {
        this.onInput(this.phone);
      }
    },
  },
  created() {
    const countriesCodes = getCountries();
    const countries = map(countriesCodes, country => ({
      value: country,
      label: `+${getCountryCallingCode(country)}`,
      code: toNumber(getCountryCallingCode(country)),
      flag: this.getCountryFlag(country),
    }));
    this.countries = orderBy(countries, ['code']);

    if (this.model) {
      this.onInput(this.model);
    }
  },
  methods: {
    get,
    getCountryFlag(country) {
      return this.getRegionalIndicatorSymbol(country[0]) + this.getRegionalIndicatorSymbol(country[1]);
    },
    getRegionalIndicatorSymbol(letter) {
      return String.fromCodePoint(0x1F1E6 - 65 + letter.toUpperCase().charCodeAt(0));
    },
    onBlur() {
      this.isActive = false;
    },
    onFocus() {
      this.isActive = true;
    },
    onInput(number) {
      try {
        this.phoneNumber = parsePhoneNumber(number, {
          defaultCountry: this.country,
          extract: false,
        });
        this.country = get(this, 'phoneNumber.country') || this.country;
        this.phone = new AsYouType(this.country).input(this.phoneNumber.nationalNumber);
        if (this.model !== this.phoneNumber.number) {
          this.$emit('input', this.phoneNumber.number);
        }
      } catch (exception) {
        this.$emit('input', number);
      }
    },
  },
};
</script>
<template>
  <div
    :class="[
      'PhoneInputComponent',
      { 'PhoneInputComponent--active': isActive, 'PhoneInputComponent--disabled': disabled },
    ]"
  >
    <label
      :disabled="disabled"
      class="PhoneInputComponent__label emobg-font-weight-semibold pb-1 d-block"
    >
      {{ label }}
    </label>
    <div class="PhoneInputComponent__input-wrapper d-flex emobg-border-radius-small">
      <MuiSelect
        v-model="country"
        :options="countries"
        :searchbox="{
          placeholder: country ? '' : 'Select',
          threshold: 1,
        }"
        :disabled="disabled"
        :on-close="onBlur"
        required
        placeholder="Select"
        name="prefix"
        data-test-id="prefix-select"
        @click="onFocus"
      >
        <template #selected="{ item }">
          {{ get(item, 'flag') }} {{ get(item, 'label') }}
        </template>
        <template #item="{ item }">
          {{ get(item, 'flag') }} {{ get(item, 'label') }}
        </template>
      </MuiSelect>
      <MuiInputText
        :model-value="phone"
        :disabled="disabled"
        :placeholder="placeholder"
        name="phone"
        class="w-100"
        data-test-id="phone-input"
        @blur="onBlur"
        @focus="onFocus"
        @click="onFocus"
        @input="onInput"
      />
    </div>
  </div>
</template>
