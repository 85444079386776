import { COOKIE_NAMES } from '@emobg/web-utils';
import { authService } from '@emobg/access-utils';

import includes from 'lodash/includes';
import Cookie from 'js-cookie';
import { SHARED_URLS, SKIP_URLS } from '@/router/RouterMap';
import { allowCookiesHandler } from '../constants/auth';

export async function setupAuth({ auth }) {
  const {
    domain, clientID, audience, responseType, scope,
  } = auth;

  authService.init({
    domain,
    clientID,
    redirectUri: window.location.origin,
    audience,
    responseType,
    scope,
  });

  const { authenticated } = await authService.instance.handleAuthentication();

  return authenticated;
}

// Never remove this!
export async function setReturnUrl() {
  const errorPagePattern = /^\/[4-5][0-9][0-9]/;
  const path = window.location.pathname;
  const returnUrl = (errorPagePattern.test(path) || includes(SKIP_URLS, path))
    ? SHARED_URLS.home
    : `${path}${window.location.search}`;

  Cookie.set(COOKIE_NAMES.returnUrl, returnUrl, {
    ...(!window.location.host.includes('localhost') && { secure: true }),
  });
}

export function logoutAuth0() {
  authService.instance.clearCookies(allowCookiesHandler);
  setReturnUrl(); // Never remove setReturnUrl !
  authService.instance.logout();
}
