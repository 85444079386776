<script>
import { DELAY } from '@emobg/web-utils';

export default {
  name: 'CopyToClipboard',
  methods: {
    copyToClipboard() {
      try {
        const range = document.createRange();
        range.selectNode(this.$refs.copyElement);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
        document.execCommand('copy');
        window.getSelection().removeAllRanges();
        this.$notify({
          message: 'Copied',
          textAction: '',
          delay: DELAY.long,
        });
      } catch (error) {
        this.$throwError(error.message);
      }
    },
  },
};
</script>

<template>
  <div class="CopyToClipboard d-flex align-items-center">
    <span
      ref="copyElement"
      class="text-break"
    >
      <slot />
    </span>
    <ui-icon
      :size="ICONS_SIZES.medium"
      :icon="ICONS.copy"
      class="cursor-pointer ml-1"
      @click="copyToClipboard"
    />
  </div>
</template>
