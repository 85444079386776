import GoogleMaps from 'google-maps';

const GoogleMapsPlugin = {
  async install(Vue, {
    libraries,
    key,
  }) {
    const V = Vue;

    GoogleMaps.LIBRARIES = libraries;
    GoogleMaps.KEY = key;
    GoogleMaps.VERSION = 'weekly';

    V.prototype.$GoogleMaps = {
      instance: await new Promise(resolve => {
        GoogleMaps.load(google => {
          resolve(google);
        });
      }),
      getInstance() {
        return this.instance;
      },
      destroyMap(_map, element) {
        this.instance.maps.event.removeListener(element);
      },
    };
  },
};

export default GoogleMapsPlugin;
