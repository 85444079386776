import result from 'lodash/result';

import { authService } from '@emobg/access-utils';
import { logoutAuth0 } from '@/utils';

export const authGuard = (_to, _from, next) => {
  if (!result(authService, 'instance.isAuthenticated')) {
    logoutAuth0();
  } else {
    next();
  }
};
