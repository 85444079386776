<script>
export default {
  name: 'SwitchToOldViewComponent',
  props: {
    url: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: '_self',
    },
  },
  data() {
    return {
      oldPageUrl: `${this.$route.path}?showOld=true`,
    };
  },
};
</script>

<template>
  <div
    class="SwitchToOldViewComponent
            align-right
            px-6 py-4 py-md-2
            bg-color-main-light"
  >
    <a
      :href="url || oldPageUrl"
      :target="target"
      class="emobg-color-ink"
    >
      Switch to the old view
    </a>
  </div>
</template>
