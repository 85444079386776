<script>
import { isImage } from '@emobg/web-utils';

export default {
  name: 'ImageTemplate',
  props: {
    url: {
      type: String,
      default: null,
    },
    height: {
      type: Number,
      default: 60,
    },
  },
  data() {
    return {
      isRenderable: false,
    };
  },
  watch: {
    url: {
      immediate: true,
      async handler() {
        this.isRenderable = await isImage(this.url);
      },
    },
  },
};
</script>
<template>
  <div
    :key="$attrs.key"
    class="ImageTemplate"
  >
    <img
      v-if="isRenderable"
      :src="url"
      :style="`max-height: ${height}px; width: auto; height: 100%;`"
      alt="picture"
    >
    <span v-else>
      {{ FALLBACK_MESSAGE.dash }}
    </span>
  </div>
</template>
