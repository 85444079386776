import invoicing from './InvoicingRouterMap';
import { INVOICING_PERMISSIONS } from '../const/permissions';

const InvoiceListView = () => import(/* webpackChunkName: "invoicing" */'../Invoices/List/InvoiceListView.vue');
const InvoicingView = () => import(/* webpackChunkName: "invoicing" */'../InvoicingView.vue');
const PaymentsView = () => import(/* webpackChunkName: "invoicing" */'../Payments/PaymentsView.vue');
const PayoutsView = () => import(/* webpackChunkName: "invoicing" */'../Payouts/PayoutsView.vue');
const InvoiceView = () => import(/* webpackChunkName: "invoicing-invoice" */'../Invoices/Invoice/InvoiceView.vue');
const InvoiceDetailsView = () => import(/* webpackChunkName: "invoicing-invoice-details" */'../Invoices/Invoice/Details/InvoiceDetailsView.vue');
const InvoiceRelatedView = () => import(/* webpackChunkName: "invoicing-invoice-related" */'../Invoices/Invoice/Related/InvoicesRelatedView.vue');
const InvoiceSendView = () => import(/* webpackChunkName: "invoicing-invoice-send" */'../Invoices/Invoice/Send/InvoiceSendView.vue');
const InvoiceAuditView = () => import(/* webpackChunkName: "invoicing-invoice-audit" */'../Invoices/Invoice/Audit/InvoiceAuditView.vue');
const InvoiceAuditInvoicesView = () => import(/* webpackChunkName: "invoicing-invoice-audit" */'../Invoices/Invoice/Audit/InvoiceAuditInvoicesView.vue');
const InvoiceAuditLinesView = () => import(/* webpackChunkName: "invoicing-invoice-audit" */'../Invoices/Invoice/Audit/InvoiceAuditLinesView.vue');
const InvoiceAuditPaymentsView = () => import(/* webpackChunkName: "invoicing-invoice-audit" */'../Invoices/Invoice/Audit/InvoiceAuditPaymentsView.vue');
const SapFilesListView = () => import(/* webpackChunkName: "invoicing-invoice-sap" */'../Invoices/Invoice/Sap/SapFilesListView');

export default [
  {
    path: '/payments/payments',
    redirect: { name: invoicing.payments },
    // TODO: PH-11794 remove redirections when whole monolith is migrated
  },
  {
    path: 'invoicing',
    name: invoicing.index,
    component: InvoicingView,
    meta: {
      label: 'Invoicing',
    },
    redirect: {
      name: invoicing.invoices.list,
    },
    children: [
      {
        path: 'invoices',
        name: invoicing.invoices.list,
        component: InvoiceListView,
        meta: {
          label: 'Invoices',
          permissions: [INVOICING_PERMISSIONS.viewInvoicingInvoices],
        },
      },
      {
        path: 'sap-files',
        name: invoicing.invoices.sapFiles,
        component: SapFilesListView,
        meta: {
          label: 'Sap files',
          permissions: [INVOICING_PERMISSIONS.viewInvoicingInvoices],
        },
      },
      {
        path: 'invoices/:invoiceUuid/summary',
        name: invoicing.invoices.invoice.summary,
        component: InvoiceView,
        meta: {
          label: 'Invoice',
          permissions: [INVOICING_PERMISSIONS.viewInvoicingInvoices],
        },
        redirect: {
          name: invoicing.invoices.invoice.details,
        },
        children: [
          {
            path: 'details',
            name: invoicing.invoices.invoice.details,
            component: InvoiceDetailsView,
            meta: {
              label: 'Details',
              permissions: [INVOICING_PERMISSIONS.viewInvoicingInvoices],
            },
          },
          {
            path: 'related',
            name: invoicing.invoices.invoice.related,
            component: InvoiceRelatedView,
            meta: {
              label: 'Related',
              permissions: [INVOICING_PERMISSIONS.viewInvoicingInvoices],
            },
          },
          {
            path: 'send',
            name: invoicing.invoices.invoice.send,
            component: InvoiceSendView,
            meta: {
              label: 'Send',
              permissions: [INVOICING_PERMISSIONS.viewInvoicingInvoices],
            },
          },
          {
            path: 'audit',
            name: invoicing.invoices.invoice.audit.index,
            component: InvoiceAuditView,
            meta: {
              label: 'Audit',
              permissions: [INVOICING_PERMISSIONS.viewInvoicingInvoices],
            },
            children: [
              {
                path: 'invoices',
                name: invoicing.invoices.invoice.audit.invoices,
                component: InvoiceAuditInvoicesView,
                meta: {
                  label: 'Invoices',
                  permissions: [INVOICING_PERMISSIONS.viewInvoicingInvoices],
                },
              },
              {
                path: 'lines',
                name: invoicing.invoices.invoice.audit.lines,
                component: InvoiceAuditLinesView,
                meta: {
                  label: 'Lines',
                  permissions: [INVOICING_PERMISSIONS.viewInvoicingInvoices],
                },
              },
              {
                path: 'payments',
                name: invoicing.invoices.invoice.audit.payments,
                component: InvoiceAuditPaymentsView,
                meta: {
                  label: 'Payments transaction',
                  permissions: [INVOICING_PERMISSIONS.viewInvoicingInvoices],
                },
              },
            ],
          },
        ],
      },
      {
        path: 'payments',
        name: invoicing.payments,
        component: PaymentsView,
        meta: {
          label: 'Payments',
          permissions: [INVOICING_PERMISSIONS.viewInvoicingPayments],
        },
      },
      {
        path: 'payouts',
        name: invoicing.payouts,
        component: PayoutsView,
        meta: {
          label: 'Payouts',
          permissions: [INVOICING_PERMISSIONS.viewInvoicingPayouts],
        },
      },
    ],
  },
];
