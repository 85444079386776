var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Transition", { attrs: { name: "modal" } }, [
    _c(
      "section",
      {
        staticClass: "backdrop position-fixed d-flex justify-content-center",
        on: {
          click: function ($event) {
            if ($event.target !== $event.currentTarget) return null
            _vm.backdropDismiss && _vm.closeModal()
          },
        },
      },
      [
        _c(
          "article",
          {
            staticClass:
              "Modal emobg-background-color-white emobg-border-radius-large overflow-hidden",
            class: [_vm.size, _vm.modalClasses],
          },
          [
            _c(
              "ModalHeaderComponent",
              _vm._b(
                {
                  attrs: { title: _vm.title },
                  on: { "close-modal": _vm.closeModal },
                },
                "ModalHeaderComponent",
                _vm.header,
                false
              )
            ),
            _c(
              "aside",
              { staticClass: "Modal__alerts" },
              [_vm._t("alerts")],
              2
            ),
            _c(
              "section",
              {
                staticClass: "Modal__body overflow-y-auto",
                attrs: { role: "main" },
              },
              [_vm._t("body")],
              2
            ),
            _vm.$slots.footer
              ? _c(
                  "footer",
                  {
                    staticClass:
                      "Modal__footer emobg-border-top-1 emobg-background-color-ground-lightest emobg-border-color-ground-light p-3",
                  },
                  [_vm._t("footer")],
                  2
                )
              : _vm._e(),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }