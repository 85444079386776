<script>
export default {
  name: 'PageView',
  props: {
    isTabContent: {
      type: Boolean,
      default: false,
    },
    isInnerTabContent: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<template>
  <div
    :class="[
      'PageView',
      `${isInnerTabContent ? 'PageView--is-inner-tab-content pt-4' : 'px-5'}`,
      {
        'PageView--is-tab-content pt-4': isTabContent,
      },
    ]"
  >
    <slot />
  </div>
</template>

<style lang="scss">
@import "/node_modules/@emobg/sass/bootstrap/variables";

.PageView {
  &--is-tab-content {
    .PageTabs--inner-tabs {
      margin-top: -#{map-get($spacers, 4)};
    }
  }
}
</style>
