<script>
import clone from 'lodash/clone';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import moment from 'moment-timezone';
import toNumber from 'lodash/toNumber';
import { MuiDatePicker } from '@emobg/vue-base';
import { MuiCollapse } from '@emobg/motion-ui/v1';
import { DATE_FORMAT, DATE_FORMAT_KEYS, DELAY } from '@emobg/web-utils';

export default {
  name: 'DatesRange',
  components: {
    MuiCollapse,
    MuiDatePicker,
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    toggled: {
      type: Boolean,
      default: true,
    },
    attributeName: {
      type: String,
      required: true,
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isFilterOpened: this.toggled,
      internalRange: [null, null],
      from: null,
      to: null,
    };
  },
  watch: {
    modelValue: {
      handler() {
        if (isEmpty(this.modelValue)) {
          this.clearDates();
        } else if (!isEqual(this.modelValue, this.internalRange)) {
          this.createDatesFromModel();
        }
      },
      deep: true,
    },
    internalRange: {
      handler() {
        this.debouncesWatchInternalRange();
      },
      deep: true,
    },
  },
  created() {
    this.DATE_FORMAT = DATE_FORMAT;
    this.DATE_FORMAT_KEYS = DATE_FORMAT_KEYS;
    this.debouncesWatchInternalRange = debounce(() => {
      this.$emit('update:modelValue', clone(this.internalRange));
    }, DELAY.short);

    this.createDatesFromModel();
  },
  methods: {
    get,
    clearDates() {
      this.from = null;
      this.to = null;
      this.internalRange = [null, null];
    },
    setFrom(fromDate) {
      this.from = fromDate;
      const timestamp = fromDate && fromDate.isValid() ? toNumber(fromDate.format('X')) : null;
      this.internalRange.splice(0, 1, timestamp);
    },
    setTo(toDate) {
      this.to = toDate;
      const timestamp = toDate && toDate.isValid() ? toNumber(toDate.format('X')) : null;
      this.internalRange.splice(1, 1, timestamp);
    },
    createDatesFromModel() {
      const dateRangeFrom = get(this.modelValue, '[0]');
      const dateRangeTo = get(this.modelValue, '[1]');
      this.from = dateRangeFrom ? moment(dateRangeFrom, 'X') : null;
      this.to = dateRangeTo ? moment(dateRangeTo, 'X') : null;
    },
  },
};
</script>
<template>
  <div class="RefinementList p-3 emobg-border-bottom-1 emobg-border-color-ground">
    <div class="d-flex justify-content-between">
      <span
        class="font-weight-bold"
        v-html="title"
      />
      <span
        class="cursor-pointer emobg-color-ink-light"
        @click="isFilterOpened = !isFilterOpened"
      >
        {{ isFilterOpened ? 'Hide' : 'Show' }}
      </span>
    </div>
    <MuiCollapse
      v-if="isFilterOpened"
      :open="isFilterOpened"
    >
      <MuiDatePicker
        :date="from"
        :date-format-key="DATE_FORMAT_KEYS.defaultExtended"
        class="w-100 mt-3"
        label="From"
        data-test-id="from-input"
        @update:date="setFrom"
      />

      <MuiDatePicker
        :date="to"
        :date-format-key="DATE_FORMAT_KEYS.defaultExtended"
        class="w-100 mt-3"
        label="To"
        data-test-id="to-input"
        @update:date="setTo"
      />

      <div
        class="emobg-font-weight-semibold emobg-font-small emobg-color-primary cursor-pointer py-2"
        @click="clearDates"
      >
        Clear dates
      </div>
    </MuiCollapse>
  </div>
</template>
