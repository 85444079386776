import Cookie from 'js-cookie';

import includes from 'lodash/includes';
import get from 'lodash/get';
import pickBy from 'lodash/pickBy';
import startsWith from 'lodash/startsWith';
import find from 'lodash/find';
import first from 'lodash/first';
import omit from 'lodash/omit';
import intersection from 'lodash/intersection';
import isArray from 'lodash/isArray';
import isUndefined from 'lodash/isUndefined';
import reduce from 'lodash/reduce';
import set from 'lodash/set';

import { backOffice } from '@emobg/web-api-client';
import {
  COUNTRIES_ISO_CODES,
  DISTANCE_UNIT,
  LOG_TYPE,
  TIME_ZONE,
  cookie as cookieManager,
  createCustomStore,
  logger,
  withScopes,
} from '@emobg/web-utils';
import { authService, ldClient } from '@emobg/access-utils';

import { CARSHARING_PERMISSIONS } from '@domains/Carsharing/const/permissions';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';

import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { MSBO_COOKIE_NAMES } from '@/constants/cookies.const';
import { FULL_CURRENCY_NAME } from '@/constants/currencies.const';

import Utils, { setupBeamer } from '../../../../utils';

const SCOPES = {
  user: 'user',
  operators: 'operators',
  features: 'features',
  foundOperator: 'foundOperator',
};

export const ACTIONS = {
  setOperatorChildren: 'setOperatorChildren',
  getUserAccount: 'getUserAccount',
  getOperators: 'getOperators',
  setActiveOperator: 'setActiveOperator',
  getOperatorsConfigs: 'getOperatorsConfigs',
  getUserAndOperators: 'getUserAndOperators',
};

export const GETTERS = {
  isActiveOperatorChild: 'isActiveOperatorChild',
  hasPermissions: 'hasPermissions',
  getOperatorFilter: 'getOperatorFilter',
  operatorTimezone: 'operatorTimezone',
  activeOperator: 'activeOperator',
  areNonConnectedVehiclesAllowed: 'areNonConnectedVehiclesAllowed',
  fullCurrencyName: 'fullCurrencyName',
  isFrenchOperator: 'isFrenchOperator',
};

export const MUTATIONS = {
  setOperatorChildren: 'setOperatorChildren',
  setMileageUnit: 'setMileageUnit',
  setOperatorConfiguration: 'setOperatorConfiguration',
  setCountryCode: 'setCountryCode',
  setActiveOperator: 'setActiveOperator',
  setMFARequired: 'setMFARequired',
};

export const UserAccount = createCustomStore(({ runAsyncFlow }) => withScopes(SCOPES, ({
  state: {
    [SCOPES.operators]: {
      active: {},
      configuration: {
        currencyCode: undefined,
      },
      mileageUnit: undefined,
      countryCode: undefined,
    },
  },
  mutations: {
    [MUTATIONS.setOperatorChildren](state, newOperators) {
      state.operators.data = newOperators;
    },
    [MUTATIONS.setMileageUnit](state, mileageUnit) {
      state.operators.mileageUnit = mileageUnit;
    },
    [MUTATIONS.setOperatorConfiguration](state, configuration) {
      state.operators.configuration = configuration;
    },
    [MUTATIONS.setCountryCode](state, countryCode) {
      state.operators.countryCode = countryCode;
    },
    [MUTATIONS.setActiveOperator](state, value) {
      const { name: activeOperatorName } = value;
      const { user } = state;
      state.operators.active = value;
      setupBeamer(user, activeOperatorName);
    },
    [MUTATIONS.setMFARequired](state, isRequired) {
      set(state, 'user.data.mfa_required', isRequired);
    },
  },
  getters: {
    [GETTERS.isActiveOperatorChild](state) {
      return get(state, 'operators.active.parent_cs_operator_id', null) !== null;
    },
    [GETTERS.hasPermissions]: state => permissionsToCheck => {
      const permissions = get(state, 'user.data.permissions', []);
      return intersection(
        permissions,
        !isArray(permissionsToCheck)
          ? [permissionsToCheck]
          : permissionsToCheck,
      ).length;
    },
    [GETTERS.getOperatorFilter]: (state, getters) => ({
      attribute = 'cs_operator_fk',
      withChildren = true,
      index,
      searchUsing = 'id',
    } = {}) => {
      const childOperatorIndex = getters.isActiveOperatorChild && index;
      const activeOperatorValue = get(state, `operators.active.${searchUsing}`);
      const activeOperatorUuid = get(state, 'operators.active.uuid');
      let filter = '';

      switch (childOperatorIndex) {
        case ALGOLIA_INDEXES.users:
          return `employee.company.cs_operator_uuid:${activeOperatorUuid}`;
        case ALGOLIA_INDEXES.vehicles:
          return `dedicated_cs_fk:${activeOperatorValue}`;
        default:
          if (isUndefined(state.operators.active)) {
            return `${attribute}:undefined`;
          }
          filter = `${attribute}:${activeOperatorValue}`;
          if (withChildren) {
            if (isUndefined(state.operators.active.children)) {
              return filter;
            }
            filter = reduce(
              state.operators.active.children,
              (filterString, childOperator) => `${filterString} OR ${attribute}:${get(childOperator, searchUsing)}`, filter,
            );
          }
          return filter;
      }
    },
    [GETTERS.operatorTimezone](state) {
      return get(state, 'operators.active.timezone', TIME_ZONE.default) || TIME_ZONE.default;
    },
    [GETTERS.activeOperator](state) {
      return get(state, 'operators.active');
    },
    // TODO: https://europcarmobility.atlassian.net/browse/CF-199 refactor getter to composition api
    [GETTERS.areNonConnectedVehiclesAllowed](state) {
      const permissions = get(state, 'user.data.permissions', []);
      return includes(permissions, CARSHARING_PERMISSIONS.allowNonConnectedVehicles);
    },
    [GETTERS.fullCurrencyName](state) {
      const currencyCode = get(state, 'operators.configuration.currencyCode');

      return FULL_CURRENCY_NAME[currencyCode];
    },
    [GETTERS.isFrenchOperator](state) {
      const currentCountryCode = get(state, 'operators.countryCode');
      return currentCountryCode === COUNTRIES_ISO_CODES.france;
    },
  },
  actions: {
    [ACTIONS.setOperatorChildren]({ state, commit }, payload) {
      const operatorsWithChildrenIds = state.operators.data.map(operator => {
        const children = payload.get(operator.id);
        return {
          ...operator, children,
        };
      });
      commit(MUTATIONS.setOperatorChildren, operatorsWithChildrenIds);
    },
    async [ACTIONS.getUserAccount]({ state, commit }) {
      const stateActiveOperator = first(state.operators.data);
      let activeOperator;
      try {
        activeOperator = JSON.parse(Cookie.get(MSBO_COOKIE_NAMES.operator));
      } catch (err) {
        logger.message('No operator information stored in cookie - using data from application state', LOG_TYPE.error);
        activeOperator = stateActiveOperator;
      }
      try {
        authService.instance.activeOperatorId = get(activeOperator, 'id');
        const idpUuid = get(authService, 'instance.authData.idp_uuid');
        await runAsyncFlow(commit, {
          request: backOffice.userCompany.getUserFromIdp,
          params: [{ idpUuid }],
          scope: SCOPES.user,
        }).then(async () => {
          const envConfig = await Utils.loadEnvConfig();
          const launchDarklyApiKey = get(envConfig, 'env.launchDarkly');
          const launchDarklyInstance = ldClient(launchDarklyApiKey, { key: get(state, 'user.data.uuid') });
          if (launchDarklyInstance) {
            launchDarklyInstance.on('ready', () => {
              const featureFlags = launchDarklyInstance.allFlags();
              const msboFeatureFlags = pickBy(featureFlags, (_value, key) => startsWith(key, 'msbo'));
              commit('setStatus', { value: 'LOADING', scope: SCOPES.features });
              commit('setData', { value: msboFeatureFlags, scope: SCOPES.features });
              commit('setStatus', { value: 'LOADED', scope: SCOPES.features });
              // Cookie set to exchange data with node server
              Cookie.set('msboFeatureFlags', JSON.stringify(msboFeatureFlags), { expires: 14 });

              const csrevFeatureFlags = pickBy(featureFlags, (_value, key) => startsWith(key, 'CSREV'));

              cookieManager.set('csrevFeatureFlags', JSON.stringify(csrevFeatureFlags), { expires: 14 }, true);
            });
          }
        });
      } catch (error) {
        commit('setError', { error, scope: SCOPES.user });
        commit('setStatus', { value: 'ERROR', scope: SCOPES.user });
      }
    },
    async [ACTIONS.getUserAndOperators]({ dispatch }) {
      await Promise.all([
        dispatch(ACTIONS.getOperators),
        dispatch(ACTIONS.getUserAccount),
      ]);
    },
    async [ACTIONS.getOperators]({ commit }) {
      await runAsyncFlow(commit, {
        request: backOffice.agent.getCSOperators,
        scope: SCOPES.operators,
      });
    },
    [ACTIONS.setActiveOperator]({ state, commit, dispatch }, payload = {}) {
      const { id } = payload;
      if (id) {
        const activeOperator = find(state.operators.data, { id }) || first(state.operators.data);
        const activeOperatorWithoutChildren = omit(activeOperator, ['children']);
        const replacerSecureIfNotLocalhost = { ...window.location.host.includes('localhost') && { secure: true } };
        const operatorCookieContent = JSON.stringify(activeOperatorWithoutChildren, replacerSecureIfNotLocalhost);

        Cookie.set(MSBO_COOKIE_NAMES.operator, operatorCookieContent, { expires: 1 });
        dispatch('getOperatorsConfigs', activeOperator && activeOperator.uuid);
        commit(MUTATIONS.setActiveOperator, activeOperator);
      }
    },
    async [ACTIONS.getOperatorsConfigs]({ state, commit }, payload) {
      try {
        await runAsyncFlow(commit, {
          request: backOffice.userCompany.getOperatorsConfig,
          params: [payload],
          scope: SCOPES.foundOperator,
        }).then(() => {
          const mileageUnit = get(state, 'foundOperator.data.configuration.mileageUnit', DISTANCE_UNIT.kilometers);
          const countryCode = get(state, 'foundOperator.data.countryCode');
          const operatorConfig = get(state, 'foundOperator.data.configuration');
          commit(MUTATIONS.setOperatorConfiguration, operatorConfig);
          commit(MUTATIONS.setMileageUnit, mileageUnit);
          commit(MUTATIONS.setCountryCode, countryCode);
        });
      } catch (err) {
        commit(DOMAINS_MODEL.app.messages.errors.throwError, err, { root: true });
      }
    },
  },
})));
