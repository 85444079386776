/**
 * This function returns only the text that would be rendered, because in
 * case we want to compare a string, we don't want to match attributes or tags
 * @param {string} htmlString string of html, usually `data.html` in `DayPilot` or innerHtml of a node
 * @returns {string} innerText of the html node
 */
export const getTextFromHtmlString = htmlString => {
  const domElement = document.createElement('i');
  domElement.innerHTML = htmlString;
  return domElement.innerText;
};
