<script>
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';

export default {
  name: 'TableContentComponent',
  props: {
    schemaItem: {
      type: Object,
      required: true,
    },
    dataItem: {
      type: [Object, Array],
      required: true,
    },
    dataIndex: {
      type: Number,
      required: true,
    },
  },
  computed: {
    contentClass() {
      const hasEllipsis = get(this.schemaItem, 'ellipsis', true);
      return `TableContentComponent__content${hasEllipsis ? ' TableContentComponent__content--ellipsis' : ''}`;
    },
  },
  methods: {
    get,
    isFunction,
  },
};
</script>

<template>
  <Component
    :is="schemaItem.component"
    v-if="schemaItem.component"
    :class="[contentClass, get(schemaItem.props(dataItem), 'class')]"
    v-bind="schemaItem.props(dataItem, dataIndex)"
  />
  <div
    v-else
    :class="contentClass"
  >
    {{ isFunction(schemaItem.template) ? schemaItem.template(dataItem) : dataItem[schemaItem.template] }}
  </div>
</template>
