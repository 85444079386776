var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "GenericModalFooterComponent d-flex flex-wrap align-items-center justify-content-end emobg-border-color-ground-light p-3 emobg-background-color-ground-lightest",
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }