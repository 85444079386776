import { createCustomStore, withScopes } from '@emobg/web-utils';
import { backOffice } from '@emobg/web-api-client';
import { clearErrors } from '@/utils';

export const VEHICLE_DAMAGES_SCOPES = {
  vehicle: 'vehicle',
  newDamage: 'newDamage',
  damage: 'damage',
  deleteDamage: 'deleteDamage',
};

const vehicleDamages = createCustomStore(({ runAsyncFlow }) => withScopes(VEHICLE_DAMAGES_SCOPES, ({
  state: {
    [VEHICLE_DAMAGES_SCOPES.damage]: {
      data: null,
    },
    [VEHICLE_DAMAGES_SCOPES.vehicle]: {
      data: null,
    },
  },
  mutations: {
    clearErrors,
  },
  actions: {
    async getVehicleDetails({ commit }, vehicleUuid) {
      await runAsyncFlow(commit, {
        request: backOffice.damages.getVehicleDetails,
        params: [vehicleUuid],
        scope: VEHICLE_DAMAGES_SCOPES.vehicle,
      });
    },
    async getVehicleDamage({ commit }, { damageUuid }) {
      await runAsyncFlow(commit, {
        request: backOffice.damages.getVehicleDamage,
        params: [damageUuid, 'v2'],
        scope: VEHICLE_DAMAGES_SCOPES.damage,
      });
    },
    async postVehicleDamage({ commit }, { data }) {
      await runAsyncFlow(commit, {
        request: backOffice.damages.postVehicleDamage,
        params: [data, 'v2'],
        scope: VEHICLE_DAMAGES_SCOPES.newDamage,
      });
    },
    async putVehicleDamage({ commit }, { damageUuid, data }) {
      await runAsyncFlow(commit, {
        request: backOffice.damages.putVehicleDamage,
        params: [damageUuid, data, 'v2'],
        scope: VEHICLE_DAMAGES_SCOPES.newDamage,
      });
    },
    async deleteVehicleDamage({ commit }, { damageUuid }) {
      await runAsyncFlow(commit, {
        request: backOffice.damages.deleteVehicleDamage,
        params: [damageUuid, 'v2'],
        scope: VEHICLE_DAMAGES_SCOPES.deleteDamage,
      });
    },
  },
})));

export default vehicleDamages;
