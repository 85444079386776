import { ERRORS } from '../const/errors';
import { isEnglishDayName } from '../isEnglishDayName/isEnglishDayName';
/**
 * Validates day as a week day name in English
 * @param {string} day key as a week day name in English
 */
export const validateDay = day => {
  if (!isEnglishDayName(day)) {
    throw new Error(ERRORS.dayName);
  }
};
