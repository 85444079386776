<script>
import { DELAY } from '@emobg/web-utils';

export default {
  name: 'SaveButtonDebounced',
  data() {
    return {
      isButtonDisabled: false,
      isButtonLoading: false,
    };
  },
  methods: {
    onClickbutton() {
      this.isButtonDisabled = true;
      this.isButtonLoading = true;
      this.$emit('click');

      setTimeout(() => {
        this.isButtonDisabled = false;
        this.isButtonLoading = false;
      }, DELAY.extraLong);
    },
  },
};
</script>
<template>
  <ui-button
    class="SaveButton"
    :disabled="isButtonDisabled"
    :loading="isButtonLoading"
    v-bind="$attrs"
    narrow
    @clickbutton="onClickbutton"
  >
    <slot>
      Save
    </slot>
  </ui-button>
</template>
