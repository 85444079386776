<script>
import isEmpty from 'lodash/isEmpty';
import intersection from 'lodash/intersection';
import cloneDeep from 'lodash/cloneDeep';
import filter from 'lodash/filter';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import slice from 'lodash/slice';

import {
  MuiCheckbox,
  MuiCollapse,
  MuiInputText,
} from '@emobg/motion-ui/v1';

export default {
  name: 'RefinementList',
  components: {
    MuiCollapse,
    MuiCheckbox,
    MuiInputText,
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    toggled: {
      type: Boolean,
      default: true,
    },
    attributeName: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
    showMoreLimit: {
      type: Number,
      default: 10,
    },
    defaultSelected: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isFilterOpened: this.toggled,
      internalSelected: cloneDeep(this.modelValue),
      savedSelected: [],
      showMore: false,
      search: '',
    };
  },
  computed: {
    optionsFiltered() {
      const optionsLimited = slice(this.options, 0, !this.showMore ? this.showMoreLimit : this.options.length);

      return filter(optionsLimited, option => RegExp(this.search, 'i').test(option.label));
    },
  },
  watch: {
    modelValue(newValue, oldValue) {
      if (isEqual(newValue, oldValue)) {
        return;
      }
      this.internalSelected = cloneDeep(newValue);
    },
  },
  created() {
    if (!isEmpty(this.defaultSelected)) {
      const defaultFilters = intersection(this.defaultSelected, map(this.options, 'value'));
      this.$emit('update:modelValue', defaultFilters);
    }
  },
  methods: {
    onClickShowMore() {
      this.showMore = !this.showMore;
      this.search = '';
    },
  },
};
</script>
<template>
  <div class="RefinementList p-3 emobg-border-bottom-1 emobg-border-color-ground">
    <div class="d-flex justify-content-between">
      <span
        class="font-weight-bold"
        v-html="title"
      />
      <span
        class="cursor-pointer emobg-color-ink-light"
        @click="isFilterOpened = !isFilterOpened"
      >
        {{ isFilterOpened ? 'Hide' : 'Show' }}
      </span>
    </div>
    <MuiCollapse
      v-if="isFilterOpened"
      :open="isFilterOpened"
    >
      <MuiInputText
        v-if="showMore"
        v-model="search"
        name="search"
        placeholder="Search filter"
        class="mt-3"
        icon-to-right
        icon="search"
      />
      <div class="py-3">
        <div
          v-for="option in optionsFiltered"
          :key="option.value"
          class="d-flex justify-content-between align-items-center"
        >
          <MuiCheckbox
            :model-value="internalSelected"
            :size="SIZES.small"
            :label="option.label"
            :value="option.value"
            @change="newSelected => $emit('update:modelValue', newSelected)"
          />
          <ui-badge
            :color="GRAYSCALE.ground"
            :text="option.count"
          />
        </div>
      </div>
      <span
        v-if="options.length > showMoreLimit"
        class="emobg-font-weight-semibold emobg-font-small emobg-color-primary cursor-pointer mb-2"
        @click="onClickShowMore"
      >
        {{ showMore ? '- Show less' : '+ Show more' }}
      </span>
    </MuiCollapse>
  </div>
</template>
