<script>
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { DATE_FORMAT, formatUtc } from '@emobg/web-utils';

export default {
  name: 'CommentsComponent',
  props: {
    comments: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Enter a new comment here',
    },
    author: {
      type: String,
      default: '',
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
    canRemove: {
      type: Boolean,
      default: true,
    },
    canComment: {
      type: Boolean,
      default: true,
    },
    limit: {
      type: Number,
      default: 3,
    },
  },
  data() {
    return {
      content: this.placeholder,
      commentUuid: '',
      isValid: true,
      isSubmitDisabled: true,
      isEditing: false,
      length: this.limit,
    };
  },
  computed: {
    isOverLimit() {
      return this.comments.length > this.length;
    },
  },
  created() {
    this.DATE_FORMAT = DATE_FORMAT;
  },
  methods: {
    formatUtc,
    addCommentEvent(content) {
      if (this.content === this.placeholder) {
        this.isValid = false;
        return;
      }
      this.$emit('addComment', content);
      this.restorePlaceholder();
    },
    cancelUpdate() {
      this.isEditing = false;
      this.restorePlaceholder();
    },
    editCommentEvent(body, commentUuid) {
      this.restorePlaceholder();
      this.isEditing = true;
      this.content = body;
      this.commentUuid = commentUuid;
    },
    updateCommentEvent(body) {
      if (this.content === this.placeholder) {
        this.isValid = false;
        return;
      }
      this.$emit('updateComment', { uuid: this.commentUuid, body });
      this.restorePlaceholder();
      this.isEditing = false;
    },
    deleteCommentEvent(comment) {
      const commentUuid = this.getCommentUuid(comment);

      this.$emit('deleteComment', commentUuid);
      this.restorePlaceholder();
    },
    removePlaceholder(value) {
      this.isSubmitDisabled = false;
      this.content = value === this.placeholder ? '' : value;
    },
    restorePlaceholder(value) {
      this.isValid = true;
      this.content = isEmpty(value) ? this.placeholder : value;
      this.isSubmitDisabled = isEmpty(value);
    },
    getReporterFullName: comment => `${get(comment, 'reporter.firstName')} ${get(comment, 'reporter.lastName')}`,
    getCommentUuid(comment) {
      return get(comment, 'uuid') || get(comment, 'commentUuid') || get(comment, 'id');
    },
    isAuthor(comment) {
      const reporterId = get(comment, 'reporter.uuid') || get(comment, 'reporter.id');
      return reporterId === this.author;
    },
    toggleMoreLess() {
      this.length = this.length > this.limit
        ? this.limit
        : this.comments.length;
    },
  },
};
</script>

<template>
  <div class="CommentsComponent row">
    <ui-loader
      v-if="isLoading"
      class="col-12 pt-6 center"
    />
    <template v-else>
      <div class="col-12">
        <textarea
          v-model="content"
          :class="[
            'w-100 border-radius mb-3 p-3 font-m color-ink-light',
            `border-color-${isValid ? 'ground' : 'danger'}`
          ]"
          rows="5"
          @blur="restorePlaceholder(content)"
          @focus="removePlaceholder(content)"
        />

        <div class="d-flex justify-content-end">
          <ui-button
            v-if="!isSubmitDisabled || isEditing"
            class="mr-3"
            :face="FACES.text"
            :color="GRAYSCALE.inkLight"
            @clickbutton="cancelUpdate"
          >
            Cancel
          </ui-button>
          <ui-button
            v-if="!isEditing && canComment"
            :disabled="isSubmitDisabled || isLoading"
            @clickbutton="addCommentEvent(content)"
          >
            Submit
          </ui-button>

          <ui-button
            v-if="isEditing"
            @clickbutton="updateCommentEvent(content)"
          >
            Update
          </ui-button>
        </div>
      </div>
      <div class="col-12">
        <div
          v-for="comment in comments.slice(0, length)"
          :key="getCommentUuid(comment)"
        >
          <div class="border-bottom-01 pb-2 mb-1 border-color-ground color-ink">
            <p class="emobg-font-small emobg-color-ink-light pt-2">
              {{ formatUtc(comment.updatedAt || comment.createdAt, DATE_FORMAT.defaultExtended) }}
            </p>
            <p class="pt-2 emobg-font-weight-bold">
              {{ getReporterFullName(comment) }}
            </p>
            <div class="row pt-3">
              <div
                class="col"
                v-html="comment.body"
              />
              <div
                v-if="isAuthor(comment)"
                class="col-auto d-flex justify-content-between"
              >
                <ui-button
                  v-if="canEdit"
                  :face="FACES.text"
                  :square="true"
                  class="mr-3"
                  @clickbutton="editCommentEvent(comment.body, getCommentUuid(comment))"
                >
                  <ui-icon
                    :icon="ICONS.edit"
                    :size="SIZES.small"
                    :color="GRAYSCALE.inkLight"
                  />
                </ui-button>
                <ui-button
                  v-if="canRemove"
                  :face="FACES.text"
                  :square="true"
                  @clickbutton="deleteCommentEvent(comment)"
                >
                  <ui-icon
                    :color="GRAYSCALE.inkLight"
                    :icon="ICONS.trash"
                  />
                </ui-button>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="comments.length > limit"
          :class="[
            'link pt-2',
            `${ isOverLimit ? ICONS.plus : ICONS.minus }`
          ]"
          @click="toggleMoreLess"
        >
          Show {{ isOverLimit ? 'more': 'less' }}
        </div>
      </div>
    </template>
  </div>
</template>
