import '../types';
import { validateDay } from '../validateDay/validateDay';
import { validateInterval } from '../validateInterval/validateInterval';
import { insertOrderedInterval } from '../insertOrderedInterval/insertOrderedInterval';
/**
   * @returns {Schedule}
   * @param {Interval} interval
   * @param {string} day key as a week day name in English
   * @param {Schedule} schedule
   */
export const addIntervalToSchedule = (interval, day, schedule) => {
  validateDay(day);
  validateInterval(interval);
  insertOrderedInterval(interval, day, schedule);
  return schedule;
};
