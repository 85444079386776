var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "MobileFiltersWrapperComponent d-flex flex-column d-md-none",
        {
          "MobileFiltersWrapperComponent--show": _vm.show,
        },
      ],
    },
    [
      _c(
        "div",
        { staticClass: "MobileFiltersWrapperComponent__header w-100" },
        [
          _c("div", {
            staticClass: "icons-arrow-back font-xl",
            on: {
              click: function ($event) {
                return _vm.hide()
              },
            },
          }),
          _c("div", { staticClass: "w-100 text-center emobg-title-3" }, [
            _vm._v(" " + _vm._s(_vm.title) + " "),
          ]),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "MobileFiltersWrapperComponent__filters flex-fill w-100",
        },
        [
          _c(
            "div",
            { staticClass: "p-4 row no-gutters" },
            [_vm._t("filters")],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }