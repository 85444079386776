import get from 'lodash/get';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { CARSHARING_PERMISSIONS } from '@domains/Carsharing/const/permissions';
import { scopes } from '@domains/Fleet/Locations/store/LocationsModule';
import fleet from './FleetRouterMap';

const VehicleSettingsView = () => import(/* webpackChunkName: "fleet-vehicle-models" */'../VehicleSettings/VehicleSettingsView.vue');
const VehicleModelsListView = () => import(/* webpackChunkName: "fleet-vehicle-models" */'../VehicleModels/List/VehicleModelsListView.vue');
const VehicleBrandsListView = () => import(/* webpackChunkName: "fleet-vehicle-models" */'../VehicleSettings/Brands/List/BrandsListView.vue');
const VehicleCategoriesListView = () => import(/* webpackChunkName: "fleet-vehicle-models" */'../VehicleSettings/Categories/List/CategoriesListView.vue');
const VehicleModelsView = () => import(/* webpackChunkName: "fleet-vehicle-models" */'../VehicleModels/VehicleModelsView.vue');
const VehicleModelsDetailView = () => import(/* webpackChunkName: "fleet-vehicle-models" */'../VehicleModels/Detail/VehicleModelsDetailView.vue');
const VehicleEquipmentListView = () => import(/* webpackChunkName: "fleet-vehicle-models" */'../VehicleSettings/Equipment/List/EquipmentListView');

const ChargePostModelsView = () => import(/* webpackChunkName: "fleet-charge-post-models" */'../ChargePostModels/ChargePostModelsView.vue');
const ChargePostModelsListView = () => import(/* webpackChunkName: "fleet-charge-post-models" */'../ChargePostModels/List/ChargePostModelsListView.vue');
const ChargePostModelsDetailView = () => import(/* webpackChunkName: "fleet-charge-post-models" */'../ChargePostModels/Detail/ChargePostModelsDetailView.vue');

const ChargePostsView = () => import(/* webpackChunkName: "fleet-charge-posts" */'../ChargePosts/ChargePostsView.vue');
const ChargePostsListView = () => import(/* webpackChunkName: "fleet-charge-posts" */'../ChargePosts/List/ChargePostsListView.vue');
const ChargePostsDetailView = () => import(/* webpackChunkName: "fleet-charge-posts" */'../ChargePosts/Detail/ChargePostsDetailView.vue');
const ChargePostsDetailSetup = () => import(/* webpackChunkName: "fleet-charge-posts" */'../ChargePosts/Detail/Setup/ChargePostsDetailSetup.vue');
const ChargePostComments = () => import(/* webpackChunkName: "fleet-charge-posts" */'../ChargePosts/Detail/Comments/ChargePostComments.vue');

const HardwareView = () => import(/* webpackChunkName: "fleet-hardware" */'../Hardware/HardwareView.vue');
const DeviceProviderListView = () => import(/* webpackChunkName: "fleet-hardware" */'../Hardware/DeviceProviders/List/DeviceProvidersListView.vue');
const DevicesListView = () => import(/* webpackChunkName: "fleet-hardware" */'../Hardware/Devices/List/DevicesListView.vue');
const FuelCardsListView = () => import(/* webpackChunkName: "fleet-hardware" */'../Hardware/FuelCards/List/FuelCardsListView.vue');
const SimsListView = () => import(/* webpackChunkName: "fleet-hardware" */'../Hardware/Sims/List/SimsListView.vue');

const LocationsView = () => import(/* webpackChunkName: "fleet-locations" */'../Locations/LocationsView.vue');
const LocationListView = () => import(/* webpackChunkName: "fleet-locations" */'../Locations/List/LocationsListView.vue');
const LocationDetailView = () => import(/* webpackChunkName: "fleet-locations" */'../Locations/Detail/LocationDetailView.vue');
const LocationSetupView = () => import(/* webpackChunkName: "fleet-locations" */'../Locations/Detail/Setup/LocationSetupView.vue');
const LocationBookingsView = () => import(/* webpackChunkName: "fleet-locations" */'../Locations/Detail/Bookings/LocationBookingsView.vue');
const LocationVehiclesView = () => import(/* webpackChunkName: "fleet-locations" */'../Locations/Detail/Vehicles/LocationVehiclesView.vue');

const CreateLocationView = () => import(/* webpackChunkName: "fleet-locations-create" */'../Locations/Create/CreateLocationView.vue');
const CreateLocationForm = () => import(/* webpackChunkName: "fleet-locations-create" */'../Locations/Create/components/CreateLocationForm.vue');

const CitiesView = () => import(/* webpackChunkName: "fleet-cities" */'../Cities/CitiesView.vue');
const CitiesListView = () => import(/* webpackChunkName: "fleet-cities" */'../Cities/List/CitiesListView.vue');

// As fleet is already on carsharing domain for the moment we can't add paths just with "fleet" or "vehicles"
export default [
  {
    path: 'fleet/vehicles/settings',
    name: fleet.vehicleSettings.index,
    redirect: { name: fleet.vehicleSettings.brands },
    meta: {
      label: 'Settings',
    },
    component: VehicleSettingsView,
    children: [
      {
        path: 'brands',
        meta: {
          label: 'Brands',
          permissions: [CARSHARING_PERMISSIONS.viewCarsharingVehicles],
        },
        name: fleet.vehicleSettings.brands,
        component: VehicleBrandsListView,
      },
      {
        path: 'models',
        meta: {
          label: 'Models',
          permissions: [CARSHARING_PERMISSIONS.viewCarsharingVehicles],
        },
        name: fleet.vehicleSettings.models.index,
        component: VehicleModelsListView,
      },
      {
        path: 'equipments',
        meta: {
          label: 'Equipments',
          permissions: [CARSHARING_PERMISSIONS.viewCarsharingVehicles],
        },
        name: fleet.vehicleSettings.equipment,
        component: VehicleEquipmentListView,
      },
      {
        path: 'categories',
        meta: {
          label: 'Categories',
          permissions: [CARSHARING_PERMISSIONS.viewCarsharingVehicles],
        },
        name: fleet.vehicleSettings.categories,
        component: VehicleCategoriesListView,
      },
    ],
  },
  {
    path: 'fleet/vehicles/settings/models',
    redirect: { name: fleet.vehicleSettings.models.detail },
    component: VehicleModelsView,
    props: true,
    meta: {
      label: 'Vehicle settings',
      permissions: [CARSHARING_PERMISSIONS.viewCarsharingVehicles],
    },
    children: [
      {
        path: ':vehicleModelUuid',
        name: fleet.vehicleSettings.models.detail,
        component: VehicleModelsDetailView,
        props: true,
        meta: {
          label: 'Models',
          permissions: [CARSHARING_PERMISSIONS.viewCarsharingVehicles],
        },
      },
    ],
  },
  {
    path: 'fleet/hardware',
    name: fleet.hardware.hardware,
    redirect: { name: fleet.hardware.deviceProviders },
    meta: {
      label: 'Hardware',
    },
    component: HardwareView,
    children: [
      {
        path: 'device-providers',
        name: fleet.hardware.deviceProviders,
        meta: { label: 'Device providers' },
        component: DeviceProviderListView,
      },
      {
        path: 'devices',
        name: fleet.hardware.devices,
        meta: { label: 'Devices' },
        component: DevicesListView,
      },
      {
        path: 'fuel-cards',
        name: fleet.hardware.fuelCards,
        meta: { label: 'Fuel cards' },
        component: FuelCardsListView,
      },
      {
        path: 'sims',
        name: fleet.hardware.sims,
        meta: { label: 'Sims' },
        component: SimsListView,
      },
    ],
  },
  {
    path: 'fleet/charge-post-models',
    meta: {
      label: 'Charge post models',
    },
    component: ChargePostModelsView,
    children: [
      {
        path: '/',
        name: fleet.chargePostModels.index,
        component: ChargePostModelsListView,
        meta: {
          permissions: [CARSHARING_PERMISSIONS.viewCarsharingVehicles],
        },
      },
      {
        path: ':chargePostModelUuid',
        props: true,
        name: fleet.chargePostModels.detail,
        component: ChargePostModelsDetailView,
        meta: {
          label: 'Model details',
          permissions: [CARSHARING_PERMISSIONS.viewCarsharingVehicles],
        },
      },
    ],
  },
  {
    path: 'fleet/charge-posts',
    component: ChargePostsView,
    meta: {
      label: 'Charge posts',
    },
    children: [
      {
        path: '/',
        name: fleet.chargePosts.index,
        component: ChargePostsListView,
        meta: {
          label: '',
          title: 'Charge posts',
        },
      },
      {
        path: ':chargePostUuid',
        props: true,
        component: ChargePostsDetailView,
        redirect: { name: fleet.chargePosts.detail.setup },
        meta: {
          label: 'Details',
        },
        children: [
          {
            path: 'setup',
            name: fleet.chargePosts.detail.setup,
            component: ChargePostsDetailSetup,
            meta: {
              label: 'Setup',
            },
          },
          {
            path: 'comments',
            name: fleet.chargePosts.detail.comments,
            component: ChargePostComments,
            meta: {
              label: 'Comments',
            },
          },
        ],
      },
    ],
  },
  {
    path: 'fleet/locations',
    component: LocationsView,
    meta: {
      label: 'Locations',
    },
    children: [
      {
        path: '/',
        name: fleet.locations.index,
        props: true,
        component: LocationListView,
        permissions: [CARSHARING_PERMISSIONS.viewCarsharingLocations],
        meta: {
          title: 'Locations',
        },
      },
      {
        path: 'create',
        component: CreateLocationView,
        props: true,
        redirect: { name: fleet.locations.create },
        permissions: [CARSHARING_PERMISSIONS.viewCarsharingLocations],
        meta: {
          label: 'Create location',
          title: 'Create location',
        },
        children: [
          {
            path: ':type',
            props: true,
            name: fleet.locations.create,
            component: CreateLocationForm,
            meta: {
              label: '',
            },
          },
        ],
      },
      {
        path: ':locationUuid',
        props: true,
        name: fleet.locations.detail.index,
        component: LocationDetailView,
        permissions: [CARSHARING_PERMISSIONS.viewCarsharingLocations],
        redirect: { name: fleet.locations.detail.setup },
        meta: {
          label: 'Detail',
          title: {
            storeDomain: DOMAINS_MODEL.fleet.locations,
            scope: scopes.detail,
            template: data => get(data, 'name', ''),
          },
        },
        children: [
          {
            path: 'setup',
            name: fleet.locations.detail.setup,
            component: LocationSetupView,
            meta: {
              label: 'Setup',
            },
          },
          {
            path: 'bookings',
            name: fleet.locations.detail.bookings,
            component: LocationBookingsView,
            meta: {
              label: 'Bookings',
            },
          },
          {
            path: 'vehicles',
            name: fleet.locations.detail.vehicles,
            component: LocationVehiclesView,
            meta: {
              label: 'Vehicles',
            },
          },
        ],
      },
    ],
  },
  {
    path: 'fleet/cities',
    component: CitiesView,
    meta: {
      label: 'Cities',
    },
    children: [
      {
        path: '/',
        name: fleet.cities.index,
        component: CitiesListView,
        meta: {
          label: 'Cities list',
        },
      },
    ],
  },
];
