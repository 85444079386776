import isString from 'lodash/isString';
import { ERRORS } from '../const/errors';

/**
 * It tests that the string received follows format HH:mm and it ranges
 * from 00:00 to 24:00, both included
 */
export const timeRegex = new RegExp(/(^(([01]\d)|2[0-3]):((00|15|30|45))$)|(24:00)/);
/**
 * Validates that the string it receives, follows the pattern rules and time ranges allowed
 * @param {string} time in format "HH:mm". From 00:00 to 24:00
 */
export const validateTime = time => {
  if (!isString(time)) {
    throw new Error(ERRORS.timeString);
  }
  const isValid = timeRegex.test(time);
  if (!isValid) {
    throw new Error(ERRORS.timePattern);
  }
  if (time.length > 5) {
    throw new Error(ERRORS.timeLength);
  }
};
