import { backOffice } from '@emobg/web-api-client';
import { createCustomStore, withScopes } from '@emobg/web-utils';

const { getPermissions } = backOffice.permissions;

export const scopes = {
  permissions: 'permissions',
  permissionsByRole: 'permissionsByRole',
};

const permissionsModel = createCustomStore(({ runAsyncFlow }) => withScopes(scopes, {
  actions: {
    async getPermissions({ commit }) {
      await runAsyncFlow(commit, {
        scope: scopes.permissions,
        request: getPermissions,
      });
    },
    async getPermissionsByRole({ commit }, role) {
      await runAsyncFlow(commit, {
        scope: scopes.permissionsByRole,
        request: getPermissions,
        params: [role],
      });
    },
  },
}));

export default permissionsModel;
