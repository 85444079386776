import get from 'lodash/get';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';

import createStore from '../../store';
import { SHARED_URLS } from '../RouterMap';

export const permissionsGuard = async (to, _from, next) => {
  const store = createStore();
  if (!get(store, 'state.App.UserAccount.user.data.permissions')) {
    await store.dispatch('App/UserAccount/getUserAccount');
  }

  const userPermissions = get(store, 'state.App.UserAccount.user.data.permissions', []);

  if (isEmpty(userPermissions) && to.path !== SHARED_URLS.forbidden) {
    next(SHARED_URLS.forbidden);
  }

  const routePermissions = get(to, 'meta.permissions', []);
  const hasUserAndRoutePermissions = !isEmpty(userPermissions) && !isEmpty(routePermissions);

  const canNavigate = !hasUserAndRoutePermissions
    ? true
    : intersection(userPermissions, routePermissions).length;

  if (canNavigate) {
    next();
  } else {
    next(SHARED_URLS.forbidden);
  }
};
