<script>
import get from 'lodash/get';
import set from 'lodash/set';
import marked from 'marked';
import { mapActions, mapMutations, mapState } from 'vuex';
import { MuiTextarea, Validate } from '@emobg/motion-ui/v1';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { COMMENTS_SCOPES } from '@/store/modules/App/Comments/CommentsModule';
import {
  CancelButton,
  GenericModalComponent,
  MarkdownInformation,
  SaveButton,
  StoreNotificationComponent,
} from '@/components';

export default {
  name: 'CommentForm',
  components: {
    CancelButton,
    GenericModalComponent,
    MarkdownInformation,
    MuiTextarea,
    SaveButton,
    StoreNotificationComponent,
  },
  directives: {
    Validate,
  },
  props: {
    comment: {
      type: Object,
      default: null,
    },
    entity: {
      type: String,
      required: true,
    },
    entityUuid: {
      type: String,
      required: true,
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isInitialized: true,
      body: '',
    };
  },
  computed: {
    modalTitle() {
      if (this.isInitialized) {
        return this.comment ? 'Edit comment' : 'Add comment';
      }

      return '';
    },
    areRequiredFieldsFilled() {
      return this.body && this.body.length > 5;
    },
    hasSameValues() {
      return this.body === get(this, 'comment.body');
    },
    ...mapState(DOMAINS_MODEL.app.comments, {
      newCommentStatus: state => state.newComment.STATUS,
    }),
  },
  async created() {
    if (this.comment) {
      this.body = get(this, 'comment.body', '');
    }
    this.COMMENTS_SCOPES = COMMENTS_SCOPES;
    this.DOMAINS_MODEL = DOMAINS_MODEL;
    this.isInitialized = true;
  },
  methods: {
    marked,
    checkValidation() {
      set(this, '$refs.comment.digestedValidations.valid', this.body.length > 5);
    },
    async request() {
      this.clearErrors();
      const request = this.comment ? this.putComment : this.postComment;
      const data = {
        body: this.body,
        commentable_uuid: this.entityUuid,
        commentable_type: this.entity,
      };

      const params = this.comment ? { commentUuid: get(this, 'comment.uuid'), data } : data;
      await request(params);

      if (!this.newCommentStatus.ERROR) {
        const message = this.comment ? 'Comment edited successfully!' : 'Comment added successfully!';
        this.$emit('closeModal');
        this.$notify({
          message,
          textAction: '',
        });
        this.callback();
      }
    },
    ...mapActions(DOMAINS_MODEL.app.comments, [
      'putComment',
      'postComment',
    ]),
    ...mapMutations(DOMAINS_MODEL.app.comments, [
      'clearErrors',
    ]),
  },
};
</script>
<template>
  <GenericModalComponent
    :title="modalTitle"
    :header="{ isClosable: true }"
    class="CommentForm"
    v-on="$listeners"
  >
    <template slot="alerts">
      <StoreNotificationComponent
        :store-domain="DOMAINS_MODEL.app.comments"
        :scope="COMMENTS_SCOPES.newComment"
        :is-editing="comment"
        element="comments"
        data-test-id="notification"
      />
    </template>
    <template slot="body">
      <div>
        <MuiTextarea
          v-if="isInitialized"
          ref="comment"
          v-model="body"
          v-validate="{
            isRequired: true,
            isMinLength: {
              message: 'We need at least 6 characters',
              length: 6,
            }
          }"
          label="Comment*"
          placeholder="Write a comment"
          name="comment"
          rows="4"
          required
          @input="checkValidation"
        />
        <ui-skeleton
          v-else
          class="mb-4"
        />
      </div>
      <MarkdownInformation class="mt-2" />

      <label class="d-block emobg-label-1 mt-4">
        Comment preview:
      </label>
      <div
        class="CommentsList__markdown mt-4"
        style="word-wrap: break-word;"
        v-html="marked(body)"
      />
    </template>
    <template slot="footer">
      <div class="d-flex justify-content-center justify-content-sm-end align-items-center">
        <div class="d-flex justify-content-center">
          <CancelButton @click="() => $emit('closeModal')" />
        </div>

        <div class="d-flex justify-content-center">
          <SaveButton
            :disabled="!areRequiredFieldsFilled || hasSameValues"
            :loading="newCommentStatus.LOADING"
            @click="request"
          >
            {{ comment ? 'Save' : 'Add' }}
          </SaveButton>
        </div>
      </div>
    </template>
  </GenericModalComponent>
</template>
