import { getValue, interpolatedString, isObjectEmpty } from '@emobg/web-utils';
import phrases from './phrases';

const PhrasesPlugin = {
  install(Vue) {
    Vue.mixin({
      beforeCreate() {
        this.$t = (key, replaces = {}) => {
          const phrase = getValue(phrases, key, key);
          return isObjectEmpty(replaces) ? phrase : interpolatedString(phrase, replaces);
        };
      },
    });
  },
};

export default PhrasesPlugin;
