import get from 'lodash/get';
import { authService } from '@emobg/access-utils';
import Cookie from 'js-cookie';
const {
  DEVICE_TOKEN_HEADER,
  DEVICE_USER_COOKIE,
  getIdpUuidFromAuthToken,
} = require('../constants/auth');

/**
 * @returns a new headers object with all needed headers for backoffice API requests
 * @param {{request,ENV}} configuration
 * @param {any} configuration.request axios request object
 */
export const getUpdatedHeaders = ({ request }) => {
  const token = get(authService, 'instance.accessToken', '');
  const Authorization = `Bearer ${token}`;
  const operatorId = get(authService, 'instance.activeOperatorId');

  const idpUuid = getIdpUuidFromAuthToken(token);
  const userDeviceToken = Cookie.get(`${DEVICE_USER_COOKIE}${idpUuid}`);

  const newHeaders = {
    ...request.headers,
    Authorization,
    'Accept-Language': '',
  };

  if (userDeviceToken) {
    newHeaders[DEVICE_TOKEN_HEADER] = userDeviceToken;
  }
  if (operatorId) {
    newHeaders['Active-Operator'] = operatorId;
  }

  return newHeaders;
};
