var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "p",
        {
          staticClass:
            "emobg-body-medium emobg-font-weight-semibold d-flex align-items-center mb-1 px-1",
        },
        [
          _vm._v(" " + _vm._s(_vm.label) + " "),
          _c(
            "ui-tooltip",
            {
              staticClass: "d-inline-block ml-1",
              attrs: { tooltip: _vm.tooltip },
            },
            [
              _c("ui-icon", {
                attrs: {
                  icon: _vm.ICONS.infoFull,
                  color: _vm.GRAYSCALE.inkLight,
                  size: _vm.ICONS_SIZES.small,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }