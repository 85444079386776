var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ui-paginate", {
    staticClass: "TablePaginatorComponent",
    attrs: {
      total: _vm.totalPages,
      page: _vm.totalPages ? _vm.page : 0,
      size: _vm.SIZES.medium,
      "data-test-id": "table_paginator",
    },
    on: { next: _vm.next, previous: _vm.previous },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }