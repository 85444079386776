export const CARRENTAL_PERMISSIONS = {
  vehicleViewList: 'cr_vehicle_view_list',
  vehicleViewDetailsHeader: 'cr_vehicle_details_view_header',
  vehicleShowPosition: 'cr_vehicle_show_position',
  vehicleValidateTelemetry: 'cr_vehicle_validate_telemetry',
  vehicleViewInformationTab: 'cr_vehicle_details_view_information_tab',
  vehicleEditInfleetingStatus: 'cr_vehicle_edit_infleeting_status',
  vehicleViewGreenwayTab: 'cr_vehicle_details_view_greenway_tab',
  vehicleViewAlertsTab: 'cr_vehicle_details_view_alerts_tab',
  vehicleViewMovementsTab: 'cr_vehicle_details_view_movements_tab',
  vehicleViewTelemetryTab: 'cr_vehicle_details_view_telemetry_tab',
  vehicleViewTripsTab: 'cr_vehicle_details_view_trips_tab',
  movementViewList: 'cr_movement_view_list',
  stationViewList: 'cr_station_view_list',
  stationViewZones: 'cr_station_view_zones',
  stationEditZones: 'cr_station_edit_zones',
  stationFleetCheck: 'cr_station_fleet_check',
  mapView: 'cr_view_map',
  alertsViewList: 'cr_alerts_view_list',
  alertsViewDetails: 'cr_alerts_details_view',
  alertsComment: 'cr_alerts_details_comment',
  alertsChangeStatus: 'cr_alerts_details_change_status',
  deviceViewList: 'cr_device_view_list',
  configurableAlertsViewList: 'cr_configurable_alerts_view_list',
  configurableAlertsAddTrigger: 'cr_configurable_alerts_add_trigger',
  configurableAlertsUpdateStatus: 'cr_configurable_alerts_update_status',
  configurableAlertsDeleteTrigger: 'cr_configurable_alerts_delete_trigger',
  agentsViewList: 'cr_agents_view_list',
  agentsAdd: 'cr_agents_add',
  agentsImport: 'cr_agents_import_list',
  agentsPromote: 'cr_agents_promote',
  agentsEdit: 'cr_agents_edit',
  agentsRemove: 'cr_agents_remove',
};
