<script>
import get from 'lodash/get';
import set from 'lodash/set';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import { MuiFileDrop } from '@emobg/motion-ui';
import {
  base64WithoutName,
  getBase64,
  getImageName,
} from '@emobg/web-utils';
import { FileListComponent } from '@/components';

export default {
  components: {
    MuiFileDrop,
    FileListComponent,
  },
  model: {
    prop: 'files',
    event: 'change',
  },
  props: {
    hidden: {
      type: Boolean,
      default: false,
    },
    existingFiles: {
      type: Array,
      default: () => [],
    },
    files: {
      type: Array,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    showPreview: {
      type: Boolean,
      default: false,
    },
    draggableHeight: {
      type: Number,
      default: undefined,
    },
    acceptedFormats: {
      type: String,
      default: 'image/*',
    },
  },
  data() {
    return {
      fileDrop: {},
      isReadyToValidate: false,
    };
  },
  computed: {
    hasPreview() {
      const previewName = get(this, 'normalizedFilesForList[0].name', '');
      return previewName.match(/[^/]+(pdf)$/i);
    },
    normalizedFilesForList() {
      return map(this.files, file => ({
        name: getImageName(file),
        src: base64WithoutName(file),
      }));
    },
    imageForPreview() {
      let preview = get(this, 'normalizedFilesForList[0].src', null) || get(this, 'existingFiles[0].src', null);
      if (preview && this.normalizedFilesForList[0]) {
        preview = `data:image/png;base64,${preview}`;
      }
      return this.showPreview ? preview : null;
    },
  },
  mounted() {
    this.isReadyToValidate = true;
  },
  methods: {
    get,
    removeFile(file, index) {
      this.files.splice(index, 1);
      get(this, 'fileDrop.files', []).splice(index, 1);
      this.$emit('change', this.files);
    },
    removeExistingFile(file, index) {
      this.existingFiles.splice(index, 1);
      this.$emit('existingFileRemoved', this.existingFiles);
    },
    async onFileChange() {
      if (this.isReadyToValidate) {
        const files = get(this, 'fileDrop.files', []);
        set(this, '$refs.fileDrop.$refs.fileInput.value', null);
        this.$emit('change', await this.transformToCasesBase64(Array.from(files)));
        this.$emit('files', files);
      }
    },
    transformToCasesBase64(files) {
      if (files && files.length) {
        const base64Promises = [];

        forEach(files, file => {
          base64Promises.push(getBase64(file, { isHeaderRemoved: true, types: ['jpeg', 'jpg', 'png', 'pdf', 'vnd.openxmlformats-officedocument.spreadsheetml.sheet'] }));
        });

        return Promise.all(base64Promises);
      }

      return [];
    },
  },
};
</script>
<template>
  <div class="DragFileComponent d-flex flex-column">
    <MuiFileDrop
      ref="fileDrop"
      v-model="fileDrop"
      :accept="acceptedFormats"
      :max-items="multiple ? null : 1"
      :clear-items="!multiple"
      :style="draggableHeight && { height: `${draggableHeight}px` }"
      :class="[
        'cursor-pointer',
        { 'DragFileComponent__uploader__box p-2 border-radius d-flex justify-content-center ': !hidden, }
      ]"
      data-test-id="file_drop"
      @change="onFileChange"
    >
      <template v-if="!hidden">
        <div
          v-if="hasPreview"
          class="d-flex flex-column align-items-center justify-content-center emobg-background-color-white h-100"
        >
          <p>
            Preview not available
          </p>
        </div>
        <div
          :style="imageForPreview ? {
            'background-image': `url('${imageForPreview}')`,
            'background-size': 'cover',
            'background-position': 'center',
          } : {}"
          class="d-flex flex-column align-items-center justify-content-center p-4 emobg-color-ink-light full-height"
          data-test-id="preview_image"
        >
          <i
            :class="{ invisible: imageForPreview }"
            class="mx-auto icons-upload icons-large emobg-font-weight-semibold"
          />
          <p
            :class="{ invisible: imageForPreview }"
            class="mx-auto mt-3"
          >
            Drop your file here or
            <span class="emobg-color-primary emobg-font-weight-semibold">Browse</span>
            <slot name="extraInformation" />
          </p>
        </div>
      </template>
      <slot v-else />
    </MuiFileDrop>
    <FileListComponent
      v-if="!hidden"
      :files="normalizedFilesForList"
      :on-delete-item="removeFile"
      deleteable
      data-test-id="new-files_list"
    />
    <FileListComponent
      v-if="!hidden"
      :files="existingFiles"
      :on-delete-item="removeExistingFile"
      deleteable
      data-test-id="existing-files_list"
    />
  </div>
</template>
