export default {
  verificationCode: 'verificationCode',
};

export const SHARED_URLS = {
  home: '/',
  dashboard: '/dashboard',
  login: '/login',
  logout: '/logout',
  forbidden: '/403',
  mfa: '/mfa-verification',
};

export const SKIP_URLS = [
  SHARED_URLS.home,
  SHARED_URLS.login,
  SHARED_URLS.logout,
  SHARED_URLS.mfa,
  SHARED_URLS.dashboard,
  '/undefined',
  '/favicon.ico',
];
