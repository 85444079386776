var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.schemaItem.component
    ? _c(
        _vm.schemaItem.component,
        _vm._b(
          {
            tag: "Component",
            class: [
              _vm.contentClass,
              _vm.get(_vm.schemaItem.props(_vm.dataItem), "class"),
            ],
          },
          "Component",
          _vm.schemaItem.props(_vm.dataItem, _vm.dataIndex),
          false
        )
      )
    : _c("div", { class: _vm.contentClass }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.isFunction(_vm.schemaItem.template)
                ? _vm.schemaItem.template(_vm.dataItem)
                : _vm.dataItem[_vm.schemaItem.template]
            ) +
            " "
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }