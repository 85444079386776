<script>
/**
 * if `useRouter` is truthy, it uses `to` property to use RouterLink.
 * if `useRouter` is falsey, it uses `href` property with an anchor tag
 */
export default {
  name: 'LinkSwitch',
  props: {
    to: {
      type: String,
      default: '/404',
    },
    href: {
      type: String,
      default: '/404',
    },
    target: {
      type: String,
      default: '_self',
    },
    useRouter: {
      type: [
        Boolean,
        Object,
        String,
      ],
      default: undefined,
    },
  },

};
</script>
<template>
  <RouterLink
    v-if="useRouter"
    :to="to"
    :target="target"
  >
    <slot />
  </RouterLink>
  <a
    v-else
    :href="href"
    :target="target"
  >
    <slot />
  </a>
</template>
