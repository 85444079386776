import get from 'lodash/get';
import each from 'lodash/each';
import find from 'lodash/find';
import indexOf from 'lodash/indexOf';
import some from 'lodash/some';
import { isValidNumber } from 'libphonenumber-js';
import { ITALY_COMPANY_VALIDATION_PATTERN, VALIDATION_PATTERN } from '@emobg/web-utils';

export const NEGATIVE_OR_POSITIVE_DECIMAL_PATTERN = /^[-|+]?\d*([,.])?(\d{1,2})?$/;
export const DECIMAL_PATTERN = /^\d*([,.])?(\d{1,2})?$/;
export const INTEGER_BUT_ZERO_PATTERN = /^-?(?!0+$)\d+$/;
export const POSITIVE_BUT_ZERO_PATTERN = /^(?!0+$)\d+$/;
export const UUID_PATTERN = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;

export const PATTERN_INPUT_VALIDATIONS = {
  decimalNumber: {
    pattern: DECIMAL_PATTERN,
    message: 'Only numbers up to two decimals are allowed (e.g. 10,35)',
  },
  floatNumber: {
    pattern: VALIDATION_PATTERN.decimalNumber,
    message: 'Only float numbers allowed.',
  },
  wholeNumber: {
    pattern: VALIDATION_PATTERN.number,
    message: 'Only whole numbers allowed',
  },
  alphanumeric: {
    pattern: VALIDATION_PATTERN.alphanumeric,
    message: 'Only letters and numbers allowed',
  },
  uuid: {
    pattern: UUID_PATTERN,
    label: '',
    message: 'Invalid UUID format',
  },
};

export const DATE_INPUT_VALIDATIONS = {
  validDate: date => ({
    isValid: date && date.isValid(),
    message: 'Date or format invalid',
  }),
};

export const PHONE_VALIDATIONS = {
  isValidPhone: value => ({
    isValid: isValidNumber(value),
    message: 'Invalid phone number',
  }),
};

export const GOOGLE_ADDRESS_VALIDATIONS = {
  hasRequiredComponents: place => {
    const { addressComponents } = place;
    const requiredAddressComponents = {
      street_number: null,
      route: null,
      locality: null,
      administrative_area_level_1: null,
      administrative_area_level_2: null,
      country: null,
      postal_code: null,
    };

    each(requiredAddressComponents, (value, key) => {
      requiredAddressComponents[key] = find(addressComponents, component => indexOf(component.types, key) > -1);
    });

    return {
      message: 'Missing required address details as number, country or city.',
      isValid: !some(requiredAddressComponents, componentValue => !componentValue),
    };
  },
};

export const FILE_VALIDATIONS = {
  isMaxSize: (files, maxSizeInMB = 10) => {
    const file = get(files, '[0]');
    const maxSizeInBytes = maxSizeInMB * (10 ** 6);
    return {
      isValid: file && file.size < maxSizeInBytes,
      message: `File size should be maximum ${maxSizeInMB} MB`,
    };
  },
};

export const FISCAL_CODE_VALIDATIONS = {
  isValidCompanyFiscalCode: fiscalCode => {
    const isValidFormat = ITALY_COMPANY_VALIDATION_PATTERN.companyCodiceFiscale;
    return {
      isValid: isValidFormat.test(fiscalCode),
      message: 'Invalid fiscal code',
    };
  },
};

