const carsharing = Object.freeze({
  index: 'carsharing',
  bookings: {
    index: 'carsharing.bookings',
    detail: {
      index: 'carsharing.bookings.detail.index',
      events: 'carsharing.bookings.detail.events',
      invoices: 'carsharing.bookings.detail.invoices',
      audits: 'carsharing.bookings.detail.audits',
      cases: 'carsharing.bookings.detail.cases',
      comments: 'carsharing.bookings.detail.comments',
      additionalCosts: 'carsharing.detail.additionalCosts',
      fraud: 'carsharing.detail.fraud',
      vehicleInteractions: 'carsharing.bookings.detail.vehicleInteractions',
    },
    management: 'carsharing.bookings.management',
  },
  planning: 'carsharing.planning',
  bookingSets: {
    index: 'carsharing.bookingSets.index',
    create: 'carsharing.bookingSets.create',
    generalInformation: 'carsharing.bookingSets.generalInformation',
    selectVehicles: 'carsharing.bookingSets.selectVehicles',
    list: 'carsharing.bookingSets.list',
    occurrences: 'carsharing.bookingSets.occurrences',
  },
  prebookings: {
    index: 'carsharing.prebookings',
  },
});

export default carsharing;
