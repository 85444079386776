import { backOffice } from '@emobg/web-api-client';
import { createCustomStore, clearData } from '@emobg/web-utils';

const damagesModule = createCustomStore(({ runAsyncFlow }) => ({
  mutations: {
    clearData,
  },
  actions: {
    async getDamages({ commit }, vehicleUuid) {
      await runAsyncFlow(commit, {
        request: backOffice.csfleet.getDamages,
        params: [vehicleUuid, 'v2'],
      });
    },
  },
}));

export default damagesModule;
