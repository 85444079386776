export const INVOICING_PERMISSIONS = {
  viewInvoicingInvoices: 'view_invoicing_invoices',
  viewInvoicingPayments: 'view_invoicing_payments',
  viewInvoicingPayouts: 'view_invoicing_payouts',
};
export const CRM_PERMISSIONS = {
  viewCrmUsers: 'view_crm_users',
  viewCrmCompanies: 'view_crm_companies',
};

