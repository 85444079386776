<script>
import ModalHeaderComponent from './ModalHeader/ModalHeaderComponent';

export default {
  components: {
    ModalHeaderComponent,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    header: {
      type: Object,
      default: () => ({}),
    },
    backdropDismiss: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    size: {
      type: String,
      default: 'medium',
    },
    modalClasses: {
      type: String,
      default: '',
    },
  },
  methods: {
    closeModal() {
      if (this.onClose) {
        this.onClose();
      }
      this.$emit('close-modal');
    },
  },
};
</script>

<template>
  <Transition name="modal">
    <section
      class="backdrop position-fixed d-flex justify-content-center"
      @click.self="backdropDismiss && closeModal()"
    >
      <article
        class="Modal emobg-background-color-white emobg-border-radius-large overflow-hidden"
        :class="[size, modalClasses]"
      >
        <ModalHeaderComponent
          :title="title"
          v-bind="header"
          @close-modal="closeModal"
        />
        <aside class="Modal__alerts">
          <slot name="alerts" />
        </aside>
        <section
          role="main"
          class="Modal__body overflow-y-auto"
        >
          <slot name="body" />
        </section>
        <footer
          v-if="$slots.footer"
          class="Modal__footer emobg-border-top-1 emobg-background-color-ground-lightest emobg-border-color-ground-light p-3"
        >
          <slot name="footer" />
        </footer>
      </article>
    </section>
  </Transition>
</template>
