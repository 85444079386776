import { backOffice } from '@emobg/web-api-client';
import { INITIAL_STATUS, createCustomStore, withScopes } from '@emobg/web-utils';
import { setError as globalSetError } from '@/store/setError';

const {
  getCommentsByType,
  postComment,
  putComment,
  deleteComment,
} = backOffice.comments;

export const COMMENTS_SCOPES = {
  comments: 'comments',
  newComment: 'newComment',
  deleteComment: 'deleteComment',
};

const comments = createCustomStore(({ runAsyncFlow }) => withScopes(COMMENTS_SCOPES, ({
  mutations: {
    setError(state, payload) {
      const { scope, value } = payload;
      if (scope === COMMENTS_SCOPES.deleteComment) {
        globalSetError.bind(this)(state, payload);
      } else {
        state[scope].error = value;
      }
    },
    clearErrors(state) {
      state[COMMENTS_SCOPES.newComment].error = null;
      state[COMMENTS_SCOPES.newComment].STATUS = { ...INITIAL_STATUS };
    },
    clearData(state) {
      state[COMMENTS_SCOPES.comments].data = [];
      state[COMMENTS_SCOPES.comments].STATUS = { ...INITIAL_STATUS };
    },
  },
  actions: {
    async getCommentsByType({ commit }, { commentableUuid, commentableType, version = 'v2' }) {
      await runAsyncFlow(commit, {
        request: getCommentsByType,
        params: [commentableUuid, commentableType, version],
        scope: COMMENTS_SCOPES.comments,
      });
    },
    async putComment({ commit }, { commentUuid, data }) {
      await runAsyncFlow(commit, {
        request: putComment,
        params: [commentUuid, data],
        scope: COMMENTS_SCOPES.newComment,
      });
    },
    async postComment({ commit }, data) {
      await runAsyncFlow(commit, {
        request: postComment,
        params: [data],
        scope: COMMENTS_SCOPES.newComment,
      });
    },
    async deleteComment({ commit }, commentUuid) {
      await runAsyncFlow(commit, {
        request: deleteComment,
        params: [commentUuid],
        scope: COMMENTS_SCOPES.deleteComment,
      });
    },
  },
})));

export default comments;
