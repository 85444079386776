export const ZONE_DEFAULTS = Object.freeze({
  fillOpacity: 0.5,
  fillColor: '#f15a6d',
  strokeColor: '#000',
  strokeWeight: 3,
  strokeOpacity: 1,
  isDraggable: false,
  isEditable: false,
});

export const ZONE_COLORS = [
  { name: 'blue', hex: '#55c4d0' },
  { name: 'navy', hex: '#375573' },
  { name: 'red', hex: '#f15a6d' },
  { name: 'green', hex: '#6ec190' },
  { name: 'yellow', hex: '#ffc845' },
];

export const ZONE_TYPES = Object.freeze({
  circle: 'circle',
  polygon: 'polygon',
  polyline: 'polyline',
});

export const DEFAULT_COUNTRY_ZOOM = 5;

export const SEARCH_LOCATION_ZOOM = 14;

export const COUNTRY_COORDINATES = Object.freeze({
  es: {
    coordinates: {
      lat: 40.1301441,
      lng: -8.2021324,
    },
  },
  fr: {
    coordinates: {
      lat: 46.1390236,
      lng: -2.4353706,
    },
  },
  be: {
    coordinates: {
      lat: 50.4974442,
      lng: 3.3451426,
    },
    zoom: 7,
  },
  gb: {
    coordinates: {
      lat: 54.231603,
      lng: -13.4275055,
    },
  },
  pt: {
    coordinates: {
      lat: 37.14588,
      lng: -23.3170909,
    },
  },
  it: {
    coordinates: {
      lat: 41.2118762,
      lng: 8.223391,
    },
  },
});

export const DRAWING_EVENTS = Object.freeze({
  overlayComplete: 'overlaycomplete',
  setAt: 'set_at',
  insertAt: 'insert_at',
  removeAt: 'remove_at',
  centerChanged: 'center_changed',
  radiusChanged: 'radius_changed',
});
