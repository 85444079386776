import { ICONS } from '@emobg/vue-base';

export const NOTIFICATION_TYPES = {
  success: 'success',
  info: 'info',
  alert: 'warning',
  error: 'danger',
};

export const NOTIFICATION_ICONS = {
  [NOTIFICATION_TYPES.success]: ICONS.bold.check,
  [NOTIFICATION_TYPES.info]: ICONS.infoFull,
  [NOTIFICATION_TYPES.alert]: ICONS.alertFull,
  [NOTIFICATION_TYPES.error]: ICONS.alertFull,
};
