<script>
export default {
  name: 'ContentCellComponent',
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    suffix: {
      type: String,
      default: '',
    },
    isImage: {
      type: Boolean,
      default: false,
    },
    isOnHeader: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<template>
  <div class="ContentCellComponent">
    <h3 v-if="isOnHeader">
      {{ label }}
    </h3>
    <h4 v-else>
      {{ label }}
    </h4>
    <div class="emobg-body-1 mt-1">
      <slot>
        <template v-if="isImage">
          <img
            :alt="label"
            :src="value"
            style="width: auto; height: 100%; max-height: 60px;"
          >
        </template>
        <template v-else>
          {{ value || FALLBACK_MESSAGE.dash }}{{ suffix ? suffix : '' }}
        </template>
      </slot>
    </div>
  </div>
</template>
