import Api from './api';

const AxiosPlugin = {};

AxiosPlugin.install = (Vue, { ENV }) => {
  const apiInstance = new Api(ENV);
  return [Vue, apiInstance];
};

export default AxiosPlugin;
