const cases = Object.freeze({
  index: 'cases',
  caseEvents: {
    index: 'cases.caseEvents',
    list: 'cases.list',
    create: 'cases.create',
    detail: 'cases.detail',
  },
});

export default cases;
