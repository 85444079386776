var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "DocumentPreview" }, [
    _c(
      "div",
      {
        staticClass:
          "p-2 emobg-background-color-ground-lighter emobg-border-color-ground emobg-border-1 emobg-border-radius-small",
        on: {
          click: function ($event) {
            return _vm.$emit("click")
          },
        },
      },
      [
        _c(
          "div",
          {
            class: [
              "d-flex flex-column emobg-background-color-white justify-content-center align-items-center",
              { "cursor-pointer": !_vm.isPointerDisabled },
            ],
            style: _vm.imageStyle,
          },
          [
            _vm.isPdf
              ? [
                  _vm._v(" Preview not available "),
                  _c(
                    "ui-button",
                    {
                      staticClass: "mt-1",
                      attrs: {
                        face: _vm.FACES.text,
                        color: _vm.COLORS.primary,
                      },
                      on: {
                        clickbutton: function ($event) {
                          $event.stopPropagation()
                          return (() => {
                            _vm.downloadFile({
                              url: _vm.image,
                              name: _vm.name || _vm.getImageName(_vm.image),
                            })
                          }).apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(" Open "),
                      _c("ui-icon", {
                        staticClass: "ml-1",
                        attrs: {
                          icon: _vm.ICONS.externalUrl,
                          size: _vm.ICONS_SIZES.xSmall,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              : _vm._e(),
          ],
          2
        ),
      ]
    ),
    !_vm.isDownloadHidden
      ? _c(
          "div",
          { staticClass: "d-flex justify-content-end mt-1" },
          [
            _c("ui-icon", {
              staticClass: "cursor-pointer p-1",
              attrs: {
                icon: _vm.ICONS.download,
                size: _vm.ICONS_SIZES.xSmall,
                color: _vm.GRAYSCALE.inkLight,
              },
              on: {
                click: () => {
                  _vm.downloadFile({
                    url: _vm.image,
                    name: _vm.name || _vm.getImageName(_vm.image),
                  })
                },
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }