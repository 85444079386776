<script>
import TimeInterval from './TimeInterval';
import { intervalDuration } from '../utils/consecutiveMinutes/consecutiveMinutes';

export default {
  components: {
    TimeInterval,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    intervals: {
      type: Array,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    minDuration: {
      type: [Number, String],
      default: 0,
    },
  },
  methods: {
    isIntervalOutOfMinDuration(interval) {
      return this.minDuration && intervalDuration(interval) < this.minDuration;
    },
  },
};
</script>
<template>
  <div class="d-flex mx-n1 my-3 emobg-border-bottom-1 emobg-border-color-ground-lighter">
    <p class="WeekSchedule__DayIntervals__day d-flex align-items-center p-2">
      {{ name }}
    </p>
    <ul class="intervals p-2">
      <TimeInterval
        v-for="interval in intervals"
        :key="interval"
        :interval="interval"
        :is-disabled="isDisabled"
        :class="{
          'emobg-color-danger': isIntervalOutOfMinDuration(interval),
        }"
        v-on="$listeners"
      />
    </ul>
  </div>
</template>
