export default {
  namespaced: true,
  state: {
    errorMessage: '',
  },
  mutations: {
    setErrorMessage(state, payload = '') {
      state.errorMessage = payload;
    },
  },
  actions: {
    setErrorMessage({ commit }, payload) {
      commit('setErrorMessage', payload || '');
    },
  },
};
