<script>
export default {
  name: 'EditButton',
};
</script>
<template>
  <ui-button
    :face="FACES.text"
    :color="GRAYSCALE.inkLight"
    square
    @clickbutton="$emit('click')"
  >
    <ui-icon
      :icon="ICONS.edit"
      :size="SIZES.xLarge"
      :color="GRAYSCALE.inkLight"
    />
  </ui-button>
</template>
