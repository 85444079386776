import Cookie from 'js-cookie';
import {
  STORAGE_NAMES,
  getValue,
  isObjectEmpty,
  isValueIncluded,
} from '@emobg/web-utils';

import createStore from '../../store';
import RouterMap, { SHARED_URLS } from '../RouterMap';

export const twoFactorRedirection = async (to, _from, next) => {
  const excludedRoutes = [SHARED_URLS.logout];
  const isExcluded = isValueIncluded(excludedRoutes, to.path);
  const store = createStore();
  let user = getValue(store, 'state.App.UserAccount.user.data', {});

  if (isObjectEmpty(user)) {
    await store.dispatch('App/UserAccount/getUserAndOperators');
    user = getValue(store, 'state.App.UserAccount.user.data', {});
  }
  if (user.mfa_required && to.name !== RouterMap.verificationCode && !isExcluded) {
    next({ name: RouterMap.verificationCode });
  } else if (!user.mfa_required && to.name === RouterMap.verificationCode && !isExcluded) {
    const returnUrl = Cookie.get(STORAGE_NAMES.returnUrl) || SHARED_URLS.dashboard;
    next({ path: returnUrl });
  } else {
    next();
  }
};
