var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "EmptyStateComponent d-flex flex-column align-items-center justify-content-center flex-fill p-5",
        {
          "emobg-background-color-ground-lightest": _vm.hasBackground,
          "emobg-border-1 emobg-border-color-ground": _vm.hasBorder,
        },
      ],
      attrs: { "data-test-id": "empty-state" },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "EmptyStateComponent__content d-flex flex-column align-center",
        },
        [
          _vm.src || _vm.icon
            ? _c(
                "div",
                [
                  _vm.src
                    ? _c("figure", { staticClass: "mb-4" }, [
                        _c("img", {
                          staticClass: "EmptyStateComponent__image",
                          attrs: { src: _vm.src, alt: "empty state" },
                        }),
                      ])
                    : _vm.icon
                    ? _c("ui-icon", {
                        staticClass: "mb-4",
                        attrs: { icon: _vm.icon, size: _vm.ICONS_SIZES.xLarge },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.title
            ? _c(
                "h2",
                {
                  staticClass:
                    "emobg-font-large emobg-color-ink-light emobg-font-weight-bold mb-0",
                  attrs: { "data-test-id": "title" },
                },
                [_vm._v(" " + _vm._s(_vm.title) + " ")]
              )
            : _vm._e(),
          _vm.description || _vm.$slots.description
            ? _c(
                "div",
                {
                  staticClass: "emobg-color-ink-light mt-3",
                  attrs: { "data-test-id": "description" },
                },
                [
                  _vm.description
                    ? _c("p", { staticClass: "mb-0" }, [
                        _vm._v(" " + _vm._s(_vm.description) + " "),
                      ])
                    : _vm._e(),
                  _vm.errorMessage
                    ? [
                        _c("p", { staticClass: "mt-3" }, [
                          _vm._v(" " + _vm._s(_vm.additionalFeedback) + " "),
                        ]),
                        _c("p", { staticClass: "mt-3" }, [
                          _vm._v(
                            " Alternatively, provide your administrator with the following error message: "
                          ),
                        ]),
                        _c("p", {
                          staticClass: "emobg-color-danger my-3 font-small",
                          attrs: { "data-test-id": "error-message" },
                          domProps: { textContent: _vm._s(_vm.errorMessage) },
                        }),
                      ]
                    : _vm._t("description"),
                ],
                2
              )
            : _vm._e(),
        ]
      ),
      _vm._t("buttons", function () {
        return [
          !_vm.isEmpty(_vm.actions)
            ? [
                _c(
                  "div",
                  { staticClass: "mt-3 mb-4" },
                  [
                    _vm._l(_vm.actions, function (button, index) {
                      return [
                        button.to
                          ? _c(
                              "RouterLink",
                              { key: button.text, attrs: { to: button.to } },
                              [
                                _c(
                                  "ui-button",
                                  {
                                    class: { "ml-3": index > 0 },
                                    attrs: {
                                      face: button.face || _vm.FACES.fill,
                                      narrow: "",
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(button.text) + " ")]
                                ),
                              ],
                              1
                            )
                          : button.callback
                          ? _c(
                              "ui-button",
                              {
                                key: button.text,
                                class: { "ml-3": index > 0 },
                                attrs: {
                                  face: button.face || _vm.FACES.fill,
                                  narrow: "",
                                },
                                on: { clickbutton: button.callback },
                              },
                              [_vm._v(" " + _vm._s(button.text) + " ")]
                            )
                          : _vm._e(),
                      ]
                    }),
                  ],
                  2
                ),
              ]
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }