var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "PhoneInputComponent",
        {
          "PhoneInputComponent--active": _vm.isActive,
          "PhoneInputComponent--disabled": _vm.disabled,
        },
      ],
    },
    [
      _c(
        "label",
        {
          staticClass:
            "PhoneInputComponent__label emobg-font-weight-semibold pb-1 d-block",
          attrs: { disabled: _vm.disabled },
        },
        [_vm._v(" " + _vm._s(_vm.label) + " ")]
      ),
      _c(
        "div",
        {
          staticClass:
            "PhoneInputComponent__input-wrapper d-flex emobg-border-radius-small",
        },
        [
          _c("MuiSelect", {
            attrs: {
              options: _vm.countries,
              searchbox: {
                placeholder: _vm.country ? "" : "Select",
                threshold: 1,
              },
              disabled: _vm.disabled,
              "on-close": _vm.onBlur,
              required: "",
              placeholder: "Select",
              name: "prefix",
              "data-test-id": "prefix-select",
            },
            on: { click: _vm.onFocus },
            scopedSlots: _vm._u([
              {
                key: "selected",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.get(item, "flag")) +
                        " " +
                        _vm._s(_vm.get(item, "label")) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "item",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.get(item, "flag")) +
                        " " +
                        _vm._s(_vm.get(item, "label")) +
                        " "
                    ),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.country,
              callback: function ($$v) {
                _vm.country = $$v
              },
              expression: "country",
            },
          }),
          _c("MuiInputText", {
            staticClass: "w-100",
            attrs: {
              "model-value": _vm.phone,
              disabled: _vm.disabled,
              placeholder: _vm.placeholder,
              name: "phone",
              "data-test-id": "phone-input",
            },
            on: {
              blur: _vm.onBlur,
              focus: _vm.onFocus,
              click: _vm.onFocus,
              input: _vm.onInput,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }