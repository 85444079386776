import { createCustomStore } from '@emobg/web-utils';

const omnisearchModule = createCustomStore(() => ({
  state: {
    isReady: false,
  },
  mutations: {
    setIsReady(state, payload) {
      state.isReady = payload;
    },
  },
  actions: {
    setOmnisearchReady({ commit }) {
      commit('setIsReady', true);
    },
  },
}));

export default omnisearchModule;
