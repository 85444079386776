<script>
import isFunction from 'lodash/isFunction';
import debounce from 'lodash/debounce';
import each from 'lodash/each';
import filter from 'lodash/filter';
import map from 'lodash/map';
import set from 'lodash/set';
import get from 'lodash/get';
import some from 'lodash/some';
import reduce from 'lodash/reduce';
import size from 'lodash/size';

import { MuiCollapse } from '@emobg/motion-ui/v1';
import { DELAY, FALLBACK_MESSAGE } from '@emobg/web-utils';

import TableContentWrapperComponent from './TableContentWrapperComponent';

// TODO: Replace usages with v1 MuiTable when refactored https://europcarmobility.atlassian.net/browse/CF-424
export default {
  name: 'TableComponent',
  components: {
    MuiCollapse,
    TableContentWrapperComponent,
  },
  props: {
    /**
     * Schema of table
     * {
     *   header: 'Text of header',
     *   template: 'attribute3' | rowData => rowData.attribute3,
     *   component: Component,
     *   props: (rowData) => ({ prop1: rowData.attribute1 }),
     *   collapsed: false,
     *   collapsable: false,
     *   minWidth: 80,
     *   width: 120,
     * }
     */
    schema: {
      type: Array,
      default: () => [],
    },
    // Contains the data to show on table
    data: {
      type: Array,
      default: () => [],
    },
    /**
     * Array of options that dropdown component must has
     * {
     *   label: 'Action 1',
     *   action: () => {},
     *   labelClass: 'emobg-color-danger',
     *   hideAction: (row) => { rowCondition },
     * }
     */
    rowActions: {
      type: Array,
      default: () => [],
    },
    emptyLabel: {
      type: String,
      default: FALLBACK_MESSAGE.noResults,
    },
  },
  data() {
    return {
      innerSchema: [],
      rowOptions: [],
      rowOverflowMargin: 0,
      globalToggle: false,
    };
  },
  computed: {
    collapsedSchema() {
      return filter(this.innerSchema, 'isInternallyCollapsed');
    },
    nonCollapsedSchema() {
      return filter(this.innerSchema, schemaItem => !schemaItem.isInternallyCollapsed);
    },
    alwaysVisibleSchema() {
      return filter(this.innerSchema, schemaItem => !schemaItem.collapsed && !schemaItem.collapsable);
    },
    collapsableSchema() {
      return filter(this.innerSchema, schemaItem => !schemaItem.collapsed && schemaItem.collapsable);
    },
  },
  watch: {
    data: {
      handler(newData) {
        this.rowOptions = map(newData, () => ({
          cellOptions: map(this.schema, () => ({
            hasTooltip: false,
          })),
          isCollapseOpened: false,
        }));

        this.$nextTick(() => {
          this.handleTooltips();
        });
      },
      immediate: true,
    },
  },
  created() {
    this.MIN_DEFAULT_CELL_WIDTH = 80;
    this.innerSchema = map(this.schema, itemSchema => ({ ...itemSchema, isInternallyCollapsed: itemSchema.collapsed }));
    this.debouncedResizeListener = debounce(this.handleResize, DELAY.short);
  },
  mounted() {
    setTimeout(this.handleResize, DELAY.short);
    window.addEventListener('resize', this.debouncedResizeListener);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.debouncedResizeListener);
  },
  methods: {
    map,
    some,
    size,
    get,
    isStickyColumn(schemaItem, index) {
      return get(schemaItem, 'isSticky') && index === 0 && !get(this, 'collapsedSchema.length', 0);
    },
    filterActions(row) {
      return filter(this.rowActions, action => (action.hideAction && isFunction(action.hideAction) ? !action.hideAction(row) : true));
    },
    handleResize() {
      const { headerActionCell, table } = this.$refs;

      if (!table) {
        return;
      }

      const actionColumnWidth = this.rowActions.length && this.$refs.headerActionCell ? headerActionCell.clientWidth : 0;
      const tableWidth = table.clientWidth;
      const totalCellsWidth = reduce(
        this.alwaysVisibleSchema,
        (sum, itemSchema) => sum + (itemSchema.minWidth || this.MIN_DEFAULT_CELL_WIDTH),
        0,
      ) + actionColumnWidth;

      let availableSpace = tableWidth - totalCellsWidth;
      each(this.collapsableSchema, itemSchema => {
        const itemWidth = (itemSchema.minWidth || this.MIN_DEFAULT_CELL_WIDTH);
        if (availableSpace >= itemWidth) {
          set(itemSchema, 'isInternallyCollapsed', false);
          availableSpace -= itemWidth;
        } else {
          set(itemSchema, 'isInternallyCollapsed', true);
        }
      });

      this.rowOverflowMargin = 0;
      this.$nextTick(() => {
        const rowOverflowMargin = this.$refs.table.scrollWidth - this.$refs.table.clientWidth;

        if (rowOverflowMargin > 0) {
          this.rowOverflowMargin = rowOverflowMargin;
        }

        this.handleTooltips();
      });
    },
    handleTooltips() {
      const tableCells = this.$refs.cell;
      each(tableCells, cellElement => {
        const ellipsisElement = cellElement.querySelector('.TableContentComponent__content');
        const rowIndex = cellElement.getAttribute('row-index');
        const cellIndex = cellElement.getAttribute('cell-index');
        const hasTooltip = !!(ellipsisElement && ellipsisElement.scrollWidth > ellipsisElement.clientWidth);

        set(this.rowOptions, `[${rowIndex}].cellOptions[${cellIndex}].hasTooltip`, hasTooltip);
      });
    },
    toggleAll() {
      this.globalToggle = !this.globalToggle;
      each(this.rowOptions, rowOption => {
        set(rowOption, 'isCollapseOpened', this.globalToggle);
      });
    },
    getCellStyles(schemaItem) {
      const { minWidth: minWidthSchema, width: widthSchema } = schemaItem;
      const minWidth = minWidthSchema && `${minWidthSchema}px`;
      const width = widthSchema && {
        maxWidth: `${widthSchema}px`,
        flex: '1 1 100%',
      };
      return (minWidth || width) && {
        ...width,
        minWidth,
      };
    },
  },
};
</script>

<template>
  <div
    ref="table"
    class="Table d-flex flex-column emobg-background-color-white emobg-border-1 emobg-border-color-ground-light overflow-x-auto"
  >
    <header
      class="Table__header Table__row emobg-label-1"
      :style="rowOverflowMargin && { marginRight: `-${rowOverflowMargin}px` }"
    >
      <div
        v-for="(schemaItem, index) in nonCollapsedSchema"
        :key="index"
        :style="getCellStyles(schemaItem)"
        :class="[
          'Table__cell emobg-background-color-ground-lighter emobg-border-bottom-4 emobg-border-color-ground-light',
          { 'Table__cell--sticky': isStickyColumn(schemaItem, index) },
        ]"
      >
        <div class="Table__cell--inner text-ellipsis">
          {{ schemaItem.header }}
        </div>
      </div>

      <div
        v-if="rowActions.length || collapsedSchema.length"
        ref="headerActionCell"
        class="Table__cell Table__cell--actions emobg-background-color-ground-lighter emobg-border-bottom-4 emobg-border-color-ground-light"
      >
        <div
          v-if="rowActions.length"
          style="width: 40px;"
        />
        <ui-button
          v-if="collapsedSchema.length"
          :color="GRAYSCALE.inkLight"
          :face="FACES.text"
          square
          @clickbutton="toggleAll"
        >
          <ui-icon :icon="globalToggle ? ICONS.arrowUp : ICONS.arrowDown" />
        </ui-button>
      </div>
    </header>
    <div
      v-for="(row, indexData) in data"
      :key="indexData"
      :class="[
        'Table__row emobg-body-1 emobg-border-bottom-1 emobg-border-color-ground-light',
        { 'emobg-border-bottom-1 emobg-border-color-ground-light': indexData < data.length - 1 },
      ]"
      :style="rowOverflowMargin && { marginRight: `-${rowOverflowMargin}px` }"
    >
      <div class="Table__cells-non-collapsed">
        <div
          v-for="(schemaItem, index) in nonCollapsedSchema"
          ref="cell"
          :key="index"
          :style="getCellStyles(schemaItem)"
          :row-index="indexData"
          :cell-index="index"
          :class="['Table__cell', { 'Table__cell--sticky': isStickyColumn(schemaItem, index) }]"
        >
          <TableContentWrapperComponent
            :schema-item="schemaItem"
            :data-item="row"
            :has-tooltip="rowOptions[indexData].cellOptions[index].hasTooltip"
            :data-index="indexData"
          />
        </div>
        <div
          v-if="rowActions.length || collapsedSchema.length"
          class="Table__cell Table__cell--actions"
        >
          <ui-dropdown :class="{'invisible': !filterActions(row).length}">
            <ui-icon
              slot="trigger"
              :icon="ICONS.optionsHorizontalFull"
              :color="GRAYSCALE.inkLight"
            />
            <ui-dropdown-actions
              slot="content"
              :actions.prop="map(filterActions(row), rowAction => ({ ...rowAction, action: () => rowAction.action(row, indexData) }))"
            />
          </ui-dropdown>

          <ui-button
            v-if="collapsedSchema.length"
            :color="GRAYSCALE.inkLight"
            :face="FACES.text"
            square
            @clickbutton="rowOptions[indexData].isCollapseOpened = !rowOptions[indexData].isCollapseOpened"
          >
            <ui-icon :icon="rowOptions[indexData].isCollapseOpened ? ICONS.arrowUp : ICONS.arrowDown" />
          </ui-button>
        </div>
      </div>

      <div
        v-if="collapsedSchema.length"
        class="Table__cells-collapsed"
      >
        <MuiCollapse
          :open="rowOptions[indexData].isCollapseOpened"
          class="Table__collapse-section overflow-hidden d-flex flex-wrap"
        >
          <div
            v-for="(collapsedSchemaItem, collapsedSchemaIndex) in collapsedSchema"
            :key="collapsedSchemaIndex"
            class="Table__cell-collapsed"
          >
            <TableContentWrapperComponent
              :schema-item="collapsedSchemaItem"
              :data-item="row"
              :data-index="indexData"
              is-collapsed
            />
          </div>
        </MuiCollapse>
      </div>
    </div>
    <div
      v-if="!size(data)"
      class="Table__no-results flex-fill d-flex flex-column justify-content-center align-items-center py-4 px-3 text-center emobg-background-color-ground-lightest"
      :style="rowOverflowMargin && { marginRight: `-${rowOverflowMargin}px` }"
    >
      <slot name="empty">
        {{ emptyLabel }}
      </slot>
    </div>
  </div>
</template>
