import CryptoJS from 'crypto-js';
import axios from 'axios';
import { logger } from '@emobg/web-utils';
export * from './algolia';
export * from './beamer';
export * from './auth0';
export * from './document';
export * from './numbers';
export * from './validations';
export * from './momentMixin';
export * from './store';
export * from './objects';
export * from './files';
export * from './images';

const Utils = {
  // Update the html document
  setupStyle({ title, style }) {
    // We update the title of the document
    document.title = title;

    // We Set the CSS style for the page
    if (style) {
      const link = document.createElement('link');
      link.href = style;
      link.type = 'text/css';
      link.rel = 'stylesheet';
      link.media = 'screen,print';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
  },

  async loadEnvConfig() {
    try {
      const { data } = await axios.get('/middleware/config');
      // Desencrypt envs vars
      const bytes = CryptoJS.AES.decrypt(data.env, 'e3b0c44298fc1c149afbf4c8996fb92427ae41e4649b934ca495991b7852b855');
      const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
      const envConfig = decryptedText && JSON.parse(decryptedText);
      data.env = envConfig;

      return data;
    } catch (error) {
      logger.message('Error while loading environmental data or while encryption.', 'error');
      logger.message(error, 'error');
      return {};
    }
  },

  async setToken({ accessToken }) {
    try {
      return await axios.post('/middleware/token', { accessToken });
    } catch (error) {
      logger.message('Error while loading environmental data or while encryption.', 'error');
      logger.message(error, 'error');
      return {};
    }
  },
};

export default Utils;
