<script>
import each from 'lodash/each';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import some from 'lodash/some';
import upperFirst from 'lodash/upperFirst';

import { BUTTON_TYPES, Validate } from '@emobg/vue-base';

import {
  isIntervalOverlap,
  timeRegex,
  validateInterval,
  validateTime,
} from '../utils';
import { ERRORS } from '../utils/const/errors';

export default {
  name: 'InputInterval',
  directives: {
    Validate,
  },
  props: {
    dayOptions: {
      type: Array,
      required: true,
    },
    weekScheduler: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      start: '',
      end: '',
      intervalFeedback: [],
      resettingCounter: 0,
      formKey: 0,
      weekDays: [],
      formStatus: { isValid: false },
      timeValidator: time => {
        let errors;
        let valid;
        try {
          validateTime(time);
          valid = true;
        } catch (error) {
          errors = error.message;
          valid = false;
        }
        return {
          valid,
          errors,
        };
      },
    };
  },
  computed: {
    areFieldsEmpty() {
      return isEmpty(this.start) || isEmpty(this.end) || isEmpty(this.weekDays);
    },
    isAddDisabled() {
      this.validateInput();
      return !this.formStatus.isValid
        || this.areFieldsEmpty
        || !!size(this.intervalFeedback);
    },
    isFeedbackVisible() {
      return !this.areFieldsEmpty && get(this.$refs, 'form.areAllValid', true) && this.isAddDisabled;
    },
  },
  created() {
    this.timeRegex = timeRegex;
    this.ERRORS = ERRORS;
    this.BUTTON_TYPES = BUTTON_TYPES;
  },
  methods: {
    upperFirst,
    validateInput() {
      const feedback = [];
      const currentInterval = `${this.start}-${this.end}`;
      try {
        validateInterval(currentInterval);
      } catch (error) {
        if (error.message !== ERRORS.timePattern) {
          feedback.push(error.message);
        }
      }
      each(this.weekDays, weekDay => {
        const day = this.weekScheduler.get()[weekDay];
        const hasOverlap = some(day, interval => isIntervalOverlap(interval, currentInterval));
        if (hasOverlap) {
          feedback.push(
            `${find(this.dayOptions, { value: weekDay }).label}: ${this.errors.intervalOverlap}`,
          );
        }
      });
      if (!size(this.weekDays)) {
        feedback.push(this.errors.dayNotSelected);
      }
      this.intervalFeedback = feedback;
    },
    onClickAdd() {
      const payload = {
        days: this.weekDays,
        interval: `${this.start}-${this.end}`,
      };
      this.$emit('add-time-intervals', payload);
      this.resetInputs();
    },
    resetInputs() {
      this.start = '';
      this.end = '';
      this.weekDays = [];
      this.resettingCounter += 1;
      this.formKey += 1;
    },
  },
};
</script>
<template>
  <ui-form
    :key="formKey"
    @submitform="onClickAdd"
  >
    <ui-validate
      class="d-flex w-100"
      @status="event => formStatus = event.detail"
    >
      <div
        class="WeekSchedule__InputInterval__day mr-2 flex-fill font-weight-normal"
        style="min-width: 250px;"
      >
        <ui-select-multiple
          v-validate.selectMultiple="{
            isRequired: true,
          }"
          :values.prop="weekDays"
          :options.prop="dayOptions"
          label="Days*"
          placeholder="Select"
          class="w-100"
          name="weekDay"
          multiple
          @selectoptions="({ detail }) => weekDays = detail"
        />
      </div>
      <div class="WeekSchedule__InputInterval__time mx-2 flex-fill">
        <ui-text-input
          :ref="`start-${resettingCounter}`"
          v-validate.input="{
            isRequired: true,
            isPattern: {
              pattern: timeRegex,
              message: ERRORS.timePattern,
            },
          }"
          :value="start"
          label="From*"
          class="flex-fill"
          name="start"
          placeholder="HH:mm"
          @changevalue="({ detail }) => start = detail"
        />
      </div>
      <div class="WeekSchedule__InputInterval__time flex-fill mx-2">
        <ui-text-input
          :ref="`end-${resettingCounter}`"
          v-validate.input="{
            isRequired: true,
            isPattern: {
              pattern: timeRegex,
              message: ERRORS.timePattern,
            },
          }"
          :value="end"
          label="To*"
          class="flex-fill"
          name="end"
          placeholder="HH:mm"
          @changevalue="({ detail }) => end = detail"
        />
      </div>
      <div role="cell">
        <ui-button
          :disabled="isAddDisabled"
          :face="FACES.outline"
          :type="BUTTON_TYPES.submit"
          class="ml-2 d-inline-block"
          style="margin-top: 23px;"
        >
          Add
        </ui-button>
      </div>
    </ui-validate>
    <ul
      v-if="isFeedbackVisible"
      class="d-flex flex-column justify-content-start emobg-color-danger align-items-center my-3 emobg-font-weight-bold"
    >
      <li
        v-for="feedback of intervalFeedback"
        :key="feedback"
        class="my-2 d-flex align-items-center align-self-start"
      >
        <ui-icon
          :size="ICONS_SIZES.medium"
          :icon="ICONS.info"
          class="mr-1"
          data-test-id="info-icon"
        />
        {{ upperFirst(feedback) }}
      </li>
    </ul>
  </ui-form>
</template>
