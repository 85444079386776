var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { key: _vm.$attrs.key, staticClass: "ImageTemplate" }, [
    _vm.isRenderable
      ? _c("img", {
          style: `max-height: ${_vm.height}px; width: auto; height: 100%;`,
          attrs: { src: _vm.url, alt: "picture" },
        })
      : _c("span", [_vm._v(" " + _vm._s(_vm.FALLBACK_MESSAGE.dash) + " ")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }