import get from 'lodash/get';
import map from 'lodash/map';
import values from 'lodash/values';
import { GOOGLE_MAP_STYLES } from '@/components/GoogleMap/const/googleMapStyle.const';

export const DEFAULT_MAP_OPTIONS = {
  // Europe
  center: { lat: 47.3797106, lng: 10.1123123 },
  zoom: 4,
  styles: GOOGLE_MAP_STYLES,
};

export const DEFAULT_ZONE_OPTIONS = {
  fillColor: '#55C4D0',
  fillOpacity: 0.3,
  strokeColor: '#333',
  strokeOpacity: 0.3,
  strokeWidth: 1,
};

export const ZONE_TYPES = {
  polygon: 'polygon',
  circle: 'circle',
  rectangle: 'rectangle',
};

export const AVAILABLE_ZONE_TYPES = values(ZONE_TYPES);

export const ZONE_TYPE_TO_GCLASS = {
  [ZONE_TYPES.polygon]: 'Polygon',
  [ZONE_TYPES.circle]: 'Circle',
  [ZONE_TYPES.rectangle]: 'Rectangle',
};

/**
 * Returns an object/array of data according to the google zone provided. Usefully to return data to BE.
 * If the zone provided has ID it means it's updating so return an object with the ID of the zone and the data updated.
 * If not it means it's a new zone so it will return the data and the type of the zone.
 * @param zone
 * @returns {{}|{data: ({center: {lng: *, lat: *}, radius: *}|[{lng: *, lat: *},{lng: *, lat: *}]), id}|{center: {lng: *, lat: *}, radius: *}|[{lng: *, lat: *},{lng: *, lat: *}]}
 */
export const GOOGLE_ZONE_TO_DATA = zone => {
  let data;

  switch (zone.type) {
    case ZONE_TYPES.polygon: {
      data = map(zone.getPath().getArray(), point => ({
        lat: point.lat(),
        lng: point.lng(),
      }));
      break;
    }
    case ZONE_TYPES.circle: {
      data = {
        center: {
          lat: zone.getCenter().lat(),
          lng: zone.getCenter().lng(),
        },
        radius: zone.getRadius(),
      };
      break;
    }
    case ZONE_TYPES.rectangle: {
      const rectangleBounds = zone.getBounds();
      const southWestPoint = rectangleBounds.getSouthWest();
      const northEastPoint = rectangleBounds.getNorthEast();
      data = [
        {
          lat: southWestPoint.lat(),
          lng: southWestPoint.lng(),
        },
        {
          lat: northEastPoint.lat(),
          lng: northEastPoint.lng(),
        },
      ];
      break;
    }
    default: {
      return {};
    }
  }

  return zone.id
    ? { id: zone.id, data } // updating
    : { type: zone.type, data }; // creating
};

/**
 * Object data converted to google zone properties according the type. Usefully to use the data from BE.
 * Data expected:
 * {
 *   id: X,
 *   type: ZONE_TYPE,
 *   data: [according_the_type],
 * }
 * @param data
 * @returns {{bounds: {east, south, north, west}}|{path}|*}
 * @constructor
 */
export const DATA_TO_GOOGLE_ZONE = data => {
  switch (data.type) {
    case ZONE_TYPES.polygon: {
      return {
        path: data.data,
      };
    }
    case ZONE_TYPES.circle: {
      return data.data;
    }
    case ZONE_TYPES.rectangle: {
      const swPoint = get(data.data, '[0]', {});
      const nePoint = get(data.data, '[1]', {});
      const rectangleBounds = {
        east: nePoint.lng,
        north: nePoint.lat,
        south: swPoint.lat,
        west: swPoint.lng,
      };
      return { bounds: rectangleBounds };
    }
    default: {
      return {};
    }
  }
};
