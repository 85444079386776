<script>
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import includes from 'lodash/includes';
import { MuiTabs } from '@emobg/vue-base';

import { navigationErrorHandler } from '@emobg/web-utils';

export default {
  name: 'PageTabs',
  components: {
    MuiTabs,
  },
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    hasOverview: {
      type: Boolean,
      default: false,
    },
    areInnerTabs: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    activeTab() {
      const activeTabIndex = findIndex(this.tabs, tab => {
        const routeTab = this.$router.resolve({
          name: tab.url,
        });
        const isRedirected = get(routeTab, 'route.redirectedFrom');
        const routePath = isRedirected || get(routeTab, 'route.fullPath');
        return includes(get(this, '$route.fullPath', ''), routePath);
      });

      return activeTabIndex < 0 ? 0 : activeTabIndex;
    },
  },
  methods: {
    updateRoute(url) {
      const tabFound = find(this.tabs, tab => tab.url === url);
      const isSameRoute = tabFound ? tabFound.url === this.$route.name : false;
      if (isSameRoute) {
        return;
      }
      if (get(tabFound, 'isExternal')) {
        window.location = url;
      } else {
        this.$router.push({ name: url }).catch(navigationErrorHandler);
      }
    },
  },
};
</script>

<template>
  <div
    :class="[
      'PageTabs',
      {
        'PageTabs--overview': hasOverview,
        'PageTabs--inner-tabs': areInnerTabs,
        'emobg-background-color-ground-lightest': !areInnerTabs,
      }
    ]"
  >
    <MuiTabs
      :tabs="tabs"
      :active="activeTab"
      class="MuiTabs--scrollable px-2 px-lg-5"
      @active-tab="updateRoute"
    />
  </div>
</template>

<style lang="scss">
  .PageTabs {
    > .MuiTabs {
      background: none !important;

      &__label {
        padding-right: 16px !important;
        padding-left: 16px !important;
        margin-right: 48px;
        background: none !important;
      }
    }

    &--overview {
      .MuiTabs {
        background: none !important;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);

        &__label {
          margin-right: unset;
          font-size: 14px;
          font-weight: 700;
          background: none !important;
        }
      }
    }

    // TODO @adam is working on secondary navigation, https://europcarmobility.atlassian.net/browse/OU-3
    &--inner-tabs {
      .MuiTabs {
        padding: 0 !important;
        margin: 0 !important;
        background: none !important;

        &__label {
          padding-right: 32px;
          padding-left: 0;
          margin-right: unset;
          font-size: 16px;
          background: none !important;
          border: 0;

          &--active,
          &:hover {
            border-color: transparent !important;
          }

          &--active {
            color: #003d69;
          }

          &:hover {
            color: #005593;
          }
        }
      }

      .PageTabs__text-wrapper {
        padding: 0 !important;
        margin: 0 !important;
      }
    }
  }
</style>
