import axios from 'axios';
import { authService } from '@emobg/access-utils';
import { logger } from '@emobg/web-utils';

import { getUpdatedHeaders } from './getUpdatedHeaders';
import { MAX_API_RETRIES } from './constants';
/**
 * Updates auth0 token, sets it to the headers and returns the new request
 * @param {{state,ENV}} configuration
 * @param {any} configuration.state axios object received on errored response interceptor
 * @param {any} configuration.ENV environment global retrieved of file .ENV
 */
export const retryRequest = async ({ state, ENV }) => {
  let parsedData;
  try {
    parsedData = JSON.parse(state.config.data);
  } catch (errorData) {
    parsedData = {};
  }
  parsedData.retriesLeft = parsedData.retriesLeft ?? MAX_API_RETRIES;

  if (parsedData.retriesLeft > 0) {
    parsedData.retriesLeft -= 1;
    await authService.instance.renewTokens();
    // config.data setter is already stringifying. By serializing it's more sure that string is correct
    state.config.data = JSON.stringify(parsedData);
    state.config.headers = getUpdatedHeaders({ ENV, request: state.request });
    return axios.request(state.config);
  }
  logger.message(`could not get request to ${state.config.url}`);

  throw state.response;
};
