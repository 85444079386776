import cloneDeep from 'lodash/cloneDeep';
import each from 'lodash/each';
import filter from 'lodash/filter';
import find from 'lodash/find';
import get from 'lodash/get';
import intersection from 'lodash/intersection';
import isArray from 'lodash/isArray';
import isFunction from 'lodash/isFunction';
import isObject from 'lodash/isObject';
import isUndefined from 'lodash/isUndefined';
import map from 'lodash/map';
import pullAt from 'lodash/pullAt';
import reduce from 'lodash/reduce';
import snakeCase from 'lodash/snakeCase';
import union from 'lodash/union';
import values from 'lodash/values';
import {
  FILTERS,
  TYPES as FILTER_TYPES,
  FILTER_TYPE_MAP,
} from '@/components/Table/table.const';
import join from 'lodash/join';
import { FALLBACK_MESSAGE } from '@emobg/web-utils';

const deepValues = object => {
  let objectValues = [];
  each(object, value => {
    if (isObject(value)) {
      objectValues = union(objectValues, deepValues(value));
    } else {
      objectValues.push(value);
    }
  });
  return objectValues;
};

const createOptionObject = (label, value, count = 0) => ({
  label,
  value,
  count,
});

export const getFilterOptionsFromEndpoint = (filterSchemaItem, response) => {
  const attribute = snakeCase(get(filterSchemaItem, 'props.attributeName', ''));
  const transformValue = get(filterSchemaItem, 'props.transformValue', value => value);
  const filtersFromEndpoint = get(response, 'filters', []);
  const valuesFromEndpoint = get(find(filtersFromEndpoint, { attribute }), 'values');

  return map(
    valuesFromEndpoint,
    ({ label = FALLBACK_MESSAGE.noData, value, count = 0 }) => createOptionObject(
      transformValue(label),
      value,
      count,
    ),
  );
};

export const getFilterOptionsFromData = (filterSchemaItem, data) => {
  const attribute = get(filterSchemaItem, 'props.attributeName', '');
  const transformValue = get(filterSchemaItem, 'props.transformValue', value => value);
  const groupedOptions = reduce(
    data,
    /* eslint-disable no-param-reassign */
    (result, value) => {
      const attributeValue = get(value, attribute);

      if (isArray(attributeValue)) {
        each(attributeValue, item => {
          result[item] = result[item] || createOptionObject(transformValue(item), item);
          result[item].count += 1;
        });
      } else if (isUndefined(attributeValue)) {
        return result;
      } else {
        result[attributeValue]
          = result[attributeValue] || createOptionObject(transformValue(attributeValue), attributeValue);
        result[attributeValue].count += 1;
      }
      return result;
    },
    /* eslint-enable no-param-reassign */
    {},
  );
  return values(groupedOptions);
};

const applyNumericFilter = (filteredData, filterValue, attributeName) => {
  // Numeric
  const from = get(filterValue, '[0]');
  const to = get(filterValue, '[1]');

  return from || to
    ? filter(filteredData, item => {
      const valueToSearch = get(item, attributeName);
      const isGreaterOrEqualThanFrom = from && valueToSearch >= from;
      const isLowerOrEqualThanTo = to && valueToSearch <= to;
      return from && to ? isGreaterOrEqualThanFrom && isLowerOrEqualThanTo : isGreaterOrEqualThanFrom || isLowerOrEqualThanTo;
    })
    : filteredData;
};

const applyDisjunctiveFilter = (filteredData, filterValue, attributeName) => filterValue.length
  ? filter(filteredData, item => {
    const valueToSearch = get(item, attributeName);
    const args = isArray(valueToSearch) ? valueToSearch : [valueToSearch];
    return intersection(filterValue, args).length;
  })
  : filteredData;

const getFilteredData = (data, filtersRefined) => {
  let filteredData = cloneDeep(data);

  each(filtersRefined, ({ type, value, attributeName }) => {
    filteredData = type === FILTER_TYPES.numeric
      ? applyNumericFilter(filteredData, value, attributeName)
      : applyDisjunctiveFilter(filteredData, value, attributeName);
  });

  return filteredData;
};

export const getDataApplyingFilters = options => {
  const {
    data = [],
    filtersToApply = [],
    filtersIndexToOmit = [],
    search = '',
  } = options;
  const filtersRefined = cloneDeep(filtersToApply);
  pullAt(filtersRefined, filtersIndexToOmit);

  return search
    ? filter(getFilteredData(data, filtersRefined), item => {
      const itemValues = deepValues(item);
      return find(itemValues, value => new RegExp(search, 'i').test(value));
    })
    : getFilteredData(data, filtersRefined);
};

export const getDefaultFilterValue = filterType => filterType === FILTERS.datesRange ? [null, null] : [];

/**
 * Create the array of applied filters using the filter schema.
 * [
 *  {
 *    attributeName: 'attribute_1' -
 *    value: ['value1', 'value2'] | [number | null, number | null]
 *    type: 'disjunctive' | 'numeric'
 *  }
 * ]
 *
 * attributeName: Name of attribute to be filtered,
 * filterType: Name of the filter component,
 * type: Type of filter:
 *  Disjunctive for selecting multiple values.
 *  Numeric for filtering from an start point to and end point. Can be only start or only end points.
 * value: Array of values according the type of filter.
 */
export const createFiltersApplied = schema => map(schema, schemaItem => ({
  attributeName: schemaItem.props.attributeName,
  value: getDefaultFilterValue(schemaItem.type),
  type: FILTER_TYPE_MAP[schemaItem.type],
  filterType: schemaItem.type,
}));

export const getCsvContentValue = (configItem, item) => {
  const schemaTemplate = get(configItem, 'template');
  const resultTemplate = isFunction(schemaTemplate) ? schemaTemplate(item) : get(item, schemaTemplate);
  return `${resultTemplate || ''}`;
};

export const getCsvContent = (config, items) => {
  const header = join(map(config, column => `"${column.header}"`), ',');
  const rows = join(map(items, item => join(map(config, configItem => `"${getCsvContentValue(configItem, item)}"`), ',')), '\r\n');
  if (header && rows) {
    return `${header}\r\n${rows}`;
  }
  return '';
};

