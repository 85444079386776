<script>
import isObject from 'lodash/isObject';
import map from 'lodash/map';
import uniqBy from 'lodash/uniqBy';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import first from 'lodash/first';
import find from 'lodash/find';
import { MuiSelect } from '@emobg/motion-ui/v1';
import { camelCaseKeys } from '@emobg/web-utils';

export default {
  name: 'GoogleLocationSelect',
  components: {
    MuiSelect,
  },
  model: {
    prop: 'model',
    event: 'change',
  },
  props: {
    model: {
      type: [String, Object],
      default: null,
    },
  },
  data() {
    return {
      place: null,
      selected: null,
      autocompleteService: null,
      geocoder: null,
      options: [],
    };
  },
  computed: {
    optionsDisplayed() {
      let options = map(this.options, ({ description }) => ({ label: description, value: description }));
      if (this.selected) {
        options.unshift(this.selected);
      }

      options = uniqBy(options, 'value');

      return options;
    },
  },
  watch: {
    selected(newPlace, previousPlace) {
      const newPlaceValue = get(newPlace, 'value', null);
      if (!isEqual(previousPlace, newPlace) && this.model !== newPlaceValue) {
        this.$emit('update:place', this.place);
        this.$emit('change', newPlaceValue);
      }
    },
  },
  async created() {
    const googleInstance = this.$GoogleMaps.getInstance();
    this.autocompleteService = new googleInstance.maps.places.AutocompleteService();
    this.geocoder = new googleInstance.maps.Geocoder();

    if (this.model) {
      const request = isObject(this.model) ? { location: this.model } : { address: this.model };
      await this.geocode(request);
      this.$emit('created:place', this.place);
    }
  },
  methods: {
    async geocode(request) {
      let geoCodedResult = null;
      try {
        const { results } = await this.geocoder.geocode(request);
        const result = find(results, ['formatted_address', get(request, 'address', '')]) || first(results);
        geoCodedResult = camelCaseKeys(result);
      } catch (_error) {
        return;
      }

      if (geoCodedResult) {
        // This is to allow british locations with administrative_area_level_2 instead of locality to validate the address with required locality
        const addressComponentsLvl2 = find(geoCodedResult.addressComponents, { types: ['administrative_area_level_2'] });
        if (addressComponentsLvl2) {
          addressComponentsLvl2.types.push('locality');
        }

        // For germany we had administrative_area_level_3 (state of germany) instead of administrative_area_level_2 (province)
        const isStateComponent = find(geoCodedResult.addressComponents, { types: ['administrative_area_level_3'] });
        if (isStateComponent) {
          isStateComponent.types.push('administrative_area_level_2');
        }

        this.place = geoCodedResult;
        this.selected = {
          label: geoCodedResult.formattedAddress,
          value: geoCodedResult.formattedAddress,
        };
      }
    },
    onChange(address) {
      if (address) {
        this.geocode({ address });
        this.updatePredictions([]);
      } else {
        this.place = null;
        this.selected = null;
      }
    },
    async updateQuery() {
      this.autocompleteService.getPlacePredictions({
        input: this.$refs.select.searchboxText,
      }, this.updatePredictions);
    },
    updatePredictions(predictions) {
      this.options = predictions;
    },
  },
};
</script>
<template>
  <MuiSelect
    ref="select"
    v-bind="$attrs"
    :options="optionsDisplayed"
    :model-value="model"
    :searchbox="{
      placeholder: '',
      customSearch: () => true,
    }"
    icon-left=""
    icon-right=""
    class="w-100"
    name="select"
    @input.native="updateQuery"
    @change="onChange"
  >
    <template #item="{ item }">
      <div class="d-flex align-items-center">
        <ui-icon
          :icon="ICONS.location"
          :size="ICONS_SIZES.xSmall"
          :color="COLORS.primary"
          class="mr-1"
        />
      </div>
      <p>
        {{ item.label }}
      </p>
    </template>
  </MuiSelect>
</template>
