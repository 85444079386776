const fleet = Object.freeze({
  index: 'fleet',
  vehicles: {
    index: 'fleet.vehicles',
    damages: 'fleet.vehicles.damages',
    detail: {
      index: 'fleet.vehicles.details.index',
      details: 'fleet.vehicles.details.detail',
      setup: 'fleet.vehicles.details.setup',
      interactions: 'fleet.vehicles.details.interactions',
      bookings: 'fleet.vehicles.details.bookings',
      planning: 'fleet.vehicles.details.planning',
      cases: 'fleet.vehicles.details.cases',
      comments: 'fleet.vehicles.details.comments',
      damages: 'fleet.vehicles.details.damages',
      logs: {
        index: 'fleet.vehicles.details.logs',
        audit: 'fleet.vehicles.details.logs.audit',
        event: 'fleet.vehicles.details.logs.event',
        fuelLog: 'fleet.vehicles.details.logs.fuelLog',
        tractionBatteryLog: 'fleet.vehicles.details.logs.tractionBatteryLog',
        connectivity: 'fleet.vehicles.details.logs.connectivity',
        chargingStatus: 'fleet.vehicles.details.logs.chargingStatus',
        vehicleLocationRequests: 'fleet.vehicles.details.logs.vehicleLocationRequests',
      },
    },
  },
  availability: {
    index: 'fleet.availability',
    vehicleAllocation: 'fleet.availability.vehicleAllocation',
    longDistance: 'fleet.availability.longDistance',
    unavailabilities: 'fleet.availability.unavailabilities',
  },
  damages: {
    index: 'damages.index',
    create: 'damages.create',
    edit: 'damages.edit',
  },
});

export default fleet;
