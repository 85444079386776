import { backOffice } from '@emobg/web-api-client';
import { createCustomStore, withScopes } from '@emobg/web-utils';
import { clearErrors } from '@/utils';

export const scopes = {
  equipments: 'equipments',
  descriptions: 'descriptions',
  salesOrder: 'salesOrder',
};

export default createCustomStore(({ runAsyncFlow }) => withScopes(scopes, {
  namespaced: true,
  actions: {
    getEquipments({ commit }, vehicleUuid) {
      return runAsyncFlow(commit, {
        request: backOffice.csfleet.getEquipments,
        params: [vehicleUuid],
        scope: scopes.equipments,
      });
    },
    putEquipments({ commit }, { vehicleUuid, data }) {
      return runAsyncFlow(commit, {
        request: backOffice.csfleet.putEquipments,
        params: [vehicleUuid, data],
        scope: scopes.equipments,
      });
    },
    getDescriptions({ commit }, vehicleUuid) {
      return runAsyncFlow(commit, {
        request: backOffice.csfleet.getDescriptions,
        params: [vehicleUuid],
        scope: scopes.descriptions,
      });
    },
    patchVehicleDetails({ commit }, { vehicleUuid, data }) {
      return runAsyncFlow(commit, {
        request: backOffice.csfleet.patchVehicleDetails,
        params: [vehicleUuid, data],
        scope: scopes.descriptions,
      });
    },
    getSalesOrderVehicle({ commit }, vehicleUuid) {
      return runAsyncFlow(commit, {
        request: backOffice.fleetInvoicing.getVehicle,
        params: [vehicleUuid],
        scope: scopes.salesOrder,
      });
    },
  },
  mutations: { clearErrors },
}));
