import cloneDeep from 'lodash/cloneDeep';
import '../types';
import { validateSchedule } from '../validateSchedule/validateSchedule';
import { addIntervalToSchedule } from '../addIntervalToSchedule/addIntervalToSchedule';
import { removeIntervalToSchedule } from '../removeIntervalToSchedule/removeIntervalToSchedule';
import { maxConsecutiveMinutes, minConsecutiveMinutes } from '../consecutiveMinutes/consecutiveMinutes';
import { emptyWeek } from '../const/mocks';
/**
 *
 * @param {Schedule} schedule
 */
export const weekSchedule = schedule => {
  if (schedule) {
    validateSchedule(schedule);
  }
  // if it's not initialized, we create a week schedule
  const scheduleInstance = schedule || cloneDeep(emptyWeek);
  /**
   * returns the week schedule
   * @returns {Schedule}
   */
  const get = () => scheduleInstance;

  /**
   * @returns {number} maximum consecutive minutes in consecutive intervals
   */
  const getMaxConsecutive = () => maxConsecutiveMinutes(schedule);

  /**
   * @returns {number} minimum consecutive minutes in consecutive intervals
   */
  const getMinConsecutive = () => minConsecutiveMinutes(schedule);

  /**
   * @returns {Schedule}
   * @param {Interval} interval
   * @param {string} day key as a week day name in English
   */
  const addInterval = (interval, day) => addIntervalToSchedule(interval, day, scheduleInstance);

  /**
   * @returns {Interval[]}
   * @param {Interval} interval
   * @param {string} day key as a week day name in English
   */
  const removeInterval = (interval, day) => removeIntervalToSchedule(interval, day, scheduleInstance);

  return Object.freeze({
    addInterval,
    get,
    getMaxConsecutive,
    getMinConsecutive,
    removeInterval,
  });
};
