var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "NotificationList position-fixed z-index-1032 d-inline-flex flex-column align-items-end w-100",
      attrs: { "data-test-id": "notification-list" },
    },
    _vm._l(_vm.floatingNotifications, function (notification) {
      return _c(
        "ui-notification",
        {
          key: notification.id,
          staticClass: "position-relative mb-4",
          attrs: {
            type: notification.type,
            icon: _vm.get(_vm.NOTIFICATION_ICONS, notification.type),
            dismissible: false,
            "data-test-id": "notification",
          },
          on: {
            dismissNotification: function ($event) {
              return _vm.removeNotification(notification)
            },
          },
        },
        [
          _c("span", {
            attrs: { "data-test-id": "message" },
            domProps: { innerHTML: _vm._s(notification.message) },
          }),
          notification.textAction
            ? _c(
                "div",
                { attrs: { slot: "customAction" }, slot: "customAction" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "emobg-link-primary emobg-body-2 ml-2 NotificationList--removeNotification",
                      on: {
                        click: function ($event) {
                          return _vm.removeNotification(notification)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(notification.textAction) + " ")]
                  ),
                ]
              )
            : _vm._e(),
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }