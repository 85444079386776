import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';

/**
 * Throws an error to application's notification module
 * @param {*} _state
 * @param {*} payload
 */
export function setError(_state, payload) {
  if (payload) {
    const { value } = payload;
    this.commit(DOMAINS_MODEL.app.messages.errors.throwError, value || payload, { root: true });
  }
}
