var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "CopyToClipboard d-flex align-items-center" },
    [
      _c(
        "span",
        { ref: "copyElement", staticClass: "text-break" },
        [_vm._t("default")],
        2
      ),
      _c("ui-icon", {
        staticClass: "cursor-pointer ml-1",
        attrs: { size: _vm.ICONS_SIZES.medium, icon: _vm.ICONS.copy },
        on: { click: _vm.copyToClipboard },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }