import Errors from './Errors/ErrorsModule';
import Notifications from './Notifications/NotificationsModule';

export default {
  namespaced: true,
  modules: {
    Errors,
    Notifications,
  },
};
