import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import cases from './CasesServicesRouterMap';
import { CASES_PERMISSIONS } from '../const/permissions';

const CaseEventsDetailView = () => import(/* webpackChunkName: "case-services" */'../CaseEvents/Detail/CaseEventsDetailView.vue');
const CaseEventsListView = () => import(/* webpackChunkName: "case-services" */'../CaseEvents/List/CaseEventsListView.vue');
const CaseEventsView = () => import(/* webpackChunkName: "case-services" */'../CaseEvents/CaseEventsView.vue');
const CasesServicesView = () => import(/* webpackChunkName: "case-services" */'../CasesServicesView.vue');

export default [
  {
    path: 'cases-service',
    name: cases.index,
    component: CasesServicesView,
    redirect: { name: cases.caseEvents.index },
    meta: {
      label: 'CRM',
    },
    children: [
      {
        path: 'case-events',
        name: cases.caseEvents.index,
        redirect: { name: cases.caseEvents.list },
        component: CaseEventsView,
        meta: {
          label: 'Cases',
        },
        children: [
          {
            path: '/',
            name: cases.caseEvents.list,
            component: CaseEventsListView,
            meta: {
              label: 'Case list',
              permissions: [CASES_PERMISSIONS.viewCrmCases],
              title: 'Cases',
            },
          },
          {
            path: 'create',
            name: cases.caseEvents.create,
            component: CaseEventsDetailView,
            meta: {
              label: 'Create case',
              permissions: [CASES_PERMISSIONS.viewCrmCases],
            },
          },
          {
            path: ':caseEventUuid',
            name: cases.caseEvents.detail,
            component: CaseEventsDetailView,
            meta: {
              label: 'Case event detail',
              permissions: [CASES_PERMISSIONS.viewCrmCases],
              title: {
                storeDomain: DOMAINS_MODEL.casesServices.caseEvents.detail,
                template: ({ id }) => `Case #${id}`,
              },
            },
          },
        ],
      },
    ],
  },
];
