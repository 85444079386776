import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import set from 'lodash/set';
import transform from 'lodash/transform';

/**
 * Return a new object with the keys of after and before params that are same name but contains different value.
 * Iterates over afterValue, so if it's not an object will return empty.
 * The value of the key will be the one from afterValue.
 * It makes a deep check recursively.
 *
 * @param afterValue
 * @param beforeValue
 * @returns Object
 */
export function differenceBySameKey(afterValue, beforeValue) {
  const after = cloneDeep(afterValue || {});
  const before = cloneDeep(beforeValue || {});
  return transform(after, (result, value, key) => {
    if (!isEqual(value, before[key])) {
      set(result, key, (isObject(value) && isObject(before[key])) ? differenceBySameKey(value, before[key]) : value);
    }
  });
}

