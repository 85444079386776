const fleetInvoicing = Object.freeze({
  contracts: 'Domains/FleetInvoicing/Contracts',
  salesOrders: 'Domains/FleetInvoicing/SalesOrders',
  serviceFees: 'Domains/FleetInvoicing/ServiceFees',
  serviceTypes: 'Domains/FleetInvoicing/ServiceTypes',
  invoices: 'Domains/FleetInvoicing/Invoices',
  invoicePlan: 'Domains/FleetInvoicing/InvoicePlan',
});

export default fleetInvoicing;
