<script>
export default {
  name: 'TablePaginatorComponent',
  props: {
    totalPages: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    previous() {
      this.$emit('update:page', this.page - 1);
    },
    next() {
      this.$emit('update:page', this.page + 1);
    },
  },
};
</script>

<template>
  <ui-paginate
    :total="totalPages"
    :page="totalPages ? page : 0"
    :size="SIZES.medium"
    class="TablePaginatorComponent"
    data-test-id="table_paginator"
    @next="next"
    @previous="previous"
  />
</template>

<style lang="scss">
@import "~@emobg/sass/colors/variables";

.TablePaginatorComponent {
  .Ui-Button {
    border: 0 !important;
  }

  .Ui-Paginate__previous {
    margin-right: 0 !important;
  }

  .Ui-Paginate {
    color: map-get($motion-grayscale, "ink-light");
  }
}
</style>
