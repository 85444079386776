<script>
import { mapMutations, mapState } from 'vuex';
import filter from 'lodash/filter';
import invoke from 'lodash/invoke';
import first from 'lodash/first';
import get from 'lodash/get';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { NOTIFICATION_ICONS } from '@/constants/notifications';

export default {
  name: 'InlineNotificationComponent',
  computed: {
    ...mapState(DOMAINS_MODEL.app.messages.notifications.index, {
      inlineNotifications: state => filter(state.notifications, { isFloating: false }),
    }),
    firstInlineNotification() {
      const firstInlineNotification = first(this.inlineNotifications);
      if (firstInlineNotification) {
        const isAutomaticClosed = !firstInlineNotification.textAction || firstInlineNotification.delay;
        if (isAutomaticClosed) {
          // eslint-disable-next-line vue/no-async-in-computed-properties
          setTimeout(() => {
            this.remove(firstInlineNotification.id);
          }, firstInlineNotification.delay || 10000);
        }
      }
      return firstInlineNotification;
    },
  },
  created() {
    this.NOTIFICATION_ICONS = NOTIFICATION_ICONS;
    this.removeAll({ isFloating: false });
  },
  beforeDestroy() {
    this.removeAll({ isFloating: false });
  },
  methods: {
    get,
    ...mapMutations(DOMAINS_MODEL.app.messages.notifications.index, ['remove', 'removeAll']),
    removeNotification() {
      invoke(this.firstInlineNotification, 'action');
      this.remove(this.firstInlineNotification.id);
    },
  },
};
</script>

<template>
  <ui-alert
    v-if="firstInlineNotification"
    :key="firstInlineNotification.id"
    :color="firstInlineNotification.type"
    class="InlineNotificationComponent w-100 d-inline-block animated fadeIn"
  >
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center">
        <ui-icon
          :color="firstInlineNotification.type"
          :icon="get(NOTIFICATION_ICONS, firstInlineNotification.type)"
          :size="ICONS_SIZES.large"
          class="mr-2"
        />
        <span v-html="firstInlineNotification.message" />
      </div>
      <div
        v-if="firstInlineNotification.textAction"
        class="emobg-link-primary emobg-body-2 ml-2"
        @click="removeNotification"
      >
        {{ firstInlineNotification.textAction }}
      </div>
    </div>
  </ui-alert>
</template>
