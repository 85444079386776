import { BYTES_PER_UNIT, FILE_TYPES } from '@emobg/web-utils';

/**
 * Returns true when the base64 size is under provided maxSizeInBytes
 * @param imageBase64 - string
 * @param maxSizeInBytes - number
 * @returns {boolean}
 */
export const isImageUnderMaxSize = (imageBase64, maxSizeInBytes = BYTES_PER_UNIT.megaByte) => {
  const stringLength = imageBase64.length;
  const sizeInBytes = Math.ceil((stringLength / 4)) * 3;

  return sizeInBytes <= maxSizeInBytes;
};

/**
 * Resizes the provided base64 image to the maximum provided size in bytes
 * @param base64Image
 * @param maxSizeInBytes
 * @returns {Promise<unknown>}
 */
const resizeBase64Image = (base64Image, maxSizeInBytes = BYTES_PER_UNIT.megaByte) => (new Promise((resolve, reject) => {
  const img = new Image();
  img.src = base64Image;
  img.onload = () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const aspectRatio = img.width / img.height;
    canvas.width = Math.sqrt(maxSizeInBytes * aspectRatio);
    canvas.height = Math.sqrt(maxSizeInBytes / aspectRatio);
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    resolve(canvas.toDataURL(FILE_TYPES.jpeg));
  };

  img.onerror = () => {
    reject();
  };
}));

/**
 * Returns the provided base64 image cropped to the provided aspect ratio.
 * @param base64Image - string
 * @param aspectRatio - number
 * @param quality - number
 * @param minDimensions - Object - { height, width }
 * @returns {Promise<string>}
 */
export const cropImageToAspectRatio = (base64Image, aspectRatio, quality = 0.8, minDimensions = { height: 384, width: 216 }) => (new Promise((resolve, reject) => {
  const originalImage = new Image();
  originalImage.onload = async () => {
    const { naturalWidth: originalWidth, naturalHeight: originalHeight } = originalImage;
    const originalImageAspectRatio = originalWidth / originalHeight;

    if (originalHeight <= minDimensions.height || originalWidth <= minDimensions.width) {
      reject();
    }

    let croppedWidth = originalWidth;
    let croppedHeight = originalHeight;
    if (originalImageAspectRatio > aspectRatio) {
      croppedWidth = originalHeight * aspectRatio;
    } else if (originalImageAspectRatio < aspectRatio) {
      croppedHeight = originalWidth / aspectRatio;
    }

    const canvas = document.createElement('canvas');
    canvas.width = croppedWidth;
    canvas.height = croppedHeight;

    const canvasContext = canvas.getContext('2d');
    const croppedX = (croppedWidth - originalWidth) * 0.5;
    const croppedY = (croppedHeight - originalHeight) * 0.5;
    canvasContext.drawImage(originalImage, croppedX, croppedY);

    const transformedImage = canvas.toDataURL(FILE_TYPES.jpeg, quality);
    resolve(isImageUnderMaxSize(transformedImage) ? transformedImage : await resizeBase64Image(transformedImage));
  };

  originalImage.onerror = () => {
    reject();
  };

  originalImage.src = base64Image;
}));
