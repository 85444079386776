var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "PageView",
    { staticClass: "AutoInterventionsForm" },
    [
      _c(
        "div",
        { staticClass: "d-flex mb-2 align-items-center" },
        [
          _c("h2", [_vm._v(" Auto-intervention settings ")]),
          _c(
            "ui-tooltip",
            {
              staticClass: "ml-1",
              attrs: {
                "no-arrow": "",
                tooltip:
                  "Activate to have cleaning interventions created automatically.",
              },
            },
            [
              _c("ui-icon", {
                attrs: {
                  icon: _vm.ICONS.infoFull,
                  size: _vm.SIZES.small,
                  color: _vm.GRAYSCALE.inkLight,
                  hover: "",
                  "data-test-id": "icon",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("ui-card", { staticClass: "d-flex flex-column flex-fill" }, [
        _c("div", { staticClass: "row d-flex flex-column flex-fill" }, [
          _c(
            "h2",
            {
              staticClass:
                "col-12 emobg-font-weight-bold emobg-font-large pb-3",
            },
            [_vm._v(" Summary ")]
          ),
          _c(
            "div",
            { staticClass: "col-xl-6" },
            [
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c("div", { staticClass: "mr-6" }, [
                    _c(
                      "div",
                      { staticClass: "emobg-font-weight-bold pb-3 pt-1" },
                      [_vm._v(" Operator ")]
                    ),
                    _c(
                      "div",
                      [
                        _vm.initialized
                          ? [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.get(_vm.configuration, "csOperatorName")
                                  ) +
                                  " "
                              ),
                            ]
                          : _c("ui-skeleton", {
                              staticClass: "mt-2",
                              attrs: { height: "30" },
                            }),
                      ],
                      2
                    ),
                  ]),
                  _vm.initialized
                    ? _c("ui-toggle", {
                        attrs: {
                          value: _vm.inputs.active,
                          text: _vm.activateLabel,
                          name: "active",
                          label: "Status",
                        },
                        on: {
                          changevalue: (event) =>
                            (_vm.inputs.active = event.detail),
                        },
                      })
                    : _c("ui-skeleton", {
                        staticClass: "mt-2",
                        attrs: { height: "30" },
                      }),
                ],
                1
              ),
              _vm.hasActiveChanged
                ? _c(
                    "ui-alert",
                    {
                      staticClass: "pt-3 w-100 d-block",
                      attrs: {
                        color: _vm.COLORS.primary,
                        icon: _vm.ICONS.infoFull,
                      },
                    },
                    [
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(_vm.activateConfigFeedback),
                        },
                      }),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("div", { staticClass: "col-12" }, [
            _c("hr", {
              staticClass:
                "emobg-border-top-1 emobg-border-color-ground-light my-4",
            }),
          ]),
          _c(
            "div",
            {
              staticClass: "row col-12 pb-3",
              class: { disabled: !_vm.inputs.active },
            },
            [
              _c(
                "div",
                { staticClass: "col-xl-6 mb-sm-4" },
                [
                  _c(
                    "h2",
                    {
                      staticClass:
                        "emobg-font-weight-bold emobg-font-large pb-3",
                    },
                    [_vm._v(" Details ")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-items-center emobg-font-weight-bold mb-1",
                    },
                    [
                      _vm._v(" User* "),
                      _c(
                        "ui-tooltip",
                        {
                          staticClass: "ml-1",
                          attrs: {
                            "no-arrow": "",
                            tooltip:
                              "The intervention will be assigned to the selected user.",
                          },
                        },
                        [
                          _c("ui-icon", {
                            attrs: {
                              icon: _vm.ICONS.infoFull,
                              size: _vm.SIZES.small,
                              color: _vm.GRAYSCALE.inkLight,
                              hover: "",
                              "data-test-id": "icon",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.initialized
                    ? _c("MuiAlgoliaSelect", {
                        staticClass: "w-100",
                        attrs: {
                          index: _vm.ALGOLIA_INDEXES.users,
                          filters: `cs_operator_uuid: ${_vm.activeOperatorUuid} OR employee.company.cs_operator_uuid: ${_vm.activeOperatorUuid}`,
                          title: (result) =>
                            `${result.first_name} ${result.last_name} - ${result.email}`,
                          name: "user",
                          "no-cache": "",
                          "path-value": "uuid",
                          placeholder: "Select",
                        },
                        model: {
                          value: _vm.inputs.userUuid,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputs, "userUuid", $$v)
                          },
                          expression: "inputs.userUuid",
                        },
                      })
                    : _c("ui-skeleton", {
                        staticClass: "mt-2",
                        attrs: { height: "30" },
                      }),
                ],
                1
              ),
              _c("div", { staticClass: "col-xl-6" }, [
                _c(
                  "h2",
                  {
                    staticClass: "emobg-font-weight-bold emobg-font-large pb-3",
                  },
                  [_vm._v(" Rule ")]
                ),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-lg-6 mb-3" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex align-items-center emobg-font-weight-bold mb-1",
                        },
                        [
                          _vm._v(" Trigger* "),
                          _c(
                            "ui-Tooltip",
                            {
                              staticClass: "ml-1",
                              attrs: {
                                "no-arrow": "",
                                tooltip:
                                  "Condition which will trigger the intervention.",
                              },
                            },
                            [
                              _c("ui-icon", {
                                attrs: {
                                  icon: _vm.ICONS.infoFull,
                                  size: _vm.SIZES.small,
                                  color: _vm.GRAYSCALE.inkLight,
                                  hover: "",
                                  "data-test-id": "icon",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.initialized
                        ? _c("ui-select", {
                            staticClass: "w-100",
                            attrs: {
                              value: _vm.inputs.trigger,
                              name: "trigger",
                            },
                            domProps: { options: _vm.triggers },
                            on: {
                              selectoption: ({ detail }) =>
                                (_vm.inputs.trigger = detail),
                            },
                          })
                        : _c("ui-skeleton", {
                            staticClass: "mt-2",
                            attrs: { height: "30" },
                          }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-lg-6 mb-3" },
                    [
                      _c(
                        "label",
                        { staticClass: "d-block emobg-font-weight-bold mb-1" },
                        [_vm._v(" Trigger value (Hours)* ")]
                      ),
                      _vm.initialized
                        ? _c("MuiInputText", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  isPattern: {
                                    pattern: _vm.HOURS_PATTERN,
                                    message: "Only whole numbers allowed",
                                    label: "Only whole numbers allowed",
                                  },
                                },
                                expression:
                                  "{\n                  isPattern: {\n                    pattern: HOURS_PATTERN,\n                    message: 'Only whole numbers allowed',\n                    label: 'Only whole numbers allowed'\n                  },\n                }",
                              },
                            ],
                            ref: "triggerValue",
                            staticClass: "w-100 pt-1",
                            attrs: {
                              "min-value": 1,
                              placeholder: "Add number of hours",
                              name: "triggerValue",
                              type: "number",
                            },
                            model: {
                              value: _vm.inputs.triggerValue,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.inputs,
                                  "triggerValue",
                                  _vm._n($$v)
                                )
                              },
                              expression: "inputs.triggerValue",
                            },
                          })
                        : _c("ui-skeleton", {
                            staticClass: "mt-2",
                            attrs: { height: "30" },
                          }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-lg-6 mb-3" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex align-items-center emobg-font-weight-bold mb-1",
                        },
                        [_vm._v(" Intervention type* ")]
                      ),
                      _vm.initialized
                        ? _c("ui-select", {
                            staticClass: "w-100",
                            attrs: {
                              value: _vm.inputs.interventionType,
                              name: "interventionType",
                            },
                            domProps: { options: _vm.interventions },
                            on: {
                              selectoption: ({ detail }) =>
                                (_vm.inputs.interventionType = detail),
                            },
                          })
                        : _c("ui-skeleton", {
                            staticClass: "mt-2",
                            attrs: { height: "30" },
                          }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-lg-6 mb-3" },
                    [
                      _c(
                        "label",
                        { staticClass: "d-block emobg-font-weight-bold mb-1" },
                        [_vm._v(" Intervention duration (Hours)* ")]
                      ),
                      _vm.initialized
                        ? _c("MuiInputText", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  isPattern: {
                                    pattern: _vm.HOURS_PATTERN,
                                    message: "Only whole numbers allowed",
                                    label: "Only whole numbers allowed",
                                  },
                                  outOfHours: () => ({
                                    message:
                                      "Too long! It does not fit into the schedule",
                                    isValid: !_vm.isOutOfMinHours,
                                  }),
                                },
                                expression:
                                  "{\n                  isPattern: {\n                    pattern: HOURS_PATTERN,\n                    message: 'Only whole numbers allowed',\n                    label: 'Only whole numbers allowed'\n                  },\n                  outOfHours: () => ({message: 'Too long! It does not fit into the schedule', isValid: !isOutOfMinHours}),\n                }",
                              },
                            ],
                            ref: "interventionHours",
                            staticClass: "w-100",
                            attrs: {
                              "min-value": 1,
                              placeholder: "Add number of hours",
                              name: "interventionHours",
                            },
                            model: {
                              value: _vm.inputs.interventionHours,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.inputs,
                                  "interventionHours",
                                  _vm._n($$v)
                                )
                              },
                              expression: "inputs.interventionHours",
                            },
                          })
                        : _c("ui-skeleton", {
                            staticClass: "mt-2",
                            attrs: { height: "30" },
                          }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-12 mb-3" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex align-items-center emobg-font-weight-bold mb-1",
                        },
                        [
                          _vm._v(" Schedule* "),
                          _c(
                            "ui-tooltip",
                            {
                              staticClass: "ml-1",
                              attrs: {
                                "no-arrow": "",
                                tooltip:
                                  "Days when auto-intervention can be created.",
                              },
                            },
                            [
                              _c("ui-icon", {
                                attrs: {
                                  icon: _vm.ICONS.infoFull,
                                  size: _vm.SIZES.small,
                                  color: _vm.GRAYSCALE.inkLight,
                                  hover: "",
                                  "data-test-id": "icon",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.initialized
                        ? _c(
                            "div",
                            [
                              _c("WeekSchedule", {
                                attrs: {
                                  "day-names": _vm.dayNames,
                                  "min-duration": _vm.inputs.interventionHours,
                                  "is-disabled": !_vm.inputs.active,
                                },
                                on: {
                                  "min-consecutive-minutes":
                                    _vm.setScheduleMinHours,
                                },
                                model: {
                                  value: _vm.inputs.schedule.days,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.inputs.schedule, "days", $$v)
                                  },
                                  expression: "inputs.schedule.days",
                                },
                              }),
                              _vm.isOutOfMinHours
                                ? _c(
                                    "ui-alert",
                                    {
                                      staticClass: "pt-3 w-100",
                                      attrs: {
                                        color: _vm.COLORS.danger,
                                        icon: _vm.ICONS.alertFull,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " Schedule conflicts with intervention duration! "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _c("ui-skeleton", {
                            staticClass: "mt-2",
                            attrs: { height: "30" },
                          }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]
          ),
          _c(
            "footer",
            {
              staticClass:
                "emobg-border-top-1 emobg-border-color-ground-light p-3 emobg-background-color-ground-lightest d-flex justify-content-end mx-n2 mb-n3",
            },
            [
              _c("SaveButton", {
                attrs: {
                  disabled:
                    !_vm.areAllRequiredFieldFilled ||
                    _vm.hasSameValues ||
                    !_vm.isFormValid(),
                  loading: _vm.configurationStatus.LOADING,
                },
                on: { click: _vm.updateConfiguration },
              }),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }