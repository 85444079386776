import isEmpty from 'lodash/isEmpty';
import { ENV } from '@emobg/web-utils';

const envName = process.env.NODE_ENV;
const isDevelopment = envName === ENV.local || envName === ENV.development;

const OPERATOR_NAMES = {
  ecod: 'Europcar On Demand',
  legacy: 'Ubeeqo',
};

export function setupBeamer(user = {}, activeOperatorName = '') {
  if (isEmpty(user.data) || isDevelopment) {
    return;
  }

  const activeOperatorFilter = () => `${activeOperatorName}; ${activeOperatorName.replace(OPERATOR_NAMES.ecod, OPERATOR_NAMES.legacy)}'`;

  if (!window.Beamer) {
    const {
      first_name: firstName,
      last_name: lastName,
      email,
      uuid,
    } = user.data;

    const beamerJs = document.createElement('script');
    beamerJs.type = 'text/javascript';
    beamerJs.src = 'https://app.getbeamer.com/js/beamer-embed.js';

    window.beamer_config = {
      product_id: 'LaVMsHaa7659',
      selector: '#beamer',
      user_id: uuid,
      user_firstname: firstName,
      user_lastname: lastName,
      user_email: email,
      filter: `agent; ${activeOperatorFilter()}`,
    };

    document.body.appendChild(beamerJs);
  }
}
