var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {
    staticClass: "MarkdownComponent",
    attrs: { "data-test-id": "markdown_component" },
    domProps: {
      innerHTML: _vm._s(_vm.description || _vm.FALLBACK_MESSAGE.noData),
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }