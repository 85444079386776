import { createCustomStore, withScopes } from '@emobg/web-utils';
import { backOffice } from '@emobg/web-api-client';
import { clearErrors } from '@/utils';
import { setError as globalSetError } from '@/store/setError';

export const USER_SCOPES = {
  detail: 'detail',
  editSettings: 'editSettings',
  paymentMethodStatusForm: 'paymentMethodStatusForm',
  paymentMethodForm: 'paymentMethodForm',
  userPendingPayments: 'userPendingPayments',
  addPromotionForm: 'addPromotionForm',
  editPromotionForm: 'editPromotionForm',
  statusForm: 'statusForm',
  userBadge: 'userBadge',
  newBadge: 'newBadge',
  drivingLicense: 'drivingLicense',
  drivingLicenseComments: 'drivingLicenseComments',
  drivingLicenseUpload: 'drivingLicenseUpload',
  drivingLicenseEdit: 'drivingLicenseEdit',
  drivingLicenseValidate: 'drivingLicenseValidate',
  drivingLicenseInvalidate: 'drivingLicenseInvalidate',
  documents: 'documents',
  addDocument: 'addDocument',
  deleteDocument: 'deleteDocument',
  resendVerificationMail: 'resendVerificationMail',
  resetPassword: 'resetPassword',
  cancelableAccount: 'cancelableAccount',
  cancelAccount: 'cancelAccount',
  userTariffs: 'userTariffs',
  availableUserTariffs: 'availableUserTariffs',
  availableUserTariffPeriods: 'availableUserTariffPeriods',
  userTariffForm: 'userTariffForm',
  deleteUserTariff: 'deleteUserTariff',
  coverForm: 'coverForm',
  insurance: 'insurance',
  impersonation: 'impersonation',
  impersonationReasons: 'impersonationReasons',
  impersonationFinish: 'impersonationFinish',
  notifications: 'notifications',
};

const users = createCustomStore(({ runAsyncFlow }) => withScopes(USER_SCOPES, ({
  state: {
    [USER_SCOPES.detail]: {
      data: null,
    },
    [USER_SCOPES.userBadge]: {
      data: null,
    },
    [USER_SCOPES.drivingLicense]: {
      data: null,
    },
    [USER_SCOPES.insurance]: {
      data: null,
    },
    [USER_SCOPES.impersonation]: {
      data: null,
    },
  },
  mutations: {
    setError(state, payload) {
      const { scope, value } = payload;

      if (![
        USER_SCOPES.drivingLicenseInvalidate,
        USER_SCOPES.drivingLicenseValidate,
        USER_SCOPES.deleteDocument,
        USER_SCOPES.resetPassword,
        USER_SCOPES.cancelAccount,
        USER_SCOPES.deleteUserTariff,
        USER_SCOPES.coverForm,
        USER_SCOPES.impersonation,
        USER_SCOPES.impersonationFinish,
      ].includes(scope)) {
        state[scope].error = value;
      } else {
        globalSetError.bind(this)(state, payload);
      }
    },
    clearErrors,
  },
  actions: {
    async getUser({ commit }, userUuid) {
      await runAsyncFlow(commit, {
        request: backOffice.userCompany.getUser,
        params: [userUuid],
        scope: USER_SCOPES.detail,
      });
    },
    async activateUserPaymentMethod({ commit }, { userId, paymentMethodId }) {
      await runAsyncFlow(commit, {
        request: backOffice.invoicing.putActivatePaymentMethod,
        params: [userId, paymentMethodId],
        scope: USER_SCOPES.paymentMethodStatusForm,
      });
    },
    async deactivateUserPaymentMethod({ commit }, { userId, paymentMethodId }) {
      await runAsyncFlow(commit, {
        request: backOffice.invoicing.putDeactivatePaymentMethod,
        params: [userId, paymentMethodId],
        scope: USER_SCOPES.paymentMethodStatusForm,
      });
    },
    async postUserPaymentMethod({ commit }, { userId, data }) {
      await runAsyncFlow(commit, {
        request: backOffice.invoicing.postAddPaymentMethod,
        params: [userId, data],
        scope: USER_SCOPES.paymentMethodForm,
      });
    },
    async postUserPromotion({ commit }, { userUuid, promotionUuid }) {
      await runAsyncFlow(commit, {
        request: backOffice.promotions.postUserPromotion,
        params: [userUuid, promotionUuid],
        scope: USER_SCOPES.addPromotionForm,
      });
    },
    async patchUserPromotion({ commit }, { userUuid, userPromotionUuid, data }) {
      await runAsyncFlow(commit, {
        request: backOffice.promotions.patchUserPromotion,
        params: [userUuid, userPromotionUuid, data],
        scope: USER_SCOPES.editPromotionForm,
      });
    },
    async postPersonalStatus({ commit }, { userUuid, data }) {
      await runAsyncFlow(commit, {
        request: backOffice.user.postUserPersonalStatus,
        params: [userUuid, data],
        scope: USER_SCOPES.statusForm,
      });
    },
    async postBusinessStatus({ commit }, { userUuid, data }) {
      await runAsyncFlow(commit, {
        request: backOffice.user.postUserBusinessStatus,
        params: [userUuid, data],
        scope: USER_SCOPES.statusForm,
      });
    },
    async patchUserInformation({ commit }, { userUuid, data }) {
      await runAsyncFlow(commit, {
        request: backOffice.user.patchUserInformation,
        params: [userUuid, data, 'v2'],
        scope: USER_SCOPES.editSettings,
      });
    },
    async putUserPersonalProfile({ commit }, { userUuid, data }) {
      await runAsyncFlow(commit, {
        request: backOffice.user.putUserPersonalProfile,
        params: [userUuid, data],
        scope: USER_SCOPES.editSettings,
      });
    },
    async putUserBusinessProfile({ commit }, { userUuid, data }) {
      await runAsyncFlow(commit, {
        request: backOffice.user.putUserBusinessProfile,
        params: [userUuid, data],
        scope: USER_SCOPES.editSettings,
      });
    },
    async getUserBadge({ commit }, userUuid) {
      await runAsyncFlow(commit, {
        request: backOffice.badges.getUserBadge,
        params: [userUuid],
        scope: USER_SCOPES.userBadge,
      });
    },
    async postUserBadge({ commit }, { userUuid, data }) {
      await runAsyncFlow(commit, {
        request: backOffice.badges.postUserBadge,
        params: [userUuid, data],
        scope: USER_SCOPES.newBadge,
      });
    },
    async putUserBadge({ commit }, { userUuid, badgeUuid, data }) {
      await runAsyncFlow(commit, {
        request: backOffice.badges.putUserBadge,
        params: [userUuid, badgeUuid, data],
        scope: USER_SCOPES.newBadge,
      });
    },
    async getDrivingLicenseComments({ commit }, { commentableUuid, commentableType = 'driving_license' }) {
      await runAsyncFlow(commit, {
        request: backOffice.comments.getCommentsByType,
        params: [commentableUuid, commentableType],
        scope: USER_SCOPES.drivingLicenseComments,
      });
    },
    async getDrivingLicense({ commit }, userUuid) {
      await runAsyncFlow(commit, {
        request: backOffice.drivingLicense.getUserDrivingLicense,
        params: [userUuid],
        scope: USER_SCOPES.drivingLicense,
      });
    },
    async postUserDrivingLicense({ commit }, { userUuid, data }) {
      await runAsyncFlow(commit, {
        request: backOffice.drivingLicense.postUserDrivingLicense,
        params: [userUuid, data],
        scope: USER_SCOPES.drivingLicenseUpload,
      });
    },
    async putUserDrivingLicense({ commit }, { userUuid, drivingLicenseUuid, data }) {
      await runAsyncFlow(commit, {
        request: backOffice.drivingLicense.putUserDrivingLicense,
        params: [userUuid, drivingLicenseUuid, data],
        scope: USER_SCOPES.drivingLicenseEdit,
      });
    },
    async postValidateDrivingLicense({ commit }, { userUuid, drivingLicenseUuid }) {
      await runAsyncFlow(commit, {
        request: backOffice.drivingLicense.postValidateDrivingLicense,
        params: [userUuid, drivingLicenseUuid],
        scope: USER_SCOPES.drivingLicenseValidate,
      });
    },
    async postInvalidateDrivingLicense({ commit }, { userUuid, drivingLicenseUuid }) {
      await runAsyncFlow(commit, {
        request: backOffice.drivingLicense.postInvalidateDrivingLicense,
        params: [userUuid, drivingLicenseUuid],
        scope: USER_SCOPES.drivingLicenseInvalidate,
      });
    },
    async getUserDocuments({ commit }, userUuid) {
      await runAsyncFlow(commit, {
        request: backOffice.documents.getUserDocuments,
        params: [userUuid],
        scope: USER_SCOPES.documents,
      });
    },
    async postUserDocument({ commit }, { userUuid, data }) {
      await runAsyncFlow(commit, {
        request: backOffice.documents.postUserDocument,
        params: [userUuid, data],
        scope: USER_SCOPES.addDocument,
      });
    },
    async deleteUserDocument({ commit }, { userUuid, documentUuid }) {
      await runAsyncFlow(commit, {
        request: backOffice.documents.deleteUserDocument,
        params: [userUuid, documentUuid],
        scope: USER_SCOPES.deleteDocument,
      });
    },
    async getResendVerificationEmail({ commit }, { userUuid }) {
      await runAsyncFlow(commit, {
        request: backOffice.user.getResendVerificationEmail,
        params: [userUuid],
        scope: USER_SCOPES.resendVerificationMail,
      });
    },
    async putResetPassword({ commit }, { userUuid }) {
      await runAsyncFlow(commit, {
        request: backOffice.user.putResetPassword,
        params: [userUuid],
        scope: USER_SCOPES.resetPassword,
      });
    },
    async getCheckCancelAccount({ commit }, { userUuid }) {
      await runAsyncFlow(commit, {
        request: backOffice.user.getCheckCancelAccount,
        params: [userUuid],
        scope: USER_SCOPES.cancelableAccount,
      });
    },
    async putCancelAccount({ commit }, { userUuid }) {
      await runAsyncFlow(commit, {
        request: backOffice.user.putCancelAccount,
        params: [userUuid],
        scope: USER_SCOPES.cancelAccount,
      });
    },
    async getUserTariffs({ commit }, { userUuid }) {
      await runAsyncFlow(commit, {
        request: backOffice.pricingUsers.getUserTariffs,
        params: [userUuid],
        scope: USER_SCOPES.userTariffs,
      });
    },
    async postUserTariff({ commit }, { userUuid, data }) {
      await runAsyncFlow(commit, {
        request: backOffice.tariffs.postUserTariff,
        params: [userUuid, data],
        scope: USER_SCOPES.userTariffForm,
      });
    },
    async postMigratedUserTariff({ commit }, { userUuid, data }) {
      await runAsyncFlow(commit, {
        request: backOffice.pricingUsers.postUserTariff,
        params: [userUuid, data],
        scope: USER_SCOPES.userTariffForm,
      });
    },
    async putUserTariff({ commit }, { userTariffUuid, data, version = 'v3' }) {
      await runAsyncFlow(commit, {
        request: backOffice.tariffs.putUserTariff,
        params: [userTariffUuid, data, version],
        scope: USER_SCOPES.userTariffForm,
      });
    },
    async deleteUserTariff({ commit }, { userTariffUuid, version = 'v2' }) {
      await runAsyncFlow(commit, {
        request: backOffice.tariffs.deleteUserTariff,
        params: [userTariffUuid, version],
        scope: USER_SCOPES.deleteUserTariff,
      });
    },
    async getUserInsurance({ commit }, { userUuid }) {
      await runAsyncFlow(commit, {
        request: backOffice.insurance.getUserInsurance,
        params: [userUuid],
        scope: USER_SCOPES.insurance,
      });
    },
    async postContractAnnualCover({ commit }, { userUuid }) {
      await runAsyncFlow(commit, {
        request: backOffice.insurance.postContractAnnualCover,
        params: [userUuid],
        scope: USER_SCOPES.coverForm,
      });
    },
    async putCancelAutoRenewalCover({ commit }, { userUuid }) {
      await runAsyncFlow(commit, {
        request: backOffice.insurance.putCancelAutoRenewalCover,
        params: [userUuid],
        scope: USER_SCOPES.coverForm,
      });
    },
    async getUserImpersonate({ commit }, userUuid) {
      await runAsyncFlow(commit, {
        request: backOffice.user.getUserImpersonate,
        params: [userUuid],
        scope: USER_SCOPES.impersonation,
      });
    },
    async getImpersonateReasons({ commit }) {
      await runAsyncFlow(commit, {
        request: backOffice.userCompany.getImpersonateReasons,
        params: [],
        scope: USER_SCOPES.impersonationReasons,
      });
    },
    async postUserImpersonateReason({ commit }, { userUuid, data }) {
      await runAsyncFlow(commit, {
        request: backOffice.user.postUserImpersonateReason,
        params: [userUuid, data],
        scope: USER_SCOPES.impersonation,
      });
    },
    async postUserImpersonateFinish({ commit }, userUuid) {
      await runAsyncFlow(commit, {
        request: backOffice.user.postUserImpersonateFinish,
        params: [userUuid],
        scope: USER_SCOPES.impersonationFinish,
      });
    },
    async getUserNotifications({ commit }, { userUuid, params }) {
      await runAsyncFlow(commit, {
        request: backOffice.userNotifications.getUserNotifications,
        params: [userUuid, params],
        scope: USER_SCOPES.notifications,
      });
    },
    async getAvailableUserTariffs({ commit }, { userUuid, params }) {
      await runAsyncFlow(commit, {
        request: backOffice.pricingUsers.getAvailableUserTariffs,
        params: [userUuid, params],
        scope: USER_SCOPES.availableUserTariffs,
      });
    },
    async getAvailableTariffPeriods({ commit }, { tariffUuid, params }) {
      await runAsyncFlow(commit, {
        request: backOffice.tariffs.getAvailableTariffPeriods,
        params: [tariffUuid, params],
        scope: USER_SCOPES.availableUserTariffPeriods,
      });
    },
  },
})));

export default users;
