<script>
import isNil from 'lodash/isNil';
import { FALLBACK_MESSAGE } from '@emobg/web-utils';
export default {
  name: 'FallbackComponent',
  props: {
    check: {
      type: [String, Number, Object],
      default: null,
    },
    message: {
      type: String,
      default: FALLBACK_MESSAGE.dash,
    },
    component: {
      type: Function,
      default: () => {},
    },
    props: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    showFallback() {
      return isNil(this.check);
    },
  },
};
</script>

<template>
  <div class="FallbackComponent">
    <span v-if="showFallback">
      {{ message }}
    </span>
    <Component
      :is="component"
      v-else
      v-bind="props"
    />
  </div>
</template>
