var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex align-items-center" },
    [
      _c(
        "time",
        {
          staticClass: "WeekSchedule__DayIntervals__time flex-fill ml-1",
          attrs: { datetime: _vm.getStart(_vm.interval) },
        },
        [_vm._v(" " + _vm._s(_vm.getStart(_vm.interval)) + " ")]
      ),
      _c(
        "time",
        {
          staticClass: "WeekSchedule__DayIntervals__time flex-fill mx-2",
          attrs: { datetime: _vm.getEnd(_vm.interval) },
        },
        [_vm._v(" " + _vm._s(_vm.getEnd(_vm.interval)) + " ")]
      ),
      _c(
        "ui-button",
        {
          staticClass: "ml-4",
          attrs: {
            disabled: _vm.isDisabled,
            color: _vm.GRAYSCALE.inkLight,
            face: _vm.FACES.text,
            square: true,
          },
          on: {
            clickbutton: function ($event) {
              return _vm.$emit("delete-interval", _vm.interval)
            },
          },
        },
        [
          _c("ui-icon", {
            attrs: { icon: _vm.ICONS.minus, size: _vm.SIZES.small },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }