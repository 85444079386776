import values from 'lodash/values';
import { backOffice, external } from '@emobg/web-api-client';
import { createCustomStore, withScopes } from '@emobg/web-utils';
import { toastError } from '@/utils';

const {
  getOpenDoors,
  getCloseDoors,
  getOpenAsAdmin,
  getCloseAsAdmin,
  getImmobilize,
  getMobilize,
} = backOffice.csfleet;

const { patchInvalidateVirtualKey } = external.booking;

export const scopes = {
  mobilize: 'mobilize',
  immobilize: 'immobilize',
  doorsLock: 'doorsLock',
  doorsUnlock: 'doorsUnlock',
  menuOpen: 'menuOpen',
  menuClose: 'menuClose',
  virtualKeys: 'virtualKeys',
};

const vehicleDetailsInteractions = createCustomStore(({ runAsyncFlow }) => withScopes(scopes,
  (
    {
      mutations: {
        setError({ state }, payload) {
          toastError.bind(this)(state, payload, values(scopes));
        },
      },
      actions: {
        getMobilizeVehicle({ commit }, vehicleUuid) {
          runAsyncFlow(commit, {
            request: getMobilize,
            params: [vehicleUuid],
            scope: scopes.mobilize,
          });
        },
        getImmobilizeVehicle({ commit }, vehicleUuid) {
          runAsyncFlow(commit, {
            request: getImmobilize,
            params: [vehicleUuid],
            scope: scopes.immobilize,
          });
        },

        getUnlockVehicle({ commit }, vehicleUuid) {
          runAsyncFlow(commit, {
            request: getOpenDoors,
            params: [vehicleUuid],
            scope: scopes.doorsUnlock,
          });
        },
        getLockVehicle({ commit }, vehicleUuid) {
          runAsyncFlow(commit, {
            request: getCloseDoors,
            params: [vehicleUuid],
            scope: scopes.doorsLock,
          });
        },

        getOpenMasterMenu({ commit }, vehicleUuid) {
          runAsyncFlow(commit, {
            request: getOpenAsAdmin,
            params: [vehicleUuid],
            scope: scopes.menuOpen,
          });
        },
        getCloseMasterMenu({ commit }, vehicleUuid) {
          runAsyncFlow(commit, {
            request: getCloseAsAdmin,
            params: [vehicleUuid],
            scope: scopes.menuClose,
          });
        },
        patchInvalidateVirtualKey({ commit }, bookingUuid) {
          runAsyncFlow(commit, {
            request: patchInvalidateVirtualKey,
            params: [bookingUuid],
            scope: scopes.virtualKeys,
          });
        },
      },
    }
  )));

export default vehicleDetailsInteractions;
