var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "SwitchToOldViewComponent align-right px-6 py-4 py-md-2 bg-color-main-light",
    },
    [
      _c(
        "a",
        {
          staticClass: "emobg-color-ink",
          attrs: { href: _vm.url || _vm.oldPageUrl, target: _vm.target },
        },
        [_vm._v(" Switch to the old view ")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }