var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "CommentForm",
        attrs: { title: _vm.modalTitle, header: { isClosable: true } },
      },
      _vm.$listeners
    ),
    [
      _c(
        "template",
        { slot: "alerts" },
        [
          _c("StoreNotificationComponent", {
            attrs: {
              "store-domain": _vm.DOMAINS_MODEL.app.comments,
              scope: _vm.COMMENTS_SCOPES.newComment,
              "is-editing": _vm.comment,
              element: "comments",
              "data-test-id": "notification",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "body" },
        [
          _c(
            "div",
            [
              _vm.isInitialized
                ? _c("MuiTextarea", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: {
                          isRequired: true,
                          isMinLength: {
                            message: "We need at least 6 characters",
                            length: 6,
                          },
                        },
                        expression:
                          "{\n          isRequired: true,\n          isMinLength: {\n            message: 'We need at least 6 characters',\n            length: 6,\n          }\n        }",
                      },
                    ],
                    ref: "comment",
                    attrs: {
                      label: "Comment*",
                      placeholder: "Write a comment",
                      name: "comment",
                      rows: "4",
                      required: "",
                    },
                    on: { input: _vm.checkValidation },
                    model: {
                      value: _vm.body,
                      callback: function ($$v) {
                        _vm.body = $$v
                      },
                      expression: "body",
                    },
                  })
                : _c("ui-skeleton", { staticClass: "mb-4" }),
            ],
            1
          ),
          _c("MarkdownInformation", { staticClass: "mt-2" }),
          _c("label", { staticClass: "d-block emobg-label-1 mt-4" }, [
            _vm._v(" Comment preview: "),
          ]),
          _c("div", {
            staticClass: "CommentsList__markdown mt-4",
            staticStyle: { "word-wrap": "break-word" },
            domProps: { innerHTML: _vm._s(_vm.marked(_vm.body)) },
          }),
        ],
        1
      ),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-center justify-content-sm-end align-items-center",
          },
          [
            _c(
              "div",
              { staticClass: "d-flex justify-content-center" },
              [
                _c("CancelButton", {
                  on: { click: () => _vm.$emit("closeModal") },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "d-flex justify-content-center" },
              [
                _c(
                  "SaveButton",
                  {
                    attrs: {
                      disabled:
                        !_vm.areRequiredFieldsFilled || _vm.hasSameValues,
                      loading: _vm.newCommentStatus.LOADING,
                    },
                    on: { click: _vm.request },
                  },
                  [_vm._v(" " + _vm._s(_vm.comment ? "Save" : "Add") + " ")]
                ),
              ],
              1
            ),
          ]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }