import pricing from './PricingRouterMap';
import { PRICING_PERMISSIONS } from '../const/permissions';

const PricingView = () => import(/* webpackChunkName: "pricing" */'../PricingView.vue');
const TariffsListView = () => import(/* webpackChunkName: "pricing" */'../Tariffs/List/TariffsListView');
const ServicesListView = () => import(/* webpackChunkName: "pricing-services" */'../Services/List/ServicesListView.vue');
const TariffFormView = () => import(/* webpackChunkName: "pricing" */'../Tariffs/TariffFormView');
const LegacyTariffDetail = () => import(/* webpackChunkName: "pricing" */'../Tariffs/LegacyTariffDetail/LegacyTariffDetail');
const CreateModifierView = () => import(/* webpackChunkName: "pricing" */'../Tariffs/Modifiers/ModifierForm/CreateModifierView');

export default [
  {
    path: 'pricing',
    name: pricing.index,
    component: PricingView,
    redirect: { name: pricing.tariffs.index },
    meta: {
      label: 'Pricing',
    },
    children: [
      {
        path: 'tariffs',
        component: PricingView,
        redirect: { name: pricing.tariffs.index },
        children: [
          {
            path: '/',
            name: pricing.tariffs.index,
            component: TariffsListView,
            meta: {
              label: 'Tariffs',
              permissions: [
                PRICING_PERMISSIONS.viewSettingsTariffs,
                PRICING_PERMISSIONS.oldViewSettingsTariffs,
              ],
            },
          },
          {
            path: 'create',
            props: true,
            name: pricing.tariffs.create,
            component: TariffFormView,
            meta: {
              label: 'Create new tariff',
              permissions: [PRICING_PERMISSIONS.manageTariffSettings],
            },
          },
          {
            path: ':tariffUuid',
            props: true,
            name: pricing.tariffs.update,
            component: TariffFormView,
            meta: {
              label: 'Edit tariff',
              permissions: [PRICING_PERMISSIONS.manageTariffSettings],
            },
          },
          {
            path: ':tariffUuid/modifiers/create',
            props: true,
            name: pricing.tariffs.modifiers,
            component: CreateModifierView,
            meta: {
              label: 'Create new modifier',
              permissions: [PRICING_PERMISSIONS.manageTariffSettings],
            },
          },
          {
            path: 'legacy/:tariffUuid',
            props: true,
            name: pricing.tariffs.legacy,
            component: LegacyTariffDetail,
            meta: {
              label: 'Legacy tariff details',
              permissions: [PRICING_PERMISSIONS.manageTariffSettings],
            },
          },
        ],
      },
      {
        path: 'services',
        name: pricing.services,
        component: ServicesListView,
        meta: {
          label: 'Services',
          permissions: [
            PRICING_PERMISSIONS.viewSettingsTariffs,
            PRICING_PERMISSIONS.oldViewSettingsTariffs,
          ],
        },
      },
    ],
  },
];
