/**
 * @typedef {{start:string,end:string}} Interval
 * @typedef {Interval[]} Day
 * @typedef {{monday:Day,tuesday:Day,wednesday:Day,thursday:Day,friday:Day,saturday:Day,sunday:Day}} Schedule
 * @typedef {{monday:string,tuesday:string,wednesday:string,thursday:string,friday:string,saturday:string,sunday:string}} DayNames
 * @typedef {object} SchedulerErrors
 * @prop {string} SchedulerErrors.dayName
 * @prop {string} SchedulerErrors.dayNotSelected
 * @prop {string} SchedulerErrors.intervalEnd
 * @prop {string} SchedulerErrors.intervalObject
 * @prop {string} SchedulerErrors.intervalOrder
 * @prop {string} SchedulerErrors.intervalOverlap
 * @prop {string} SchedulerErrors.intervalStart
 * @prop {string} SchedulerErrors.scheduleAllArray
 * @prop {string} SchedulerErrors.scheduleObject
 * @prop {string} SchedulerErrors.scheduleLength
 * @prop {string} SchedulerErrors.scheduleDaysMissing
 * @prop {string} SchedulerErrors.timeLength
 * @prop {string} SchedulerErrors.timePattern
 * @prop {string} SchedulerErrors.timeString
 */
