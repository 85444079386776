<script>
import random from 'lodash/random';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import keys from 'lodash/keys';
import isObject from 'lodash/isObject';
import assign from 'lodash/assign';
import debounce from 'lodash/debounce';
import moment from 'moment-timezone';
import { DELAY } from '@emobg/web-utils';
import { COLORS, DATE } from './constants';
const viewSample = {
  label: '1 Day',
  config: {
    days: 1,
    scale: 'Day',
    // Optional parameter, only it's necessary when "scale" is set "CellDurantion"
    // cellDuration: 15,
    timeHeaders: [
      { groupBy: 'Day', format: 'dd/MM/yyyy' },
      { groupBy: 'Hour' },
    ],
  },
};

export default {
  name: 'DayPilot',

  props: {
    scriptUrl: {
      type: String,
      default: null,
    },

    config: {
      type: Object,
      default: () => ({}),
    },

    resources: {
      type: Array,
      default: () => [],
    },

    onAfterEventRender: {
      type: Function,
      default: () => {},
    },
    onRowFilter: {
      type: Function,
      default: () => {},
    },

    resourcesBubble: {
      type: Function,
      default: () => {},
    },

    cellBubble: {
      type: Function,
      default: () => {},
    },
    events: {
      type: Array,
      default: () => [],
    },

    onEventFilter: {
      type: Function,
      default: () => {},
    },

    onEventClicked: {
      type: Function,
      default: () => {},
    },

    eventsBubble: {
      type: Function,
      default: () => {},
    },

    // It's not reactive, use $data value
    views: {
      type: Array,
      default: () => [],
    },

    // It's not reactive, use $data value
    currentViewIndex: {
      type: Number,
      default: 0,
    },

    date: {
      type: Object,
      default: () => moment(),
    },

    datetimepickerConfig: {
      type: Object,
      default: () => ({}),
    },

    onTimeRangeSelecting: {
      type: Function,
      default: () => {},
    },

    onTimeRangeSelected: {
      type: Function,
      default: () => {},
    },

    onAfterUpdate: {
      type: Function,
      default: () => {},
    },

    onEventMouseOver: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      id: `Daypilot-${random(10000)}`,
      dp: null,
      viewsType: this.views,
      currentViewTypeIndex: null,
      defaultConfig: {
        // TYPE TIMELINE
        locale: 'en-gb',
        cellWidthSpec: 'Auto',
        cellWidthMin: '50',
        treeEnabled: true,
        timeFormat: 'Clock24Hours',
        treePreventParentUsage: true,
        height: '100%',
        heightSpec: 'Parent100Pct',

        cellSweeping: true,
        cellSweepingCacheSize: 2000,
        dynamicEventRendering: 'Progressive',
        dynamicEventRenderingCacheSweeping: true,
        dynamicEventRenderingCacheSize: 1000,
        scrollDelayCells: 100,
        progressiveRowRendering: true,
        progressiveRowRenderingPreload: 50,

        // ROW HEADER
        rowHeaderWidth: 150,
        rowMinHeight: 60,
        rowHeaderWidthAutoFit: false,

        // EVENT MOVING/RESIZE
        eventMoveHandling: 'Disabled',
        eventEditHandling: 'Disabled',
        eventResizeHandling: 'Disabled',
        eventSelectHandling: 'Disabled',
        eventHeight: 50,

        useEventBoxes: 'Never',
        durationBarVisible: false,
        separators: [
          {
            color: 'Red',
            width: 2,
            layer: 'AboveEvents',
            location: this.formatMoment(),
          },
        ],
        businessBeginsHour: 0,
        businessEndsHour: 0,

        onBeforeRowHeaderRender: element => {
          element.row.backColor = get(element, 'row.index', 0) % 2 ? COLORS.gray : COLORS.white;
        },

        onBeforeCellRender: element => {
          element.cell.backColor = get(element, 'cell.y', 0) % 2 ? COLORS.gray : COLORS.white;
        },
      },
    };
  },

  computed: {
    daypilotIsLoaded() {
      return this.dp;
    },

    isValidData() {
      return data => this.daypilotIsLoaded && isArray(data);
    },

    isValidViewType() {
      return view => {
        // check if view passed has Label and Config
        const neededProps = keys(viewSample).filter(item => {
          // When prop does not exist, return it
          if (!view[item]) {
            return true;
          }

          // Check if prop is an object and it has the minimum configuration
          if (isObject(viewSample[item]) && isObject(view[item])) {
            const defaultViewConfig = viewSample[item];
            const viewConfig = view[item];

            // check if view config has at least the same configuration to be overridden
            const fn = configItem => !viewConfig[configItem];
            const neededConfigProps = keys(defaultViewConfig).filter(fn)
              .length;
            if (neededConfigProps) {
              return true;
            }
          }

          return false;
        }).length;

        // throw an error when view has not a needed properties
        if (neededProps) {
          throw Error(`View type is not valid: ${JSON.stringify(view)}`);
        }

        return true;
      };
    },

    isValidDate() {
      return date => date && typeof date.isValid === 'function' && date.isValid();
    },
  },

  watch: {
    'dp.rowlist': {
      handler(rows) {
        this.$emit('num-rows-visible', rows.filter(item => !item.hidden));
      },
    },

    resources: {
      handler(val) {
        if (this.isValidData(val)) {
          this.setResources(val);
          this.update();
        }
      },
    },

    events: {
      handler(val) {
        if (this.isValidData(val)) {
          this.setEvents(val);
          this.update();
        }
      },
    },

    date: {
      handler(val) {
        if (this.isValidDate(val)) {
          this.setDate(val);
        }
      },
      deep: true,
    },
  },

  created() {
    if (this.views && this.views.length) {
      this.viewsType.forEach(view => this.isValidViewType(view));

      this.currentViewTypeIndex = this.currentViewIndex;
    }
  },

  mounted() {
    this.injectScript();
  },

  methods: {
    injectScript() {
      if (!window.DayPilot) {
        const script = document.createElement('script');
        script.onload = this.onLoad;
        script.src = this.scriptUrl;
        document.body.appendChild(script);
      } else {
        this.onLoad();
      }
    },
    onLoad() {
      const element = document && document.getElementById(this.id);
      if (element) {
        this.dp = new window.DayPilot.Scheduler(this.id);
        assign(
          this.dp,
          this.defaultConfig,
          {
            bubble: this.onLoadEventsBubble(),
            resourceBubble: this.onLoadResourcesBubble(),
            cellBubble: this.onLoadCellBubble(),

            // Bind events
            onAfterEventRender: this.onAfterEventRender,
            onEventMouseOver: this.onEventMouseOver,
            onAfterUpdate: this.onAfterUpdate,
            onEventClicked: this.onEventClicked,
            onEventFilter: this.onEventFilter,
            onRowFilter: this.onRowFilter,
            onTimeRangeSelected: this.onTimeRangeSelected,
            onTimeRangeSelecting: this.onTimeRangeSelecting,
          },
          this.config,
        );

        if (this.isValidDate(this.date)) {
          this.setDate(this.date);
        }
        this.dp.separator = this.formatMoment();
        this.dp.init();

        if (this.currentViewTypeIndex !== null) {
          const defaultView = this.viewsType[
            this.currentViewTypeIndex
          ];
          assign(this.dp, defaultView.config);
        }

        if (this.isValidData(this.resources)) {
          this.setResources(this.resources);
          this.update();
        }

        if (this.isValidData(this.events)) {
          this.setEvents(this.events);
          this.update();
        }
      }
    },

    onLoadEventsBubble() {
      return this.eventsBubble({
        Bubble: window.DayPilot.Bubble,
        find: this.dp.rows.find,
      });
    },

    onLoadResourcesBubble() {
      return this.resourcesBubble({
        Bubble: window.DayPilot.Bubble,
        find: this.dp.rows.find,
      });
    },

    onLoadCellBubble() {
      return this.cellBubble({
        Bubble: window.DayPilot.Bubble,
        find: this.dp.rows.find,
      });
    },

    setResources(resources) {
      this.dp.resources = resources;
    },

    setEvents(events) {
      this.dp.events.list = events;
    },

    setCurrentViewTypeIndex(index) {
      const view = this.viewsType[index];
      assign(this.dp, view.config);
      this.currentViewTypeIndex = index;
      this.update();
    },

    setDate(momentDate) {
      if (this.dp) {
        this.dp.startDate = this.formatMoment(
          momentDate,
        );
        this.update();
      }
    },

    filterResources(filters) {
      this.dp.rows.filter(filters);
    },

    update: debounce(function updateDayPilot() {
      this.dp.update();
    }, DELAY.medium),

    formatMoment(dateTime = moment()) {
      return dateTime.format(DATE.detail);
    },
  },
};
</script>

<template>
  <div
    :class="[
      'Daypilot',
      'w-100',
      'border-top-0 border-bottom-01 border-right-01 border-left-01',
      'border-color-ground',
      `js-${id}`,
    ]"
  >
    <section :id="id" />
  </div>
</template>
