import shared from './SharedRouterMap';
const FeedbackDialogView = () => import(/* webpackChunkName: "shared" */'../FeedbackDialog/FeedbackDialogView.vue');

export default [
  {
    path: '/404',
    name: shared.notFound,
    component: FeedbackDialogView,
  },
  {
    path: '*',
    alias: ['[4-5]\\d{2}'],
    component: FeedbackDialogView,
  }, // please, always keep it as the last item
];
