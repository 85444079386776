import promotions from './PromotionsRouterMap';
const PromotionsView = () => import(/* webpackChunkName: "promotions" */ '../PromotionsView.vue');
const PromotionsListView = () => import(/* webpackChunkName: "promotions" */ '../List/PromotionsListView');

export default [
  {
    path: 'promotions',
    name: promotions.index,
    component: PromotionsView,
    redirect: { name: promotions.list },
    meta: {
      label: 'Promotions',
    },
    children: [
      {
        path: 'promotions',
        name: promotions.list,
        component: PromotionsListView,
        meta: {
          label: 'Promotions & Referrals',
        },
      },
    ],
  },
];
