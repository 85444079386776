<script>
export default {
  name: 'DeleteModalBodyComponent',
  props: {
    title: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
  },
};
</script>
<template>
  <div class="DeleteModalBodyComponent">
    <h3
      v-if="title"
      :class="[
        'emobg-font-large emobg-font-weight-semibold emobg-font-line-height-large emobg-color-ink',
        { 'pb-3': text },
      ]"
      v-html="title"
    />

    <p
      v-if="text"
      class="emobg-font-line-height-large emobg-color-ink-light emobg-font-small"
      style="font-size: 16px; white-space: pre-wrap;"
      v-html="text"
    />
  </div>
</template>
