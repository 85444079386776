<script>
import { FILTER_TO_COMPONENT } from './table.const';

export default {
  name: 'TableFiltersComponent',
  props: {
    filters: {
      type: Array,
      default: () => [],
    },
    filtersApplied: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    this.FILTERS_MAP = FILTER_TO_COMPONENT;
  },
  methods: {
    onUpdateModelValueFilterComponent(value, index) {
      // Emit single filter updated, so parent will change the entire array of applied filters
      this.$emit(
        'update:filterApplied',
        {
          index,
          value,
        },
      );
    },
    clearFilters() {
      this.$emit('clear:filtersApplied');
    },
  },
};
</script>
<template>
  <div
    class="TableFiltersComponent d-flex flex-column emobg-border-1 emobg-background-color-ground-lightest emobg-border-color-ground-light mr-2"
    style="flex: 0 0 360px;"
  >
    <div class="d-flex justify-content-between emobg-background-color-ground-lighter emobg-border-bottom-4 emobg-border-color-ground-light py-3 px-2">
      <span class="font-weight-bold">Filters</span>
      <span
        class="cursor-pointer emobg-color-primary font-weight-bold"
        @click="clearFilters"
      >
        Clear
      </span>
    </div>
    <template v-for="(filterItem, index) in filters">
      <Component
        :is="FILTERS_MAP[filterItem.type]"
        :key="filterItem.props.attributeName"
        :model-value="filtersApplied[index].value"
        v-bind="filterItem.props"
        @update:modelValue="newValues => onUpdateModelValueFilterComponent(newValues, index)"
      />
    </template>
  </div>
</template>
