<script>
import { isPdf } from '@/utils';

export default {
  name: 'DocumentThumbnail',
  props: {
    image: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: '',
    },
    onClickImage: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    imageStyle() {
      return {
        'background-image': `url('${this.image}')`,
        'background-size': 'cover',
        'background-position': 'center',
      };
    },
    isPdf() {
      return isPdf(this.image);
    },
  },
};
</script>
<template>
  <div
    class="DocumentThumbnail"
    @click="onClickImage"
  >
    <div
      class="emobg-background-color-white emobg-border-color-ground emobg-border-1
      emobg-border-radius-small d-flex flex-column emobg-background-color-white
      justify-content-center align-items-center cursor-pointer"
      style="width: 62px; height: 42px;"
    >
      <ui-icon
        v-if="isPdf"
        :icon="ICONS.filePdf"
        :size="ICONS_SIZES.large"
      />
      <div
        v-else
        :style="imageStyle"
        class="w-100 h-100"
      />
    </div>
  </div>
</template>
