var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "DeleteModalBodyComponent" }, [
    _vm.title
      ? _c("h3", {
          class: [
            "emobg-font-large emobg-font-weight-semibold emobg-font-line-height-large emobg-color-ink",
            { "pb-3": _vm.text },
          ],
          domProps: { innerHTML: _vm._s(_vm.title) },
        })
      : _vm._e(),
    _vm.text
      ? _c("p", {
          staticClass:
            "emobg-font-line-height-large emobg-color-ink-light emobg-font-small",
          staticStyle: { "font-size": "16px", "white-space": "pre-wrap" },
          domProps: { innerHTML: _vm._s(_vm.text) },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }