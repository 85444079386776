var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "TranslatableFieldComponent" },
    [
      _c("ui-select", {
        staticClass: "d-block mb-4",
        attrs: {
          value: _vm.internalLanguage,
          label: _vm.languageLabel,
          name: "language",
        },
        domProps: { options: _vm.AVAILABLE_LANGUAGES },
        on: { selectoption: ({ detail }) => (_vm.internalLanguage = detail) },
      }),
      _vm.isInputText
        ? _c(
            "ui-text-input",
            _vm._b(
              {
                attrs: {
                  value:
                    _vm.inputValue[
                      _vm.getCodeKeyByLocale(_vm.internalLanguage)
                    ],
                  name: "field",
                },
                on: {
                  changevalue: ({ detail }) =>
                    (_vm.inputValue[
                      _vm.getCodeKeyByLocale(_vm.internalLanguage)
                    ] = detail),
                },
              },
              "ui-text-input",
              _vm.$attrs,
              false
            )
          )
        : _c(
            "ui-text-area",
            _vm._b(
              {
                attrs: {
                  value:
                    _vm.inputValue[
                      _vm.getCodeKeyByLocale(_vm.internalLanguage)
                    ],
                  name: "field",
                },
                on: {
                  changevalue: ({ detail }) =>
                    (_vm.inputValue[
                      _vm.getCodeKeyByLocale(_vm.internalLanguage)
                    ] = detail),
                },
              },
              "ui-text-area",
              _vm.$attrs,
              false
            )
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }