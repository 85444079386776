<script>
import { mapGetters } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';

export default {
  name: 'PermissionLink',
  props: {
    linkPermissions: {
      type: Array,
      default: () => [],
    },
    to: {
      type: Object,
      default: null,
    },
    href: {
      type: String,
      default: null,
    },
    target: {
      type: String,
      default: '_self',
    },
    text: {
      type: [String, Number],
      default: '',
    },
    classLink: {
      type: String,
      default: 'link',
    },
    classText: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters(DOMAINS_MODEL.app.userAccount, [
      'hasPermissions',
    ]),
  },
};
</script>
<template>
  <RouterLink
    v-if="to && hasPermissions(linkPermissions)"
    :to="to"
    :target="target"
    :class="classLink"
  >
    <slot>
      {{ text }}
    </slot>
  </RouterLink>
  <a
    v-else-if="href && hasPermissions(linkPermissions)"
    :href="href"
    :target="target"
    :class="classLink"
  >
    <slot>
      {{ text }}
    </slot>
  </a>
  <span
    v-else
    :class="classText"
  >
    <slot>
      {{ text }}
    </slot>
  </span>
</template>
