var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "CommentComponent" }, [
    _c(
      "div",
      {
        staticClass:
          "d-flex justify-content-between align-items-center pb-2 mb-1",
      },
      [
        _c("div", { staticClass: "d-flex flex-column" }, [
          _c("span", { staticClass: "emobg-color-ink-light pb-1" }, [
            _vm._v(" " + _vm._s(_vm.dateText) + " "),
          ]),
          _c(
            "div",
            { staticClass: "d-flex align-items-center" },
            [
              _c("span", { staticClass: "emobg-font-weight-semibold" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.get(_vm.comment, "reporter.firstName")) +
                    " " +
                    _vm._s(_vm.get(_vm.comment, "reporter.lastName")) +
                    " "
                ),
              ]),
              _vm.bookingRate
                ? _c(
                    "ui-badge",
                    {
                      staticClass: "ml-1",
                      attrs: { color: _vm.GRAYSCALE.ground },
                    },
                    [_vm._v(" " + _vm._s(_vm.bookingRate) + " ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
        _vm.isEditable
          ? _c("Actions", {
              attrs: { actions: _vm.actions, "list-align": "bottom-left" },
            })
          : _vm._e(),
      ],
      1
    ),
    _c("div", {
      staticClass: "CommentsList__markdown",
      domProps: {
        innerHTML: _vm._s(_vm.marked(_vm.get(_vm.comment, "body", ""))),
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }