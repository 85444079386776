<script>
import isEmpty from 'lodash/isEmpty';
import each from 'lodash/each';
import throttle from 'lodash/throttle';
import filter from 'lodash/filter';
import unionBy from 'lodash/unionBy';
import get from 'lodash/get';

import * as MarkerClusterer from '@google/markerclustererplus';
import { DELAY } from '@emobg/web-utils';
import googleMapMixin from './googleMapMixin';
import { GOOGLE_MAP_STYLES } from './const/googleMapStyle.const';

export default {
  name: 'GoogleMapClusterComponent',
  mixins: [googleMapMixin],
  props: {
    clusterSettings: {
      type: Array,
      required: true,
      default: () => [],
    },
    clusterGroup: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      cluster: null,
    };
  },
  computed: {
    hasMarkersMismatch() {
      return this.markers.length > this.markerSources.length;
    },
  },
  watch: {
    markerSources(newMarkers, oldMarkers) {
      if (isEmpty(newMarkers)) {
        this.cluster.clearMarkers();
        this.clearMarkers();
        return;
      }

      this.clearMarkers();
      const isOldMarkersEmpty = oldMarkers.length;

      this.cluster.clearMarkers();
      this.renderMap(isOldMarkersEmpty);
    },
  },
  created() {
    this.clusteringOptions = {
      gridSize: 80,
      fitToMarkers: true,
      minimumClusterSize: 3,
      maxZoom: 16,
      styles: this.clusterSettings,
    };
  },
  methods: {
    clearMarkers() {
      each(this.markers, item => item.setMap(null));
      this.markers = [];
    },
    initGoogle() {
      this.googleInstance = this.$GoogleMaps.getInstance();
      this.bounds = new this.googleInstance.maps.LatLngBounds();
      const element = this.$refs[`gMap${this.mapId}`];
      this.map = new this.googleInstance.maps.Map(element, {
        gestureHandling: this.gestureHandling,
        mapTypeControl: this.mapTypeControl,
        streetViewControl: this.streetViewControl,
        fullscreenControl: this.fullScreenControl,
        zoomControl: true,
        mapTypeControlOptions: {
          position: this.googleInstance.maps.ControlPosition.RIGHT_LEFT,
        },
        zoomControlOptions: {
          position: this.googleInstance.maps.ControlPosition.RIGHT_CENTER,
        },
        styles: GOOGLE_MAP_STYLES,
      });

      this.map.fitBounds(this.bounds);

      this.map.addListener('bounds_changed', throttle(
        () => {
          const bounds = this.map.getBounds();
          const ne = bounds.getNorthEast();
          const sw = bounds.getSouthWest();

          this.$emit('boundsChange', {
            latitude_from: ne.lng(),
            latitude_to: ne.lat(),
            longitude_from: sw.lng(),
            longitude_to: sw.lat(),
          });
        }, DELAY.long,
        {
          maxWait: DELAY.long,
          trailing: true,
        },
      ));
    },
    renderMap(isOldMarkersEmpty = true) {
      this.processMarkers();

      const clusters = this.clusterGroup
        ? filter(this.markers, { itemType: this.clusterGroup })
        : this.markers;

      this.cluster = new MarkerClusterer(
        this.map,
        unionBy(clusters, 'uuid'),
        this.clusteringOptions,
      );

      if (!isOldMarkersEmpty) {
        this.map.panToBounds(this.bounds);
        this.map.fitBounds(this.bounds);
      }
    },
    processMarkers() {
      if (!this.markerSources || this.hasMarkersMismatch) {
        return;
      }
      this.markerSources.forEach(this.processMarkerCoordinates);
      this.map.panBy(0, 0);
    },
    addLocation(location) {
      const marker = new this.googleInstance.maps.Marker(location);

      marker.setMap(this.map);

      this.markers.push(marker);

      marker.addListener('click', () => {
        this.$emit('getDetails', {
          uuid: get(marker, 'uuid'),
          type: get(marker, 'itemType'),
        });

        this.map.panTo(marker.getPosition());
      });
    },
  },
};
</script>

<template>
  <div class="GoogleMapComponent bg-color-ground">
    <div
      :ref="`gMap${mapId}`"
      :class="[
        'GoogleMapComponent__holder',
        { 'GoogleMapComponent__holder--full-height': fullHeight }
      ]"
    >
      `gMap${mapId}`
    </div>
  </div>
</template>
