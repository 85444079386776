var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "GoogleMapComponent emobg-background-color-ground position-relative",
    },
    [
      _c("div", {
        ref: "mapElement",
        staticClass: "GoogleMapComponent__map",
        staticStyle: { "min-height": "342px" },
      }),
      _vm.editable
        ? _c(
            "div",
            {
              staticClass: "GoogleMapComponent__edit-actions position-absolute",
              style: { left: `${24 * (_vm.drawingModes.length + 1) + 10}px` },
            },
            [
              _c(
                "MuiPopper",
                {
                  attrs: {
                    show: _vm.isDeleting,
                    "arrow-class": `emobg-background-color-secondary`,
                    options: {
                      placement: _vm.PLACEMENTS.top,
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: [0, 14],
                          },
                        },
                      ],
                    },
                    arrow: "",
                    "popper-class": "fade",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "popper",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "v1-MuiTooltip__tooltip p-2 emobg-font-weight-light emobg-border-radius-medium emobg-background-color-secondary emobg-color-white",
                                staticStyle: { "white-space": "nowrap" },
                              },
                              [_vm._v(" Click on a zone to delete it ")]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    1088726176
                  ),
                },
                [
                  _c(
                    "ui-button",
                    {
                      key: _vm.isDeleting,
                      class: [
                        "GoogleMapComponent__edit-button",
                        { "emobg-font-weight-semibold": _vm.isDeleting },
                      ],
                      attrs: {
                        face: _vm.FACES.outline,
                        color: _vm.isDeleting
                          ? _vm.GRAYSCALE.ink
                          : _vm.GRAYSCALE.inkLight,
                        size: _vm.SIZES.small,
                        square: "",
                      },
                      on: { clickbutton: _vm.setDeleteMode },
                    },
                    [_c("ui-icon", { attrs: { icon: _vm.ICONS.trash } })],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }