var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "TableContentWrapperComponent w-100" },
    [
      _vm.isCollapsed
        ? _c("label", { staticClass: "d-block emobg-label-1 mb-1 mb-sm-2" }, [
            _vm._v(" " + _vm._s(_vm.schemaItem.header) + " "),
          ])
        : _vm._e(),
      _vm.tooltipText
        ? _c(
            "ui-tooltip",
            {
              staticClass: "d-block w-100",
              attrs: { tooltip: _vm.tooltipText },
            },
            [
              _c("TableContentComponent", {
                ref: "content",
                attrs: {
                  "data-item": _vm.dataItem,
                  "data-index": _vm.dataIndex,
                  "schema-item": _vm.schemaItem,
                },
              }),
            ],
            1
          )
        : [
            _c("TableContentComponent", {
              ref: "content",
              attrs: {
                "data-item": _vm.dataItem,
                "data-index": _vm.dataIndex,
                "schema-item": _vm.schemaItem,
              },
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }