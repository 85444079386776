import SettingsView from '@domains/Settings/SettingsView';
import AutoInterventionsForm from '@domains/Settings/AutoInterventions/AutoInterventionsForm';
import settings from './SettingsRouterMap';
import { SETTINGS_PERMISSIONS } from '../const/permissions';
const FuelPriceView = () => import(/* webpackChunkName: "fuel-price" */ '../FuelPriceLog/FuelPriceLog.vue');
const FuelPriceList = () => import(/* webpackChunkName: "fuel-price" */ '../FuelPriceLog/List/FuelPriceList.vue');
const BookingRulesView = () => import(/* webpackChunkName: "booking-rules" */ '../BookingRules/BookingRulesView.vue');
export default [
  {
    path: 'settings',
    name: settings.index,
    component: SettingsView,
    meta: {
      label: 'Settings',
    },
    children: [
      {
        path: 'auto-interventions',
        name: settings.autoInterventions.form,
        component: AutoInterventionsForm,
        meta: {
          label: 'Auto-interventions settings',
          permissions: [SETTINGS_PERMISSIONS.viewSettingsAutoInterventions],
        },
      },
      {
        path: 'booking-rules',
        name: settings.bookingRules.index,
        component: BookingRulesView,
        meta: {
          label: 'Booking rules',
        },
      },
    ],
  },
  {
    path: 'settings/fuel-price-log',
    redirect: { name: settings.fuelPriceLog.list },
    name: settings.fuelPriceLog.index,
    component: FuelPriceView,
    meta: {
      label: 'Fuel Price',
      permissions: [SETTINGS_PERMISSIONS.viewSettingsFuelPrice],
    },
    children: [
      {
        path: 'list',
        name: settings.fuelPriceLog.list,
        component: FuelPriceList,
        meta: {
          label: ' ',
          permissions: [SETTINGS_PERMISSIONS.viewSettingsFuelPrice],
        },
      },
    ],
  },
];
