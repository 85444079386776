import RefinementList from '@/components/Table/filters/RefinementList';
import DatesRange from '@/components/Table/filters/DatesRange';

export const FILTERS = {
  refinementList: 'refinementList',
  datesRange: 'datesRange',
};

export const TYPES = {
  disjunctive: 'disjunctive',
  numeric: 'numeric',
};

export const FILTER_TO_COMPONENT = {
  [FILTERS.refinementList]: RefinementList,
  [FILTERS.datesRange]: DatesRange,
};

export const FILTER_TYPE_MAP = {
  [FILTERS.refinementList]: TYPES.disjunctive,
  [FILTERS.datesRange]: TYPES.numeric,
};
