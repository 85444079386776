<script>
import cloneDeep from 'lodash/cloneDeep';
import each from 'lodash/each';
import floor from 'lodash/floor';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import omit from 'lodash/omit';
import { mapActions, mapState } from 'vuex';
import {
  MuiAlgoliaSelect,
  MuiInputText,
  Validate,
} from '@emobg/motion-ui/v1';
import { sentenceCase } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { PageView, SaveButton, WeekSchedule } from '@/components';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { HOURS_PATTERN } from './const';

export default {
  directives: {
    Validate,
  },
  components: {
    MuiAlgoliaSelect,
    MuiInputText,
    PageView,
    SaveButton,
    WeekSchedule,
  },
  data() {
    return {
      inputs: {
        csOperatorUuid: null,
        userUuid: null,
        trigger: null,
        triggerValue: null,
        interventionType: null,
        interventionHours: null,
        schedule: {
          days: null,
        },
        active: false,
      },
      scheduleMinHours: 0,
      initialized: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.settings.autoInterventions, {
      configuration: state => state.configuration.data,
      triggers: ({ settings }) => map(settings.data.triggers, item => ({ label: sentenceCase(item), value: item })),
      interventions: ({ settings }) => map(settings.data.interventionTypes, item => ({ label: sentenceCase(item), value: item })),
      configurationStatus: state => state.configuration.STATUS,
    }),
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeOperatorUuid: state => state.operators.active.uuid,
    }),
    isOutOfMinHours() {
      return this.isScheduleEmpty() ? false : this.inputs.interventionHours > this.scheduleMinHours;
    },
    activateConfigFeedback() {
      const actionNode = '<b class="emobg-font-weight-bold">Save this page</b>';
      const sentence = get(this, 'configuration.active') && !this.inputs.active
        ? `Automatic interventions will not be created from the moment you ${actionNode}`
        : `Automatic interventions will be created from the moment you ${actionNode}`;
      const fillRequiredSentence = 'All required fields are needed in order to <b class="emobg-font-weight-bold">inactivate</b> the Auto-intervention settings';

      return !this.inputs.active && !this.areAllRequiredFieldFilled
        ? `${sentence} <br /> ${fillRequiredSentence}`
        : sentence;
    },
    hasActiveChanged() {
      return this.initialized && this.inputs.active !== get(this, 'configuration.active');
    },
    activateLabel() {
      return this.inputs.active ? 'Active for this operator' : 'Inactive for this operator';
    },
    areAllRequiredFieldFilled() {
      return this.inputs.userUuid
        && this.inputs.trigger
        && this.inputs.triggerValue
        && this.inputs.interventionType
        && this.inputs.interventionHours
        && !this.isScheduleEmpty();
    },
    hasSameValues() {
      return isEqual(omit(this.inputs, ['schedule']), omit(this.configuration, ['csOperatorName', 'schedule']))
        && isEqual(get(this, 'configuration.schedule.days'), get(this, 'inputs.schedule.days'));
    },
    dayNames() {
      return {
        monday: this.$t('Common.Time.monday'),
        tuesday: this.$t('Common.Time.tuesday'),
        wednesday: this.$t('Common.Time.wednesday'),
        thursday: this.$t('Common.Time.thursday'),
        friday: this.$t('Common.Time.friday'),
        saturday: this.$t('Common.Time.saturday'),
        sunday: this.$t('Common.Time.sunday'),
      };
    },
  },
  watch: {
    activeOperatorUuid() {
      this.getConfiguration();
    },
  },
  created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.HOURS_PATTERN = HOURS_PATTERN;
    this.getConfiguration();
  },
  methods: {
    get,
    ...mapActions(DOMAINS_MODEL.settings.autoInterventions, [
      'getTriggerAndInterventionTypes',
      'getAutoInterventionsSettings',
      'putAutoInterventionsSettings',
    ]),
    async getConfiguration() {
      this.initialized = false;
      await this.getAutoInterventionsSettings(this.activeOperatorUuid);
      await this.getTriggerAndInterventionTypes();

      this.inputs.trigger = get(this, 'configuration.trigger') || get(this, 'triggers[0].value');
      this.inputs.interventionType = get(this, 'configuration.interventionType') || get(this, 'interventions[0].value');
      this.inputs.schedule.days = cloneDeep(get(this, 'configuration.schedule.days'));
      this.inputs.userUuid = get(this, 'configuration.userUuid', null);
      this.inputs.triggerValue = get(this, 'configuration.triggerValue');
      this.inputs.interventionHours = get(this, 'configuration.interventionHours');
      this.inputs.active = get(this, 'configuration.active', false);
      this.inputs.csOperatorUuid = get(this, 'configuration.csOperatorUuid');
      this.initialized = true;
    },
    async updateConfiguration() {
      const data = cloneDeep(this.inputs);
      await this.putAutoInterventionsSettings({
        operatorUuid: this.activeOperatorUuid,
        data,
      });

      if (!this.configurationStatus.ERROR) {
        this.$notify({ message: 'Settings saved' });
        await this.getConfiguration();
      }
    },
    setScheduleMinHours(minMinutes) {
      this.scheduleMinHours = floor(minMinutes / 60);
    },
    isScheduleEmpty() {
      let isEmpty = true;
      each(this.inputs.schedule.days, value => {
        isEmpty = value.length === 0;
        return isEmpty;
      });

      return isEmpty;
    },
    isFormValid() {
      return !this.isOutOfMinHours
        && get(this, 'inputs.triggerValue', '').toString().match(this.HOURS_PATTERN)
        && get(this, 'inputs.interventionHours', '').toString().match(this.HOURS_PATTERN);
    },
  },
};
</script>
<template>
  <PageView class="AutoInterventionsForm">
    <div class="d-flex mb-2 align-items-center">
      <h2>
        Auto-intervention settings
      </h2>
      <ui-tooltip
        no-arrow
        tooltip="Activate to have cleaning interventions created automatically."
        class="ml-1"
      >
        <ui-icon
          :icon="ICONS.infoFull"
          :size="SIZES.small"
          :color="GRAYSCALE.inkLight"
          hover
          data-test-id="icon"
        />
      </ui-tooltip>
    </div>
    <ui-card class="d-flex flex-column flex-fill">
      <div class="row d-flex flex-column flex-fill">
        <h2 class="col-12 emobg-font-weight-bold emobg-font-large pb-3">
          Summary
        </h2>

        <div class="col-xl-6">
          <div class="d-flex">
            <div class="mr-6">
              <div class="emobg-font-weight-bold pb-3 pt-1">
                Operator
              </div>
              <div>
                <template v-if="initialized">
                  {{ get(configuration, 'csOperatorName') }}
                </template>
                <ui-skeleton
                  v-else
                  height="30"
                  class="mt-2"
                />
              </div>
            </div>
            <ui-toggle
              v-if="initialized"
              :value="inputs.active"
              :text="activateLabel"
              name="active"
              label="Status"
              @changevalue="(event) => inputs.active = event.detail"
            />
            <ui-skeleton
              v-else
              height="30"
              class="mt-2"
            />
          </div>
          <ui-alert
            v-if="hasActiveChanged"
            :color="COLORS.primary"
            :icon="ICONS.infoFull"
            class="pt-3 w-100 d-block"
          >
            <span v-html="activateConfigFeedback" />
          </ui-alert>
        </div>
        <div class="col-12">
          <hr class="emobg-border-top-1 emobg-border-color-ground-light my-4">
        </div>

        <div
          class="row col-12 pb-3"
          :class="{ disabled: !inputs.active }"
        >
          <div class="col-xl-6 mb-sm-4">
            <h2 class="emobg-font-weight-bold emobg-font-large pb-3">
              Details
            </h2>
            <div class="d-flex align-items-center emobg-font-weight-bold mb-1">
              User*
              <ui-tooltip
                no-arrow
                tooltip="The intervention will be assigned to the selected user."
                class="ml-1"
              >
                <ui-icon
                  :icon="ICONS.infoFull"
                  :size="SIZES.small"
                  :color="GRAYSCALE.inkLight"
                  hover
                  data-test-id="icon"
                />
              </ui-tooltip>
            </div>
            <MuiAlgoliaSelect
              v-if="initialized"
              v-model="inputs.userUuid"
              :index="ALGOLIA_INDEXES.users"
              :filters="`cs_operator_uuid: ${activeOperatorUuid} OR employee.company.cs_operator_uuid: ${activeOperatorUuid}`"
              :title="result => `${result.first_name} ${result.last_name} - ${result.email}`"
              name="user"
              no-cache
              path-value="uuid"
              placeholder="Select"
              class="w-100"
            />
            <ui-skeleton
              v-else
              height="30"
              class="mt-2"
            />
          </div>
          <div class="col-xl-6">
            <h2 class="emobg-font-weight-bold emobg-font-large pb-3">
              Rule
            </h2>
            <div class="row">
              <div class="col-lg-6 mb-3">
                <div class="d-flex align-items-center emobg-font-weight-bold mb-1">
                  Trigger*
                  <ui-Tooltip
                    no-arrow
                    tooltip="Condition which will trigger the intervention."
                    class="ml-1"
                  >
                    <ui-icon
                      :icon="ICONS.infoFull"
                      :size="SIZES.small"
                      :color="GRAYSCALE.inkLight"
                      hover
                      data-test-id="icon"
                    />
                  </ui-tooltip>
                </div>
                <ui-select
                  v-if="initialized"
                  :value="inputs.trigger"
                  :options.prop="triggers"
                  class="w-100"
                  name="trigger"
                  @selectoption="({ detail }) => inputs.trigger = detail"
                />
                <ui-skeleton
                  v-else
                  height="30"
                  class="mt-2"
                />
              </div>
              <div class="col-lg-6 mb-3">
                <label class="d-block emobg-font-weight-bold mb-1">
                  Trigger value (Hours)*
                </label>
                <MuiInputText
                  v-if="initialized"
                  ref="triggerValue"
                  v-model.number="inputs.triggerValue"
                  v-validate="{
                    isPattern: {
                      pattern: HOURS_PATTERN,
                      message: 'Only whole numbers allowed',
                      label: 'Only whole numbers allowed'
                    },
                  }"
                  :min-value="1"
                  placeholder="Add number of hours"
                  name="triggerValue"
                  class="w-100 pt-1"
                  type="number"
                />
                <ui-skeleton
                  v-else
                  height="30"
                  class="mt-2"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 mb-3">
                <div class="d-flex align-items-center emobg-font-weight-bold mb-1">
                  Intervention type*
                </div>
                <ui-select
                  v-if="initialized"
                  :value="inputs.interventionType"
                  :options.prop="interventions"
                  class="w-100"
                  name="interventionType"
                  @selectoption="({ detail }) => inputs.interventionType = detail"
                />
                <ui-skeleton
                  v-else
                  height="30"
                  class="mt-2"
                />
              </div>
              <div class="col-lg-6 mb-3">
                <label class="d-block emobg-font-weight-bold mb-1">
                  Intervention duration (Hours)*
                </label>
                <MuiInputText
                  v-if="initialized"
                  ref="interventionHours"
                  v-model.number="inputs.interventionHours"
                  v-validate="{
                    isPattern: {
                      pattern: HOURS_PATTERN,
                      message: 'Only whole numbers allowed',
                      label: 'Only whole numbers allowed'
                    },
                    outOfHours: () => ({message: 'Too long! It does not fit into the schedule', isValid: !isOutOfMinHours}),
                  }"
                  :min-value="1"
                  placeholder="Add number of hours"
                  name="interventionHours"
                  class="w-100"
                />
                <ui-skeleton
                  v-else
                  height="30"
                  class="mt-2"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 mb-3">
                <div class="d-flex align-items-center emobg-font-weight-bold mb-1">
                  Schedule*
                  <ui-tooltip
                    no-arrow
                    tooltip="Days when auto-intervention can be created."
                    class="ml-1"
                  >
                    <ui-icon
                      :icon="ICONS.infoFull"
                      :size="SIZES.small"
                      :color="GRAYSCALE.inkLight"
                      hover
                      data-test-id="icon"
                    />
                  </ui-tooltip>
                </div>
                <div v-if="initialized">
                  <WeekSchedule
                    v-model="inputs.schedule.days"
                    :day-names="dayNames"
                    :min-duration="inputs.interventionHours"
                    :is-disabled="!inputs.active"
                    @min-consecutive-minutes="setScheduleMinHours"
                  />
                  <ui-alert
                    v-if="isOutOfMinHours"
                    :color="COLORS.danger"
                    :icon="ICONS.alertFull"
                    class="pt-3 w-100"
                  >
                    Schedule conflicts with intervention duration!
                  </ui-alert>
                </div>
                <ui-skeleton
                  v-else
                  height="30"
                  class="mt-2"
                />
              </div>
            </div>
          </div>
        </div>

        <footer
          class="emobg-border-top-1 emobg-border-color-ground-light p-3 emobg-background-color-ground-lightest
        d-flex justify-content-end mx-n2 mb-n3"
        >
          <SaveButton
            :disabled="(!areAllRequiredFieldFilled || hasSameValues || !isFormValid())"
            :loading="configurationStatus.LOADING"
            @click="updateConfiguration"
          />
        </footer>
      </div>
    </ui-card>
  </PageView>
</template>
