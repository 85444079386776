<template>
  <div class="emobg-background-color-ground-lightest emobg-color-ink-light mt-1 p-3 emobg-border-radius-small">
    <ui-label label="Text formatting help:" />
    <br>
    # Title<br>
    ## Subtitle<br>
    *italic*<br>
    **bold**<br>
    * bullet point<br>
    ![image-alt-text](image-url)<br>
    [link-text](link-url)<br>
  </div>
</template>

<script>
export default {
  name: 'MarkdownInformation',
};
</script>
