<script>
export default {
  name: 'LabelWithTooltipComponent',
  props: {
    label: {
      type: String,
      required: true,
    },
    tooltip: {
      type: String,
      required: true,
    },
  },
};
</script>

<template>
  <!-- Click prevent to avoid re-triggering clicks in complex components like MuiSelect -->
  <div>
    <p class="emobg-body-medium emobg-font-weight-semibold d-flex align-items-center mb-1 px-1">
      {{ label }}
      <ui-tooltip
        :tooltip="tooltip"
        class="d-inline-block ml-1"
      >
        <ui-icon
          :icon="ICONS.infoFull"
          :color="GRAYSCALE.inkLight"
          :size="ICONS_SIZES.small"
        />
      </ui-tooltip>
    </p>
    <slot />
  </div>
</template>
