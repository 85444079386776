<script>
export default {
  name: 'GenericModalFooterComponent',
};
</script>

<template>
  <div
    class="
          GenericModalFooterComponent
          d-flex flex-wrap align-items-center justify-content-end
          emobg-border-color-ground-light p-3
          emobg-background-color-ground-lightest
        "
  >
    <slot />
  </div>
</template>
