export const CARSHARING_PERMISSIONS = {
  viewCarsharingBookings: 'view_carsharing_bookings',
  viewCarsharingVehicles: 'view_carsharing_vehicles',
  viewCsVehicleInteractionsLock: 'view_cs_vehicle_interactions_lock',
  viewCsVehicleInteractionsLockMaster: 'view_cs_vehicle_interactions_lock_master',
  viewCsVehicleInteractionsMobilise: 'view_cs_vehicle_interactions_mobilise',
  viewCarsharingDamages: 'view_carsharing_damages',
  viewCarsharingLocations: 'view_carsharing_locations',
  viewCarsharingAvailability: 'view_carsharing_availability',
  viewCsVehicleSetup: 'view_cs_vehicle_setup',
  viewCsVehicleComments: 'view_cs_vehicle_comments',
  viewCsVehicleLogs: 'view_cs_vehicle_logs',
  viewCsVehicleInteractions: 'view_cs_vehicle_interactions',
  viewSettingsFleetHardware: 'view_settings_fleet_hardware',
  viewCarsharingPlanningLinks: 'view_carsharing_planning_links',
  viewCsVehicleCurrentLocation: 'view_cs_vehicle_current_location',
  allowNonConnectedVehicles: 'allow_non_connected_vehicles',
  allowSecondaryFuelCard: 'allow_secondary_fuelcard',
};

