import { LOG_TYPE, getImageName, logger } from '@emobg/web-utils';
import xlsx from 'xlsx';
import get from 'lodash/get';
import map from 'lodash/map';
import slice from 'lodash/slice';
import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';
import head from 'lodash/head';
import trim from 'lodash/trim';

// TODO: use these utils in import devices - carrental
// https://europcarmobility.atlassian.net/browse/SBCCC-1151
export const getSheetFromEvent = event => {
  const result = get(event, 'target.result');
  if (!result) {
    logger.message('Invalid event', LOG_TYPE.error);
  }
  const data = new Uint8Array(result);
  const workbook = xlsx.read(data, { type: 'array' });
  const sheet = workbook.Sheets[head(workbook.SheetNames)];
  return xlsx.utils.sheet_to_json(sheet, { header: 1, blankrows: false });
};

export const getXlsData = (file, fileHeaders) => map(slice(file, 1), row => reduce(row, (acc, value, key) => {
  const headerName = fileHeaders[key];
  // This is to avoid adding data random in the xls file.
  if (headerName) {
    acc[headerName] = trim(value);
    return acc;
  }
  return {};
}, {})).filter(data => !isEmpty(data));

export function isPdf(imageSrc) {
  const imageName = getImageName(imageSrc);
  return /\.pdf/i.test(imageName);
}

/**
 * Downloads a file
 * @param file { name, url }
 */
export function downloadFile({ name = 'file', url }) {
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  link.download = name;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
