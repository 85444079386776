var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "FallbackComponent" },
    [
      _vm.showFallback
        ? _c("span", [_vm._v(" " + _vm._s(_vm.message) + " ")])
        : _c(
            _vm.component,
            _vm._b({ tag: "Component" }, "Component", _vm.props, false)
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }