var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "DocumentThumbnail", on: { click: _vm.onClickImage } },
    [
      _c(
        "div",
        {
          staticClass:
            "emobg-background-color-white emobg-border-color-ground emobg-border-1 emobg-border-radius-small d-flex flex-column emobg-background-color-white justify-content-center align-items-center cursor-pointer",
          staticStyle: { width: "62px", height: "42px" },
        },
        [
          _vm.isPdf
            ? _c("ui-icon", {
                attrs: { icon: _vm.ICONS.filePdf, size: _vm.ICONS_SIZES.large },
              })
            : _c("div", { staticClass: "w-100 h-100", style: _vm.imageStyle }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }