<script>
import differenceBy from 'lodash/differenceBy';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import set from 'lodash/set';
import {
  getCurrentInstance, onMounted, ref, watch,
} from 'vue';
import { MuiPopper } from '@emobg/motion-ui/v1';
import { useGoogleMaps } from './composable/GoogleMap.composable';
import { AVAILABLE_ZONE_TYPES, DEFAULT_ZONE_OPTIONS, GOOGLE_ZONE_TO_DATA } from './composable/GoogleMap.const';

export default {
  name: 'GoogleMapComponent',
  components: {
    MuiPopper,
  },
  props: {
    markers: {
      type: Array,
      default: () => [],
    },
    zones: {
      type: Array,
      default: () => [],
    },
    zoneTypes: {
      type: Array,
      default: () => AVAILABLE_ZONE_TYPES,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:zone', 'create:zone', 'delete:zone'],
  setup(props, context) {
    const isDeleting = ref(false);
    const mapElement = ref(null);
    const vmInstance = getCurrentInstance().proxy;
    const {
      createOrUpdateMarkers,
      createOrUpdateZones,
      deleteMarkers,
      deleteZones,
      drawingModes,
      fitMap,
      initDrawingManager,
      initMap,
      removeDrawingManager,
      setDrawingMode,
      setZoneAppearanceOptions,
      setZonesEditable,
    } = useGoogleMaps({
      googleInstance: vmInstance.$GoogleMaps.getInstance(),
      zoneListeners: {
        polygon: {
          set_at: zone => context.emit('update:zone', GOOGLE_ZONE_TO_DATA(zone)),
          insert_at: zone => context.emit('update:zone', GOOGLE_ZONE_TO_DATA(zone)),
        },
        circle: {
          radius_changed: zone => context.emit('update:zone', GOOGLE_ZONE_TO_DATA(zone)),
        },
        rectangle: {
          bounds_changed: zone => context.emit('update:zone', GOOGLE_ZONE_TO_DATA(zone)),
        },
      },
      onMapLoaded: () => {
        createOrUpdateMarkers(props.markers);
        createOrUpdateZones(props.zones);

        fitMap();

        if (props.editable) {
          initDrawingManager({
            drawingControlOptions: {
              drawingModes: props.zoneTypes,
            },
          });
          setZonesEditable();
        }
      },
      onZoneCreated: event => {
        setDrawingMode(null);
        set(event, 'overlay.type', event.type);
        context.emit(
          'create:zone',
          GOOGLE_ZONE_TO_DATA(event.overlay),
        );
      },
      onZoneClick: zone => {
        if (isDeleting.value) {
          context.emit('delete:zone', zone.id);
        }
      },
      onDrawingModeChange: () => {
        isDeleting.value = false;
      },
    });

    watch(
      [() => props.markers, () => props.zones],
      ([newMarkers, newZones], [oldMarkers, oldZones]) => {
        if (isEqual(newMarkers, oldMarkers) && isEqual(newZones, oldZones)) {
          return;
        }
        const differentMarkers = differenceBy(newMarkers, oldMarkers, 'id');
        const differentMarkersIds = map(differentMarkers, marker => marker.id);
        deleteMarkers(differentMarkersIds);
        createOrUpdateMarkers(newMarkers);

        const differentZones = differenceBy(oldZones, newZones, 'id');
        const differentZonesIds = map(differentZones, zone => zone.id);
        deleteZones(differentZonesIds);
        createOrUpdateZones(
          props.editable
            ? map(newZones, newZone => ({ editable: get(newZone, 'isEditable', true), ...newZone }))
            : newZones,
        );

        if (!isDeleting.value) {
          fitMap();
        }
      },
      { deep: true },
    );

    watch(
      () => props.editable,
      newValue => {
        if (!newValue) {
          removeDrawingManager();
          setZonesEditable(false);
          return;
        }

        initDrawingManager({
          drawingControlOptions: {
            drawingModes: props.zoneTypes,
          },
        });
      },
    );

    watch(
      () => props.zoneTypes,
      (newValue, oldValue) => {
        if (!isEqual(newValue, oldValue)) {
          initDrawingManager({
            drawingControlOptions: {
              drawingModes: props.zoneTypes,
            },
          });
        }
      },
    );

    onMounted(() => {
      initMap(mapElement.value, {
        mapTypeControl: false,
      });
    });

    return {
      drawingModes,
      isDeleting,
      initDrawingManager,
      mapElement,
      setDrawingMode,
      setZoneAppearanceOptions,
      setZonesEditable,
    };
  },
  watch: {
    isDeleting(value) {
      if (!value) {
        this.setZoneAppearanceOptions(DEFAULT_ZONE_OPTIONS);
        this.setZonesEditable();
      }
    },
  },
  methods: {
    setDeleteMode() {
      this.setDrawingMode(null);
      this.isDeleting = true;
      this.setZoneAppearanceOptions({
        fillColor: 'red',
        fillOpacity: 0.3,
        strokeColor: 'red',
        strokeOpacity: 0.3,
        strokeWidth: 1,
      });
      this.setZonesEditable(false);
    },
  },
};
</script>

<template>
  <div class="GoogleMapComponent emobg-background-color-ground position-relative">
    <div
      ref="mapElement"
      class="GoogleMapComponent__map"
      style="min-height: 342px;"
    />

    <div
      v-if="editable"
      :style="{ left: `${(24 * (drawingModes.length + 1)) + 10}px` }"
      class="GoogleMapComponent__edit-actions position-absolute"
    >
      <MuiPopper
        :show="isDeleting"
        :arrow-class="`emobg-background-color-secondary`"
        :options="{
          placement: PLACEMENTS.top,
          modifiers: [{
            name: 'offset',
            options: {
              offset: [0, 14],
            },
          }]}"
        arrow
        popper-class="fade"
      >
        <template #popper>
          <div
            class="v1-MuiTooltip__tooltip p-2 emobg-font-weight-light emobg-border-radius-medium emobg-background-color-secondary emobg-color-white"
            style="white-space: nowrap;"
          >
            Click on a zone to delete it
          </div>
        </template>
        <ui-button
          :key="isDeleting"
          :face="FACES.outline"
          :color="isDeleting ? GRAYSCALE.ink : GRAYSCALE.inkLight"
          :size="SIZES.small"
          square
          :class="['GoogleMapComponent__edit-button', {'emobg-font-weight-semibold': isDeleting}]"
          @clickbutton="setDeleteMode"
        >
          <ui-icon :icon="ICONS.trash" />
        </ui-button>
      </MuiPopper>
    </div>
  </div>
</template>

<style lang="scss">
.GoogleMapComponent {
  &__edit-actions {
    top: 5px;
  }
  // Google map button appearance
  &__edit-button {
    .Ui-Button {
      width: 24px !important;
      min-width: initial !important;
      height: 24px !important;
      min-height: initial !important;
      padding: 0;
      background: rgb(255, 255, 255);
      border: 0 !important;
      border-radius: 2px !important;
      box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);

      &:hover {
        background-color: #ebebeb;
      }
    }
  }
}
</style>
