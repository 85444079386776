import isNil from 'lodash/isNil';
import reduce from 'lodash/reduce';
import result from 'lodash/result';
import { DELAY } from '@emobg/web-utils';

export const createOperatorChildrenFilter = ({
  ALGOLIA_INDEXES,
  activeOperatorId,
  activeOperatorUuid,
  attribute = 'cs_operator_fk',
  index,
  isActiveOperatorChild,
  operatorChildren,
  withChildren = true,
} = {}) => {
  const childOperatorIndex = isActiveOperatorChild && index;
  let filter = '';

  switch (childOperatorIndex) {
    case ALGOLIA_INDEXES.users:
      return `employee.company.cs_operator_id:${activeOperatorId} OR employee.company.cs_operator_uuid:${activeOperatorUuid}`;
    case ALGOLIA_INDEXES.vehicles:
      return `dedicated_cs_fk:${activeOperatorId}`;
    default:
      if (isNil(activeOperatorId)) {
        return `${attribute}:undefined`;
      }

      filter = `${attribute}:${activeOperatorId}`;
      if (withChildren) {
        if (isNil(operatorChildren)) {
          return filter;
        }
        filter = reduce(
          operatorChildren,
          (filterString, childOperatorId) => `${filterString} OR ${attribute}:${childOperatorId}`, filter,
        );
      }
      return filter;
  }
};

/**
 * Workaround util for algolia table
 * Restarts algolia search store allowing the table to be updated
 * without page reload
 * @param reference vue.js $ref.refName
 * @param delay [DELAY.extraLong] period after which refresh takes place
 * @param callback Function to execute after refreshing
 */
export function refreshAlgoliaStore(reference, delay = DELAY.extraLong, callback = () => {}) {
  setTimeout(() => {
    result(reference, 'mainSearchStore.clearCache');
    result(reference, 'mainSearchStore.refresh');
    callback();
  }, delay);
}

/**
 *
 * @param reference vue.js $ref.refName
 * @returns {number} Total results of a store
 */
export function getStoreTotalResults(reference) {
  return result(reference, 'mainSearchStore.totalResults');
}

/**
 *
 * @param reference vue.js $ref.refName
 * @param {string} attributeName
 * @returns {object} Values of facet
 */
export function getFacetValues(reference, attributeName) {
  const searchStore = result(reference, 'mainSearchStore');
  return searchStore.getFacetValues(attributeName);
}

/**
 *
 * @param {string} domain
 * @returns {string} Label for no results on tables
 */
export function getNoResultsLabel(domain = 'elements') {
  return `No ${domain} to show. Create the first one, or change the filters if you are searching for it`;
}
