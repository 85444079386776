<script>
import get from 'lodash/get';
import { GRAYSCALE } from '@emobg/vue-base';

export default {
  name: 'SelectItemBadge',
  props: {
    // Object provided by MuiSelect "item" slot
    item: {
      type: Object,
      required: true,
    },
    mapColors: {
      type: Object,
      default: () => ({}),
    },
    defaultColor: {
      type: String,
      default: GRAYSCALE.ground,
    },
    pathValue: {
      type: String,
      default: 'value',
    },
  },
  methods: {
    get,
  },
};
</script>

<template>
  <div class="SelectItemBadge w-100 d-flex align-items-center justify-content-between">
    <span class="emobg-font-weight-semibold">{{ get(item, 'label') }}</span>
    <ui-badge
      :color="get(mapColors, get(item, pathValue), defaultColor)"
      circle
    />
  </div>
</template>
