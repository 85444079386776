var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "d-flex mx-n1 my-3 emobg-border-bottom-1 emobg-border-color-ground-lighter",
    },
    [
      _c(
        "p",
        {
          staticClass:
            "WeekSchedule__DayIntervals__day d-flex align-items-center p-2",
        },
        [_vm._v(" " + _vm._s(_vm.name) + " ")]
      ),
      _c(
        "ul",
        { staticClass: "intervals p-2" },
        _vm._l(_vm.intervals, function (interval) {
          return _c(
            "TimeInterval",
            _vm._g(
              {
                key: interval,
                class: {
                  "emobg-color-danger":
                    _vm.isIntervalOutOfMinDuration(interval),
                },
                attrs: { interval: interval, "is-disabled": _vm.isDisabled },
              },
              _vm.$listeners
            )
          )
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }