var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "GoogleMapComponent bg-color-ground" }, [
    _c(
      "div",
      { ref: `gMap${_vm.mapId}`, staticClass: "GoogleMapComponent__holder" },
      [_vm._v(" `gMap${mapId}` ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }