<script>
import get from 'lodash/get';
import split from 'lodash/split';
import includes from 'lodash/includes';
export default {
  name: 'DragFile',
  created() {
    this.extensions = '.xlsx,.xls,.csv';
  },
  methods: {
    openFileSelector() {
      this.$refs.input.click();
    },
    emitFiles(file) {
      this.$emit('file', file);
    },
    onInputChange(event) {
      // This is for handling cancel button on file selector.
      if (get(event, 'target.files.length')) {
        this.emitFiles(event.target.files[0]);
      }
      // This is to clear the value of the input so that the user can upload again a file with the same name as previous uploaded.
      // If we dont do this as the input has @change and the file has the same name as previous one this method is never executed.
      event.target.value = '';
    },
    onDrop(event) {
      const file = event.dataTransfer.files[0];
      const fileExtension = split(file.name, '.').pop();
      this.$emit('dragOver', false);
      // extensions is an array like ['.xls'. '.csv'] and fileExtension is 'csv'
      // TODO: https://europcarmobility.atlassian.net/browse/CF-191
      if (includes(this.extensions, fileExtension)) {
        this.emitFiles(file);
      } else {
        this.$emit('wrongExtension');
      }
    },
  },
};
</script>

<template>
  <div
    class="DragFile"
    data-test-id="drag_file-component"
    @dragover.prevent="$emit('dragOver', true)"
    @dragleave="$emit('dragOver', false)"
    @dragend="$emit('dragOver', false)"
  >
    <input
      ref="input"
      type="file"
      data-test-id="file-input"
      :accept="extensions"
      class="invisible position-absolute"
      @change="onInputChange"
      @click.stop
    >
    <div
      data-test-id="drop_area"
      class="DragFile__drop-area position-absolute"
      @drop.prevent="onDrop"
      @dragenter.prevent
    />
  </div>
</template>
