var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "RefinementList p-3 emobg-border-bottom-1 emobg-border-color-ground",
    },
    [
      _c("div", { staticClass: "d-flex justify-content-between" }, [
        _c("span", {
          staticClass: "font-weight-bold",
          domProps: { innerHTML: _vm._s(_vm.title) },
        }),
        _c(
          "span",
          {
            staticClass: "cursor-pointer emobg-color-ink-light",
            on: {
              click: function ($event) {
                _vm.isFilterOpened = !_vm.isFilterOpened
              },
            },
          },
          [_vm._v(" " + _vm._s(_vm.isFilterOpened ? "Hide" : "Show") + " ")]
        ),
      ]),
      _vm.isFilterOpened
        ? _c(
            "MuiCollapse",
            { attrs: { open: _vm.isFilterOpened } },
            [
              _c("MuiDatePicker", {
                staticClass: "w-100 mt-3",
                attrs: {
                  date: _vm.from,
                  "date-format-key": _vm.DATE_FORMAT_KEYS.defaultExtended,
                  label: "From",
                  "data-test-id": "from-input",
                },
                on: { "update:date": _vm.setFrom },
              }),
              _c("MuiDatePicker", {
                staticClass: "w-100 mt-3",
                attrs: {
                  date: _vm.to,
                  "date-format-key": _vm.DATE_FORMAT_KEYS.defaultExtended,
                  label: "To",
                  "data-test-id": "to-input",
                },
                on: { "update:date": _vm.setTo },
              }),
              _c(
                "div",
                {
                  staticClass:
                    "emobg-font-weight-semibold emobg-font-small emobg-color-primary cursor-pointer py-2",
                  on: { click: _vm.clearDates },
                },
                [_vm._v(" Clear dates ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }