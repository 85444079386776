import upperFirst from 'lodash/upperFirst';
import size from 'lodash/size';
import map from 'lodash/map';
import isNil from 'lodash/isNil';
import floor from 'lodash/floor';
import each from 'lodash/each';
import moment from 'moment';
import {
  DATE_FORMAT,
  formatUtc,
  reformatDateTime,
} from '@emobg/web-utils';
import { ERROR_KEYS } from '@/constants/errorKeys';
export const templateGenerators = new Map();
export const buildBookingLink = booking => (booking.id && booking.uuid
  ? `
  <a target="_blank" class="emobg-link-primary emobg-body-2" href="/carsharing/bookings/${booking.uuid}">#${booking.id}</a>
`
  : '');
export const buildUserLink = ({ id = 0, uuid = '', user = 'user' }) => (uuid
  ? `
  <a target="_blank" class="emobg-link-primary emobg-body-2" href="/user-company/users/${uuid}/account">
  ${upperFirst(user)} #${id}
  </a>
`
  : '');

export const elementBookingsOverlapTemplate = ({
  bookings_with_conflict: bookingsWithConflict,
  translateFn,
}) => {
  const overlaps = translateFn('Common.Comparisons.overlaps_with');
  return size(bookingsWithConflict)
    ? `${upperFirst(overlaps)}: ${map(bookingsWithConflict, overlapped => buildBookingLink(overlapped))}`
    : '';
};
export const bookingDurationConstraintTemplate = ({
  bookingMinutes,
  maximumMinutes,
  minimumMinutes,
  translateFn,
}) => {
  const bookingConstraint = translateFn(`Common.Errors.${isNil(maximumMinutes) ? 'booking_too_short' : 'booking_too_long'}`);
  const limitation = translateFn(`Common.Comparisons.${isNil(maximumMinutes) ? 'minimum' : 'maximum'}`);
  const minuteShort = translateFn('Common.Time.minute_short');
  const hourShort = translateFn('Common.Time.hour_short');
  const dayShort = translateFn('Common.Time.day_short');

  const durationConstraint = moment.duration({ minutes: maximumMinutes || minimumMinutes });
  const floorDayShort = durationConstraint.asDays() >= 1 ? `${floor(durationConstraint.asDays())}${dayShort}` : '';
  const durationConstraintFormatted = `${floorDayShort} ${durationConstraint.hours()}${hourShort} ${durationConstraint.minutes()}${minuteShort}`;

  const bookingDuration = moment.duration({ minutes: bookingMinutes });
  const floorBookingDuration = bookingDuration.asDays() >= 1 ? `${floor(bookingDuration.asDays())}${dayShort} ` : '';
  const bookingDurationFormatted = `${floorBookingDuration} ${bookingDuration.hours()}${hourShort} ${bookingDuration.minutes()}${minuteShort}`;

  return `${upperFirst(bookingConstraint)} (${bookingDurationFormatted}). ${upperFirst(limitation)}: ${durationConstraintFormatted}`;
};

export const getOverbookingByTranslationKey = translationKey => ({
  data: {
    booking_id: id,
    bookings_with_conflict: bookingsWithConflict,
  } = {},
  translateFn,
}) => elementBookingsOverlapTemplate({
  element: translateFn(translationKey),
  id,
  bookings_with_conflict: bookingsWithConflict,
  translateFn,
});

templateGenerators.set(ERROR_KEYS.carsharing.overbooked, getOverbookingByTranslationKey('Common.Business.vehicle'));

templateGenerators.set(ERROR_KEYS.carsharing.overlap, getOverbookingByTranslationKey('Common.Business.user'));

export const tariffNotFound = errorObj => {
  const {
    data,
    translateFn,
  } = errorObj;
  const {
    booking_use_case_booking_type: bookingType,
    booking_use_case_fleet: fleetCase,
    booking_use_case_profile: profile,
    user_id: id,
    user_uuid: uuid,
  } = data || {};
  const user = translateFn('Common.Business.user');
  const noTariffForUseCase = translateFn('Common.Errors.no_tariff_use_case');
  return `${buildUserLink({ id, uuid, booking: user })} ${noTariffForUseCase}:
          ${profile} - ${bookingType} - ${fleetCase}`;
};
templateGenerators.set(ERROR_KEYS.carsharing.tariffNotFound, tariffNotFound);

export const userNotActivated = errorObj => {
  const {
    data,
    translateFn,
  } = errorObj;
  const {
    user_id: id,
    user_uuid: uuid,
    user_status: userStatus,
  } = data || {};
  const user = translateFn('Common.Business.user');
  const isNotActivated = translateFn('Common.Errors.no_activated');
  const status = translateFn('Common.Business.status');
  return `${buildUserLink({ id, uuid, booking: user })} ${isNotActivated}. ${upperFirst(status)}:
          ${userStatus}`;
};
templateGenerators.set(ERROR_KEYS.carsharing.userNotActivated, userNotActivated);

export const bookingStartInvalid = errorObj => {
  const {
    data,
    timezone,
    translateFn,
  } = errorObj;
  const {
    booking_start: bookingStart,
    interval_time_unit: timeUnit,
  } = data || {};
  const bookingStartDate = translateFn('Common.Errors.booking_start_date');
  const doesNotSatisfyTimeUnit = translateFn('Common.Errors.no_satisfy_time_unit');
  const minuteShort = translateFn('Common.Time.minute_short');
  return `${upperFirst(bookingStartDate)} (${formatUtc(bookingStart, DATE_FORMAT.defaultExtended, timezone)}) ${doesNotSatisfyTimeUnit}:
          ${timeUnit}${minuteShort}`;
};
templateGenerators.set(ERROR_KEYS.carsharing.startInvalid, bookingStartInvalid);

export const bookingEndInvalid = errorObj => {
  const {
    data,
    timezone,
    translateFn,
  } = errorObj;
  const {
    booking_end: bookingEnd,
    interval_time_unit: timeUnit,
  } = data || {};
  const bookingEndDate = translateFn('Common.Errors.booking_end_date');
  const doesNotSatisfyTimeUnit = translateFn('Common.Errors.no_satisfy_time_unit');
  const minuteShort = translateFn('Common.Time.minute_short');
  return `${upperFirst(bookingEndDate)} (${formatUtc(bookingEnd, DATE_FORMAT.defaultExtended, timezone)}) ${doesNotSatisfyTimeUnit}:
          ${timeUnit}${minuteShort}`;
};
templateGenerators.set(ERROR_KEYS.carsharing.endInvalid, bookingEndInvalid);

export const bookingMinimumDuration = errorObj => {
  const {
    data,
    translateFn,
  } = errorObj;
  const {
    min_allowed_minutes: minimumMinutes,
    booking_minutes: bookingMinutes,
  } = data || {};

  return bookingDurationConstraintTemplate({
    bookingMinutes,
    minimumMinutes,
    translateFn,
  });
};
templateGenerators.set(ERROR_KEYS.carsharing.minimumDuration, bookingMinimumDuration);

export const bookingMaximumDuration = errorObj => {
  const {
    data,
    translateFn,
  } = errorObj;
  const {
    max_allowed_minutes: maximumMinutes,
    booking_minutes: bookingMinutes,
  } = data || {};
  return bookingDurationConstraintTemplate({
    bookingMinutes,
    maximumMinutes,
    translateFn,
  });
};
templateGenerators.set(ERROR_KEYS.carsharing.maximumDuration, bookingMaximumDuration);
export const bookingMinutesBeforeNow = errorObj => {
  const {
    data,
    translateFn,
  } = errorObj;
  const {
    max_allowed_minutes_before_now: maximumMinutes,
    booking_minutes_before_now: minutesBeforeNow,
  } = data || {};
  const translation = translateFn('Common.Errors.booking_minutes_before_now', { maximumMinutes, minutesBeforeNow });

  return `${translation}.`;
};
templateGenerators.set(ERROR_KEYS.carsharing.minutesBeforeNow, bookingMinutesBeforeNow);

export const bookingNotInAnticipationTime = errorObj => {
  const {
    data,
    translateFn,
  } = errorObj;
  const { limit_date: limitDate } = data || {};
  const formattedDateTime = reformatDateTime(limitDate, DATE_FORMAT.defaultExtended);
  const translation = translateFn('Common.Errors.booking_not_in_anticipation_time', { limitDate: formattedDateTime });
  return `${translation}.`;
};
templateGenerators.set(ERROR_KEYS.carsharing.notInAnticipationTime, bookingNotInAnticipationTime);

export const userStatusErrors = errorObj => {
  const {
    data,
    translateFn,
  } = errorObj;
  const { keys } = data || {};
  let template = '';
  each(keys, errorKey => {
    const translation = translateFn(`Common.Errors[${errorKey}]`);
    template = `<p class="mb-2">${translation}</p>${template}`;
  });
  return template;
};
templateGenerators.set(ERROR_KEYS.crm.userStatusErrors, userStatusErrors);
