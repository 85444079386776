import { MENU_OPERATORS } from '@/constants/menuOperators';

import carrental from './CarrentalRouterMap';
import services from './ServicesRouterMap';

import { CARRENTAL_PERMISSIONS } from '../const/permissions';

const VehiclesSummaryView = () => import(/* webpackChunkName: "carrental-vehicle" */'../Vehicles/Summary/VehiclesSummaryView.vue');
const SummaryInfoView = () => import(/* webpackChunkName: "carrental-vehicle" */'../Vehicles/Summary/Info/SummaryInfoView.vue');
const StationHistoryView = () => import(/* webpackChunkName: "carrental-vehicle" */'../Vehicles/Summary/StationHistory/SummaryStationHistoryView.vue');
const SummaryEuropcarView = () => import(/* webpackChunkName: "carrental-vehicle" */'../Vehicles/Summary/Europcar/SummaryEuropcarView.vue');
const SummaryMovementsView = () => import(/* webpackChunkName: "carrental-vehicle" */'../Vehicles/Summary/Movements/SummaryMovementsView.vue');
const SummaryTelemetryView = () => import(/* webpackChunkName: "carrental-vehicle" */'../Vehicles/Summary/Telemetry/SummaryTelemetryView.vue');
const SummaryTripsView = () => import(/* webpackChunkName: "carrental-vehicle" */'../Vehicles/Summary/Trips/SummaryTripsView.vue');

const AlertsView = () => import(/* webpackChunkName: "carrental-alerts" */'../Alerts/AlertsView.vue');
const AlertsDetailsView = () => import(/* webpackChunkName: "carrental-alerts" */'../Alerts/Details/AlertsDetailsView.vue');

const CarrentalView = () => import(/* webpackChunkName: "carrental" */'../CarrentalView.vue');

const StationsView = () => import(/* webpackChunkName: "carrental-stations" */'../Stations/StationsView.vue');
const StationDetailsView = () => import(/* webpackChunkName: "carrental-stations" */'../Stations/Details/StationDetailsView.vue');

const FleetCheckView = () => import(/* webpackChunkName: "carrental-fleet-check" */'../FleetCheck/FleetCheckView.vue');
const NewFleetCheckView = () => import(/* webpackChunkName: "carrental-fleet-check" */'../FleetCheck/New/NewFleetCheckView.vue');

const AlertTriggersView = () => import(/* webpackChunkName: "configurable-alert" */'../AlertsTrigger/List/AlertTriggersView.vue');
const AddAlertTriggerView = () => import(/* webpackChunkName: "configurable-alert" */'../AlertsTrigger/Trigger/AddAlertTriggerView.vue');

const VehiclesView = () => import(/* webpackChunkName: "carrental" */'../Vehicles/VehiclesView.vue');
const DevicesView = () => import(/* webpackChunkName: "carrental" */'../Devices/DevicesView.vue');
const AgentsView = () => import(/* webpackChunkName: "carrental-agents" */'../Agents/AgentsView.vue');

const TripDetailsView = () => import(/* webpackChunkName: "trip" */'../Trip/Details/TripDetailsView.vue');

const SummaryReportView = () => import(/* webpackChunkName: "reports" */'../Reports/SummaryReportView.vue');

export default [
  {
    path: 'carrental',
    name: carrental.index,
    component: CarrentalView,
    redirect: { name: carrental.vehicles.index },
    meta: {
      label: 'Operations',
      operators: [MENU_OPERATORS.isCarrental],
    },
    children: [
      {
        path: 'vehicles',
        name: carrental.vehicles.index,
        component: VehiclesView,
        meta: {
          label: 'Vehicles',
          permissions: [CARRENTAL_PERMISSIONS.vehicleViewList],
        },
      },
      {
        path: 'vehicles/:vehicleVin/summary',
        name: carrental.vehicles.summary.index,
        component: VehiclesSummaryView,
        redirect: { name: carrental.vehicles.summary.info },
        meta: {
          label: 'Vehicle detail',
        },
        children: [
          {
            path: 'info',
            name: carrental.vehicles.summary.info,
            component: SummaryInfoView,
            meta: {
              label: 'Information',
              permissions: [CARRENTAL_PERMISSIONS.vehicleViewInformationTab],
            },
          },
          {
            path: 'station-history',
            name: carrental.vehicles.summary.stationHistory,
            component: StationHistoryView,
            meta: {
              label: 'Station Geofence',
              permissions: [CARRENTAL_PERMISSIONS.vehicleViewInformationTab],
            },
          },
          {
            path: 'greenway',
            name: carrental.vehicles.summary.greenway,
            component: SummaryEuropcarView,
            meta: {
              label: 'Greenway status',
              permissions: [CARRENTAL_PERMISSIONS.vehicleViewGreenwayTab],
            },
          },
          {
            path: 'alerts',
            name: carrental.vehicles.summary.alerts,
            component: AlertsView,
            props: { isVehicleSummaryTab: true },
            meta: {
              label: 'Alerts',
              permissions: [CARRENTAL_PERMISSIONS.alertsViewList, CARRENTAL_PERMISSIONS.vehicleViewAlertsTab],
            },
          },
          {
            path: 'movements',
            name: carrental.vehicles.summary.movements,
            component: SummaryMovementsView,
            meta: {
              label: 'Movements',
              permissions: [CARRENTAL_PERMISSIONS.movementViewList, CARRENTAL_PERMISSIONS.vehicleViewMovementsTab],
            },
          },
          {
            path: 'telemetry',
            name: carrental.vehicles.summary.telemetry,
            component: SummaryTelemetryView,
            meta: {
              label: 'Telemetry',
              permissions: [CARRENTAL_PERMISSIONS.vehicleViewTelemetryTab],
            },
          },
          {
            path: 'trips',
            name: carrental.vehicles.summary.trips,
            component: SummaryTripsView,
            meta: {
              label: 'Trips',
              permissions: [CARRENTAL_PERMISSIONS.vehicleViewTripsTab],
            },
          },
        ],
      },
      {
        path: 'vehicles/:vehicleVin/summary/trips/trip-summary',
        name: carrental.vehicles.tripsSummary,
        component: TripDetailsView,
        meta: {
          label: 'Trip summary',
        },
      },
      {
        path: 'alerts',
        name: carrental.vehicles.alerts.index,
        component: AlertsView,
        meta: {
          label: 'Alerts',
          permissions: [CARRENTAL_PERMISSIONS.alertsViewList],
        },
      },
      {
        path: 'alerts/:vehicleVin/:alertUuid/details',
        name: carrental.vehicles.alerts.detail,
        component: AlertsDetailsView,
        meta: {
          label: 'Alert detail',
          permissions: [CARRENTAL_PERMISSIONS.alertsViewDetails],
        },
      },
      {
        path: 'stations',
        name: carrental.vehicles.stations,
        component: StationsView,
        meta: {
          label: 'Stations',
          permissions: [CARRENTAL_PERMISSIONS.stationViewList],
        },
      },
      {
        path: 'stations/station/:stationUuid',
        name: carrental.vehicles.stations.details,
        component: StationDetailsView,
        meta: {
          label: 'Station details',
          permissions: [CARRENTAL_PERMISSIONS.stationViewZones],
        },
      },
      {
        path: 'fleet-check-list',
        name: carrental.vehicles.fleetCheck.index,
        component: FleetCheckView,
        meta: {
          label: 'Automated fleet check',
          permissions: [CARRENTAL_PERMISSIONS.stationFleetCheck],
        },
      },
      {
        path: 'fleet-check-list/new-fleet-check',
        name: carrental.vehicles.fleetCheck.new,
        component: NewFleetCheckView,
        meta: {
          label: 'New fleet check',
          permissions: [CARRENTAL_PERMISSIONS.stationFleetCheck],
        },
      },
      {
        path: 'reports/summary-report',
        name: carrental.summaryReport,
        component: SummaryReportView,
        meta: {
          label: 'Summary report',
        },
      },
    ],
  },
  {
    path: 'services',
    name: services.index,
    component: CarrentalView,
    redirect: { name: services.devices },
    meta: {
      label: 'Service management',
    },
    children: [
      {
        path: 'configurable-alert/list',
        name: services.alertsTrigger.index,
        component: AlertTriggersView,
        meta: {
          label: 'Alert triggers',
          permissions: [CARRENTAL_PERMISSIONS.configurableAlertsViewList],
        },
      },
      {
        path: 'configurable-alert/list/add-trigger',
        name: services.alertsTrigger.new,
        component: AddAlertTriggerView,
        meta: {
          label: 'Add new alert trigger',
          permissions: [CARRENTAL_PERMISSIONS.configurableAlertsAddTrigger],
        },
      },
      {
        path: 'configurable-alert/list/:triggerUuid/details',
        name: services.alertsTrigger.details,
        component: AddAlertTriggerView,
        meta: {
          label: 'Alert trigger details',
        },
      },
      {
        path: 'devices',
        name: services.devices,
        component: DevicesView,
        meta: {
          label: 'Devices',
          permissions: [CARRENTAL_PERMISSIONS.deviceViewList],
        },
      },
      {
        path: 'agents',
        name: services.agents,
        component: AgentsView,
        meta: {
          label: 'Agents',
          permissions: [CARRENTAL_PERMISSIONS.agentsViewList],
        },
      },
    ],
  },
];

