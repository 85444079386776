import { mapMutations } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
const GlobalNotificationMixin = {
  methods: {
    ...mapMutations(DOMAINS_MODEL.app.messages.errors.index, {
      $throwError: 'add',
    }),
  },
};
export default { GlobalNotificationMixin };
export { GlobalNotificationMixin };
