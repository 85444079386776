import get from 'lodash/get';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { USER_SCOPES } from '@domains/CRM/Users/store/UsersModule';
import { COMPANY_SCOPES } from '@domains/CRM/Companies/store/CompanyModule';
import crm from './CRMRouterMap';
import { CRM_PERMISSIONS } from '../const/permissions';

const CRMView = () => import(/* webpackChunkName: "crm" */'../CRMView.vue');

const CsOperatorsView = () => import(/* webpackChunkName: "crm" */'../CsOperators/CsOperatorsView.vue');
const LegalDocumentsView = () => import(/* webpackChunkName: "crm" */'../CsOperators/LegalDocuments/LegalDocumentsView.vue');
const LegalDocumentsListView = () => import(/* webpackChunkName: "crm" */'../CsOperators/LegalDocuments/List/LegalDocumentsListView.vue');
const CsOperatorSettingsView = () => import(/* webpackChunkName: "crm" */'../CsOperators/Settings/CsOperatorSettingsView');
const ImportOperatorsView = () => import(/* webpackChunkName: "crm" */'../CsOperators/ImportOperators/ImportOperatorsView');

const CompaniesView = () => import(/* webpackChunkName: "crm-companies" */'../Companies/CompaniesView.vue');
const CompaniesListView = () => import(/* webpackChunkName: "crm-companies" */'../Companies/List/CompaniesListView.vue');
const CompanyDetailView = () => import(/* webpackChunkName: "crm-companies-detail" */'../Companies/Detail/CompanyDetailView.vue');
const CompanyAccountView = () => import(/* webpackChunkName: "crm-companies-detail" */'../Companies/Detail/Account/CompanyAccountView.vue');
const CompanyEmployeePolicy = () => import(/* webpackChunkName: "crm-companies-detail" */'../Companies/Detail/EmployeePolicy/CompanyEmployeePolicyView.vue');
const CompanyInvoicingView = () => import(/* webpackChunkName: "crm-companies-detail-invoicing" */'../Companies/Detail/Invoicing/CompanyInvoicingView.vue');
const CompanyInvoicesView = () => import(/* webpackChunkName: "crm-companies-detail-invoicing" */'../Companies/Detail/Invoicing/Invoices/CompanyInvoicesView.vue');
const CompanyPaymentsView = () => import(/* webpackChunkName: "crm-companies-detail-invoicing" */'../Companies/Detail/Invoicing/Payments/CompanyPaymentsView.vue');
const CompanyPaymentMethods = () => import(/* webpackChunkName: "crm-companies-detail-invoicing" */'../Companies/Detail/Invoicing/PaymentMethods/CompanyPaymentMethodsView.vue');
const CompanyTariffsView = () => import(/* webpackChunkName: "crm-companies-detail-tariffs" */'../Companies/Detail/Tariffs/CompanyTariffsView.vue');
const CompanyFleetView = () => import(/* webpackChunkName: "crm-companies-detail-fleet" */'../Companies/Detail/Fleet/CompanyFleetView.vue');
const CompanyBookingsView = () => import(/* webpackChunkName: "crm-companies-detail-bookings" */'../Companies/Detail/Bookings/CompanyBookingsView.vue');
const CompanyEmployeesView = () => import(/* webpackChunkName: "crm-companies-detail-employees" */'../Companies/Detail/Employees/CompanyEmployeesView.vue');
const CompanySettingsView = () => import(/* webpackChunkName: "crm-companies-detail-settings" */'../Companies/Detail/Settings/CompanySettingsView.vue');
const CompanySettingsPremiumView = () => import(/* webpackChunkName: "crm-companies-detail-settings" */'../Companies/Detail/Settings/Premium/CompanySettingsPremiumView.vue');
const CompanySettingsCostAllocationsView = () => import(/* webpackChunkName: "crm-companies-detail-settings" */'../Companies/Detail/Settings/CostAllocations/CompanyCostAllocationsView.vue');
const CompanySettingsCostAllocationsListView = () => import(/* webpackChunkName: "crm-companies-detail-settings" */'../Companies/Detail/Settings/CostAllocations/CompanyCostAllocationsListView.vue');
const CompanySettingsCostAllocationsCodesView = () => import(/* webpackChunkName: "crm-companies-detail-settings" */'../Companies/Detail/Settings/CostAllocations/CostAllocationCodesView/CompanySettingsCostAllocationCodesView.vue');
const CompanySettingsWhitelistedDomainsView = () => import(/* webpackChunkName: "crm-companies-detail-settings" */'../Companies/Detail/Settings/WhitelistedDomains/CompanySettingsWhitelistedDomainsView.vue');
const CompanySettingsMappingCategoriesView = () => import(/* webpackChunkName: "crm-companies-detail-settings" */'../Companies/Detail/Settings/MappingCategories/CompanySettingsMappingCategoriesView.vue');
const CompanySettingsHolidaysView = () => import(/* webpackChunkName: "crm-companies-detail-settings" */'../Companies/Detail/Settings/CompanyHolidays/CompanySettingsHolidaysView.vue');
const CompanyCommentsView = () => import(/* webpackChunkName: "crm-companies-detail-comments" */'../Companies/Detail/Comments/CompanyCommentsView.vue');
const CompanyContractsView = () => import(/* webpackChunkName: "crm-companies-detail-contracts" */'../Companies/Detail/Contracts/CompanyContractsView.vue');
const CompanyContractsListView = () => import(/* webpackChunkName: "crm-companies-detail-contracts" */'../../FleetInvoicing/Contracts/ContractsView.vue');
const CompanyContractDetailView = () => import(/* webpackChunkName: "crm-companies-detail-contracts" */'../../FleetInvoicing/Contracts/ContractDetailView.vue');
const CompanyContractSalesOrdersView = () => import(/* webpackChunkName: "crm-companies-detail-contracts" */'../../FleetInvoicing/SalesOrders/SalesOrdersDetailView.vue');
const CompanyCorporateInvoicingView = () => import(/* webpackChunkName: "crm-companies-detail-corporate-invoicing" */'../Companies/Detail/CorporateInvoicing/CompanyCorporateInvoicingView.vue');
const CompanyCorporateInvoicesListView = () => import(/* webpackChunkName: "crm-companies-detail-corporate-invoicing" */'../../FleetInvoicing/Invoices/InvoicesView.vue');
const CompanyCorporateInvoicesDetailView = () => import(/* webpackChunkName: "crm-companies-detail-corporate-invoicing" */'../../FleetInvoicing/Invoices/InvoiceDetail/InvoiceDetailView.vue');
const CompanyFleetSegmentsView = () => import(/* webpackChunkName: "crm-companies-detail-fleet-segments" */'../Companies/Detail/FleetSegments/CompanyFleetSegmentsView.vue');
const CompanyFleetSegmentsListView = () => import(/* webpackChunkName: "crm-companies-detail-fleet-segments" */'../Companies/Detail/FleetSegments/CompanyFleetSegmentsListView.vue');
const CompanyFleetSegmentsForm = () => import(/* webpackChunkName: "crm-companies-detail-fleet-segments" */'../Companies/Detail/FleetSegments/FleetSegmentForm/FleetSegmentForm.vue');
const CompanyCirclesView = () => import(/* webpackChunkName: "crm-companies-detail-circles" */'../Companies/Detail/Circles/CompanyCirclesView.vue');
const CompanyCirclesListView = () => import(/* webpackChunkName: "crm-companies-detail-circles" */'../Companies/Detail/Circles/CompanyCirclesListView.vue');
const CompanyCirclesForm = () => import(/* webpackChunkName: "crm-companies-detail-circles" */'../Companies/Detail/Circles/CreateEditCircles/CreateEditCircles.vue');

const PayoutsView = () => import(/* webpackChunkName: "crm" */'../Payouts/PayoutsView.vue');

const UsersView = () => import(/* webpackChunkName: "crm-users" */'../Users/UsersView.vue');
const UserDetailsView = () => import(/* webpackChunkName: "crm-users" */'../Users/Details/UserDetailsView');
const UserBookings = () => import(/* webpackChunkName: "crm-users-bookings" */'../Users/Details/Bookings/UserBookings.vue');
const UserAudits = () => import(/* webpackChunkName: "crm-users-audits" */'../Users/Details/Audits/UserAudits.vue');
const UserAuditList = () => import(/* webpackChunkName: "crm-users-audits" */'../Users/Details/Audits/UserAuditList.vue');
const UserNotifications = () => import(/* webpackChunkName: "crm-users-notifications" */'../Users/Details/Notifications/UserNotifications.vue');
const UserCases = () => import(/* webpackChunkName: "crm-users" */'../Users/Details/Cases/UserCases.vue');
const UserLogs = () => import(/* webpackChunkName: "crm-users" */'../Users/Details/Logs/UsersLogs.vue');
const UserInvoicing = () => import(/* webpackChunkName: "crm-users-invoices" */'../Users/Details/Invoicing/UserInvoicing.vue');
const UserPaymentsView = () => import(/* webpackChunkName: "crm-users-invoices" */'../Users/Details/Invoicing/UserPayments.vue');
const UserPaymentMethod = () => import(/* webpackChunkName: "crm-users-invoices" */'../Users/Details/Invoicing/UserPaymentMethods/UserPaymentMethods.vue');
const UserInvoicesView = () => import(/* webpackChunkName: "crm-users-invoices" */'../Users/Details/Invoicing/UserInvoices.vue');
const UserCommentsView = () => import(/* webpackChunkName: "crm-users-comments" */'../Users/Details/Comments/UserCommentsView.vue');
const UserPromotionsView = () => import(/* webpackChunkName: "crm-users-promotions" */'../Users/Details/Promotions/UserPromotions.vue');
const UserAccount = () => import(/* webpackChunkName: "crm-users" */'../Users/Details/Account/UserAccount.vue');
const UserSubscriptions = () => import(/* webpackChunkName: "crm-users" */'../Users/Details/Tariffs/UserSubscriptions.vue');
const UserTariffs = () => import(/* webpackChunkName: "crm-users" */'../Users/Details/Tariffs/UserTariffs.vue');
const UserCompanyTariffs = () => import(/* webpackChunkName: "crm-users" */'../Users/Details/Tariffs/UserCompanyTariffs.vue');
const UserCover = () => import(/* webpackChunkName: "crm-users" */'../Users/Details/Insurance/UserCover.vue');

const AgentsView = () => import(/* webpackChunkName: "crm" */'../Agents/AgentsView.vue');
const AgentsListView = () => import(/* webpackChunkName: "crm" */'../Agents/List/AgentsListView.vue');
const RolesView = () => import(/* webpackChunkName: "crm-roles" */'../Roles/RolesView.vue');
const RolesListView = () => import(/* webpackChunkName: "crm-roles" */'../Roles/List/RolesListView.vue');
const RoleDetailsView = () => import(/* webpackChunkName: "crm-roles" */'../Roles/Detail/RoleDetailsView.vue');

export default [
  {
    path: 'user-company',
    name: crm.index,
    redirect: { name: crm.companies.index },
    component: CRMView,
    meta: {
      label: 'CRM',
    },
    children: [
      {
        path: 'companies',
        component: CompaniesView,
        meta: {
          label: 'Companies',
          permissions: [CRM_PERMISSIONS.viewCrmCompanies],
        },
        children: [
          {
            path: '/',
            name: crm.companies.index,
            component: CompaniesListView,
            meta: {
              permissions: [CRM_PERMISSIONS.viewCrmCompanies],
              title: 'Companies',
            },
          },
          {
            path: ':companyUuid',
            name: crm.companies.detail.index,
            component: CompanyDetailView,
            props: true,
            meta: {
              label: 'Company Details',
              permissions: [CRM_PERMISSIONS.viewCrmCompanies],
              title: {
                storeDomain: DOMAINS_MODEL.crm.company,
                scope: COMPANY_SCOPES.company,
                template: data => `${get(data, 'commercialName', '')}`,
              },
            },
            redirect: { name: crm.companies.detail.account },
            children: [
              {
                path: 'account',
                name: crm.companies.detail.account,
                component: CompanyAccountView,
                meta: {
                  label: 'Account',
                  permissions: [CRM_PERMISSIONS.viewCrmCompanies],
                },
              },
              {
                path: 'invoicing',
                name: crm.companies.detail.invoicing.index,
                redirect: { name: crm.companies.detail.invoicing.invoices },
                component: CompanyInvoicingView,
                meta: {
                  label: 'Invoicing',
                  permissions: [CRM_PERMISSIONS.viewCrmCompanies],
                },
                children: [
                  {
                    path: 'invoices',
                    name: crm.companies.detail.invoicing.invoices,
                    component: CompanyInvoicesView,
                    props: true,
                    meta: {
                      label: 'Invoices',
                      permissions: [CRM_PERMISSIONS.viewCrmCompanies],
                    },
                  },
                  {
                    path: 'payments',
                    name: crm.companies.detail.invoicing.payments,
                    component: CompanyPaymentsView,
                    props: true,
                    meta: {
                      label: 'Payments',
                      permissions: [CRM_PERMISSIONS.viewCrmCompanies],
                    },
                  },
                  {
                    path: 'payment-methods',
                    name: crm.companies.detail.invoicing.paymentMethods,
                    component: CompanyPaymentMethods,
                    meta: {
                      label: 'Payment methods',
                      permissions: [CRM_PERMISSIONS.viewCrmCompanies],
                    },
                  },
                ],
              },
              {
                path: 'fleet',
                name: crm.companies.detail.fleet,
                component: CompanyFleetView,
                meta: {
                  label: 'Fleet',
                  permissions: [CRM_PERMISSIONS.viewCrmCompanies],
                },
              },
              {
                path: 'tariffs',
                name: crm.companies.detail.tariffs,
                component: CompanyTariffsView,
                meta: {
                  label: 'Tariffs',
                  permissions: [CRM_PERMISSIONS.viewCrmCompanies],
                },
              },
              {
                path: 'bookings',
                name: crm.companies.detail.bookings,
                component: CompanyBookingsView,
                meta: {
                  label: 'Bookings',
                  permissions: [CRM_PERMISSIONS.viewCrmCompanies],
                },
              },
              {
                path: 'employees',
                name: crm.companies.detail.employees,
                component: CompanyEmployeesView,
                meta: {
                  label: 'Employees',
                  permissions: [CRM_PERMISSIONS.viewCrmCompanies],
                },
              },
              {
                path: 'settings',
                name: crm.companies.detail.settings.index,
                component: CompanySettingsView,
                redirect: { name: crm.companies.detail.settings.premium },
                meta: {
                  label: 'Settings',
                  permissions: [CRM_PERMISSIONS.viewCrmCompanies],
                },
                children: [
                  {
                    path: 'premium',
                    name: crm.companies.detail.settings.premium,
                    component: CompanySettingsPremiumView,
                    meta: {
                      label: 'Premium',
                      permissions: [CRM_PERMISSIONS.viewCrmCompanies],
                    },
                  },
                  {
                    path: 'cost-allocations',
                    name: crm.companies.detail.settings.costAllocations.index,
                    component: CompanySettingsCostAllocationsView,
                    meta: {
                      label: 'Cost allocations',
                      permissions: [CRM_PERMISSIONS.viewCrmCompanies],
                    },
                    children: [
                      {
                        path: '',
                        name: crm.companies.detail.settings.costAllocations.list,
                        component: CompanySettingsCostAllocationsListView,
                        meta: {
                          permissions: [CRM_PERMISSIONS.viewCrmCompanies],
                        },
                      },
                      {
                        path: ':costAllocationUuid',
                        name: crm.companies.detail.settings.costAllocations.codes,
                        component: CompanySettingsCostAllocationsCodesView,
                        props: true,
                        meta: {
                          permissions: [CRM_PERMISSIONS.viewCrmCompanies],
                        },
                      },
                    ],
                  },
                  {
                    path: 'whitelisted-domains',
                    name: crm.companies.detail.settings.whitelistedDomains,
                    component: CompanySettingsWhitelistedDomainsView,
                    meta: {
                      label: 'Whitelisted domains',
                      permissions: [CRM_PERMISSIONS.viewCrmCompanies],
                    },
                  },
                  {
                    path: 'mapping-categories',
                    name: crm.companies.detail.settings.mappingCategories,
                    component: CompanySettingsMappingCategoriesView,
                    meta: {
                      label: 'Mapping categories',
                      permissions: [CRM_PERMISSIONS.viewCrmCompanies, CRM_PERMISSIONS.viewMappingCategories],
                    },
                  },
                  {
                    path: 'holidays',
                    name: crm.companies.detail.settings.companyHolidays,
                    component: CompanySettingsHolidaysView,
                    meta: {
                      label: 'Holidays',
                      permissions: [CRM_PERMISSIONS.viewCrmCompanies, CRM_PERMISSIONS.viewMappingCategories],
                    },
                  },
                ],
              },
              {
                path: 'comments',
                name: crm.companies.detail.comments,
                component: CompanyCommentsView,
                meta: {
                  label: 'Comments',
                  permissions: [CRM_PERMISSIONS.viewCrmCompanies],
                },
              },
              {
                path: 'employee-policy',
                name: crm.companies.detail.employeePolicy,
                component: CompanyEmployeePolicy,
                meta: {
                  label: 'Employee policy',
                  permissions: [CRM_PERMISSIONS.viewCrmCompanies],
                },
              },
              {
                path: 'contracts',
                name: crm.companies.detail.corporateContract.index,
                redirect: { name: crm.companies.detail.corporateContract.list },
                component: CompanyContractsView,
                meta: {
                  label: 'Contracts',
                  permissions: [CRM_PERMISSIONS.viewCrmCompanies],
                },
                children: [
                  {
                    path: '/',
                    name: crm.companies.detail.corporateContract.list,
                    component: CompanyContractsListView,
                    meta: {
                      permissions: [CRM_PERMISSIONS.viewCrmCompanies],
                    },
                  },
                  {
                    path: ':contractUuid',
                    name: crm.companies.detail.corporateContract.detail,
                    props: true,
                    component: CompanyContractDetailView,
                    meta: {
                      label: 'Detail',
                      isEmptyPage: true,
                    },
                  },
                  {
                    path: ':contractUuid/sales-orders/:salesOrderUuid',
                    name: crm.companies.detail.corporateContract.salesOrders,
                    props: true,
                    component: CompanyContractSalesOrdersView,
                    meta: {
                      label: 'Sales order detail',
                      isEmptyPage: true,
                    },
                  },
                ],
              },
              {
                path: 'corporate-invoicing',
                name: crm.companies.detail.corporateInvoicing.index,
                redirect: { name: crm.companies.detail.corporateInvoicing.list },
                component: CompanyCorporateInvoicingView,
                meta: {
                  label: 'Corporate invoicing',
                  permissions: [CRM_PERMISSIONS.viewCrmCompanies],
                },
                children: [
                  {
                    path: '',
                    name: crm.companies.detail.corporateInvoicing.list,
                    component: CompanyCorporateInvoicesListView,
                    meta: {
                      permissions: [CRM_PERMISSIONS.viewCrmCompanies],
                    },
                  },
                  {
                    path: ':invoice_uuid',
                    name: crm.companies.detail.corporateInvoicing.detail,
                    component: CompanyCorporateInvoicesDetailView,
                    props: true,
                    meta: {
                      label: 'Detail',
                      permissions: [CRM_PERMISSIONS.viewCrmCompanies],
                    },
                  },
                ],
              },
              {
                path: 'mixed-use',
                name: crm.companies.detail.fleetSegments.index,
                redirect: { name: crm.companies.detail.fleetSegments.list },
                component: CompanyFleetSegmentsView,
                meta: {
                  label: 'Fleet segments',
                  permissions: [CRM_PERMISSIONS.viewCrmCompanies, CRM_PERMISSIONS.viewFleetSegments],
                },
                children: [
                  {
                    path: '',
                    name: crm.companies.detail.fleetSegments.list,
                    component: CompanyFleetSegmentsListView,
                    meta: {
                      permissions: [
                        CRM_PERMISSIONS.viewCrmCompanies,
                        CRM_PERMISSIONS.viewFleetSegments,
                      ],
                    },
                  },
                  {
                    path: 'create',
                    name: crm.companies.detail.fleetSegments.create,
                    component: CompanyFleetSegmentsForm,
                    meta: {
                      isEmptyPage: true,
                      label: 'Create',
                      permissions: [
                        CRM_PERMISSIONS.viewCrmCompanies,
                        CRM_PERMISSIONS.viewFleetSegments,
                        CRM_PERMISSIONS.manageFleetSegments,
                      ],
                    },
                  },
                  {
                    path: ':fleetSegmentUuid',
                    name: crm.companies.detail.fleetSegments.edit,
                    component: CompanyFleetSegmentsForm,
                    props: true,
                    meta: {
                      isEmptyPage: true,
                      label: 'Edit',
                      permissions: [
                        CRM_PERMISSIONS.viewCrmCompanies,
                        CRM_PERMISSIONS.viewFleetSegments,
                        CRM_PERMISSIONS.manageFleetSegments,
                      ],
                    },
                  },
                ],
              },
              {
                path: 'circles',
                name: crm.companies.detail.circles.index,
                redirect: { name: crm.companies.detail.circles.list },
                component: CompanyCirclesView,
                meta: {
                  label: 'Circles',
                  permissions: [CRM_PERMISSIONS.viewCrmCompanies],
                },
                children: [
                  {
                    path: '',
                    name: crm.companies.detail.circles.list,
                    component: CompanyCirclesListView,
                    meta: {
                      permissions: [CRM_PERMISSIONS.viewCrmCompanies],
                    },
                  },
                  {
                    path: 'create',
                    name: crm.companies.detail.circles.create,
                    component: CompanyCirclesForm,
                    meta: {
                      isEmptyPage: true,
                      label: 'Create',
                      permissions: [CRM_PERMISSIONS.viewCrmCompanies],
                    },
                  },
                  {
                    path: ':circleUuid',
                    name: crm.companies.detail.circles.edit,
                    component: CompanyCirclesForm,
                    props: true,
                    meta: {
                      isEmptyPage: true,
                      label: 'Edit',
                      permissions: [CRM_PERMISSIONS.viewCrmCompanies],
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'users',
        name: crm.users.index,
        component: CRMView,
        meta: {
          label: 'Users',
        },
        children: [
          {
            path: '/',
            component: UsersView,
            meta: {
              permissions: [CRM_PERMISSIONS.viewCrmUsers],
              label: 'Users',
            },
          },
          {
            path: ':userUuid',
            name: crm.users.detail.index,
            component: UserDetailsView,
            redirect: { name: crm.users.detail.account },
            props: true,
            meta: {
              label: 'User Details',
              permissions: [CRM_PERMISSIONS.viewCrmUsers],
              title: {
                storeDomain: DOMAINS_MODEL.crm.users,
                scope: USER_SCOPES.detail,
                template: data => `${get(data, 'info.firstName', '')} ${get(data, 'info.lastName', '')}`,
              },
            },
            children: [
              {
                path: 'account',
                name: crm.users.detail.account,
                component: UserAccount,
                props: true,
                meta: {
                  label: 'Account',
                  permissions: [CRM_PERMISSIONS.viewCrmUsers],
                },
              },
              {
                path: 'bookings',
                name: crm.users.detail.bookings,
                component: UserBookings,
                props: true,
                meta: {
                  label: 'Bookings',
                  permissions: [CRM_PERMISSIONS.viewCrmUsers],
                },
              },
              {
                path: 'subscriptions',
                name: crm.users.detail.tariffs.index,
                redirect: { name: crm.users.detail.tariffs.userTariff },
                component: UserSubscriptions,
                props: true,
                meta: {
                  label: 'Subscriptions',
                  permissions: [CRM_PERMISSIONS.viewCrmUsers],
                },
                children: [
                  {
                    path: 'user-tariffs',
                    name: crm.users.detail.tariffs.userTariff,
                    component: UserTariffs,
                    meta: {
                      label: '',
                      permissions: [CRM_PERMISSIONS.viewCrmUsers],
                    },
                  },
                  {
                    path: 'company-tariffs',
                    name: crm.users.detail.tariffs.companyTariff,
                    component: UserCompanyTariffs,
                    meta: {
                      label: '',
                      permissions: [CRM_PERMISSIONS.viewCrmUsers],
                    },
                  },
                ],
              },
              {
                path: 'annual-protection',
                name: crm.users.detail.userInsurance,
                component: UserCover,
                meta: {
                  label: '',
                  permissions: [CRM_PERMISSIONS.viewCrmUsers],
                },
              },
              {
                path: 'audits',
                name: crm.users.detail.audits.index,
                component: UserAudits,
                meta: {
                  label: 'Audits',
                  permissions: [CRM_PERMISSIONS.viewCrmUsers],
                },
                children: [
                  {
                    path: 'audits',
                    name: crm.users.detail.audits.audits,
                    component: UserAuditList,
                    meta: {
                      label: '',
                      permissions: [CRM_PERMISSIONS.viewCrmUsers],
                    },
                  },
                  {
                    path: 'related-audits',
                    name: crm.users.detail.audits.relatedAudits,
                    props: { isRelated: true },
                    component: UserAuditList,
                    meta: {
                      label: '',
                      permissions: [CRM_PERMISSIONS.viewCrmUsers],
                    },
                  },
                ],
              },
              {
                path: 'logs',
                name: crm.users.detail.logs,
                component: UserLogs,
                props: true,
                meta: {
                  label: 'Impersonate requests',
                  permissions: [CRM_PERMISSIONS.viewCrmUsers],
                },
              },
              {
                path: 'cases',
                name: crm.users.detail.cases,
                component: UserCases,
                props: true,
                meta: {
                  label: 'Cases',
                  permissions: [CRM_PERMISSIONS.viewCrmUsers],
                },
              },
              {
                path: 'promotions',
                name: crm.users.detail.promotions,
                component: UserPromotionsView,
                props: true,
                meta: {
                  label: 'Promotions',
                  permissions: [CRM_PERMISSIONS.viewCrmUsers],
                },
              },
              {
                path: 'notifications',
                name: crm.users.detail.notifications,
                component: UserNotifications,
                meta: {
                  label: 'Notifications',
                  permissions: [CRM_PERMISSIONS.viewCrmUsers],
                },
              },
              {
                path: 'invoicing',
                name: crm.users.detail.invoicing.index,
                redirect: { name: crm.users.detail.invoicing.invoices },
                component: UserInvoicing,
                meta: {
                  label: 'Invoicing',
                  permissions: [CRM_PERMISSIONS.viewCrmUsers],
                },
                children: [
                  {
                    path: 'invoices',
                    name: crm.users.detail.invoicing.invoices,
                    component: UserInvoicesView,
                    meta: {
                      label: 'Invoices',
                      permissions: [CRM_PERMISSIONS.viewCrmUsers],
                    },
                  },
                  {
                    path: 'payments',
                    name: crm.users.detail.invoicing.payments,
                    component: UserPaymentsView,
                    meta: {
                      label: 'Payments',
                      permissions: [CRM_PERMISSIONS.viewCrmUsers],
                    },
                  },
                  {
                    path: 'payment-methods',
                    name: crm.users.detail.invoicing.paymentMethods,
                    component: UserPaymentMethod,
                    meta: {
                      label: 'Payment methods',
                      permissions: [CRM_PERMISSIONS.viewCrmUsers],
                    },
                  },
                  {
                    path: 'payouts',
                    name: crm.users.detail.invoicing.payouts,
                    component: PayoutsView,
                    meta: {
                      label: 'Payouts',
                      permissions: [CRM_PERMISSIONS.viewCrmUsers],
                    },
                  },
                ],
              },
              {
                path: 'comments',
                name: crm.users.detail.comments,
                component: UserCommentsView,
                props: true,
                meta: {
                  label: 'Comments',
                  permissions: [CRM_PERMISSIONS.viewCrmUsers],
                },
              },
            ],
          },
        ],
      },
      {
        path: 'roles',
        component: RolesView,
        redirect: { name: crm.roles.index },
        meta: {
          label: 'Roles',
        },
        children: [
          {
            path: '/',
            name: crm.roles.index,
            component: RolesListView,
            meta: {
              permissions: [CRM_PERMISSIONS.viewSettingsRoles],
            },
          },
          {
            path: ':roleName',
            name: crm.roles.detail,
            component: RoleDetailsView,
            props: true,
            meta: {
              label: 'Detail',
              permissions: [CRM_PERMISSIONS.viewSettingsRoles],
            },
          },
        ],
      },
      {
        path: 'cs-operators',
        component: CsOperatorsView,
        redirect: { name: crm.csOperators.legalDocuments.index },
        meta: {
          label: 'CS Operators',
        },
        children: [
          {
            path: 'legal-documents',
            name: crm.csOperators.legalDocuments.index,
            redirect: { name: crm.csOperators.legalDocuments.list },
            component: LegalDocumentsView,
            meta: {
              label: 'Legal documents',
            },
            children: [
              {
                path: '/',
                name: crm.csOperators.legalDocuments.list,
                component: LegalDocumentsListView,
              },
            ],
          },
          {
            path: 'settings',
            name: crm.csOperators.settings,
            component: CsOperatorSettingsView,
            meta: {
              label: 'General settings',
            },
          },
          {
            path: 'import',
            name: crm.csOperators.import,
            component: ImportOperatorsView,
            meta: {
              label: 'Import operators',
              permissions: [CRM_PERMISSIONS.viewSettingsBookingRules],
            },
          },
        ],
      },
      {
        path: 'agents',
        redirect: { name: crm.agents.index },
        component: AgentsView,
        meta: {
          label: 'Agents',
        },
        children: [
          {
            path: '/',
            name: crm.agents.index,
            component: AgentsListView,
            meta: {
              label: 'Agent List',
              permissions: [CRM_PERMISSIONS.viewSettingsAgents],
            },
          },
        ],
      },
    ],
  },
];
