export const GOOGLE_MAP_STYLES = [{
  featureType: 'landscape.natural',
  elementType: 'geometry.fill',
  stylers: [{
    color: '#ebeaef',
  }],
}, {
  featureType: 'poi',
  elementType: 'labels',
  stylers: [{
    saturation: '-50',
  }],
}, {
  featureType: 'poi.park',
  elementType: 'geometry.fill',
  stylers: [{
    color: '#b9edbc',
  }],
}, {
  featureType: 'road.highway',
  elementType: 'geometry.fill',
  stylers: [{
    color: '#dddae7',
  }],
}, {
  featureType: 'road.highway',
  elementType: 'geometry.stroke',
  stylers: [{
    visibility: 'on',
  }, {
    color: '#d2cfdc',
  }],
}, {
  featureType: 'road.local',
  elementType: 'geometry',
  stylers: [{
    color: '#ffffff',
  }],
}, {
  featureType: 'transit.line',
  elementType: 'all',
  stylers: [{
    visibility: 'off',
  }],
}, {
  featureType: 'transit.station',
  elementType: 'labels.text',
  stylers: [{
    saturation: '-50',
  }],
}, {
  featureType: 'transit.station',
  elementType: 'labels.icon',
  stylers: [{
    saturation: '-50',
  }],
}, {
  featureType: 'water',
  elementType: 'geometry.fill',
  stylers: [{
    color: '#b6e1f2',
  }],
}];
