var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "PageView",
        `${
          _vm.isInnerTabContent ? "PageView--is-inner-tab-content pt-4" : "px-5"
        }`,
        {
          "PageView--is-tab-content pt-4": _vm.isTabContent,
        },
      ],
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }