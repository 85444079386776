var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    {
      staticClass:
        "Modal__header d-flex justify-content-between align-items-center",
      class: _vm.classes,
      style: {
        "background-color": _vm.customColors.headerBackground,
        color: _vm.customColors.headerColor,
      },
    },
    [
      _c("h3", { staticClass: "w-100 px-3 text-ellipsis emobg-font-large" }, [
        _vm._v(" " + _vm._s(_vm.title) + " "),
      ]),
      _vm.isClosable
        ? _c(
            "ui-button",
            {
              staticClass:
                "close p-2 wmin-initial cursor-pointer emobg-color-inherit",
              class: {
                "emobg-background-color-primary-dark-hover":
                  !_vm.customColors.headerBackground,
              },
              attrs: { color: _vm.GRAYSCALE.none, face: _vm.FACES.text },
              on: {
                clickbutton: function ($event) {
                  return _vm.onCloseAction()
                },
              },
            },
            [
              _c("ui-icon", {
                attrs: { icon: _vm.ICONS.cross, size: _vm.ICONS_SIZES.medium },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }