import auth from './Auth/store/AuthModuleMap';
import carRental from './Carrental/store/CarrentalModuleMap';
import carsharing from './Carsharing/store/CarsharingModuleMap';
import casesServices from './CasesServices/store/CaseServicesModuleMap';
import crm from './CRM/store/CRMModuleMap';
import dashboard from './Dashboard/store/DashboardModuleMap';
import fleet from './Fleet/store/FleetModuleMap';
import fleetInvoicing from './FleetInvoicing/store/FleetInvoicingModuleMap';
import invoicing from './Invoicing/store/InvoicingModuleMap';
import businessIntelligence from './BusinessIntelligence/store/BusinessIntelligenceModuleMap';
import pricing from './Pricing/store/PricingModuleMap';
import settings from './Settings/store/SettingsModuleMap';
import promotions from './Promotions/store/PromotionsModuleMap';
import shared from './Shared/store/SharedModuleMap';

const DOMAINS_MODEL = Object.freeze({
  app: {
    root: 'App',
    setup: 'Setup',
    lang: 'i18n',
    feedbackDialog: 'App/FeedbackDialog',
    menuItems: 'App/MenuItems',
    userAccount: 'App/UserAccount',
    comments: 'App/Comments',
    omnisearch: 'App/Omnisearch',
    messages: {
      errors: {
        index: 'App/Messages/Errors',
        throwError: 'App/Messages/Errors/add',
        removeError: 'App/Messages/Errors/remove',
        removeAllErrors: 'App/Messages/Errors/removeAll',
      },
      notifications: {
        index: 'App/Messages/Notifications',
        notify: 'App/Messages/Notifications/add',
        removeNotification: 'App/Messages/Notifications/remove',
        removeAllNotifications: 'App/Messages/Notifications/removeAll',
      },
    },
    permissions: 'App/Permissions',
  },
  auth,
  carRental,
  carsharing,
  casesServices,
  crm,
  dashboard,
  fleet,
  fleetInvoicing,
  invoicing,
  businessIntelligence,
  pricing,
  settings,
  promotions,
  shared,
});

export default DOMAINS_MODEL;
