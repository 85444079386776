const SETUP_INIT_STATE = {
  template: '',
  title: '',
  company: '',
  style: '',
  assets: '',
  ENV: {},
  apiInstance: null,
};

export default {
  namespaced: true,
  state: SETUP_INIT_STATE,
  mutations: {
    updateSetup(state, {
      template,
      title,
      company,
      style,
      assets,
      ENV,
    }) {
      state.template = template;
      state.title = title;
      state.company = company;
      state.style = style;
      state.assets = assets;
      state.ENV = ENV;
    },
    setupAPIInstance(state, instance) {
      state.apiInstance = instance;
    },
  },
};
