<script>
import { expand, shrink } from '@emobg/vue-base';

export default {
  name: 'FullscreenButton',
  props: {
    fullscreen: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    fullscreenIcon() {
      return this.fullscreen ? shrink : expand;
    },
  },
};
</script>
<template>
  <ui-button
    :color="GRAYSCALE.inkLight"
    :face="FACES.outline"
    class="FullscreenButton"
    square
    v-bind="$attrs"
    v-on="$listeners"
  >
    <ui-icon
      :size="ICONS_SIZES.small"
      :icon="fullscreenIcon"
      class="emobg-font-weight-bold"
    />
  </ui-button>
</template>
