<script>
import marked from 'marked';
import get from 'lodash/get';

export default {
  name: 'MarkdownComponent',
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
    language: {
      type: String,
      default: '',
    },
  },
  computed: {
    description() {
      const markdown = get(this.content, this.language);
      return markdown ? marked(markdown) : null;
    },
  },
};
</script>

<template>
  <div
    class="MarkdownComponent"
    data-test-id="markdown_component"
    v-html="description || FALLBACK_MESSAGE.noData"
  />
</template>
