import indexOf from 'lodash/indexOf';
import isEmpty from 'lodash/isEmpty';
import repeat from 'lodash/repeat';
import replace from 'lodash/replace';
import toNumber from 'lodash/toNumber';
import isNumber from 'lodash/isNumber';
import toString from 'lodash/toString';
import { DELIMITERS } from '@emobg/web-utils';
import { DECIMAL_PATTERN } from './validations';

export const toNumberUtil = (value, isDecimal = true, isStrict = false) => {
  const fixedValue = isDecimal ? replace(value, DELIMITERS.comma, DELIMITERS.point) : value;
  let numericValue;

  if (isDecimal) {
    numericValue = isNumber(fixedValue) || !isStrict ? parseFloat(fixedValue) : NaN;
  } else {
    // For some falsy values _toNumber returns 0
    numericValue = (/^\d+$/.test(fixedValue)) ? toNumber(fixedValue) : NaN;
  }
  // eslint-disable-next-line no-restricted-globals
  return isNaN(numericValue) ? fixedValue : numericValue;
};

// To work with PATTERN_INPUT_VALIDATIONS.decimalNumber validation
export const toDecimalFormat = (
  value,
  { dotFormat = false } = {},
) => {
  const precision = 2;
  const normalizedValue = replace(toString(value), DELIMITERS.comma, DELIMITERS.point);
  const isOnlyDot = normalizedValue === DELIMITERS.point;
  const isValid = DECIMAL_PATTERN.test(normalizedValue);

  // eslint-disable-next-line no-restricted-globals
  if ((isNaN(normalizedValue) && !isOnlyDot) || isEmpty(normalizedValue) || !isValid) {
    return value;
  }

  let dotIndex = indexOf(normalizedValue, DELIMITERS.point);

  if (dotIndex === -1) {
    return `${normalizedValue}${dotFormat ? DELIMITERS.point : DELIMITERS.comma}${repeat('0', precision)}`;
  }

  let formattedNumericValue = normalizedValue;

  if (dotIndex === 0) {
    formattedNumericValue = `0${formattedNumericValue}`;
    dotIndex = 1;
  }

  const zerosToAdd = precision - (formattedNumericValue.length - dotIndex) + 1;
  const numberWithAddedZeros = zerosToAdd ? (`${formattedNumericValue}${repeat('0', zerosToAdd)}`) : formattedNumericValue;
  return dotFormat ? numberWithAddedZeros : replace(numberWithAddedZeros, DELIMITERS.point, DELIMITERS.comma);
};
