const fleet = Object.freeze({
  chargePostModels: 'Domains/Fleet/ChargePostModels',
  chargePosts: 'Domains/Fleet/ChargePosts',
  devices: 'Domains/Fleet/Devices',
  fuelCards: 'Domains/Fleet/FuelCards',
  locations: 'Domains/Fleet/Locations',
  sims: 'Domains/Fleet/Sims',
  vehicleBrands: 'Domains/Fleet/VehicleBrands',
  vehicleCategories: 'Domains/Fleet/VehicleCategories',
  vehicleEquipment: 'Domains/Fleet/VehicleEquipment',
  vehicleModels: 'Domains/Fleet/VehicleModels',
  cities: 'Domains/Fleet/Cities',
});

export default fleet;
