import differenceWith from 'lodash/differenceWith';
import filter from 'lodash/filter';
import findIndex from 'lodash/findIndex';
import { NOTIFICATION_TYPES } from '@/constants/notifications';

export default {
  namespaced: true,
  state: {
    nextNotificationId: 1,
    notifications: [],
  },
  mutations: {
    /**
     * Mutation to push notifications of different types.
     * @param {*} _state
     * @param {Object} payload - The payload for adding new notification
     * @param {Object} payload.action - Action to make before the notification closes
     * @param {Object} payload.delay - Delay in ms until close the notification
     * @param {Object} payload.message - The message of the notification
     * @param {Object} payload.type - The type of the notification (success, info, warning, error)
     * @param {Object} payload.textAction - Text of the action
     * @param {Object} payload.isFloating - Show notification on the floating notification list
     */
    add(state, {
      action,
      delay,
      isFloating = true,
      message = '',
      textAction = 'Dismiss',
      type = NOTIFICATION_TYPES.success,
    } = {}) {
      const notification = {
        action,
        delay,
        id: state.nextNotificationId,
        isFloating,
        message,
        textAction,
        type,
      };

      state.notifications.push(notification);
      state.nextNotificationId += 1;

      return message;
    },
    remove(state, id) {
      const notificationIndex = findIndex(state.notifications, { id });
      if (notificationIndex > -1) {
        state.notifications.splice(notificationIndex, 1);
      }
    },
    removeAll(state, notificationFilter) {
      state.notifications = notificationFilter ? differenceWith(state.notifications, filter(state.notifications, notificationFilter)) : [];
    },
  },
};
