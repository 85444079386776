export const AVAILABLE_LANGUAGES = {
  ca_ES: 'ca_ES',
  da_DK: 'da_DK',
  de_DE: 'de_DE',
  en_GB: 'en_GB',
  es_ES: 'es_ES',
  fr_BE: 'fr_BE',
  fr_FR: 'fr_FR',
  it_IT: 'it_IT',
  pt_PT: 'pt_PT',
  it_CH: 'it_CH',
  fr_CH: 'fr_CH',
  de_CH: 'de_CH',
  en_CH: 'en_CH',
  nl_NL: 'nl_NL',
};
