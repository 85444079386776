const fleet = Object.freeze({
  index: 'fleet',
  vehicleSettings: {
    index: 'fleet.vehicle.settings',
    brands: 'fleet.vehicle.settings.brands',
    models: {
      index: 'fleet.vehicles.settings.models',
      detail: 'fleet.vehicles.settings.models.detail',
    },
    equipment: 'fleet.vehicle.settings.equipment',
    categories: 'fleet.vehicle.settings.categories',
  },
  chargePostModels: {
    index: 'fleet.chargePosts.models',
    detail: 'fleet.chargePosts.models.detail',
  },
  chargePosts: {
    index: 'fleet.chargePosts',
    detail: {
      setup: 'fleet.chargePosts.detail.setup',
      comments: 'fleet.chargePosts.detail.comments',
    },
  },
  hardware: {
    hardware: 'fleet.vehicles.hardware',
    deviceProviders: 'fleet.vehicles.hardware.deviceProviders',
    fuelCards: 'fleet.vehicles.hardware.fuelCards',
    devices: 'fleet.vehicles.hardware.devices',
    sims: 'fleet.vehicles.hardware.sims',
  },
  locations: {
    index: 'fleet.locations',
    create: 'fleet.locations.create.form',
    detail: {
      index: 'fleet.locations.detail.index',
      setup: 'fleet.locations.detail.setup',
      vehicles: 'fleet.locations.detail.vehicles',
      bookings: 'fleet.locations.detail.bookings',
    },
  },
  cities: {
    index: 'fleet.cities',
  },
});

export default fleet;
