var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ContentCellComponent" }, [
    _vm.isOnHeader
      ? _c("h3", [_vm._v(" " + _vm._s(_vm.label) + " ")])
      : _c("h4", [_vm._v(" " + _vm._s(_vm.label) + " ")]),
    _c(
      "div",
      { staticClass: "emobg-body-1 mt-1" },
      [
        _vm._t("default", function () {
          return [
            _vm.isImage
              ? [
                  _c("img", {
                    staticStyle: {
                      width: "auto",
                      height: "100%",
                      "max-height": "60px",
                    },
                    attrs: { alt: _vm.label, src: _vm.value },
                  }),
                ]
              : [
                  _vm._v(
                    " " +
                      _vm._s(_vm.value || _vm.FALLBACK_MESSAGE.dash) +
                      _vm._s(_vm.suffix ? _vm.suffix : "") +
                      " "
                  ),
                ],
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }