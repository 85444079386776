import get from 'lodash/get';
import { backOffice } from '@emobg/web-api-client';
import {
  INITIAL_STATUS,
  createCustomStore,
  setData,
  setError,
  setStatus,
  withScopes,
} from '@emobg/web-utils';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import DEFAULT_API_ERROR from '@/constants/defaultError';

import Damages from '../Damages/store/DamagesModule';
import Interactions from '../Interactions/store/InteractionsModule';
import Setup from '../Setup/store/SetupModule';
import Logs from '../Logs/store/LogsModule';

const {
  getGpsPosition,
  getVehicleDetails,
} = backOffice.csfleet;

export const VEHICLE_DETAILS_SCOPES = {
  general: 'general',
  position: 'position',
};

export default createCustomStore(() => withScopes(VEHICLE_DETAILS_SCOPES, ({
  mutations: {
    setData,
    setStatus,
    setError,
    clearErrors(state, { scope }) {
      state[scope].error = null;
      state[scope].STATUS = { ...INITIAL_STATUS };
    },
    setVehicleLocationAsInBooking(state) {
      state[VEHICLE_DETAILS_SCOPES.general].data.location = {
        lat: null,
        lng: null,
        timestamp: null,
        in_booking: true,
      };
    },
  },
  actions: {
    async getGpsPosition({ commit, state }, { vehicleUuid, reasonUuid }) {
      commit('setStatus', { value: 'LOADING', scope: VEHICLE_DETAILS_SCOPES.position });

      try {
        const location = await getGpsPosition(vehicleUuid, { reasonUuid });
        const generalData = get(state.general, 'data', { location: {} });
        generalData.location = {
          ...state.general.data.location,
          ...location,
        };

        commit('setData', {
          value: generalData,
          scope: VEHICLE_DETAILS_SCOPES.general,
        });

        commit('setStatus', { value: 'LOADED', scope: VEHICLE_DETAILS_SCOPES.position });
      } catch (error) {
        commit('setError', { value: get(error, 'data', DEFAULT_API_ERROR), scope: VEHICLE_DETAILS_SCOPES.position });
        commit('setStatus', { value: 'ERROR', scope: VEHICLE_DETAILS_SCOPES.position });
      }
    },
    async getVehicleDetails({ commit }, vehicleUuid) {
      commit('setStatus', { value: 'LOADING', scope: VEHICLE_DETAILS_SCOPES.general });

      try {
        const generalData = await getVehicleDetails(vehicleUuid);
        generalData.location = {
          ...get(generalData, 'location', {}),
          in_booking: get(generalData, 'in_booking', false),
        };
        commit('setData', {
          value: generalData,
          scope: VEHICLE_DETAILS_SCOPES.general,
        });

        commit('setStatus', { value: 'LOADED', scope: VEHICLE_DETAILS_SCOPES.general });
      } catch (error) {
        commit(DOMAINS_MODEL.app.messages.errors.throwError, error, { root: true });
      }
    },
  },
  modules: {
    Damages,
    Interactions,
    Logs,
    Setup,
  },
})));
