import badges from './BadgesRouterMap';
const BadgesView = () => import(/* webpackChunkName: "badges" */ '../BadgesView.vue');
const BadgesListView = () => import(/* webpackChunkName: "badges" */ '../BadgesList/BadgesListView.vue');
const DeliveriesListView = () => import(/* webpackChunkName: "badges" */ '../DeliveriesList/DeliveriesListView.vue');

export default [
  {
    path: 'badges',
    name: badges.index,
    component: BadgesView,
    redirect: { name: badges.list },
    meta: {
      label: 'Badges',
    },
    children: [
      {
        path: 'badges',
        name: badges.list,
        component: BadgesListView,
        meta: {
          label: 'Badges',
        },
      },
      {
        path: 'badge-deliveries',
        name: badges.deliveries,
        component: DeliveriesListView,
        meta: {
          label: 'Badge deliveries',
        },
      },
    ],
  },
];
