const invoicing = {
  index: 'invoicing',
  invoices: {
    list: 'invoicing.invoices',
    sapFiles: 'invoicing.invoices.sapFiles',
    invoice: {
      summary: 'invoicing.invoices.invoice.summary',
      details: 'invoicing.invoices.invoice.details',
      related: 'invoicing.invoices.invoice.related',
      send: 'invoicing.invoices.invoice.send',
      audit: {
        index: 'invoicing.invoices.invoice.audit',
        invoices: 'invoicing.invoices.invoice.audit.invoices',
        lines: 'invoicing.invoices.invoice.audit.lines',
        payments: 'invoicing.invoices.invoice.audit.payments',
      },
    },
  },
  payments: 'payments',
  payouts: 'payouts',
};

export default invoicing;
