const pricing = Object.freeze({
  index: 'pricing',
  tariffs: {
    index: 'pricing.tariffs',
    create: 'pricing.tariffs.create',
    update: 'pricing.tariffs.update',
    legacy: 'pricing.tariffs.legacy',
    modifiers: 'pricing.tariffs.modifiers',
  },
  services: 'pricing.services',
});

export default pricing;
