const carRental = Object.freeze({
  agents: 'Domains/Carrental/Agents',
  alert: {
    details: 'Domains/Carrental/Alerts',
  },
  alertsTrigger: {
    map: 'Domains/Carrental/AlertsTrigger/NewAlertMap',
    trigger: 'Domains/Carrental/AlertsTrigger/NewAlertTrigger',
    list: 'Domains/Carrental/AlertsTrigger/AlertTriggerList',
  },
  vehicle: {
    summary: 'Domains/Carrental/Vehicle',
    telemetry: 'Domains/Carrental/Vehicle/VehicleTelemetry',
    trips: 'Domains/Carrental/Vehicle/VehicleTrips',
    info: 'Domains/Carrental/Vehicle/VehicleInfo',
    europcar: 'Domains/Carrental/Vehicle/VehicleEuropcar',
  },
  stations: 'Domains/Carrental/Stations',
  fleetCheck: 'Domains/Carrental/FleetCheck',
  trip: {
    details: 'Domains/Carrental/Trip/TripDetails',
  },
  devices: 'Domains/Carrental/Devices',
});

export default carRental;
