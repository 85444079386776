var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ui-dropdown",
    {
      staticClass: "ActionsComponent",
      attrs: { placement: _vm.PLACEMENTS.left },
    },
    [
      _c(
        "ui-button",
        {
          attrs: {
            slot: "trigger",
            color: _vm.GRAYSCALE.white,
            face: _vm.FACES.text,
            contrast: "",
            square: "",
          },
          slot: "trigger",
        },
        [
          _c("ui-icon", {
            attrs: {
              color: _vm.GRAYSCALE.inkLight,
              icon: _vm.ICONS.optionsHorizontalFull,
            },
          }),
        ],
        1
      ),
      _vm.actions && _vm.actions.length
        ? [
            _c("ui-dropdown-actions", {
              attrs: { slot: "content" },
              domProps: { actions: _vm.actions },
              slot: "content",
            }),
          ]
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "emobg-background-color-ground-light-hover",
          attrs: { slot: "content" },
          slot: "content",
        },
        [_vm._t("default")],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }