import { getEnd, getStart } from '@/components/WeekSchedule/utils/consecutiveMinutes/consecutiveMinutes';
import '../types';

/**
 * Checks if there is an overlap
 * @returns {boolean} true if there is an overlap, false otherwise
 * @param {Interval} interval1
 * @param {Interval} interval2
 */
export const isIntervalOverlap = (interval1, interval2) => {
  const isCompletelyBefore = getEnd(interval1) <= getStart(interval2);
  if (isCompletelyBefore) {
    return false;
  }
  const isCompletelyAfter = getStart(interval1) >= getEnd(interval2);

  return !isCompletelyAfter;
};
