<script>
import get from 'lodash/get';
import replace from 'lodash/replace';
import { mapMutations } from 'vuex';
import { NOTIFICATION_TYPES } from '@/constants/notifications';
import DOMAINS_MODEL from '@/domains/DOMAINS_MODEL';
import { getMessageText } from '@/components/GlobalMessaging/GlobalMessagingComponent';
import InlineNotificationComponent from '../InlineNotification/InlineNotificationComponent';

export default {
  name: 'StoreNotificationComponent',
  components: {
    InlineNotificationComponent,
  },
  props: {
    element: {
      type: String,
      default: '',
    },
    action: {
      type: String,
      default: '',
    },
    storeDomain: {
      type: String,
      required: true,
    },
    scope: {
      type: String,
      default: undefined,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    customExceptions: {
      type: Array,
      default: () => [],
    },
    useMessage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      notification: null,
    };
  },
  computed: {
    storePath() {
      return replace(this.storeDomain, /\//g, '.');
    },
    storeError() {
      return get(this.$store.state, `${this.storePath}.${this.storePathToError}`);
    },
    storePathToError() {
      return this.scope ? `${this.scope}.error` : 'error';
    },
    text() {
      const translation = this.isEditing ? 'Common.Actions.edit_element' : 'Common.Actions.add_element';
      return this.action || this.$t(translation, { element: this.element });
    },
  },
  watch: {
    storeError(error) {
      let message = `We could not ${this.text}! Try again`;

      if (this.notification) {
        this.remove(this.notification.id);
        this.notification = null;
      }

      if (this.useMessage) {
        message = get(this, 'storeError.message', message);
      }

      if (this.customExceptions.includes(get(this, 'storeError.key'))) {
        message = this.getMessageText(error, this.$t, null);
      }

      if (error) {
        this.notification = this.$notify({
          type: NOTIFICATION_TYPES.error,
          message,
          action: this.clearErrors,
          isFloating: false,
        });
      }
    },
  },
  created() {
    this.clearErrors();
  },
  methods: {
    ...mapMutations(DOMAINS_MODEL.app.messages.notifications.index, ['remove']),
    getMessageText,
    clearErrors() {
      this.notification = null;
      this.$store.commit(`${this.storeDomain}/clearErrors`, { scope: this.scope });
    },
  },
};
</script>

<template>
  <InlineNotificationComponent class="d-block pb-5" />
</template>
