var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "emobg-background-color-ground-lightest emobg-color-ink-light mt-1 p-3 emobg-border-radius-small",
    },
    [
      _c("ui-label", { attrs: { label: "Text formatting help:" } }),
      _c("br"),
      _vm._v(" # Title"),
      _c("br"),
      _vm._v(" ## Subtitle"),
      _c("br"),
      _vm._v(" *italic*"),
      _c("br"),
      _vm._v(" **bold**"),
      _c("br"),
      _vm._v(" * bullet point"),
      _c("br"),
      _vm._v(" ![image-alt-text](image-url)"),
      _c("br"),
      _vm._v(" [link-text](link-url)"),
      _c("br"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }