var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.envName
    ? _c(
        "span",
        {
          class: [
            "EnvLabelComponent",
            { [`EnvLabelComponent--${_vm.envName}`]: _vm.envName },
          ],
        },
        [_vm._v(" " + _vm._s(_vm.envName) + " ")]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }