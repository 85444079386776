import map from 'lodash/map';

export const morningShift = '00:00-08:00';

export const dayNames = {
  sunday: 'Sunday',
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
};
export const dayOptions = map(dayNames, (label, value) => ({
  label,
  value,
}));
export const emptyWeek = {
  monday: [],
  tuesday: [],
  wednesday: [],
  thursday: [],
  friday: [],
  saturday: [],
  sunday: [],
};
