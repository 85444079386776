var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "PageTabs",
        {
          "PageTabs--overview": _vm.hasOverview,
          "PageTabs--inner-tabs": _vm.areInnerTabs,
          "emobg-background-color-ground-lightest": !_vm.areInnerTabs,
        },
      ],
    },
    [
      _c("MuiTabs", {
        staticClass: "MuiTabs--scrollable px-2 px-lg-5",
        attrs: { tabs: _vm.tabs, active: _vm.activeTab },
        on: { "active-tab": _vm.updateRoute },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }