import { backOffice } from '@emobg/web-api-client';
import {
  createCustomStore,
  withScopes,
} from '@emobg/web-utils';

export const VEHICLE_LOGS_SCOPES = {
  chargeStatusLogs: 'chargeStatusLogs',
  connectivityLogs: 'connectivityLogs',
  tractionBatteryLogs: 'tractionBatteryLogs',
  fuelLogs: 'fuelLogs',
};

export default createCustomStore(({ runAsyncFlow }) => withScopes(VEHICLE_LOGS_SCOPES, {
  namespaced: true,
  actions: {
    async getVehicleChargeStatusLog({ commit }, { vehicleUuid }) {
      await runAsyncFlow(commit, {
        request: backOffice.vehicleTelemetry.getVehicleChargeStatusLog,
        params: [vehicleUuid],
        scope: VEHICLE_LOGS_SCOPES.chargeStatusLogs,
      });
    },
    async getVehicleConnectivitiesLog({ commit }, { vehicleUuid }) {
      await runAsyncFlow(commit, {
        request: backOffice.vehicleTelemetry.getVehicleConnectivitiesLog,
        params: [vehicleUuid],
        scope: VEHICLE_LOGS_SCOPES.connectivityLogs,
      });
    },
    async getTractionBatteryLog({ commit }, { vehicleUuid }) {
      await runAsyncFlow(commit, {
        request: backOffice.vehicleTelemetry.getTractionBatteryLog,
        params: [vehicleUuid],
        scope: VEHICLE_LOGS_SCOPES.tractionBatteryLogs,
      });
    },
    async getFuelLog({ commit }, { vehicleUuid }) {
      await runAsyncFlow(commit, {
        request: backOffice.vehicleTelemetry.getFuelLog,
        params: [vehicleUuid],
        scope: VEHICLE_LOGS_SCOPES.fuelLogs,
      });
    },
  },
}));
