import first from 'lodash/first';
import flatMap from 'lodash/flatMap';
import last from 'lodash/last';
import max from 'lodash/max';
import min from 'lodash/min';
import split from 'lodash/split';
import '../types';

export const getStart = interval => {
  const splitInterval = split(interval, '-');
  return first(splitInterval);
};

export const getEnd = interval => {
  const splitInterval = split(interval, '-');
  return last(splitInterval);
};

/**
 * @returns {number} time in minutes from '00:00'
 * @param {string} time with format 'HH:mm'
 */
const timeToMinutes = time => {
  const hours = parseInt(time.substr(0, 2), 10);
  const minutes = parseInt(time.substr(3, 2), 10);
  return hours * 60 + minutes;
};
/**
 * @returns {number} duration in minutes from start to end
 * @param {Interval} interval
 */
export const intervalDuration = interval => {
  const startInMinutes = timeToMinutes(getStart(interval));
  const endInMinutes = timeToMinutes(getEnd(interval));
  return endInMinutes - startInMinutes;
};
/**
 * Calculates the duration of maximum consecutive intervals
 * @returns {number} minutes
 * @param {Schedule} schedule
 */
export const maxConsecutiveMinutes = schedule => {
  let maxMinutes = 0;
  let consecutiveMinutes = 0;
  const allIntervals = flatMap(schedule);
  const intervalsLength = allIntervals.length;
  let masterIndex = 0;
  // For each interval in allIntervals, accumulate consecutive intervals duration
  while (masterIndex < intervalsLength) {
    let currentIndex = masterIndex;
    let areIntervalsConsecutive = true;
    while (areIntervalsConsecutive) {
      const currentInterval = allIntervals[currentIndex % intervalsLength];
      const nextInterval = allIntervals[(currentIndex + 1) % intervalsLength];
      const currentIntervalDuration = intervalDuration(currentInterval);
      const accumulatedConsecutive = consecutiveMinutes + currentIntervalDuration;
      consecutiveMinutes = accumulatedConsecutive;
      currentIndex += 1;

      const isEndAndStartOfDay = getEnd(currentInterval) === '24:00' && getStart(nextInterval) === '00:00';
      const isConsecutive = (getEnd(currentInterval) === getStart(nextInterval)) || isEndAndStartOfDay;
      // In case the schedule is 24/7, count only one loop
      const isLoopCompleted = currentIndex === masterIndex + intervalsLength;
      areIntervalsConsecutive = isConsecutive && !isLoopCompleted;
    }
    maxMinutes = max([consecutiveMinutes, maxMinutes]);
    consecutiveMinutes = 0;
    // in case last interval of week has consecutive intervals at the beginning of week,
    // this will break parent loop. Otherwise, check next interval
    masterIndex = currentIndex;
  }
  return maxMinutes;
};

/**
 * Calculates the duration of minimum consecutive intervals
 * @returns {number} minutes
 * @param {Schedule} schedule
 */
export const minConsecutiveMinutes = schedule => {
  let minMinutes = Number.POSITIVE_INFINITY;
  let consecutiveMinutes = 0;
  const allIntervals = flatMap(schedule);
  const intervalsLength = allIntervals.length;
  let masterIndex = 0;
  // For each interval in allIntervals, accumulate consecutive intervals duration
  while (masterIndex < intervalsLength) {
    let currentIndex = masterIndex;
    let areIntervalsConsecutive = true;
    while (areIntervalsConsecutive) {
      const currentInterval = allIntervals[currentIndex % intervalsLength];
      const nextInterval = allIntervals[(currentIndex + 1) % intervalsLength];

      const currentIntervalDuration = intervalDuration(currentInterval);
      consecutiveMinutes += currentIntervalDuration;
      currentIndex += 1;

      const isEndAndStartOfDay = getEnd(currentInterval) === '24:00' && getStart(nextInterval) === '00:00';
      const isConsecutive = (getEnd(currentInterval) === getStart(nextInterval)) || isEndAndStartOfDay;
      // In case the schedule is 24/7, count only one loop
      const isLoopCompleted = currentIndex === masterIndex + intervalsLength;
      areIntervalsConsecutive = isConsecutive && !isLoopCompleted;
    }
    minMinutes = min([consecutiveMinutes, minMinutes]);
    consecutiveMinutes = 0;
    // in case last interval of week has consecutive intervals at the beginning of week,
    // this will break parent loop. Otherwise, check next interval
    masterIndex = currentIndex;
  }
  return minMinutes;
};
