import '../types';
/**
 * @type {SchedulerErrors}
 */
export const ERRORS = {
  dayName: 'day must be "monday", "tuesday", "wednesday", "thursday", "friday", "saturday" or "sunday"',
  dayNotSelected: 'Select a day',
  intervalEnd: 'interval must have an end property',
  intervalObject: 'interval must be an object',
  intervalOrder: 'interval end must be later than interval start',
  intervalOverlap: 'there is an interval overlap with schedules already added. Try again',
  intervalStart: 'interval must have a start property',
  scheduleAllArray: 'all days must be Arrays',
  scheduleObject: 'schedule must be an Object',
  scheduleLength: 'schedule must have 7 days',
  scheduleDaysMissing: 'schedule must the 7 days with keys in English lowercase: "monday", "tuesday", "wednesday", "thursday", "friday", "saturday" and "sunday"',
  timeLength: 'time must have only "HH:mm"',
  timePattern: 'The format should be HH:mm ranging from 00:00 - 24:00. Only quarter hours are allowed e.g. 00:00, 13:30, 20:45. 24:00.',
  timeString: 'time must be a string',
};
