const crm = Object.freeze({
  index: 'crm',
  agents: {
    index: 'crm.agents.index',
  },
  users: {
    index: 'crm.users',
    detail: {
      index: 'crm.users.detail.index',
      account: 'crm.users.detail.account',
      bookings: 'crm.users.detail.bookings',
      tariffs: {
        index: 'crm.users.detail.tariffs.index',
        userTariff: 'crm.users.detail.tariffs.userTariff',
        companyTariff: 'crm.users.detail.tariffs.companyTariff',
      },
      userInsurance: 'crm.users.detail.userInsurance',
      audits: {
        index: 'crm.users.detail.audits.index',
        audits: 'crm.users.detail.audits.audits',
        relatedAudits: 'crm.users.detail.audits.relatedAudits',
      },
      logs: 'crm.users.detail.logs',
      cases: 'crm.users.detail.cases',
      promotions: 'crm.users.detail.promotions',
      notifications: 'crm.users.detail.notifications',
      comments: 'crm.users.detail.comments',
      invoicing: {
        index: 'crm.users.detail.invoicing.index',
        invoices: 'crm.users.detail.invoicing.invoices',
        payments: 'crm.users.detail.invoicing.payments',
        payouts: 'crm.users.detail.invoicing.payouts',
        paymentMethods: 'crm.users.detail.invoicing.paymentMethods',
      },
    },
    payouts: 'crm.users.payouts',
  },
  companies: {
    index: 'crm.companies',
    detail: {
      index: 'crm.companies.detail.index',
      account: 'crm.companies.detail.account.index',
      invoicing: {
        index: 'crm.companies.detail.invoicing.index',
        invoices: 'crm.companies.detail.invoicing.invoices',
        payments: 'crm.companies.detail.invoicing.payments',
        paymentMethods: 'crm.companies.detail.invoicing.paymentMethods',
      },
      fleet: 'crm.companies.detail.fleet',
      tariffs: 'crm.companies.detail.tariffs',
      bookings: 'crm.companies.detail.bookings',
      employees: 'crm.companies.detail.employees',
      settings: {
        index: 'crm.companies.detail.settings',
        premium: 'crm.companies.detail.settings.premium',
        costAllocations: {
          index: 'crm.companies.detail.settings.costAllocations',
          list: 'crm.companies.detail.settings.costAllocationsList',
          codes: 'crm.companies.detail.settings.costAllocationsCodes',
        },
        whitelistedDomains: 'crm.companies.detail.settings.whitelistedDomains',
        mappingCategories: 'crm.companies.detail.settings.mappingCategories',
        companyHolidays: 'crm.companies.detail.settings.companyHolidays',
      },
      comments: 'crm.companies.detail.comments',
      employeePolicy: 'crm.companies.detail.employeePolicy',
      corporateContract: {
        index: 'crm.companies.detail.corporateContract',
        list: 'crm.companies.detail.corporateContract.list',
        detail: 'crm.companies.detail.corporateContract.detail',
        salesOrders: 'crm.companies.corporateContract.salesOrders',
      },
      corporateInvoicing: {
        index: 'crm.companies.detail.corporateInvoicing',
        list: 'crm.companies.detail.corporateInvoicing.list',
        detail: 'crm.companies.detail.corporateInvoicing.detail',
      },
      fleetSegments: {
        index: 'crm.companies.detail.fleetSegments',
        list: 'crm.companies.detail.fleetSegments.list',
        create: 'crm.companies.detail.fleetSegments.create',
        edit: 'crm.companies.detail.fleetSegments.edit',
      },
      circles: {
        index: 'crm.companies.detail.circles',
        list: 'crm.companies.detail.circles.list',
        create: 'crm.companies.detail.circles.create',
        edit: 'crm.companies.detail.circles.edit',
      },
    },
  },
  roles: {
    index: 'crm.roles',
    detail: 'crm.roles.detail',
  },
  csOperators: {
    index: 'crm.csOperators',
    legalDocuments: {
      index: 'crm.csOperators.legalDocuments.index',
      list: 'crm.csOperators.legalDocuments.list',
    },
    settings: 'crm.csOperators.settings',
    import: 'crm.csOperators.import',
  },
});

export default crm;
