<script>
export default {
  name: 'CancelButton',
};
</script>
<template>
  <ui-button
    :color="GRAYSCALE.inkLight"
    :face="FACES.text"
    narrow
    class="CancelButton"
    v-bind="$attrs"
    @clickbutton="$emit('click')"
  >
    Cancel
  </ui-button>
</template>
