var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ui-button",
    {
      attrs: {
        face: _vm.FACES.text,
        color: _vm.GRAYSCALE.inkLight,
        square: "",
      },
      on: {
        clickbutton: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _c("ui-icon", {
        attrs: {
          icon: _vm.ICONS.edit,
          size: _vm.SIZES.xLarge,
          color: _vm.GRAYSCALE.inkLight,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }