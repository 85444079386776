import fleetInvoicing from './FleetInvoicingRouterMap';

const FleetInvoicingView = () => import(/* webpackChunkName: "fleet-invoicing" */'../FleetInvoicingView.vue');

// TODO: SBCB-826 Refactor query params to use camelCase instead of snake_case
// https://europcarmobility.atlassian.net/browse/SBCB-826
export default [
  {
    path: 'fleet-invoicing',
    name: fleetInvoicing.index,
    component: FleetInvoicingView,
    meta: {
      label: 'Fleet invoicing',
    },
  },
];
