<script>
import get from 'lodash/get';
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  CancelButton,
  DeleteButton,
  GenericModalComponent,
} from '@/components';

export default {
  components: {
    CancelButton,
    GenericModalComponent,
    DeleteButton,
  },
  props: {
    comment: {
      type: Object,
      required: true,
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    text() {
      return `<i class="font-italic">"${get(this, 'comment.body')}"</i>`;
    },
    ...mapState(DOMAINS_MODEL.app.comments, {
      deleteCommentStatus: state => state.deleteComment.STATUS,
    }),
  },
  methods: {
    async removeComment() {
      await this.deleteComment(get(this, 'comment.uuid'));
      this.$emit('closeModal');
      if (!this.deleteCommentStatus.ERROR) {
        this.$notify({
          message: 'Comment deleted successfully!',
          textAction: '',
        });
        this.callback();
      }
    },
    ...mapActions(DOMAINS_MODEL.app.comments, [
      'deleteComment',
    ]),
  },
};
</script>
<template>
  <GenericModalComponent
    :header="{ title: 'Delete this comment?' }"
    :size="SIZES.small"
    class="DeleteComment"
    v-on="$listeners"
  >
    <template slot="body">
      <span v-html="text" />
    </template>
    <template slot="footer">
      <CancelButton @click="$emit('closeModal')" />
      <DeleteButton
        :loading="deleteCommentStatus.LOADING"
        @click="removeComment"
      />
    </template>
  </GenericModalComponent>
</template>
