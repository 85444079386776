var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ui-form",
    { key: _vm.formKey, on: { submitform: _vm.onClickAdd } },
    [
      _c(
        "ui-validate",
        {
          staticClass: "d-flex w-100",
          on: { status: (event) => (_vm.formStatus = event.detail) },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "WeekSchedule__InputInterval__day mr-2 flex-fill font-weight-normal",
              staticStyle: { "min-width": "250px" },
            },
            [
              _c("ui-select-multiple", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate.selectMultiple",
                    value: {
                      isRequired: true,
                    },
                    expression: "{\n          isRequired: true,\n        }",
                    modifiers: { selectMultiple: true },
                  },
                ],
                staticClass: "w-100",
                attrs: {
                  label: "Days*",
                  placeholder: "Select",
                  name: "weekDay",
                  multiple: "",
                },
                domProps: { values: _vm.weekDays, options: _vm.dayOptions },
                on: { selectoptions: ({ detail }) => (_vm.weekDays = detail) },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "WeekSchedule__InputInterval__time mx-2 flex-fill" },
            [
              _c("ui-text-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate.input",
                    value: {
                      isRequired: true,
                      isPattern: {
                        pattern: _vm.timeRegex,
                        message: _vm.ERRORS.timePattern,
                      },
                    },
                    expression:
                      "{\n          isRequired: true,\n          isPattern: {\n            pattern: timeRegex,\n            message: ERRORS.timePattern,\n          },\n        }",
                    modifiers: { input: true },
                  },
                ],
                ref: `start-${_vm.resettingCounter}`,
                staticClass: "flex-fill",
                attrs: {
                  value: _vm.start,
                  label: "From*",
                  name: "start",
                  placeholder: "HH:mm",
                },
                on: { changevalue: ({ detail }) => (_vm.start = detail) },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "WeekSchedule__InputInterval__time flex-fill mx-2" },
            [
              _c("ui-text-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate.input",
                    value: {
                      isRequired: true,
                      isPattern: {
                        pattern: _vm.timeRegex,
                        message: _vm.ERRORS.timePattern,
                      },
                    },
                    expression:
                      "{\n          isRequired: true,\n          isPattern: {\n            pattern: timeRegex,\n            message: ERRORS.timePattern,\n          },\n        }",
                    modifiers: { input: true },
                  },
                ],
                ref: `end-${_vm.resettingCounter}`,
                staticClass: "flex-fill",
                attrs: {
                  value: _vm.end,
                  label: "To*",
                  name: "end",
                  placeholder: "HH:mm",
                },
                on: { changevalue: ({ detail }) => (_vm.end = detail) },
              }),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { role: "cell" } },
            [
              _c(
                "ui-button",
                {
                  staticClass: "ml-2 d-inline-block",
                  staticStyle: { "margin-top": "23px" },
                  attrs: {
                    disabled: _vm.isAddDisabled,
                    face: _vm.FACES.outline,
                    type: _vm.BUTTON_TYPES.submit,
                  },
                },
                [_vm._v(" Add ")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm.isFeedbackVisible
        ? _c(
            "ul",
            {
              staticClass:
                "d-flex flex-column justify-content-start emobg-color-danger align-items-center my-3 emobg-font-weight-bold",
            },
            _vm._l(_vm.intervalFeedback, function (feedback) {
              return _c(
                "li",
                {
                  key: feedback,
                  staticClass:
                    "my-2 d-flex align-items-center align-self-start",
                },
                [
                  _c("ui-icon", {
                    staticClass: "mr-1",
                    attrs: {
                      size: _vm.ICONS_SIZES.medium,
                      icon: _vm.ICONS.info,
                      "data-test-id": "info-icon",
                    },
                  }),
                  _vm._v(" " + _vm._s(_vm.upperFirst(feedback)) + " "),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }