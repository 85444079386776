var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "DeleteComment",
        attrs: {
          header: { title: "Delete this comment?" },
          size: _vm.SIZES.small,
        },
      },
      _vm.$listeners
    ),
    [
      _c("template", { slot: "body" }, [
        _c("span", { domProps: { innerHTML: _vm._s(_vm.text) } }),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("CancelButton", {
            on: {
              click: function ($event) {
                return _vm.$emit("closeModal")
              },
            },
          }),
          _c("DeleteButton", {
            attrs: { loading: _vm.deleteCommentStatus.LOADING },
            on: { click: _vm.removeComment },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }