const carrental = Object.freeze({
  index: 'carrental',
  vehicles: {
    index: 'carrental.vehicles',
    summary: {
      alerts: 'carrental.vehicles.summary.alerts',
      index: 'carrental.vehicles.summary',
      info: 'carrental.vehicles.summary.info',
      stationHistory: 'carrental.vehicles.summary.stationHistory',
      greenway: 'carrental.vehicles.summary.greenway',
      movements: 'carrental.vehicles.summary.movements',
      telemetry: 'carrental.vehicles.summary.telemetry',
      trips: 'carrental.vehicles.summary.trips',
    },
    tripsSummary: 'carrental.vehicles.trips.summary',
    alerts: {
      index: 'carrental.vehicles.alerts',
      detail: 'carrental.vehicles.alerts.detail',
    },
    devices: 'carrental.vehicles.devices',
    stations: {
      index: 'carrental.vehicles.stations',
      details: 'carrental.vehicles.stations.details',
    },
    fleetCheck: {
      index: 'carrental.vehicles.fleetCheck.list',
      new: 'carrental.vehicles.fleetCheck.new',
    },
  },
  summaryReport: 'carrental.summaryReport',
});

export default carrental;
