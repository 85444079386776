<script>
import camelCase from 'lodash/camelCase';
import each from 'lodash/each';
import find from 'lodash/find';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import set from 'lodash/set';
import toLower from 'lodash/toLower';
import { AVAILABLE_LANGUAGES } from '@/constants/languages';
import { DEFAULT_LANGUAGE } from './TranslatableFieldComponent.const';

export default {
  name: 'TranslatableFieldComponent',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    isInputText: {
      type: Boolean,
      default: false,
    },
    language: {
      type: String,
      default: camelCase(DEFAULT_LANGUAGE),
    },
    prefix: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      internalLanguage: this.language,
      inputValue: {},
    };
  },
  computed: {
    languageLabel() {
      const fieldLabel = this.$attrs.label ? ` for ${toLower(this.$attrs.label)}` : '';
      return `Select language${fieldLabel}`;
    },
  },
  watch: {
    value: {
      handler(newValue) {
        if (isEqual(newValue, this.inputValue)) {
          return;
        }
        this.inputValue = this.transformValueToTranslations(newValue);
      },
      deep: true,
    },
    inputValue: {
      handler(newValue) {
        this.$emit('input', { ...newValue });
      },
      deep: true,
    },
    language(newLanguage) {
      const languageFound = find(this.AVAILABLE_LANGUAGES, { value: newLanguage });
      this.internalLanguage = languageFound ? newLanguage : camelCase(DEFAULT_LANGUAGE);
    },
    internalLanguage: {
      handler(newLanguage) {
        this.$emit('update:language', newLanguage);
      },
      immediate: true,
    },
  },
  created() {
    this.AVAILABLE_LANGUAGES = map(AVAILABLE_LANGUAGES, code => ({
      value: camelCase(code),
      label: code,
    }));

    this.inputValue = this.transformValueToTranslations(this.value);
  },
  methods: {
    transformValueToTranslations(value) {
      const translations = {};
      each(this.AVAILABLE_LANGUAGES, availableLanguage => {
        const codeKey = this.getCodeKeyByLocale(availableLanguage.value);
        const translation = get(value, codeKey, '');
        set(translations, codeKey, translation);
      });
      return translations;
    },
    getCodeKeyByLocale(locale) {
      return this.prefix ? camelCase(`${this.prefix}_${locale}`) : locale;
    },
  },
};
</script>

<template>
  <div class="TranslatableFieldComponent">
    <ui-select
      :value="internalLanguage"
      :options.prop="AVAILABLE_LANGUAGES"
      :label="languageLabel"
      name="language"
      class="d-block mb-4"
      @selectoption="({ detail }) => internalLanguage = detail"
    />
    <ui-text-input
      v-if="isInputText"
      :value="inputValue[getCodeKeyByLocale(internalLanguage)]"
      name="field"
      v-bind="$attrs"
      @changevalue="({ detail }) => inputValue[getCodeKeyByLocale(internalLanguage)] = detail"
    />
    <ui-text-area
      v-else
      :value="inputValue[getCodeKeyByLocale(internalLanguage)]"
      name="field"
      v-bind="$attrs"
      @changevalue="({ detail }) => inputValue[getCodeKeyByLocale(internalLanguage)] = detail"
    />
  </div>
</template>
