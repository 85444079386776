import concat from 'lodash/concat';
import VueRouter from 'vue-router';

import BadgesRouter from '@domains/Badges/router/BadgesRouter';
import BusinessIntelligenceRouter from '@domains/BusinessIntelligence/router/BusinessIntelligenceRouter';
import CRMRouter from '@domains/CRM/router/CRMRouter';
import CarrentalRouter from '@domains/Carrental/router/CarrentalRouter';
import CarsharingRouter from '@domains/Carsharing/router/CarsharingRouter';
import CasesServicesRouter from '@domains/CasesServices/router/CasesServicesRouter';
import FleetRouter from '@domains/Fleet/router/FleetRouter';
import FleetInvoicingRouter from '@domains/FleetInvoicing/router/FleetInvoicingRouter';
import InvoicingRouter from '@domains/Invoicing/router/InvoicingRouter';
import PricingRouter from '@domains/Pricing/router/PricingRouter';
import PromotionsRouter from '@domains/Promotions/router/PromotionsRouter';
import DashboardRouter from '@domains/Dashboard/router/DashboardRouter';
import SharedRouter from '@domains/Shared/router/SharedRouter';
import SettingsRouter from '@domains/Settings/router/SettingsRouter';

import { redirectGuard } from '@/router/guards/redirect.guard';
import { permissionsGuard } from '@/router/guards/permissions.guard';
import { authGuard } from '@/router/guards/auth.guard';
import { twoFactorRedirection } from '@/router/guards/2factor.guard';
import { operatorGuard } from '@/router/guards/operator.guard';

import RouterMap from './RouterMap';

const MainView = () => import(/* webpackChunkName: "shared" */'@domains/Main/MainView');
const LogOutView = () => import(/* webpackChunkName: "shared" */'@domains/Auth/LogOut/LogOutView');

const MultiFactorAuthView = () => import(/* webpackChunkName: "auth" */'@domains/Auth/MultiFactorAuth/MultiFactorAuthView');

const children = [
  {
    path: '',
    beforeEnter: redirectGuard,
  },
  ...DashboardRouter,
  ...InvoicingRouter,
  ...CRMRouter,
  ...FleetRouter,
  ...FleetInvoicingRouter,
  ...CarrentalRouter,
  ...CarsharingRouter,
  ...CasesServicesRouter,
  ...BusinessIntelligenceRouter,
  ...PricingRouter,
  ...SettingsRouter,
  ...PromotionsRouter,
  ...BadgesRouter,
  ...SharedRouter, // please, always keep it as the last spread item
];

function guardsStack(guards) {
  return (to, from, next) => {
    const stack = concat(guards);
    function runNext(params) {
      if (params) {
        next(params);
      } else {
        const guard = stack.shift();
        /* eslint-disable no-unused-expressions */
        guard(to, from, stack.length > 0 ? runNext : next);
      }
    }
    runNext();
  };
}

export const router = () => {
  const appRouter = new VueRouter({
    mode: 'history',
    base: /^\/__/.test(window.location.pathname) ? `${__dirname}__` : __dirname,
    linkActiveClass: 'Navbar__menuItemLink--active',
    routes: [
      {
        path: '/logout',
        component: LogOutView,
      },
      {
        path: '/login',
        redirect: '/logout',
      },
      {
        path: '/mfa-verification',
        component: MultiFactorAuthView,
        name: RouterMap.verificationCode,
      },
      {
        path: '/',
        component: MainView,
        children,
      },
    ],
  });

  appRouter.beforeEach(
    guardsStack([
      authGuard,
      twoFactorRedirection,
      operatorGuard,
      permissionsGuard,
    ]),
  );

  return appRouter;
};
