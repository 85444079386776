var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.useRouter
    ? _c(
        "RouterLink",
        { attrs: { to: _vm.to, target: _vm.target } },
        [_vm._t("default")],
        2
      )
    : _c(
        "a",
        { attrs: { href: _vm.href, target: _vm.target } },
        [_vm._t("default")],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }