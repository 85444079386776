<script>
import isEmpty from 'lodash/isEmpty';

export default {
  name: 'EmptyStateComponent',
  props: {
    src: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    additionalFeedback: {
      type: String,
      default: '',
    },
    errorMessage: {
      type: String,
      default: '',
    },
    actions: {
      /**
       * [{
       *   text: 'button or link text',
       *   to?: { name: route } || 'route'
       *   callback?: () => {}
       * }]
       */
      type: Array,
      default: () => [],
    },
    hasBackground: {
      type: Boolean,
      default: false,
    },
    hasBorder: {
      type: Boolean,
      default: false,
    },
    isFullHeight: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    isEmpty,
  },
};
</script>

<template>
  <div
    :class="[
      'EmptyStateComponent d-flex flex-column align-items-center justify-content-center flex-fill p-5',
      {
        'emobg-background-color-ground-lightest': hasBackground,
        'emobg-border-1 emobg-border-color-ground': hasBorder,
      },
    ]"
    data-test-id="empty-state"
  >
    <div class="EmptyStateComponent__content d-flex flex-column align-center">
      <div v-if="src || icon">
        <figure
          v-if="src"
          class="mb-4"
        >
          <img
            :src="src"
            alt="empty state"
            class="EmptyStateComponent__image"
          >
        </figure>
        <ui-icon
          v-else-if="icon"
          :icon="icon"
          :size="ICONS_SIZES.xLarge"
          class="mb-4"
        />
      </div>
      <h2
        v-if="title"
        class="emobg-font-large emobg-color-ink-light emobg-font-weight-bold mb-0"
        data-test-id="title"
      >
        {{ title }}
      </h2>
      <div
        v-if="description || $slots.description"
        class="emobg-color-ink-light mt-3"
        data-test-id="description"
      >
        <p
          v-if="description"
          class="mb-0"
        >
          {{ description }}
        </p>
        <template v-if="errorMessage">
          <p class="mt-3">
            {{ additionalFeedback }}
          </p>
          <p class="mt-3">
            Alternatively, provide your administrator with the following error
            message:
          </p>
          <p
            class="emobg-color-danger my-3 font-small"
            data-test-id="error-message"
            v-text="errorMessage"
          />
        </template>
        <slot
          v-else
          name="description"
        />
      </div>
    </div>
    <slot name="buttons">
      <template v-if="!isEmpty(actions)">
        <div class="mt-3 mb-4">
          <template v-for="(button, index) in actions">
            <RouterLink
              v-if="button.to"
              :key="button.text"
              :to="button.to"
            >
              <ui-button
                :face="button.face || FACES.fill"
                :class="{ 'ml-3': index > 0 }"
                narrow
              >
                {{ button.text }}
              </ui-button>
            </RouterLink>
            <ui-button
              v-else-if="button.callback"
              :key="button.text"
              :face="button.face || FACES.fill"
              :class="{ 'ml-3': index > 0 }"
              narrow
              @clickbutton="button.callback"
            >
              {{ button.text }}
            </ui-button>
          </template>
        </div>
      </template>
    </slot>
  </div>
</template>

<style lang="scss">
.EmptyStateComponent {
  &__content {
    max-width: 500px;

    h2 {
      line-height: 1.3;
    }
  }

  &__image {
    height: 100%;
    max-height: 165px;
  }
}
</style>
