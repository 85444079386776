var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "OverviewCollapse w-100" },
    [
      _c(
        "div",
        {
          staticClass:
            "OverviewCollapse__header d-flex flex-wrap align-items-center py-3",
        },
        [
          _c(
            "div",
            { staticClass: "OverviewCollapse__header-info flex-grow-1" },
            [_vm._t("header")],
            2
          ),
          _c(
            "div",
            { staticClass: "Overview__actions" },
            [
              _c(
                "ui-button",
                {
                  attrs: {
                    face: _vm.FACES.outline,
                    color: _vm.GRAYSCALE.inkLight,
                  },
                  on: { click: _vm.toggleCollapse },
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex align-items-center" },
                    [
                      _vm._v(" " + _vm._s(_vm.isOpen ? "Hide" : "Show") + " "),
                      _c("ui-icon", {
                        staticClass: "ml-1",
                        attrs: {
                          icon: _vm.isOpen
                            ? _vm.ICONS.arrowUp
                            : _vm.ICONS.arrowDown,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._t("actions"),
            ],
            2
          ),
        ]
      ),
      _c("hr", {
        staticClass: "emobg-border-top-1 emobg-border-color-ground-light",
      }),
      _c(
        "MuiCollapse",
        {
          ref: _vm.COLLAPSE_REF_NAME,
          staticClass: "OverviewCollapse__content",
          attrs: { open: _vm.isOpen },
        },
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }