const startsWith = require('lodash/startsWith');
const get = require('lodash/get');
const webUtils = require('@emobg/web-utils');

const DEVICE_USER_COOKIE = 'device_token_';
const DEVICE_TOKEN_HEADER = 'user-device-token';
const TOKEN_IDP_UUID = 'sub';
const sanitizeIdpUuid = idpUuid => {
  const pipeIndex = idpUuid.indexOf('|');
  return idpUuid.substr(pipeIndex + 1);
};

exports.DEVICE_USER_COOKIE = DEVICE_USER_COOKIE;
exports.DEVICE_TOKEN_HEADER = DEVICE_TOKEN_HEADER;
exports.getIdpUuidFromAuthToken = token => {
  const { payload: tokenDecoded } = webUtils.decodeJWT(token) || {};
  const idpUuid = get(tokenDecoded, TOKEN_IDP_UUID, '');
  return sanitizeIdpUuid(idpUuid);
};
exports.sanitizeIdpUuid = sanitizeIdpUuid;
exports.allowCookiesHandler = cookie => startsWith(cookie, DEVICE_USER_COOKIE);
