var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "TableSearchBoxComponent w-50 mx-2" },
    [
      _c("ui-text-input", {
        attrs: {
          value: _vm.modelValue,
          "icon-left": _vm.ICONS.search,
          reverse: "",
          name: "search",
          placeholder: "Search in table",
        },
        on: { changevalue: (event) => _vm.searchData(event.detail) },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }