var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "Daypilot",
        "w-100",
        "border-top-0 border-bottom-01 border-right-01 border-left-01",
        "border-color-ground",
        `js-${_vm.id}`,
      ],
    },
    [_c("section", { attrs: { id: _vm.id } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }