var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "TableFiltersComponent d-flex flex-column emobg-border-1 emobg-background-color-ground-lightest emobg-border-color-ground-light mr-2",
      staticStyle: { flex: "0 0 360px" },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex justify-content-between emobg-background-color-ground-lighter emobg-border-bottom-4 emobg-border-color-ground-light py-3 px-2",
        },
        [
          _c("span", { staticClass: "font-weight-bold" }, [_vm._v("Filters")]),
          _c(
            "span",
            {
              staticClass:
                "cursor-pointer emobg-color-primary font-weight-bold",
              on: { click: _vm.clearFilters },
            },
            [_vm._v(" Clear ")]
          ),
        ]
      ),
      _vm._l(_vm.filters, function (filterItem, index) {
        return [
          _c(
            _vm.FILTERS_MAP[filterItem.type],
            _vm._b(
              {
                key: filterItem.props.attributeName,
                tag: "Component",
                attrs: { "model-value": _vm.filtersApplied[index].value },
                on: {
                  "update:modelValue": (newValues) =>
                    _vm.onUpdateModelValueFilterComponent(newValues, index),
                },
              },
              "Component",
              filterItem.props,
              false
            )
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }