var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "BreadcrumbsComponent emobg-font-weight-semibold d-flex align-content-center justify-content-start",
    },
    [
      _c(
        "ul",
        { staticClass: "d-flex" },
        _vm._l(_vm.getBreadcrumbs(), function (url, index) {
          return _c(
            "li",
            { key: index },
            [
              !_vm.getIsMatchingUrl(url.link) && !_vm.isLastItem(index)
                ? _c(
                    "RouterLink",
                    {
                      staticClass: "emobg-link-primary emobg-body-2",
                      attrs: { to: url && url.link },
                    },
                    [_vm._v(" " + _vm._s(_vm.routeLabel(url)) + " ")]
                  )
                : _c(
                    "span",
                    { staticClass: "emobg-color-black emobg-body-2" },
                    [_vm._v(" " + _vm._s(_vm.routeLabel(url)) + " ")]
                  ),
              !_vm.getIsMatchingUrl(url.link) && !_vm.isLastItem(index)
                ? _c("span", { staticClass: "mr-1 emobg-color-primary" }, [
                    _vm._v("/"),
                  ])
                : _vm._e(),
            ],
            1
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }