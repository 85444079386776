import { createCustomStore } from '@emobg/web-utils';
import { backOffice } from '@emobg/web-api-client';

const menuItemsModule = createCustomStore(({ runAsyncFlow }) => ({
  namespaced: true,
  state: {
    isSidebarExpanded: false,
    menuItemOpen: undefined,
  },
  getters: {
    isSidebarExpanded(state) {
      return state.isSidebarExpanded;
    },
  },
  mutations: {
    setSidebarExpanded(state, payload) {
      state.isSidebarExpanded = payload;
    },
    setMenuItemOpen(state, payload) {
      state.menuItemOpen = payload;
    },
  },
  actions: {
    async getMenuItems({ commit }, params) {
      await runAsyncFlow(commit, {
        request: backOffice.app.getMenu,
        params: [params],
      });
    },
    setSidebarExpanded({ commit }, state) {
      commit('setSidebarExpanded', state);
    },
    setMenuItemOpen({ commit }, payload) {
      commit('setMenuItemOpen', payload);
    },
  },
}));

export default menuItemsModule;
