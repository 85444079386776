var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.to && _vm.hasPermissions(_vm.linkPermissions)
    ? _c(
        "RouterLink",
        { class: _vm.classLink, attrs: { to: _vm.to, target: _vm.target } },
        [
          _vm._t("default", function () {
            return [_vm._v(" " + _vm._s(_vm.text) + " ")]
          }),
        ],
        2
      )
    : _vm.href && _vm.hasPermissions(_vm.linkPermissions)
    ? _c(
        "a",
        { class: _vm.classLink, attrs: { href: _vm.href, target: _vm.target } },
        [
          _vm._t("default", function () {
            return [_vm._v(" " + _vm._s(_vm.text) + " ")]
          }),
        ],
        2
      )
    : _c(
        "span",
        { class: _vm.classText },
        [
          _vm._t("default", function () {
            return [_vm._v(" " + _vm._s(_vm.text) + " ")]
          }),
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }