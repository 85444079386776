var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "DragFile",
      attrs: { "data-test-id": "drag_file-component" },
      on: {
        dragover: function ($event) {
          $event.preventDefault()
          return _vm.$emit("dragOver", true)
        },
        dragleave: function ($event) {
          return _vm.$emit("dragOver", false)
        },
        dragend: function ($event) {
          return _vm.$emit("dragOver", false)
        },
      },
    },
    [
      _c("input", {
        ref: "input",
        staticClass: "invisible position-absolute",
        attrs: {
          type: "file",
          "data-test-id": "file-input",
          accept: _vm.extensions,
        },
        on: {
          change: _vm.onInputChange,
          click: function ($event) {
            $event.stopPropagation()
          },
        },
      }),
      _c("div", {
        staticClass: "DragFile__drop-area position-absolute",
        attrs: { "data-test-id": "drop_area" },
        on: {
          drop: function ($event) {
            $event.preventDefault()
            return _vm.onDrop.apply(null, arguments)
          },
          dragenter: function ($event) {
            $event.preventDefault()
          },
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }