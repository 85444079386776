import '../types';
import { HOURS_PER_DAY, MINUTES_PER_HOUR } from '@emobg/web-utils';
import { getStart } from '@/components/WeekSchedule/utils/consecutiveMinutes/consecutiveMinutes';
import { isIntervalOverlap } from '../isIntervalOverlap/isIntervalOverlap';
/**
 * Inserts an interval in the specified day of the Schedule
 * @param {Interval} interval
 * @param {string} day key as a week day name in English
 * @param {Schedule} schedule
 */
export const insertOrderedInterval = (interval, day, schedule) => {
  const selectedDay = schedule[day];
  const { length } = selectedDay;
  if (!length) {
    selectedDay.push(interval);
  } else {
    let index = 0;
    // protect loop to not iterate infinitely
    while (index < length && index < MINUTES_PER_HOUR * HOURS_PER_DAY) {
      const currentInterval = selectedDay[index];
      const isOverlapping = isIntervalOverlap(interval, currentInterval);
      if (isOverlapping) {
        throw new Error('interval overlap');
      }
      if (getStart(currentInterval) >= getStart(interval)) {
        selectedDay.splice(index, 0, interval);
        break;
      }
      if (index === length - 1) {
        selectedDay.push(interval);
      }
      index += 1;
    }
  }
};
