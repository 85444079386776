const crm = Object.freeze({
  agents: 'Domains/CRM/Agents',
  company: 'Domains/CRM/Company',
  payouts: 'Domains/CRM/Payouts',
  roles: 'Domains/CRM/Roles',
  fleetSegment: 'Domains/CRM/FleetSegment',
  costAllocations: 'Domains/CRM/CostAllocations',
  employees: 'Domains/CRM/Employees',
  users: 'Domains/CRM/Users',
  companyHolidays: 'Domains/CRM/CompanyHolidays',
  circles: 'Domains/CRM/Circles',
  whitelistedDomains: 'Domains/CRM/WhitelistedDomains',
  legalDocuments: 'Domains/CRM/LegalDocuments',
  operatorSettings: 'Domains/CRM/CsOperatorSettings',
  identityTypes: 'Domains/CRM/IdentityTypes',
});

export default crm;
