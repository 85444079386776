<script>
export default {
  model: {
    prop: 'show',
    event: 'change',
  },
  props: {
    title: {
      type: String,
      default: 'Filters',
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    hide() {
      this.$emit('change', false);
    },
  },
};
</script>

<template>
  <div
    :class="[
      'MobileFiltersWrapperComponent d-flex flex-column d-md-none',
      {
        'MobileFiltersWrapperComponent--show': show,
      }
    ]"
  >
    <div class="MobileFiltersWrapperComponent__header w-100">
      <div
        class="icons-arrow-back font-xl"
        @click="hide()"
      />
      <div class="w-100 text-center emobg-title-3">
        {{ title }}
      </div>
    </div>
    <div class="MobileFiltersWrapperComponent__filters flex-fill w-100">
      <div class="p-4 row no-gutters">
        <slot name="filters" />
      </div>
    </div>
  </div>
</template>
