<script>
export default {
  name: 'RemoveButton',
};
</script>
<template>
  <ui-button
    :color="COLORS.danger"
    class="RemoveButton"
    v-bind="$attrs"
    narrow
    @clickbutton="$emit('click')"
  >
    Remove
  </ui-button>
</template>
