<script>
import get from 'lodash/get';
import has from 'lodash/has';
import isFunction from 'lodash/isFunction';
import TableContentComponent from './TableContentComponent';

export default {
  name: 'TableContentWrapperComponent',
  components: {
    TableContentComponent,
  },
  props: {
    schemaItem: {
      type: Object,
      required: true,
    },
    dataItem: {
      type: [Object, Array],
      required: true,
    },
    dataIndex: {
      type: Number,
      required: true,
    },
    isCollapsed: {
      type: Boolean,
      default: false,
    },
    hasTooltip: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tooltipText() {
      let text = '';
      const hasSchemaTooltip = get(this.schemaItem, 'tooltip', true);
      const hasSchemaEllipsis = get(this.schemaItem, 'ellipsis', true);

      if (!this.hasTooltip || !hasSchemaEllipsis || !hasSchemaTooltip) {
        return text;
      }

      if (has(this.schemaItem, 'tooltipText') && isFunction(this.schemaItem.tooltipText)) {
        text = this.schemaItem.tooltipText(this.dataItem);
      } else {
        const templateText = isFunction(this.schemaItem.template)
          ? this.schemaItem.template(this.dataItem)
          : this.dataItem[this.schemaItem.template];
        text = this.schemaItem.component
          ? get(this.$refs, 'content.$el.innerText')
          : templateText;
      }

      return text;
    },
  },
};
</script>

<template>
  <div class="TableContentWrapperComponent w-100">
    <label
      v-if="isCollapsed"
      class="d-block emobg-label-1 mb-1 mb-sm-2"
    >
      {{ schemaItem.header }}
    </label>
    <ui-tooltip
      v-if="tooltipText"
      :tooltip="tooltipText"
      class="d-block w-100"
    >
      <TableContentComponent
        ref="content"
        :data-item="dataItem"
        :data-index="dataIndex"
        :schema-item="schemaItem"
      />
    </ui-tooltip>
    <template v-else>
      <TableContentComponent
        ref="content"
        :data-item="dataItem"
        :data-index="dataIndex"
        :schema-item="schemaItem"
      />
    </template>
  </div>
</template>
