var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "SelectItemBadge w-100 d-flex align-items-center justify-content-between",
    },
    [
      _c("span", { staticClass: "emobg-font-weight-semibold" }, [
        _vm._v(_vm._s(_vm.get(_vm.item, "label"))),
      ]),
      _c("ui-badge", {
        attrs: {
          color: _vm.get(
            _vm.mapColors,
            _vm.get(_vm.item, _vm.pathValue),
            _vm.defaultColor
          ),
          circle: "",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }