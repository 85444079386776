import { getEnd, getStart } from '@/components/WeekSchedule/utils/consecutiveMinutes/consecutiveMinutes';
import { validateTime } from '../validateTime/validateTime';
import { ERRORS } from '../const/errors';
import '../types';
/**
 * @param {Interval} interval
 */
export const validateInterval = interval => {
  validateTime(getStart(interval));
  validateTime(getEnd(interval));
  if (getEnd(interval) <= getStart(interval)) {
    throw new Error(ERRORS.intervalOrder);
  }
};
