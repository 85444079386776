import Cookie from 'js-cookie';
import { COOKIE_NAMES } from '@emobg/web-utils';
import { SHARED_URLS } from '../RouterMap';

export const redirectGuard = (_to, from, next) => {
  if (from.path === SHARED_URLS.home) {
    const returnUrl = Cookie.get(COOKIE_NAMES.returnUrl);
    const path = returnUrl && returnUrl !== SHARED_URLS.home ? returnUrl : SHARED_URLS.dashboard;
    next({ path });
  }
};
