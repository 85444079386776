import get from 'lodash/get';
import moment from 'moment-timezone';
import { DATE_FORMAT } from '@emobg/web-utils';
import { CARSHARING_PERMISSIONS } from '@domains/Carsharing/const/permissions';
import { CRM_PERMISSIONS } from '@domains/CRM/const/permissions';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { ACTIONS } from '@domains/Carsharing/Vehicles/Details/Interactions/const';
import { VEHICLE_DETAILS_SCOPES } from '@domains/Carsharing/Vehicles/Details/store/VehiclesDetailsModule';
import { VEHICLE_DAMAGES_SCOPES } from '@domains/Carsharing/Vehicles/VehicleDamages/store/VehicleDamagesModule';
import { MENU_OPERATORS } from '@/constants/menuOperators';
import carsharing from './CarsharingRouterMap';
import fleet from './FleetRouterMap';

const DetailsView = () => import(/* webpackChunkName: "carsharing-vehicle" */'../Vehicles/Details/DetailsView.vue');
const DetailsLogsView = () => import(/* webpackChunkName: "carsharing-vehicle" */'../Vehicles/Details/Logs/DetailsLogsView.vue');
const LogsFuelView = () => import(/* webpackChunkName: "carsharing-vehicle" */'../Vehicles/Details/Logs/Fuel/LogsFuelView.vue');
const TractionBatteryView = () => import(/* webpackChunkName: "carsharing-vehicle" */'../Vehicles/Details/Logs/TractionBattery/TractionBatteryView.vue');
const LogsEventsView = () => import(/* webpackChunkName: "carsharing-vehicle" */'../Vehicles/Details/Logs/Events/LogsEventsView.vue');
const ConnectivityView = () => import(/* webpackChunkName: "carsharing-vehicle" */'../Vehicles/Details/Logs/Connectivity/ConnectivityView.vue');
const ChargingStatusView = () => import(/* webpackChunkName: "carsharing-vehicle" */'../Vehicles/Details/Logs/ChargingStatus/ChargingStatusView');
const LocationRequestsView = () => import(/* webpackChunkName: "carsharing-vehicle" */'../Vehicles/Details/Logs/VehicleLocationRequests/LocationRequestsView');
const DetailsDamagesView = () => import(/* webpackChunkName: "carsharing-vehicle" */'../Vehicles/Details/Damages/DetailsDamagesView.vue');
const DetailsInteractionsView = () => import(/* webpackChunkName: "carsharing-vehicle" */'../Vehicles/Details/Interactions/DetailsInteractionsView.vue');
const DetailsBookingsView = () => import(/* webpackChunkName: "carsharing-vehicle" */'../Vehicles/Details/Bookings/DetailsBookingsView.vue');
const DetailsCasesView = () => import(/* webpackChunkName: "carsharing-vehicle" */'../Vehicles/Details/Cases/DetailsCasesView.vue');
const DetailsCommentsView = () => import(/* webpackChunkName: "carsharing-vehicle" */'../Vehicles/Details/Comments/DetailsCommentsView.vue');
const DetailsSetupView = () => import(/* webpackChunkName: "carsharing-vehicle" */'../Vehicles/Details/Setup/DetailsSetupView.vue');

const BookingsView = () => import(/* webpackChunkName: "carsharing-booking" */'../Bookings/BookingsView.vue');
const BookingManagementView = () => import(/* webpackChunkName: "carsharing-booking" */'../Bookings/Management/BookingManagementView.vue');
const BookingDetailsView = () => import(/* webpackChunkName: "carsharing-booking" */'../Bookings/Details/BookingDetailsView.vue');
const BookingDetailsEvents = () => import(/* webpackChunkName: "carsharing-booking" */'../Bookings/Details/Events/BookingDetailsEventsView.vue');
const BookingDetailsInvoices = () => import(/* webpackChunkName: "carsharing-booking" */'../Bookings/Details/Invoices/BookingDetailsInvoicesView.vue');
const BookingDetailsAudits = () => import(/* webpackChunkName: "carsharing-booking" */'../Bookings/Details/Audits/BookingDetailsAuditView.vue');
const BookingDetailsCases = () => import(/* webpackChunkName: "carsharing-booking" */'../Bookings/Details/Cases/BookingDetailsCasesView.vue');
const BookingDetailsComments = () => import(/* webpackChunkName: "carsharing-booking" */'../Bookings/Details/Comments/DetailsCommentsView.vue');
const BookingDetailsAdditionalCosts = () => import(/* webpackChunkName: "carsharing-booking" */'../Bookings/Details/AdditionalCosts/BookingDetailsAdditionalCostsView.vue');
const BookingDetailsFrauds = () => import(/* webpackChunkName: "carsharing-booking" */'../Bookings/Details/Frauds/BookingDetailsFraudsView.vue');

const CarsharingView = () => import(/* webpackChunkName: "carsharing" */'../CarsharingView.vue');

const VehiclesView = () => import(/* webpackChunkName: "carsharing" */'../Vehicles/VehiclesView.vue');
const DamagesView = () => import(/* webpackChunkName: "carsharing" */'../Damages/DamagesView.vue');
const AvailabilityView = () => import(/* webpackChunkName: "carsharing-availability" */'../Availability/AvailabilityView.vue');
const LongDistanceView = () => import(/* webpackChunkName: "carsharing-availability" */'../Availability/components/LongDistance/LongDistanceView.vue');
const UnavailabilitiesView = () => import(/* webpackChunkName: "carsharing-availability" */'../Availability/components/Unavailabilities/UnavailabilitiesView.vue');
const VehicleAllocationView = () => import(/* webpackChunkName: "carsharing-availability" */'../Availability/components/VehicleAllocation/VehicleAllocationView.vue');
const BookingSetsView = () => import(/* webpackChunkName: "booking-sets" */'../BookingSets/BookingSetsView.vue');
const BookingSetsListView = () => import(/* webpackChunkName: "booking-sets" */'../Availability/components/BookingSets/BookingSetsListView.vue');
const SelectVehiclesView = () => import(/* webpackChunkName: "booking-sets" */'../BookingSets/components/SelectVehicles/SelectVehiclesView.vue');
const GeneralInformation = () => import(/* webpackChunkName: "booking-sets" */'../BookingSets/components/GeneralInformation/GeneralInformation.vue');
const OccurrencesView = () => import(/* webpackChunkName: "booking-sets" */'../BookingSets/components/Occurrences/OccurrencesView.vue');
const PlanningView = () => import(/* webpackChunkName: "carsharing" */'../Planning/PlanningView.vue');

const PrebookingsView = () => import(/* webpackChunkName: "prebookings" */'../Prebookings/PrebookingsView');
const PrebookingsListView = () => import(/* webpackChunkName: "prebookings" */'../Prebookings/List/PrebookingsListView');

const DamageView = () => import(/* webpackChunkName: "damages" */'../Vehicles/VehicleDamages/DamageView');
const DamageReportView = () => import(/* webpackChunkName: "damages" */'../Vehicles/VehicleDamages/DamageReportView');

export default [
  {
    path: 'carsharing',
    name: carsharing.index,
    component: CarsharingView,
    redirect: { name: carsharing.bookings.index },
    meta: {
      label: 'Carsharing',
      operators: [MENU_OPERATORS.isCarsharing],
    },
    children: [
      {
        path: 'bookings',
        name: carsharing.bookings.index,
        component: BookingsView,
        meta: {
          label: 'Bookings',
          permissions: [CARSHARING_PERMISSIONS.viewCarsharingBookings],
        },
      },
      {
        path: 'bookings/management',
        name: carsharing.bookings.management,
        component: BookingManagementView,
        meta: {
          label: 'Booking Management',
          permissions: [CARSHARING_PERMISSIONS.viewCarsharingBookings],
        },
      },
      {
        path: 'bookings/:bookingUuid',
        name: carsharing.bookings.detail.index,
        redirect: { name: carsharing.bookings.detail.events },
        component: BookingDetailsView,
        props: true,
        meta: {
          label: 'Booking Details',
          permissions: [CARSHARING_PERMISSIONS.viewCarsharingBookings],
          title: {
            storeDomain: DOMAINS_MODEL.carsharing.bookings.details,
            template: data => `Booking #${get(data, 'id', '')}`,
          },
        },
        children: [
          {
            path: 'events',
            name: carsharing.bookings.detail.events,
            component: BookingDetailsEvents,
            meta: {
              label: 'Events',
              permissions: [CARSHARING_PERMISSIONS.viewCarsharingBookings],
            },
          },
          {
            path: 'invoices',
            name: carsharing.bookings.detail.invoices,
            component: BookingDetailsInvoices,
            meta: {
              label: 'Invoices',
              permissions: [CARSHARING_PERMISSIONS.viewCarsharingBookings],
            },
          },
          {
            path: 'audits',
            name: carsharing.bookings.detail.audits,
            component: BookingDetailsAudits,
            meta: {
              label: 'Audits',
              permissions: [CARSHARING_PERMISSIONS.viewCarsharingBookings],
            },
          },
          {
            path: 'cases',
            name: carsharing.bookings.detail.cases,
            component: BookingDetailsCases,
            meta: {
              label: 'Cases',
              permissions: [CARSHARING_PERMISSIONS.viewCarsharingBookings],
            },
          },
          {
            path: 'comments',
            name: carsharing.bookings.detail.comments,
            component: BookingDetailsComments,
            meta: {
              label: 'Comments',
              permissions: [CARSHARING_PERMISSIONS.viewCarsharingBookings],
            },
          },
          {
            path: 'additional-costs',
            name: carsharing.bookings.detail.additionalCosts,
            component: BookingDetailsAdditionalCosts,
            meta: {
              label: 'Additional costs',
              permissions: [CARSHARING_PERMISSIONS.viewCarsharingBookings],
            },
          },
          {
            path: 'fraud',
            name: carsharing.bookings.detail.fraud,
            component: BookingDetailsFrauds,
            meta: {
              label: 'Fraud',
              permissions: [CARSHARING_PERMISSIONS.viewCarsharingBookings],
            },
          },
          {
            path: 'vehicle-interactions',
            name: carsharing.bookings.detail.vehicleInteractions,
            component: DetailsInteractionsView,
            meta: {
              label: 'Vehicle interactions',
              action: ACTIONS.INVALIDATE_KEYS,
              permissions: [CARSHARING_PERMISSIONS.viewCarsharingBookings],
            },
          },
        ],
      },
      {
        path: 'booking-sets',
        name: carsharing.bookingSets.index,
        redirect: { name: carsharing.bookingSets.list },
        meta: {
          label: 'Booking sets',
        },
        component: BookingSetsView,
        children: [
          {
            path: 'create',
            name: carsharing.bookingSets.create,
            component: GeneralInformation,
            meta: {
              label: 'Create new booking set',
            },
          },
          {
            path: ':bookingSetUuid/general-information',
            name: carsharing.bookingSets.generalInformation,
            component: GeneralInformation,
            meta: {
              label: 'General information',
            },
          },
          {
            path: ':bookingSetUuid/select-vehicles',
            name: carsharing.bookingSets.selectVehicles,
            component: SelectVehiclesView,
            meta: {
              label: 'Select vehicles',
            },
          },
          {
            path: ':bookingSetUuid/occurrences',
            name: carsharing.bookingSets.occurrences,
            component: OccurrencesView,
            meta: {
              label: 'Occurrences',
            },
          },
        ],
      },
      {
        path: 'planning',
        name: carsharing.planning,
        component: PlanningView,
        meta: {
          label: 'Planning',
        },
      },
      {
        path: 'prebookings',
        component: PrebookingsView,
        meta: {
          label: 'Prebookings',
        },
        children: [
          {
            path: '/',
            name: carsharing.prebookings.index,
            component: PrebookingsListView,
            meta: {
              label: '',
              title: 'Prebookings',
            },
          },
        ],
      },
    ],
  },
  {
    path: 'fleet',
    name: fleet.index,
    component: CarsharingView,
    redirect: { name: fleet.vehicles.index },
    meta: {
      label: 'Carsharing',
    },
    children: [
      {
        path: 'vehicles',
        name: fleet.vehicles.index,
        component: VehiclesView,
        meta: {
          label: 'Vehicles',
          permissions: [CARSHARING_PERMISSIONS.viewCarsharingVehicles],
        },
      },
      {
        path: 'damages',
        name: fleet.vehicles.damages,
        component: DamagesView,
        meta: {
          label: 'Damages',
          permissions: [CARSHARING_PERMISSIONS.viewCarsharingDamages],
        },
      },
      {
        path: 'vehicles/:vehicle_uuid/details',
        name: fleet.vehicles.detail.details,
        component: DetailsView,
        meta: {
          label: 'Vehicle details',
          permissions: [CARSHARING_PERMISSIONS.viewCarsharingVehicles],
          title: {
            storeDomain: DOMAINS_MODEL.carsharing.vehicle.details.information,
            scope: VEHICLE_DETAILS_SCOPES.general,
            template: data => `${get(data, 'license_plate', '')} ${get(data, 'model.brand', '')} ${get(data, 'model.name', '')}`,
          },
        },
        children: [
          {
            path: 'setup',
            name: fleet.vehicles.detail.setup,
            component: DetailsSetupView,
            meta: {
              label: 'Setup',
              permissions: [CARSHARING_PERMISSIONS.viewCsVehicleSetup],
            },
          },
          {
            path: 'interactions',
            name: fleet.vehicles.detail.interactions,
            component: DetailsInteractionsView,
            meta: {
              label: 'Interactions',
              permissions: [CARSHARING_PERMISSIONS.viewCsVehicleInteractions],
            },
          },
          {
            path: 'bookings',
            name: fleet.vehicles.detail.bookings,
            component: DetailsBookingsView,
            meta: {
              label: 'Bookings',
              permissions: [CARSHARING_PERMISSIONS.viewCarsharingBookings],
            },
          },
          {
            path: 'logs',
            name: fleet.vehicles.detail.logs.index,
            component: DetailsLogsView,
            meta: {
              label: 'Logs',
            },
            children: [
              {
                path: 'events',
                name: fleet.vehicles.detail.logs.event,
                component: LogsEventsView,
                meta: {
                  label: 'Events',
                  permissions: [CARSHARING_PERMISSIONS.viewCsVehicleLogs],
                },
              },
              {
                path: 'fuel-log',
                name: fleet.vehicles.detail.logs.fuelLog,
                component: LogsFuelView,
                meta: {
                  label: 'Fuel log',
                  permissions: [CARSHARING_PERMISSIONS.viewCsVehicleLogs],
                },
              },
              {
                path: 'traction-battery-log',
                name: fleet.vehicles.detail.logs.tractionBatteryLog,
                component: TractionBatteryView,
                meta: {
                  label: 'Traction battery log',
                  permissions: [CARSHARING_PERMISSIONS.viewCsVehicleLogs],
                },
              },
              {
                path: 'connectivity',
                name: fleet.vehicles.detail.logs.connectivity,
                component: ConnectivityView,
                meta: {
                  label: 'Connectivity',
                  permissions: [CARSHARING_PERMISSIONS.viewCsVehicleLogs],
                },
              },
              {
                path: 'charging-status',
                name: fleet.vehicles.detail.logs.chargingStatus,
                component: ChargingStatusView,
                meta: {
                  label: 'Charging status',
                  permissions: [CARSHARING_PERMISSIONS.viewCsVehicleLogs],
                },
              },
              {
                path: 'vehicle-location-requests',
                name: fleet.vehicles.detail.logs.vehicleLocationRequests,
                component: LocationRequestsView,
                meta: {
                  label: 'Vehicle location requests',
                  permissions: [CARSHARING_PERMISSIONS.viewCsVehicleLogs],
                },
              },
            ],
          },
          {
            path: 'cases',
            name: fleet.vehicles.detail.cases,
            component: DetailsCasesView,
            meta: {
              label: 'Cases',
              permissions: [CRM_PERMISSIONS.viewCrmCases],
            },
          },
          {
            path: 'comments',
            name: fleet.vehicles.detail.comments,
            component: DetailsCommentsView,
            meta: {
              label: 'Comments',
              permissions: [CARSHARING_PERMISSIONS.viewCsVehicleComments],
            },
          },
          {
            path: 'damages',
            name: fleet.vehicles.detail.damages,
            component: DetailsDamagesView,
            props: true,
            meta: {
              label: 'Damages',
              permissions: [CARSHARING_PERMISSIONS.viewCarsharingDamages],
            },
          },
        ],
      },
      {
        path: 'availability',
        name: fleet.availability.index,
        component: AvailabilityView,
        redirect: { name: fleet.availability.unavailabilities },
        meta: {
          label: 'Availability',
        },
        children: [
          {
            path: 'vehicle-allocation',
            name: fleet.availability.vehicleAllocation,
            component: VehicleAllocationView,
            meta: {
              label: 'Vehicle allocation',
              permissions: [CARSHARING_PERMISSIONS.viewCarsharingAvailability],
            },
          },
          {
            path: 'long-distance',
            name: fleet.availability.longDistance,
            component: LongDistanceView,
            meta: {
              label: 'Long distance',
              permissions: [CARSHARING_PERMISSIONS.viewCarsharingAvailability],
            },
          },
          {
            path: 'unavailabilities',
            name: fleet.availability.unavailabilities,
            component: UnavailabilitiesView,
            meta: {
              label: 'Unavailabilities',
              permissions: [CARSHARING_PERMISSIONS.viewCarsharingAvailability],
            },
          },
          {
            path: 'booking-sets',
            name: carsharing.bookingSets.list,
            component: BookingSetsListView,
            meta: {
              label: 'Booking sets',
              permissions: [CARSHARING_PERMISSIONS.viewCarsharingAvailability],
            },
          },
        ],
      },
      {
        path: 'vehicles/:vehicleUuid/vehicle-damages',
        component: DamageView,
        props: true,
        meta: {
          label: 'Vehicle details',
          permissions: [CARSHARING_PERMISSIONS.viewCarsharingDamages],
        },
        name: fleet.damages.index,
        redirect: to => ({ name: fleet.vehicles.detail.damages, params: { vehicle_uuid: to.params.vehicleUuid } }),
        children: [
          {
            path: 'create',
            props: true,
            name: fleet.damages.create,
            component: DamageReportView,
            meta: {
              label: 'Report new damage',
            },
          },
          {
            path: ':damageUuid',
            props: true,
            name: fleet.damages.edit,
            component: DamageReportView,
            meta: {
              label: 'Edit damage',
              title: {
                storeDomain: DOMAINS_MODEL.carsharing.vehicleDamages,
                scope: VEHICLE_DAMAGES_SCOPES.damage,
                template: data => `Damage reporting date ${moment(get(data, 'reportingDate', '')).format(DATE_FORMAT.dob)}`,
              },
            },
          },
        ],
      },
    ],
  },
];
