<script>
import { getEnd, getStart } from '../utils/consecutiveMinutes/consecutiveMinutes';

export default {
  props: {
    interval: {
      type: String,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getStart,
    getEnd,
  },
};
</script>
<template>
  <div class="d-flex align-items-center">
    <time
      :datetime="getStart(interval)"
      class="WeekSchedule__DayIntervals__time flex-fill ml-1"
    >
      {{ getStart(interval) }}
    </time>

    <time
      :datetime="getEnd(interval)"
      class="WeekSchedule__DayIntervals__time flex-fill mx-2"
    >
      {{ getEnd(interval) }}
    </time>

    <ui-button
      :disabled="isDisabled"
      :color="GRAYSCALE.inkLight"
      :face="FACES.text"
      :square="true"
      class="ml-4"
      @clickbutton="$emit('delete-interval', interval)"
    >
      <ui-icon
        :icon="ICONS.minus"
        :size="SIZES.small"
      />
    </ui-button>
  </div>
</template>
