<script>
import get from 'lodash/get';
import filter from 'lodash/filter';
import remove from 'lodash/remove';
import delay from 'lodash/delay';
import size from 'lodash/size';
import each from 'lodash/each';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import throttle from 'lodash/throttle';
import isNil from 'lodash/isNil';
import floor from 'lodash/floor';
import map from 'lodash/map';
import head from 'lodash/head';
import mapKeys from 'lodash/mapKeys';
import find from 'lodash/find';
import concat from 'lodash/concat';

import { mapGetters, mapState } from 'vuex';
import { DELAY } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import googleMapMixin from './googleMapMixin';
import {
  DRAWING_EVENTS,
  SEARCH_LOCATION_ZOOM,
  ZONE_DEFAULTS,
  ZONE_TYPES,
} from './const/zone.const';
import { GOOGLE_MAP_STYLES } from './const/googleMapStyle.const';

export default {
  name: 'GoogleMapZonesComponent',
  mixins: [googleMapMixin],
  props: {
    zones: {
      type: [Array, Object],
      default: () => ({}),
    },
    fillOpacity: {
      type: Number,
      default: ZONE_DEFAULTS.fillOpacity,
    },
    fillColor: {
      type: String,
      default: ZONE_DEFAULTS.fillColor,
    },
    strokeColor: {
      type: String,
      default: ZONE_DEFAULTS.strokeColor,
    },
    strokeWeight: {
      type: Number,
      default: ZONE_DEFAULTS.strokeWeight,
    },
    strokeOpacity: {
      type: Number,
      default: ZONE_DEFAULTS.strokeOpacity,
    },
    isDraggable: {
      type: Boolean,
      default: ZONE_DEFAULTS.isDraggable,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: String,
      default: '',
    },
    mode: {
      type: String,
      default: ZONE_TYPES.polygon,
    },
    isStatic: {
      type: Boolean,
      default: false,
    },
    radius: {
      type: Number,
      default: 10,
    },
    address: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      markers: [],
      polygons: [],
      circles: [],
      polylines: [],
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      countryCode: state => get(state, 'operators.countryCode', '').toLowerCase(),
    }),
    ...mapGetters(DOMAINS_MODEL.app.userAccount, ['activeOperator']),
  },
  watch: {
    markerSources(_newMarkers, oldMarkers) {
      oldMarkers.forEach(location => {
        filter(this.markers, { uuid: location.uuid }).forEach(item => item.setMap(null));
        remove(this.markers, { uuid: location.uuid });
      });

      this.processMarkers();
      this.renderMap();
    },
    mode: {
      immediate: true,
      async handler(newMode) {
        await delay(() => this.initDrawingManager(newMode), DELAY.short);
      },
    },
    zones(newZone, oldZone) {
      if (!size(newZone[0])) {
        return;
      }

      this.processZones(!isEmpty(newZone[0]));

      const isZoneValid = !isEmpty(newZone[0]) && !!newZone[0].name && !!newZone[0].color && !!newZone[0].type;

      if ((this.zones.length === 1 && isZoneValid) || !isEqual(newZone, oldZone)) {
        this.googleInstance.maps.event.trigger(this.map, 'resize');
        this.renderMap(!this.isStatic);
      }
    },
    address(newAddress) {
      this.setMapCenter(newAddress, SEARCH_LOCATION_ZOOM);
    },
    edit() {
      const shapes = concat(this.polygons, this.circles, this.polylines);
      each(shapes, shape => {
        shape.setEditable(false);
        shape.setDraggable(false);
      });

      if (this.edit) {
        const zoneToEdit = find(shapes, (shape) => shape.id === this.edit || shape.name === this.edit);
        const { type } = zoneToEdit;

        zoneToEdit.setEditable(true);
        zoneToEdit.setDraggable(true);

        if (type === ZONE_TYPES.polygon) {
          this.googleInstance.maps.event.addListener(zoneToEdit.getPath(), DRAWING_EVENTS.setAt, () => {
            this.$emit('update-shape', { polygon: this.getPolygonData(zoneToEdit) });
          });

          this.googleInstance.maps.event.addListener(zoneToEdit.getPath(), DRAWING_EVENTS.insertAt, () => {
            this.$emit('update-shape', { polygon: this.getPolygonData(zoneToEdit) });
          });

          this.googleInstance.maps.event.addListener(zoneToEdit.getPath(), DRAWING_EVENTS.removeAt, () => {
            this.$emit('update-shape', { polygon: this.getPolygonData(zoneToEdit) });
          });
        } else if (type === ZONE_TYPES.circle) {
          this.googleInstance.maps.event.addListener(zoneToEdit.getPath(), DRAWING_EVENTS.radiusChanged, () => {
            this.$emit('update-shape', { circle: this.getCircleData(zoneToEdit) });
          });
        }
      } else {
        this.processZones(true);
      }
    },
    activeOperator() {
      this.geolocate();
    },
  },
  beforeDestroy() {
    const instance = this.googleInstance.maps.event;
    this.googleInstance.maps.event.clearInstanceListeners(instance);
    this.googleInstance.maps.event.clearInstanceListeners(this.map);
  },
  created() {
    this.$watch(vm => [vm.mode, vm.isEditing], async ([mode]) => {
      await delay(() => this.initDrawingManager(mode), DELAY.short);
    });
  },
  methods: {
    initGoogle() {
      this.googleInstance = this.$GoogleMaps.getInstance();
      this.bounds = new this.googleInstance.maps.LatLngBounds();
      const element = this.$refs[`gMap${this.mapId}`];
      this.map = new this.googleInstance.maps.Map(element, {
        gestureHandling: this.gestureHandling,
        mapTypeControl: this.mapTypeControl,
        streetViewControl: this.streetViewControl,
        fullscreenControl: this.fullScreenControl,
        styles: GOOGLE_MAP_STYLES,
      });

      this.drawingManager = new this.googleInstance.maps.drawing.DrawingManager({
        drawingMode: this.mode,
        drawingControl: true,
        drawingControlOptions: {
          position: this.googleInstance.maps.ControlPosition.TOP_RIGHT,
          drawingModes: [this.mode],
        },
        polygonOptions: {
          editable: true,
          draggable: true,
        },
        circleOptions: {
          editable: true,
          draggable: true,
        },
      });

      if (this.isStatic) {
        this.map.addListener('zoom_changed', throttle(
          () => {
            const zoomLevel = this.map.getZoom();
            this.$emit('zoomLevel', zoomLevel);
          }, DELAY.long,
          {
            maxWait: DELAY.short,
            trailing: true,
          },
        ));

        this.map.addListener('dragend', throttle(
          () => {
            const center = this.map.getCenter();
            const mapCenter = {
              lat: center.lat(),
              lng: center.lng(),
            };

            this.$emit('center', mapCenter);
          }, DELAY.short,
          {
            maxWait: DELAY.long,
            trailing: true,
          },
        ));
      }
    },
    async renderMap(preserveState = false) {
      this.processZones(preserveState);
      this.processMarkers();

      if (!preserveState) {
        this.geolocate();
      }
    },
    removeShapeOverlays() {
      this.overlays.map(item => item.setMap(null));
      this.overlays = [];
    },
    initDrawingManager(newMode) {
      this.drawingManager.setMap(null);
      this.removeShapeOverlays();

      if (!this.isStatic && this.isEditing) {
        this.drawingManager.setDrawingMode(null);

        this.drawingManager.setOptions(
          {
            drawingMode: newMode,
            drawingControl: true,
            drawingControlOptions: {
              position: this.googleInstance.maps.ControlPosition.TOP_RIGHT,
              drawingModes: [newMode],
            },
          },
        );

        this.drawingManager.setMap(this.map);
      }

      this.googleInstance.maps.event.addListener(this.drawingManager, DRAWING_EVENTS.overlayComplete,
        shape => {
          this.overlays.push(shape.overlay);
          const { type } = shape;
          if (type === ZONE_TYPES.polygon) {
            this.$emit('add-shape', { polygon: this.getPolygonData(shape.overlay) });
            this.googleInstance.maps.event.addListener(shape.overlay.getPath(), DRAWING_EVENTS.setAt, () => {
              this.$emit('add-shape', { polygon: this.getPolygonData(shape.overlay) });
            });

            this.googleInstance.maps.event.addListener(shape.overlay.getPath(), DRAWING_EVENTS.insertAt, () => {
              this.$emit('add-shape', { polygon: this.getPolygonData(shape.overlay) });
            });

            this.googleInstance.maps.event.addListener(shape.overlay.getPath(), DRAWING_EVENTS.removeAt, () => {
              this.$emit('add-shape', { polygon: this.getPolygonData(shape.overlay) });
            });
          } else if (type === ZONE_TYPES.circle) {
            this.$emit('add-shape', { circle: this.getCircleData(shape.overlay) });
          }
          this.drawingManager.setMap(null);
        });
    },
    processZones(preserveState = false) {
      each(this.polygons, polygon => polygon.setMap(null));
      each(this.circles, circle => circle.setMap(null));
      each(this.polylines, polyline => polyline.setMap(null));
      this.polygons = [];
      this.circles = [];
      this.polylines = [];

      const filteredZones = filter(this.zones, zone => zone && (!isNil(zone.polygon) || !isNil(zone.circle) || !isNil(zone.polyline)));
      each(filteredZones, zone => {
        const baseZone = {
          strokeColor: get(zone, 'color', this.strokeColor),
          strokeOpacity: this.strokeOpacity,
          strokeWeight: this.strokeWeight,
          fillColor: get(zone, 'color', this.fillColor),
          fillOpacity: this.fillOpacity,
          draggable: this.isDraggable,
        };
        const ZONE_ACTIONS = Object.freeze({
          polygon: this.drawPolygon(baseZone, zone),
          circle: this.drawCircle(baseZone, zone),
          polyline: this.drawPolyline(zone),
        });
        let zoneType = '';
        // if/else implementation instead of ternary due to it's a compliant solution for sonar. Nested ternary operator is a non compliant solution for sonar.
        if (zone.polygon) {
          zoneType = ZONE_TYPES.polygon;
        } else if (zone.circle) {
          zoneType = ZONE_TYPES.circle;
        } else if (zone.polyline) {
          zoneType = ZONE_TYPES.polyline;
        }

        return ZONE_ACTIONS[zoneType];
      });

      if (!preserveState) {
        this.map.panToBounds(this.bounds);
        this.map.fitBounds(this.bounds);
      }
    },
    attachZoneInfoWindow(zone) {
      // eslint-disable-next-line no-param-reassign
      zone.info = new this.googleInstance.maps.InfoWindow({
        content: zone.tooltip,
      });

      this.googleInstance.maps.event.addListener(zone, 'mouseover', () => {
        const zoneCenter = (zone.type === ZONE_TYPES.polygon || zone.type === ZONE_TYPES.polyline)
          ? this.getPolyformCenter(zone)
          : zone.getCenter();
        if (zoneCenter) {
          zone.info.setPosition(zoneCenter);
        }
        zone.info.open(this.map);
      });

      this.googleInstance.maps.event.addListener(zone, 'mouseout', () => {
        zone.info.close(this.map);
      });
    },
    getPolyformCenter(polyform) {
      const bounds = new this.googleInstance.maps.LatLngBounds();
      const polyformPath = polyform.getPath();
      polyformPath.forEach(path => bounds.extend(path));
      return bounds.getCenter();
    },
    drawCircle(baseZone, zone) {
      if (isNil(zone.circle) || isNil(zone.circle.center)) {
        return;
      }

      const circle = new this.googleInstance.maps.Circle({
        ...baseZone,
        center: this.getCenter(zone),
        radius: get(zone, 'circle.radius', this.radius),
        id: zone.id || zone.name,
        stationId: zone.stationId,
        color: zone.color,
        name: zone.name,
        tooltip: zone.name,
        type: ZONE_TYPES.circle,
        map: this.map,
      });

      this.attachZoneInfoWindow(circle);

      this.circles.push(circle);

      this.bounds.union((circle.getBounds()));

      if (circle.id === this.edit) {
        circle.setOptions({
          fillOpacity: 0.05,
        });

        this.googleInstance.maps.event
          .addListener(this.drawingManager, DRAWING_EVENTS.overlayComplete, () => {
            circle.setOptions({
              fillOpacity: 0,
              strokeOpacity: 0,
            });
          });
      } else {
        circle.setEditable(false);
        circle.setOptions({
          fillOpacity: this.fillOpacity,
        });
      }

      if (!this.isStatic) {
        const updateCircle = event => circle.addListener(event, () => {
          if (!circle.editable) {
            circle.setEditable(true);
          } else {
            circle.setEditable(false);

            this.$emit('update-shape', {
              ...zone,
              circle: {
                center: {
                  latitude: circle.getCenter().lat(),
                  longitude: circle.getCenter().lng(),
                },
                radius: floor(circle.getRadius()),
              },
            });
          }
        });

        updateCircle('click');
      }
    },
    drawPolygon(baseZone, zone) {
      if (isNil(zone.polygon)) {
        return;
      }

      const polygon = new this.googleInstance.maps.Polygon({
        ...baseZone,
        paths: this.getCoordinates(zone.polygon),
        id: zone.id || zone.name,
        stationId: zone.stationId,
        color: zone.color,
        name: zone.name,
        tooltip: zone.name,
        type: ZONE_TYPES.polygon,
        map: this.map,
      });
      const showInfoWindow = get(zone, 'showInfoWindow', true);

      if (showInfoWindow) {
        this.attachZoneInfoWindow(polygon);
      }

      this.polygons.push(polygon);

      this.locatePolyform(zone.polygon);

      if (!this.isStatic) {
        if (polygon.id === this.edit) {
          polygon.setOptions({
            fillOpacity: 0.05,
          });

          this.googleInstance.maps.event
            .addListener(this.drawingManager, DRAWING_EVENTS.overlayComplete, () => {
              polygon.setOptions({
                fillOpacity: 0,
                strokeOpacity: 0,
              });
            });
        } else {
          polygon.setEditable(false);
          polygon.setOptions({
            fillOpacity: this.fillOpacity,
          });
        }

        polygon.addListener('click', () => {
          if (!polygon.editable) {
            polygon.setEditable(true);
          } else {
            polygon.setEditable(false);

            this.$emit('update-shape', {
              ...zone,
              polygon: this.getPolygonData(polygon),
            });
          }
        });
      }
    },
    drawPolyline(zone) {
      if (isNil(zone.polyline)) {
        return;
      }

      const polyline = new this.googleInstance.maps.Polyline({
        path: this.getCoordinates(zone.polyline),
        id: zone.id || zone.name,
        color: zone.color,
        tooltip: zone.tooltip,
        strokeColor: get(zone, 'color', this.strokeColor),
        strokeOpacity: get(zone, 'strokeOpacity', this.strokeOpacity),
        strokeWeight: get(zone, 'strokeWeight', this.strokeWeight),
        type: ZONE_TYPES.polyline,
        map: this.map,
      });

      this.polylines.push(polyline);

      this.locatePolyform(zone.polyline);

      this.attachZoneInfoWindow(polyline);
    },
    getCenter: zone => ({
      lat: get(zone, 'circle.latitude') || get(zone, 'circle.center.latitude', 0),
      lng: get(zone, 'circle.longitude') || get(zone, 'circle.center.longitude', 0),
    }),
    getCoordinates: polyform => map(polyform, item => mapKeys(item, (value, key) => ((key === 'latitude' || key === 'lat') ? 'lat' : 'lng'))),
    locatePolyform(coordinates) {
      const points = this.getCoordinates(coordinates);
      each(points, point => this.bounds.extend(point));
    },
    processMarkers() {
      if (!this.markerSources) {
        return;
      }

      this.markerSources.forEach(this.processMarkerCoordinates);
    },
    addLocation(location) {
      const marker = new this.googleInstance.maps.Marker(location);
      marker.info = new this.googleInstance.maps.InfoWindow({
        content: location.tooltip,
      });

      this.googleInstance.maps.event.addListener(marker, 'mouseover', () => {
        marker.info.open(this.map, marker);
      });

      this.googleInstance.maps.event.addListener(marker, 'mouseout', () => {
        marker.info.close(this.map, marker);
      });

      marker.setMap(this.map);
      this.markers.push(marker);
      this.bounds.extend(marker.getPosition());
      this.map.panToBounds(this.bounds);
      this.map.fitBounds(this.bounds);
    },
    getPolygonData(shape) {
      const pathArray = shape.getPath().getArray();

      const points = map(pathArray, point => ({
        latitude: point.lat(),
        longitude: point.lng(),
      }));

      return [...points, head(points)];
    },
    getCircleData(shape) {
      return {
        center: {
          latitude: shape.getCenter().lat(),
          longitude: shape.getCenter().lng(),
        },
        radius: floor(shape.getRadius()),
      };
    },
    setCenterAndZoom(location, zoom) {
      delay(() => {
        this.map.setCenter(location);
        this.map.setZoom(zoom);
      }, DELAY.tiny);
    },
  },
};
</script>

<template>
  <div class="GoogleMapComponent bg-color-ground">
    <div
      :ref="`gMap${mapId}`"
      class="GoogleMapComponent__holder"
    >
      `gMap${mapId}`
    </div>
  </div>
</template>
