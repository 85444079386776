var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ui-button",
    _vm._g(
      _vm._b(
        {
          staticClass: "FullscreenButton",
          attrs: {
            color: _vm.GRAYSCALE.inkLight,
            face: _vm.FACES.outline,
            square: "",
          },
        },
        "ui-button",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c("ui-icon", {
        staticClass: "emobg-font-weight-bold",
        attrs: { size: _vm.ICONS_SIZES.small, icon: _vm.fullscreenIcon },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }