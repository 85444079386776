<script>
import get from 'lodash/get';
import marked from 'marked';
import { mapState } from 'vuex';
import { DATE_FORMAT, reformatDateTime } from '@emobg/web-utils';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { Actions } from '@/components';

export default {
  name: 'CommentComponent',
  components: {
    Actions,
  },
  props: {
    comment: {
      type: Object,
      required: true,
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      currentUserUuid: state => state.user.data.uuid,
      timezone: state => get(state, 'operators.active.timezone'),
    }),
    isEditable() {
      return this.currentUserUuid === get(this, 'comment.reporter.uuid');
    },
    dateText() {
      const dateFormatted = reformatDateTime(get(this, 'comment.createdAt'), DATE_FORMAT.defaultExtended, this.timezone);
      const isEditedText = get(this, 'comment.createdAt') !== get(this, 'comment.updatedAt') ? '(Edited)' : '';
      return `${dateFormatted} ${isEditedText}`;
    },
    bookingRate() {
      const rate = get(this.comment, 'bookingRate');
      return rate ? `Booking rating ${rate}/5` : null;
    },
  },
  created() {
    this.actions = [
      {
        label: 'Edit comment',
        action: () => this.$emit('commentToEdit', this.comment),
        labelClass: 'emobg-font-weight-semibold px-1',
      },
      {
        label: 'Delete comment',
        action: () => this.$emit('commentToDelete', this.comment),
        labelClass: 'emobg-color-danger emobg-font-weight-semibold px-1',
      },
    ];
  },
  methods: {
    get,
    marked,
  },
};
</script>
<template>
  <div class="CommentComponent">
    <div class="d-flex justify-content-between align-items-center pb-2 mb-1">
      <div class="d-flex flex-column">
        <span class="emobg-color-ink-light pb-1"> {{ dateText }} </span>

        <div class="d-flex align-items-center">
          <span class="emobg-font-weight-semibold">
            {{ get(comment, 'reporter.firstName') }} {{ get(comment, 'reporter.lastName') }}
          </span>
          <ui-badge
            v-if="bookingRate"
            :color="GRAYSCALE.ground"
            class="ml-1"
          >
            {{ bookingRate }}
          </ui-badge>
        </div>
      </div>
      <Actions
        v-if="isEditable"
        :actions="actions"
        list-align="bottom-left"
      />
    </div>
    <div
      class="CommentsList__markdown"
      v-html="marked(get(comment, 'body', ''))"
    />
  </div>
</template>
