export const CONNECTIVITY_STATUS = {
  good: 'good',
  weak: 'weak',
  veryWeak: 'veryWeak',
  noSignal: 'noSignal',
};
export const CONNECTIVITY_COLORS = {
  [CONNECTIVITY_STATUS.good]: 'emobg-color-success',
  [CONNECTIVITY_STATUS.weak]: 'emobg-color-moderate',
  [CONNECTIVITY_STATUS.veryWeak]: 'emobg-color-danger',
  [CONNECTIVITY_STATUS.noSignal]: 'emobg-color-ink-light',
};
export const CONNECTIVITY_TOOLTIPS = {
  [CONNECTIVITY_STATUS.good]: 'Interactions should work well',
  [CONNECTIVITY_STATUS.weak]: 'Interactions should still work but could be delayed or may fail',
  [CONNECTIVITY_STATUS.veryWeak]: 'Interactions unlikely to work',
  [CONNECTIVITY_STATUS.noSignal]: 'No connectivity data received from the vehicle',
};
