var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "CommentsComponent row" },
    [
      _vm.isLoading
        ? _c("ui-loader", { staticClass: "col-12 pt-6 center" })
        : [
            _c("div", { staticClass: "col-12" }, [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.content,
                    expression: "content",
                  },
                ],
                class: [
                  "w-100 border-radius mb-3 p-3 font-m color-ink-light",
                  `border-color-${_vm.isValid ? "ground" : "danger"}`,
                ],
                attrs: { rows: "5" },
                domProps: { value: _vm.content },
                on: {
                  blur: function ($event) {
                    return _vm.restorePlaceholder(_vm.content)
                  },
                  focus: function ($event) {
                    return _vm.removePlaceholder(_vm.content)
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.content = $event.target.value
                  },
                },
              }),
              _c(
                "div",
                { staticClass: "d-flex justify-content-end" },
                [
                  !_vm.isSubmitDisabled || _vm.isEditing
                    ? _c(
                        "ui-button",
                        {
                          staticClass: "mr-3",
                          attrs: {
                            face: _vm.FACES.text,
                            color: _vm.GRAYSCALE.inkLight,
                          },
                          on: { clickbutton: _vm.cancelUpdate },
                        },
                        [_vm._v(" Cancel ")]
                      )
                    : _vm._e(),
                  !_vm.isEditing && _vm.canComment
                    ? _c(
                        "ui-button",
                        {
                          attrs: {
                            disabled: _vm.isSubmitDisabled || _vm.isLoading,
                          },
                          on: {
                            clickbutton: function ($event) {
                              return _vm.addCommentEvent(_vm.content)
                            },
                          },
                        },
                        [_vm._v(" Submit ")]
                      )
                    : _vm._e(),
                  _vm.isEditing
                    ? _c(
                        "ui-button",
                        {
                          on: {
                            clickbutton: function ($event) {
                              return _vm.updateCommentEvent(_vm.content)
                            },
                          },
                        },
                        [_vm._v(" Update ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _vm._l(_vm.comments.slice(0, _vm.length), function (comment) {
                  return _c("div", { key: _vm.getCommentUuid(comment) }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "border-bottom-01 pb-2 mb-1 border-color-ground color-ink",
                      },
                      [
                        _c(
                          "p",
                          {
                            staticClass:
                              "emobg-font-small emobg-color-ink-light pt-2",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.formatUtc(
                                    comment.updatedAt || comment.createdAt,
                                    _vm.DATE_FORMAT.defaultExtended
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "p",
                          { staticClass: "pt-2 emobg-font-weight-bold" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.getReporterFullName(comment)) +
                                " "
                            ),
                          ]
                        ),
                        _c("div", { staticClass: "row pt-3" }, [
                          _c("div", {
                            staticClass: "col",
                            domProps: { innerHTML: _vm._s(comment.body) },
                          }),
                          _vm.isAuthor(comment)
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "col-auto d-flex justify-content-between",
                                },
                                [
                                  _vm.canEdit
                                    ? _c(
                                        "ui-button",
                                        {
                                          staticClass: "mr-3",
                                          attrs: {
                                            face: _vm.FACES.text,
                                            square: true,
                                          },
                                          on: {
                                            clickbutton: function ($event) {
                                              _vm.editCommentEvent(
                                                comment.body,
                                                _vm.getCommentUuid(comment)
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("ui-icon", {
                                            attrs: {
                                              icon: _vm.ICONS.edit,
                                              size: _vm.SIZES.small,
                                              color: _vm.GRAYSCALE.inkLight,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.canRemove
                                    ? _c(
                                        "ui-button",
                                        {
                                          attrs: {
                                            face: _vm.FACES.text,
                                            square: true,
                                          },
                                          on: {
                                            clickbutton: function ($event) {
                                              return _vm.deleteCommentEvent(
                                                comment
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("ui-icon", {
                                            attrs: {
                                              color: _vm.GRAYSCALE.inkLight,
                                              icon: _vm.ICONS.trash,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                      ]
                    ),
                  ])
                }),
                _vm.comments.length > _vm.limit
                  ? _c(
                      "div",
                      {
                        class: [
                          "link pt-2",
                          `${
                            _vm.isOverLimit ? _vm.ICONS.plus : _vm.ICONS.minus
                          }`,
                        ],
                        on: { click: _vm.toggleMoreLess },
                      },
                      [
                        _vm._v(
                          " Show " +
                            _vm._s(_vm.isOverLimit ? "more" : "less") +
                            " "
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              2
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }