var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "WeekSchedule d-block mx-0 px-0 container" },
    [
      _c("InputInterval", {
        staticClass: "WeekSchedule__InputInterval",
        attrs: {
          "day-options": _vm.dayOptions,
          errors: _vm.errors,
          "week-scheduler": _vm.weekScheduler,
        },
        on: { "add-time-intervals": _vm.addTimeIntervals },
      }),
      _vm._l(_vm.dayIntervals, function (dayInterval) {
        return _c(
          "DayIntervals",
          _vm._b(
            {
              key: dayInterval.day,
              staticClass: "WeekSchedule__DayIntervals",
              attrs: {
                "is-disabled": _vm.isDisabled,
                "min-duration": _vm.minDuration * 60,
              },
              on: {
                "delete-interval": function ($event) {
                  return _vm.deleteInterval($event, dayInterval.day)
                },
              },
            },
            "DayIntervals",
            dayInterval,
            false
          )
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }