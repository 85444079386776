import { INITIAL_STATUS } from '@emobg/web-utils';
import {
  includes,
  indexOf,
  isNil,
  set,
} from 'lodash';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';

/**
 * To override the setError mutations to throw an error notification
 * @param {Object} state - State of the store
 * @param {*} payload = {} - Payload of the mutation
 * @param {array} scopes = [] - Array of scopes were the toast will be thrown. Adding 'null' value to execute it into global scope.
 * @return {function} The setError you will override on mutations section.
 */
export function toastError(state, payload = {}, scopes = []) {
  const { scope, value } = payload;
  const isInScopes = includes(scopes, scope);
  const isInGeneralScope = isNil(scope) && indexOf(scopes, null) > -1;
  if (isInScopes || isInGeneralScope) {
    this.commit(DOMAINS_MODEL.app.messages.errors.throwError, value || payload, { root: true });
  } else {
    const errorPath = scope ? `${scope}.error` : 'error';
    const errorValue = value || payload;
    set(state, errorPath, errorValue);
  }
}

export function clearErrors(state, payload) {
  const { scope } = payload;
  const path = scope ? `${scope}.` : '';
  set(state, `${path}error`, null);
  set(state, `${path}STATUS`, { ...INITIAL_STATUS });
}
