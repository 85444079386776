export const CRM_PERMISSIONS = {
  viewSettingsAgents: 'view_settings_agents',
  viewCrmUsers: 'view_crm_users',
  viewCrmCases: 'view_crm_cases',
  viewCrmCompanies: 'view_crm_companies',
  viewSettingsRoles: 'view_settings_roles',
  viewMappingCategories: 'map_vehicle_categories',
  viewFleetSegments: 'view_fleet_segments',
  manageFleetSegments: 'manage_fleet_segments',
  viewSettingsBookingRules: 'view_settings_booking_rules',
};

