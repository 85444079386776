var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.isEmpty()
      ? _c("span", { attrs: { "data-test-id": "empty" } })
      : _c(
          "div",
          { staticClass: "py-1 emobg-color-ink" },
          _vm._l(_vm.files, function (file, index) {
            return _c(
              "li",
              {
                key: _vm.fileKey(file),
                staticClass:
                  "d-flex align-items-center justify-content-between mt-2",
              },
              [
                _c(
                  "div",
                  { staticClass: "d-flex align-items-center" },
                  [
                    _vm.isPdf(file.name)
                      ? _c("ui-icon", {
                          attrs: {
                            size: _vm.ICONS_SIZES.small,
                            color: _vm.GRAYSCALE.inkLight,
                            icon: _vm.ICONS.filePdf,
                          },
                        })
                      : _vm._e(),
                    _vm.isImage(file.name)
                      ? _c("ui-icon", {
                          attrs: {
                            size: _vm.ICONS_SIZES.small,
                            color: _vm.GRAYSCALE.inkLight,
                            icon: _vm.ICONS.fileImage,
                          },
                        })
                      : _vm._e(),
                    _c(
                      "span",
                      {
                        staticClass: "px-1",
                        attrs: { "data-test-id": "file-name" },
                      },
                      [_vm._v(" " + _vm._s(file.name) + " ")]
                    ),
                  ],
                  1
                ),
                _vm.deleteable
                  ? _c("ui-icon", {
                      staticClass: "cursor-pointer pt-1",
                      attrs: {
                        size: _vm.ICONS_SIZES.xSmall,
                        color: _vm.GRAYSCALE.inkLight,
                        icon: _vm.ICONS.removeFilled,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.onDeleteItem(file, index)
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          }),
          0
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }