import get from 'lodash/get';
import last from 'lodash/last';
import replace from 'lodash/replace';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';

const NotificationsPlugin = {
  install(Vue, store) {
    const notificationPath = replace(DOMAINS_MODEL.app.messages.notifications.index, /\//g, '.');

    Vue.mixin({
      beforeCreate() {
        /**
         * Global method that provides the mutation to push notifications of different types.
         * @param {Object} payload - The payload for adding new notification
         * @param {Object} payload.delay - The delay to close the notification.
         * @param {Object} payload.message - The message of the notification
         * @param {Object} payload.type - The type of the notification (success, info, warning, error)
         * @param {Object} payload.textAction - Text of the action. If empty string or null it will be closed automatically.
         * @param {Object} payload.isFloating - Show notification on the floating notification list
         */
        this.$notify = payload => {
          store.commit(DOMAINS_MODEL.app.messages.notifications.notify, payload);
          const notificationState = get(store, `state.${notificationPath}.notifications`, []);
          return last(notificationState);
        };
      },
    });
  },
};

export default NotificationsPlugin;
