var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "RefinementList p-3 emobg-border-bottom-1 emobg-border-color-ground",
    },
    [
      _c("div", { staticClass: "d-flex justify-content-between" }, [
        _c("span", {
          staticClass: "font-weight-bold",
          domProps: { innerHTML: _vm._s(_vm.title) },
        }),
        _c(
          "span",
          {
            staticClass: "cursor-pointer emobg-color-ink-light",
            on: {
              click: function ($event) {
                _vm.isFilterOpened = !_vm.isFilterOpened
              },
            },
          },
          [_vm._v(" " + _vm._s(_vm.isFilterOpened ? "Hide" : "Show") + " ")]
        ),
      ]),
      _vm.isFilterOpened
        ? _c(
            "MuiCollapse",
            { attrs: { open: _vm.isFilterOpened } },
            [
              _vm.showMore
                ? _c("MuiInputText", {
                    staticClass: "mt-3",
                    attrs: {
                      name: "search",
                      placeholder: "Search filter",
                      "icon-to-right": "",
                      icon: "search",
                    },
                    model: {
                      value: _vm.search,
                      callback: function ($$v) {
                        _vm.search = $$v
                      },
                      expression: "search",
                    },
                  })
                : _vm._e(),
              _c(
                "div",
                { staticClass: "py-3" },
                _vm._l(_vm.optionsFiltered, function (option) {
                  return _c(
                    "div",
                    {
                      key: option.value,
                      staticClass:
                        "d-flex justify-content-between align-items-center",
                    },
                    [
                      _c("MuiCheckbox", {
                        attrs: {
                          "model-value": _vm.internalSelected,
                          size: _vm.SIZES.small,
                          label: option.label,
                          value: option.value,
                        },
                        on: {
                          change: (newSelected) =>
                            _vm.$emit("update:modelValue", newSelected),
                        },
                      }),
                      _c("ui-badge", {
                        attrs: {
                          color: _vm.GRAYSCALE.ground,
                          text: option.count,
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
              _vm.options.length > _vm.showMoreLimit
                ? _c(
                    "span",
                    {
                      staticClass:
                        "emobg-font-weight-semibold emobg-font-small emobg-color-primary cursor-pointer mb-2",
                      on: { click: _vm.onClickShowMore },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.showMore ? "- Show less" : "+ Show more") +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }