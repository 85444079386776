import FeedbackDialog from './FeedbackDialog/FeedbackDialogModule';
import MenuItems from './MenuItems/MenuItemsModule';
import Messages from './Messages/MessagesModule';
import Omnisearch from './Omnisearch/OmnisearchModule';
import Permissions from './Permissions/PermissionsModule';
import { UserAccount } from './UserAccount/UserAccountModule';
import Comments from './Comments/CommentsModule';

export default {
  namespaced: true,
  modules: {
    FeedbackDialog,
    MenuItems,
    Messages,
    UserAccount,
    Omnisearch,
    Permissions,
    Comments,
  },
};
