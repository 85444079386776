const carsharing = Object.freeze({
  vehicles: 'Domains/Carsharing/Vehicles',
  vehicle: {
    details: {
      information: 'Domains/Carsharing/VehiclesDetail',
      comments: 'Domains/Carsharing/VehiclesDetail/Comments',
      damages: 'Domains/Carsharing/VehiclesDetail/Damages',
      interactions: 'Domains/Carsharing/VehiclesDetail/Interactions',
      setup: 'Domains/Carsharing/VehiclesDetail/Setup',
      logs: 'Domains/Carsharing/VehiclesDetail/Logs',
    },
  },
  availability: {
    vehicleAllocations: {
      list: 'Domains/Carsharing/Availability/VehicleAllocationsList',
      detail: 'Domains/Carsharing/Availability/VehicleAllocationDetail',
    },
    longDistance: {
      list: 'Domains/Carsharing/Availability/LongDistanceList',
      detail: 'Domains/Carsharing/Availability/LongDistanceDetail',
    },
    unavailability: {
      list: 'Domains/Carsharing/Availability/UnavailabilityList',
      detail: 'Domains/Carsharing/Availability/UnavailabilityDetail',
    },
  },
  additionalCosts: 'Domains/Carsharing/AdditionalCosts',
  bookingSets: {
    information: 'Domains/Carsharing/BookingSets',
  },
  cities: 'Domains/Carsharing/Cities',
  planning: {
    list: 'Domains/Carsharing/Planning',
    create: 'Domains/Carsharing/Planning/Create',
    edit: 'Domains/Carsharing/Planning/Edit',
  },
  prebookings: 'Domains/Carsharing/Prebookings',
  bookings: {
    details: 'Domains/Carsharing/BookingDetails',
  },
  bookingMangement: 'Domains/Carsharing/BookingManagement',
  vehicleDamages: 'Domains/Carsharing/VehicleDamages',
});

export default carsharing;
