import remove from 'lodash/remove';
import { LOG_TYPE, logger } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';

/**
 * @todo Refactor this with new approach of notificationsModule or remove it
 * in order to do it the same way.
 * https://europcarmobility.atlassian.net/browse/CBF-987
 */

/**
 * Function to be imported and used inside store modules
 * to add new errors to the error store. This is sintactic
 *  sugar on top of commiting a mutation.
 * @param {function} commit
 * @param {object} errorObj
 */
export const throwError = (commit, errorObj) => {
  commit(DOMAINS_MODEL.app.messages.errors.throwError, errorObj, { root: true });
};

export const logException = exception => {
  logger.message(
    'Error should have a translation key as "key" attribute.',
    LOG_TYPE.error,
  );
  if (exception) {
    logger.message(exception, LOG_TYPE.error);
  }
};

const defaultError = {
  key: 'Common.Errors.default',
  severity: 'error',
  data: {},
};

export default {
  namespaced: true,
  state: {
    nextErrorId: 1,
    errors: [],
  },
  mutations: {
    add(state, payload) {
      try {
        const { key, status } = payload;
        if (!key) {
          logException();
        }

        const error = {
          ...defaultError,
          ...payload,
          id: state.nextErrorId,
          status,
        };
        state.errors.push(error);
        state.nextErrorId += 1;
        return error;
      } catch (exception) {
        logException(exception);
      }
      return false;
    },
    remove(state, id) {
      remove(state.errors, { id });
    },
    removeAll(state) {
      state.errors.splice(0);
    },
  },
};
