import indexOf from 'lodash/indexOf';
import '../types';
import { validateDay } from '../validateDay/validateDay';
import { validateInterval } from '../validateInterval/validateInterval';

/**
   * @returns {Interval[]}
   * @param {Interval} interval
   * @param {number} day ranging from 0-6
   * @param {Schedule} schedule
*/
export const removeIntervalToSchedule = (interval, day, schedule) => {
  validateDay(day);
  validateInterval(interval);
  const indexToRemove = indexOf(schedule[day], interval);
  return indexToRemove !== -1
    ? schedule[day].splice(indexToRemove, 1)
    : [];
};
