const services = Object.freeze({
  index: 'services',
  devices: 'services.devices',
  map: 'services.locationMap',
  alertsTrigger: {
    index: 'services.alertsTrigger.list',
    new: 'services.alertsTrigger.new',
    details: 'services.alertsTrigger.details',
  },
  alertsByEmail: {
    index: 'services.alertsByEmail.list',
    new: 'services.alertsByEmail.new',
    edit: 'services.alertsByEmail.edit',
    view: 'services.alertsByEmail.view',
  },
  agents: 'services.agents',
});

export default services;
