var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "FilterTableComponent position-relative p-3 emobg-border-1 emobg-border-color-ground emobg-background-color-white",
    },
    [
      _vm.isLoading
        ? _c("ui-loader", { attrs: { "data-test-id": "loader", absolute: "" } })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "d-flex align-items-center mb-2" },
        [
          _vm.filters.length
            ? _c(
                "ui-button",
                {
                  staticClass: "hydrated",
                  attrs: {
                    face: _vm.FACES.outline,
                    color: _vm.GRAYSCALE.inkLight,
                  },
                  on: {
                    clickbutton: function ($event) {
                      _setup.areFiltersVisible = !_setup.areFiltersVisible
                    },
                  },
                },
                [
                  _c("ui-icon", {
                    staticClass: "mr-1",
                    attrs: { icon: _vm.ICONS.settingsSlider },
                  }),
                  _vm._v(
                    " " +
                      _vm._s(_setup.areFiltersVisible ? "Hide" : "Show") +
                      " filters "
                  ),
                  _setup.filtersCount
                    ? _c("ui-badge", {
                        staticClass: "ml-1",
                        attrs: {
                          color: _vm.COLORS.primary,
                          text: _setup.filtersCount,
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-grow-1 align-items-center justify-content-end",
            },
            [
              _vm.searchable
                ? _c(_setup.TableSearchBoxComponent, {
                    attrs: { "model-value": _setup.search },
                    on: { "update:modelValue": _setup.setSearchDebounced },
                  })
                : _vm._e(),
              _c(_setup.TablePaginatorComponent, {
                attrs: { "total-pages": _setup.totalPages, page: _setup.page },
                on: { "update:page": _setup.setPage },
              }),
              _setup.actionsMerged.length
                ? _c(
                    "ui-dropdown",
                    [
                      _c("ui-icon", {
                        attrs: {
                          slot: "trigger",
                          icon: _vm.ICONS.optionsHorizontalFull,
                          color: _vm.GRAYSCALE.inkLight,
                        },
                        slot: "trigger",
                      }),
                      _c("ui-dropdown-actions", {
                        attrs: {
                          slot: "content",
                          "data-test-id": "dropdown-actions",
                        },
                        domProps: { actions: _setup.actionsMerged },
                        slot: "content",
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex flex-row" },
        [
          _setup.areFiltersVisible
            ? _c(_setup.TableFiltersComponent, {
                attrs: {
                  "filters-applied": _setup.filtersApplied,
                  filters: _setup.internalFilters,
                },
                on: {
                  "update:filterApplied": _setup.setSingleFiltersApplied,
                  "clear:filtersApplied": _setup.clearFiltersApplied,
                },
              })
            : _vm._e(),
          _c(
            _setup.TableComponent,
            _vm._b(
              {
                staticClass: "flex-grow-1",
                attrs: { schema: _vm.schema, data: _setup.dataToShow },
              },
              "TableComponent",
              _vm.$attrs,
              false
            )
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }