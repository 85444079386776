var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "MuiSelect",
    _vm._b(
      {
        ref: "select",
        staticClass: "w-100",
        attrs: {
          options: _vm.optionsDisplayed,
          "model-value": _vm.model,
          searchbox: {
            placeholder: "",
            customSearch: () => true,
          },
          "icon-left": "",
          "icon-right": "",
          name: "select",
        },
        on: { change: _vm.onChange },
        nativeOn: {
          input: function ($event) {
            return _vm.updateQuery.apply(null, arguments)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function ({ item }) {
              return [
                _c(
                  "div",
                  { staticClass: "d-flex align-items-center" },
                  [
                    _c("ui-icon", {
                      staticClass: "mr-1",
                      attrs: {
                        icon: _vm.ICONS.location,
                        size: _vm.ICONS_SIZES.xSmall,
                        color: _vm.COLORS.primary,
                      },
                    }),
                  ],
                  1
                ),
                _c("p", [_vm._v(" " + _vm._s(item.label) + " ")]),
              ]
            },
          },
        ]),
      },
      "MuiSelect",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }