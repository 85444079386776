<script>
export default {
  name: 'ActionsComponent',
  props: {
    actions: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<template>
  <ui-dropdown
    :placement="PLACEMENTS.left"
    class="ActionsComponent"
  >
    <ui-button
      slot="trigger"
      :color="GRAYSCALE.white"
      :face="FACES.text"
      contrast
      square
    >
      <ui-icon
        :color="GRAYSCALE.inkLight"
        :icon="ICONS.optionsHorizontalFull"
      />
    </ui-button>
    <template v-if="actions && actions.length">
      <ui-dropdown-actions
        slot="content"
        :actions.prop="actions"
      />
    </template>
    <div
      slot="content"
      class="emobg-background-color-ground-light-hover"
    >
      <slot />
    </div>
  </ui-dropdown>
</template>
