var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: () => _vm.setMonthPickerVisibility(false),
          expression: "() => setMonthPickerVisibility(false)",
        },
      ],
      staticClass: "MonthSelectorComponent mt-n2",
      attrs: { "data-test-id": "month_selector" },
    },
    [
      _c("ui-text-input", {
        attrs: {
          value: _vm.date ? _vm.date.format(_vm.format) : null,
          "icon-left": _vm.hideIcon ? null : _vm.ICONS.calendar,
          placeholder: _vm.format,
          disabled: _vm.disableTyping,
          name: "date",
          reverse: "",
          "data-test-id": "toggle_window-input",
        },
        on: {
          click: function ($event) {
            return _vm.setMonthPickerVisibility(true)
          },
        },
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isMonthPickerVisible,
              expression: "isMonthPickerVisible",
            },
          ],
          staticClass: "VehicleAllocationView__calendarWrapper",
          attrs: { "data-test-id": "vehicle_allocation_calendar_wrapper" },
        },
        [
          _c("ui-date-year-month-picker", {
            domProps: {
              selectedMonth: _vm.selectedDate.month(),
              selectedYear: _vm.selectedDate.year(),
            },
            on: { selectyearmonth: ({ detail }) => _vm.onChangeDate(detail) },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }