<script>
import { KEYCODE } from '@emobg/web-utils';

export default {
  name: 'ModalHeaderComponent',
  props: {
    title: {
      type: String,
      required: true,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    classes: {
      type: String,
      default: 'emobg-background-color-primary emobg-color-white',
    },
    isClosable: {
      type: Boolean,
      default: true,
    },
    customColors: {
      type: Object,
      default: () => ({
        headerBackground: '',
        headerColor: '',
        closeBackground: '#0086E6',
        closeColor: '',
      }),
    },
  },
  mounted() {
    window.addEventListener('keyup', this.onKeyUpEscToClose);
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.onKeyUpEscToClose);
  },
  methods: {
    onCloseAction() {
      this.$emit('close-modal');
    },
    onKeyUpEscToClose($event) {
      if ($event.keyCode === KEYCODE.ESCAPE) {
        this.onCloseAction();
      }
    },
  },
};
</script>

<template>
  <header
    class="Modal__header d-flex justify-content-between align-items-center"
    :class="classes"
    :style="{
      'background-color': customColors.headerBackground,
      'color': customColors.headerColor
    }"
  >
    <h3 class="w-100 px-3 text-ellipsis emobg-font-large">
      {{ title }}
    </h3>
    <ui-button
      v-if="isClosable"
      class="close p-2 wmin-initial cursor-pointer emobg-color-inherit"
      :class="{'emobg-background-color-primary-dark-hover': !customColors.headerBackground}"
      :color="GRAYSCALE.none"
      :face="FACES.text"
      @clickbutton="onCloseAction()"
    >
      <ui-icon
        :icon="ICONS.cross"
        :size="ICONS_SIZES.medium"
      />
    </ui-button>
  </header>
</template>
