<template>
  <div>
    <span
      v-if="isEmpty()"
      data-test-id="empty"
    />
    <div
      v-else
      class="py-1 emobg-color-ink"
    >
      <li
        v-for="(file, index) in files"
        :key="fileKey(file)"
        class="d-flex align-items-center justify-content-between mt-2"
      >
        <div class="d-flex align-items-center">
          <ui-icon
            v-if="isPdf(file.name)"
            :size="ICONS_SIZES.small"
            :color="GRAYSCALE.inkLight"
            :icon="ICONS.filePdf"
          />
          <ui-icon
            v-if="isImage(file.name)"
            :size="ICONS_SIZES.small"
            :color="GRAYSCALE.inkLight"
            :icon="ICONS.fileImage"
          />
          <span
            data-test-id="file-name"
            class="px-1"
          >
            {{ file.name }}
          </span>
        </div>
        <ui-icon
          v-if="deleteable"
          :size="ICONS_SIZES.xSmall"
          :color="GRAYSCALE.inkLight"
          :icon="ICONS.removeFilled"
          class="cursor-pointer pt-1"
          @click="onDeleteItem(file, index)"
        />
      </li>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FileListComponent',
  props: {
    files: {
      type: Array,
      default: () => [],
    },
    deleteable: {
      type: Boolean,
      default: false,
    },
    onDeleteItem: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    isEmpty() {
      return this.files.length === 0;
    },
    isImage(name) {
      return name.match(/[^/]+(jpg|png|gif)$/i);
    },
    isPdf(name) {
      return name.match(/[^/]+(pdf)$/i);
    },
    fileKey(file) {
      return file.lastModified + file.name;
    },
  },
};
</script>
