const ALGOLIA_INDEXES = Object.freeze({
  agents: 'agents',
  badges: 'badges',
  badgesDeliveries: 'badge_deliveries',
  badgeProviders: 'badge_providers',
  carsharingTags: 'carsharing_tags',
  caseEvents: 'case_events',
  caseTypes: 'case_types',
  chargePosts: 'charge_posts',
  cities: 'cities',
  circles: 'circles',
  companies: 'companies',
  companyCostAllocations: 'company_cost_allocations',
  companyPaymentMethods: 'company_payment_method',
  countries: 'countries',
  csBookings: 'cs_bookings',
  csBookingsStartDesc: 'cs_bookings_start_desc',
  csBookingSets: 'cs_booking_sets',
  csOperators: 'cs_operators',
  csTelemetryConnectivity: 'cs_telemetry_connectivity',
  csTelemetryChargingStatus: 'cs_telemetry_charging_status',
  devices: 'devices',
  deviceProviders: 'device_providers',
  drivingLicenses: 'driving_licenses',
  fuelcards: 'fuelcards',
  fleetSegments: 'fleet_segments',
  csFuelPrices: 'cs_fuel_prices',
  employees: 'employees',
  invoices: 'invoices',
  invoiceSeries: 'invoice_series',
  impersonateLogs: 'impersonate_logs',
  locations: 'locations',
  locales: 'locales',
  legalDocs: 'legal_documents',
  legalDocumentTypes: 'legal_document_types',
  prebookings: 'prebookings',
  reports: 'reports',
  roles: 'roles',
  tariffs: 'tariffs',
  tariffQuotas: 'tariff_quotas',
  titles: 'titles',
  chargeTypes: 'charge_types',
  chargePostModels: 'charge_post_models',
  payoutTransactions: 'payout_transactions',
  paymentMethods: 'payment_methods',
  paymentTransactions: 'payment_transactions',
  promotions: 'promotions',
  transceiverEvents: 'transceiver_events',
  sims: 'sims',
  status: 'status',
  users: 'users',
  userNotifications: 'user_notifications',
  userPaymentMethod: 'user_payment_method',
  userPromotion: 'user_promotion',
  vehicleDamages: 'vehicle_damages',
  vehicleBrands: 'vehicle_brands',
  vehicleCategories: 'vehicle_categories',
  vehicleLocation: 'vehicle_location',
  vehicleLocationRequests: 'vehicle_location_requests',
  vehicleModels: 'vehicle_models',
  vehicles: 'vehicles',
  vehicleAreas: 'vehicle_areas',
  vtIcsTelemetryFuel: 'vt_ics_telemetry_fuel',
  fpInvoicePlans: 'svc_fp_invoice_plans',
  fpSalesOrders: 'svc_fp_sales_orders',
  fpServiceFees: 'svc_fp_service_fees',
  fpVehicles: 'svc_fp_vehicles',
  fpInvoices: 'svc_fp_invoices',
  fpContracts: 'svc_fp_contracts',
  fpSalesOrderVehicles: 'svc_fp_sales_order_vehicles',
  services: 'services',
  taxes: 'taxes',
});

export default ALGOLIA_INDEXES;
