import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import delay from 'lodash/delay';
import concat from 'lodash/concat';
import { DELAY } from '@emobg/web-utils';
import {
  COUNTRY_COORDINATES,
  DEFAULT_COUNTRY_ZOOM,
} from './const/zone.const';

const europe = {
  lat: 54.5260,
  lng: 15.2551,
};

export default {
  props: {
    markerSources: {
      type: Array,
      default: () => [],
    },
    mapId: {
      type: String,
      default: '',
    },
    gestureHandling: {
      type: String,
      default: null,
    },
    mapTypeControl: {
      type: Boolean,
      default: true,
    },
    streetViewControl: {
      type: Boolean,
      default: true,
    },
    fullScreenControl: {
      type: Boolean,
      default: false,
    },
    zoomControl: {
      type: Boolean,
      default: false,
    },
    fullHeight: {
      type: Boolean,
      default: false,
    },
    zoom: {
      type: Number,
      default: null,
    },
    center: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      markers: [],
      googleInstance: null,
      drawingManager: null,
      map: null,
      bounds: null,
      polygons: [],
      circles: [],
      polylines: [],
      overlays: [],
    };
  },
  mounted() {
    this.initGoogle();
    delay(() => this.renderMap(), DELAY.tiny);
  },
  methods: {
    async geolocate() {
      const shapes = concat(this.polygons, this.circles, this.polylines);
      if (!isEmpty(this.markers) || !isEmpty(shapes)) {
        return;
      }
      this.map.setOptions({ maxZoom: 12 });
      const hasCenter = this.center && !!this.center.lat && !!this.center.lng;
      const hasZoom = !!this.zoom;
      const { lat, lng } = this.center || {};
      delay(() => this.map.setOptions({ maxZoom: null }), DELAY.long);

      if (hasZoom) {
        delay(() => this.map.setZoom(this.zoom), DELAY.tiny);
      }

      if (hasCenter) {
        this.setMapCenter({ lat, lng });
      } else if (navigator.geolocation) {
        this.geolocateToCountry();
        navigator.geolocation.getCurrentPosition(position => {
          const coordinates = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          this.fitBounds(coordinates);
          delay(() => this.map.setOptions({ maxZoom: null }), DELAY.tiny);
        });
      } else {
        this.geolocateToCountry();
      }
    },
    setMapCenter(center, zoom = null) {
      delay(() => {
        this.map.setCenter(center);
        if (zoom) {
          this.map.setOptions({ maxZoom: zoom });
          this.map.setZoom(zoom);
        }
      }, DELAY.short);
    },
    geolocateToCountry() {
      const countryCoordinates = COUNTRY_COORDINATES[this.countryCode] || {};
      const countryInfo = {
        ...{ coordinates: europe, zoom: DEFAULT_COUNTRY_ZOOM },
        ...countryCoordinates,
      };
      const { zoom, coordinates } = countryInfo;

      this.fitBounds(coordinates);
      delay(() => this.map.setZoom(zoom), DELAY.tiny);
    },
    processMarkerCoordinates({
      latitude,
      longitude,
      label,
      icon,
      uuid,
      itemType,
      zIndex,
      tooltip,
    }) {
      if (isNil(latitude) || isNil(longitude)) {
        return;
      }

      const position = { lat: latitude, lng: longitude };
      const marker = {
        position,
        label,
        uuid,
        itemType,
        zIndex,
        tooltip,
      };

      if (icon) {
        marker.icon = { ...icon, labelOrigin: new this.googleInstance.maps.Point(90, 20) };
      }

      this.addLocation(marker);
    },
    fitBounds(coordinates) {
      const mapOptions = {
        maxZoom: 12,
        center: coordinates,
      };

      this.map.setOptions(mapOptions);
      this.bounds = new this.googleInstance.maps.LatLngBounds();
      this.bounds.extend(coordinates);
      this.map.fitBounds(this.bounds);
    },
  },
};
